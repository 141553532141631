import React, { useCallback, useEffect, useState } from "react";
// import { Alert } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import { useAuth } from "../utils/Context";
// import Modal from "react-bootstrap/Modal";
// import { FaEdit } from "react-icons/fa";
// import { useAuth } from "../utils/Context";

const Departments = ({ menuWidth }) => {
  const { id, role_id } = useAuth();
  const [loading, setLoading] = useState(false);
  const [getMovement, setGetMovement] = useState([]);

  const [start_date, setStartDate] = useState("");
  const [end_date, setEndDate] = useState("");

  // const fetchData = useCallback(() => {
  //   setLoading(true);
  //   var requestOptions = {
  //     method: "GET",
  //     redirect: "follow",
  //   };
  //   fetch(
  //     `${process.env.REACT_APP_API_BASE_URL}/admin/moment-list?&user_id=${employee_id}&start_date=${start_date}&end_date=${end_date}`,
  //     requestOptions
  //   )
  //     .then((response) => response.json())
  //     .then((result) => {
  //       if (result.status === true) {
  //         setGetMovement(result.data);
  //       }
  //       setLoading(false);
  //     })
  //     .catch((error) => console.log("error", error));
  // },[start_date,end_date])



  // const fetchData = useCallback(() => {
  //   setLoading(true);
    
  //   let url;
  //   if (role_id === "1") {
 
  //     url = `${process.env.REACT_APP_API_BASE_URL}/admin/moment-list`;
    
  //   } else {
  //     url = `${process.env.REACT_APP_API_BASE_URL}/admin/moment-list?user_id=${id}`;
  //   }
  
  //   url += `&start_date=${start_date}&end_date=${end_date}`;
  
  //   var requestOptions = {
  //     method: "GET",
  //     redirect: "follow",
  //   };
  
  //   fetch(url, requestOptions)
  //     .then((response) => response.json())
  //     .then((result) => {
  //       if (result.status === true) {
  //         setGetMovement(result.data);
  //       }
  //       setLoading(false);
  //     })
  //     .catch((error) => {
  //       console.error("Error fetching data:", error);
  //       setLoading(false);
  //     });
  // }, [start_date, end_date, role_id, id]);
  
  // useEffect(() => {
  //   fetchData();
  // }, [start_date, end_date, role_id, id, fetchData]);
  

  useEffect(() => {
    if (role_id === '1'){
     var requestOptions = {
       method: 'GET',
       redirect: 'follow'
     };
     
     fetch(`${process.env.REACT_APP_API_BASE_URL}/admin/moment-list?&start_date=${start_date}&end_date=${end_date}`, requestOptions)
       .then(response => response.json())
       .then(result => {
         if(result.status === true) {
          setGetMovement(result.data);
         }
       })
       .catch(error => console.log('error', error));
     }
   }, [role_id,start_date,end_date]);
   
   
   useEffect(() => {
     if (role_id === '2'){
     var requestOptions = {
       method: "GET",
       redirect: "follow",
     };
   
     fetch(`${process.env.REACT_APP_API_BASE_URL}/admin/moment-list?user_id=${id}&start_date=${start_date}&end_date=${end_date}`,requestOptions)
       .then((response) => response.json())
       .then((result) => {
         if (result.status === true) {
          setGetMovement(result.data);
         }
       })
       .catch((error) => console.log("error", error));
     }
   }, [id,role_id,start_date,end_date]);






  const convertToAmPm = (time) => {
    if (time) {
        // Split the time into hours and minutes
        const [hours, minutes] = time.split(':');
        // Create a new Date object with today's date and the provided time
        const date = new Date();
        date.setHours(hours);
        date.setMinutes(minutes);
        // Format the time in AM/PM format
        const formattedTime = date.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true });
        return formattedTime;
    } else {
        return "-"; // or any other default value or error handling you prefer
    }
}; 


const reload = () => {
  window.location.reload();
};

  return (
    <section className="left_width" style={{ marginLeft: `${menuWidth}px` }}>
      <div className="d-flex justify-content-between align-items-center pt-4 mb-3 w-100">
        <h4 className="m-0">Movement</h4>
      </div>
      <div className="employee_table p-4 text-center">
        <div>


        <div className="d-lg-flex justify-content-between align-items-center">
                From
                <Form.Control type="date" className="mx-2 h-75" value={start_date}  onChange={(e) => setStartDate(e.target.value)} />
                To
                <Form.Control type="date" className="mx-2 h-75" value={end_date} onChange={(e) => setEndDate(e.target.value)} />
                <button className="btn btn-primary puch_btn" onClick={reload}>
            Reset
          </button>
              </div>
          <div className="table-responsive custom-scrollbar table-view-responsive py-primary">
            {!loading ? (
              <table className="table mb-0">
                <thead>
                  <tr>
                    <th
                      track-by="0"
                      className="datatable-th py-4 px-3 table_color_width"
                    >
                      <span className="font-size-default">
                        <span>S.No.</span>
                      </span>
                    </th>
                    <th
                      track-by="3"
                      className="datatable-th py-4 px-3 table_color text-right"
                    >
                      <span className="font-size-default">Name</span>
                    </th>
                    <th
                      track-by="3"
                      className="datatable-th py-4 px-3 table_color text-right"
                    >
                      <span className="font-size-default">Break Type</span>
                    </th>
                   
                    <th
                      track-by="3"
                      className="datatable-th py-4 px-3 table_color text-right"
                    >
                      <span className="font-size-default">Start Time</span>
                    </th>
                    <th
                      track-by="3"
                      className="datatable-th py-4 px-3 table_color text-right"
                    >
                      <span className="font-size-default">End Time</span>
                    </th>
                    <th
                      track-by="3"
                      className="datatable-th py-4 px-3 table_color text-right"
                    >
                      <span className="font-size-default">Break Note</span>
                    </th>
                    {/* <th
                      track-by="3"
                      className="datatable-th py-4 px-3 table_color text-right"
                    >
                      <span className="font-size-default">Break status</span>
                    </th> */}
                    <th
                      track-by="3"
                      className="datatable-th py-4 px-3 table_color text-right"
                    >
                      <span className="font-size-default">Break Date</span>
                    </th>
                    <th
                      track-by="3"
                      className="datatable-th py-4 px-3 table_color text-right"
                    >
                      <span className="font-size-default">Total Time</span>
                    </th>
                  </tr>
                </thead>

                {getMovement.length > 0 ? (
                  <tbody>
                    {getMovement.map((item) => (
                      <tr key={item.id}>
                        <td className="text-center">{item.sr_no}</td>
                        <td className="text-center">{item.user_id}</td>
                        <td className="text-center">{item.break_id}</td>
                        <td className="text-center">{convertToAmPm(item.start_time) || '-'}</td>
                        <td className="text-center">{convertToAmPm(item.end_time) || '-'}</td>
                        <td className="text-center">{item.start_break_note || "-"}</td>
                        <td className="text-center">{item.break_start_date || '-'}</td>
                        <td className="text-center">{item.total_time || '-'}</td>
                      </tr>
                    ))}
                  </tbody>
                ) : (
                  <tbody>
                    <tr className="text-center">
                      <td colSpan="9">
                        <span className="pt-5 pb-5">
                          <img src="/no_data.svg" alt="no data" width="150px" />
                          <h6>Nothing to show here</h6>
                          <p className="small text-secondary">
                            Please add a new entity or manage the data table to
                            see the content here <br />
                            Thank you
                          </p>
                        </span>
                      </td>
                    </tr>
                  </tbody>
                )}
              </table>
            ) : (
              <div className="spinner_height">
                <div className="loader"></div>
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Departments;
