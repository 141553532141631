import React, { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import { FiBookOpen } from "react-icons/fi";
import Select from "react-select";
import makeAnimated from "react-select/animated";
const animatedComponents = makeAnimated();

const UsersandRole = ({ menuWidth }) => {
  const [show, setShow] = useState(false);
  const [showInvite, setShowInvite] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [showRole, setShowRole] = useState(false);
  const handleClose = () => setShow(false);
  const[loading, setLoading] = useState(false);
  const handleInviteClose = () => setShowInvite(false);
  const handleEditClose = () => setShowEdit(false);
  const handleRoleClose = () => setShowRole(false);
  
  const colourOptions = [
    { value: "probtain", label: "Probtain", color: "#00B8D9", isFixed: true },
    { value: "permanent", label: "Permanent", color: "#0052CC" },
    { value: "terminated", label: "Terminated", color: "#5243AA" },
  ];

const [userRole, setUserRole] = useState([])

  useEffect(()=>{
    setLoading(true)
    var requestOptions = {
      method: 'GET',
      redirect: 'follow'
    };
    
    fetch(`${process.env.REACT_APP_API_BASE_URL}/admin/get_user_roles`, requestOptions)
      .then(response => response.json())
      .then(result =>{
        if(result.status === true) {
          setUserRole(result.data)
        }
        setLoading(false)
      })
      .catch(error => console.log('error', error));
  },[])

  return (
    <section className="left_width" style={{ marginLeft: `${menuWidth}px` }}>
      <div className="d-lg-flex justify-content-between align-items-center pt-4 mb-3 w-100 ">
        <h4 className="m-0">Users & Roles</h4>
      
      </div>
<div>
     
        <div className=" ">
          <div className="attendance p-3 ">
            <div className="d-lg-flex justify-content-between align-items-center ">
              <h5 className="m-0">Roles</h5>
            </div>
          </div>

          <div className="table-responsive custom-scrollbar table-view-responsive py-primary">
          {!loading ? 
            <table className="table mb-0">
              <thead>
                <tr>
                  <th className="datatable-th py-4 px-3 table_color ">Role name</th>
                  <th className="datatable-th py-4 px-3 table_color ">Users</th>
                </tr>
              </thead>
              {userRole.length > 0 ?
              <tbody>
              {userRole && userRole.map((item)=> 
                <tr key={item.role_id}>
                  <td>{item.role}</td>
               
                  <td>
                  {item.users.map((user)=>
                  <div key={user.id} className="d-flex aligin-items-center ">
                    <h6 className="name_width">{user.name.charAt(0)}</h6>
                    &nbsp;  &nbsp;  &nbsp;
                    <div>
                    <h6 className="m-0">{user.name}</h6>
                    <p className="small text-secondary mb-0">{user.email}</p>
                    </div>
                    </div>
                  )}
                  </td>
                  {/* <td className="rounded">
                    <FiTool  />
                  </td> */}
                </tr>
                )}
              </tbody>
              :
              
                    <tbody>
                    <tr className="text-center">
              <td colSpan="3" >
              <span className="pt-5 pb-5">
                <img
                  src="/no_data.svg"
                  alt="no data"
                  width="150px"
                />
                <h6>Nothing to show here</h6>
                <p className="small text-secondary">
                  Please add a new entity or manage the data table to see the
                  content here <br/>Thank you
                </p>
              </span>
  
              </td>
                  </tr>
  </tbody>
                      }
            </table>
              :
              <div  className="spinner_height">
                     <div className="loader"></div>
                      </div>
            }
          </div>
        </div>
      </div>

      {/* -------------------Add User---------------------------- */}

      <Modal show={show} onHide={handleClose} size="lg">
        <Modal.Header closeButton className="p-4  ">
          <Modal.Title>Add Employee</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <div className="d-flex align-items-center">
              <FiBookOpen className="text-warning h5" />
              &nbsp;&nbsp;
              <h6> No email settings found.</h6>
            </div>

            <div className="employee_bg">
              <ol>
                <li>
                  Please make sure you setup the cron job on your hosted server
                  as instructed on the <a href="/">documentation</a> for sending
                  emails.
                </li>
                <li>
                  For sending invitation you must set up email settings. Click{" "}
                  <a href="/">here</a> to add email settings.
                </li>
              </ol>
            </div>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-secondary puch_btn" onClick={handleClose}>
            Close
          </button>
        </Modal.Footer>
      </Modal>

      {/* -------------------Invite User---------------------------- */}

      <Modal show={showInvite} onHide={handleInviteClose} size="lg">
        <Modal.Header closeButton className="p-4  ">
          <Modal.Title>Invite Employee</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <div className="d-flex align-items-center">
              <FiBookOpen className="text-warning h5" />
              &nbsp;&nbsp;
              <h6> No email settings found.</h6>
            </div>

            <div className="employee_bg">
              <ol>
                <li>
                  Please make sure you setup the cron job on your hosted server
                  as instructed on the <a href="/">documentation</a> for sending
                  emails.
                </li>
                <li>
                  For sending invitation you must set up email settings. Click{" "}
                  <a href="/">here</a> to add email settings.
                </li>
              </ol>
            </div>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-secondary puch_btn"
            onClick={handleInviteClose}
          >
            Close
          </button>
        </Modal.Footer>
      </Modal>
      {/* ------------------Edit User--------------------------- */}

      <Modal show={showEdit} onHide={handleEditClose} size="md">
        <Modal.Header closeButton className="p-4  ">
          <Modal.Title>Edit User</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group>
              <Form.Label>First Name</Form.Label>
              <Form.Control type="text" placeholder="Enter First Name" />
            </Form.Group>
            <Form.Group className="mt-3">
              <Form.Label>Last Name</Form.Label>
              <Form.Control type="text" placeholder="Enter Last Name" />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-secondary puch_btn"
            onClick={handleEditClose}
          >
            Close
          </button>
          <button
            className="btn btn-primary puch_btn"
            onClick={handleEditClose}
          >
            Save
          </button>
        </Modal.Footer>
      </Modal>
      {/* ------------------Manage Role--------------------------- */}

      <Modal show={showRole} onHide={handleRoleClose} size="md">
        <Modal.Header closeButton className="p-4  ">
          <Modal.Title>Manage Role</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <div className="w-100">
              <Form.Label>For paid leave</Form.Label>
              <Select
                closeMenuOnSelect={false}
                components={animatedComponents}
                defaultValue={[colourOptions[4], colourOptions[5]]}
                isMulti
                options={colourOptions}
                className="w-100 m-0 "
              />
            </div>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-secondary puch_btn"
            onClick={handleRoleClose}
          >
            Close
          </button>
          <button
            className="btn btn-primary puch_btn"
            onClick={handleRoleClose}
          >
            Save
          </button>
        </Modal.Footer>
      </Modal>
    </section>
  );
};

export default UsersandRole;
