import React, { useState, useEffect, useCallback } from "react";
import Tab from "react-bootstrap/Tab";
import Nav from "react-bootstrap/Nav";
import { Alert, Col, Form, Modal, Row } from "react-bootstrap";
import { MdArrowForwardIos } from "react-icons/md";
import { FiUser } from "react-icons/fi";
import { CiCalendar, CiLocationOn } from "react-icons/ci";
import { GoGift } from "react-icons/go";
import { useAuth } from "../utils/Context";
import { LiaPhoneSolid } from "react-icons/lia";
import { IoMdCheckmarkCircleOutline } from "react-icons/io";
import { FaRegEye, FaRegEyeSlash } from "react-icons/fa6";

const Profile = ({ menuWidth }) => {
  const { role_id, id, employee_id } = useAuth();
  const [isEditing, setIsEditing] = useState(true);
  const [validated, setValidated] = useState(false);
  const [validatedPassword, setValidatedPassword] = useState(false);
  const pattern = new RegExp(/^\d{1,10}$/);
  const [accept, setAccept] = useState(false);
  // const [forceUpdate, setForceUpdate] = useState(false);
  const [eyeold, setEyeold] = useState(false);
  const [eyenew, setEyenew] = useState(false);
  const [eyenew2, setEyenew2] = useState(false);
  const [employment_statuses, setEmploymentStatuses] = useState([]);
  const handleAccept = () => setAccept(true);

  // const handleAcceptClose = () => setAccept(false);
  // useEffect(() => {
  //   // Update profile image from local storage
  //   localStorage.getItem("photo", photo);

  // }, [forceUpdate]); // Re-run effect when forceUpdate changes

  const [acceptPassword, setAcceptPassword] = useState(false);

  const handlePassword = () => setAcceptPassword(true);

  const handlePasswordClose = () => setAcceptPassword(false);

  const [addCredentials, setAddCredentials] = useState({
    first_name: "",
    last_name: "",
    contact_number: "",
    gander: "",
    employee_id: id,
    // gander: 1,
    photo: "",
    about_me: "",
    date_of_birth: "dd-mm-yyyy",
    address: "",
    emergency_contact: "",
    status: "",
    employee_ment_id:"",
    marital_status:"",
    salary:"",
    error: "",
    personal_email:""
  });
  const [changePassword, setChangePassword] = useState({
    old_password: "",
    new_password: "",
    confirm_password: "",
    employee_id: id,
    error: "",
  });

  const handleEditClick = () => {
    setIsEditing(false);
  };

  useEffect(() => {
    if (role_id) {
      fetchData();
    }
  }, [role_id, id, employee_id]);
  
      const fetchData = () => {
        var formdata = new FormData();
        formdata.append("employee_id", id);

        var requestOptions = {
          method: "POST",
          body: formdata,
          redirect: "follow",
        };

        fetch(
          `${process.env.REACT_APP_API_BASE_URL}/admin/profile`,
          requestOptions
        )
          .then((response) => response.json())
          .then((result) => {
            if (result.status === true) {
              // localStorage.setItem("photo", result.data.photo);
              const gander = result.data.gander === 1 ? "Male" : "Female";
              setAddCredentials((prevState) => ({
                ...prevState,
                first_name: result.data.first_name
                  ? result.data.first_name
                  : "",
                last_name: result.data.last_name ? result.data.last_name : "",
                email: result.data.email ? result.data.email : "",
                contact_number: result.data.contact_number
                  ? result.data.contact_number
                  : "",
                gander: gander,
                emergency_contact: result.data.emergency_contact
                  ? result.data.emergency_contact
                  : "",
                status: result.data.status ? result.data.status : "",
                employee_id: result.data.employee_id
                  ? result.data.employee_id
                  : "",
                photo: result.data.photo ? result.data.photo : "",
                joining_date: result.data.joining_date
                  ? result.data.joining_date
                  : "",
                address: result.data.address ? result.data.address : "",
                about_me: result.data.about_me ? result.data.about_me : "",
                date_of_birth: result.data.date_of_birth
                  ? result.data.date_of_birth
                  : "",
                department_id: result.data.department_id
                  ? result.data.department_id
                  : "",
                designation_id: result.data.designation_id
                  ? result.data.designation_id
                  : "",
                employee_ment_id: result.data.employee_ment_id
                  ? result.data.employee_ment_id
                  : "",
                  // employee_ment_id: result.data.employee_ment_id
                  // ? result.data.employee_ment_id
                  // : "",
                  salary: result.data.salary
                  ? result.data.salary
                  : "",
                  personal_email: result.data.salary
                  ? result.data.personal_email
                  : "",
                  marital_status: result.data.marital_status
                  ? result.data.marital_status
                  : "",
              }));
          
            }
          })
          .catch((error) => console.log("error", error));
      };
   

  const handleUpdate = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }

    setValidated(true);
    var formdata = new FormData();
    const ganderValue = addCredentials.gander === "Male" ? 1 : 2;
    formdata.append("employee_id", id);
    formdata.append("first_name", addCredentials.first_name);
    formdata.append("last_name", addCredentials.last_name);
    formdata.append("contact_number", addCredentials.contact_number);
    formdata.append("address", addCredentials.address);
    formdata.append("emergency_contact", addCredentials.emergency_contact);
    formdata.append("status", addCredentials.status);
    formdata.append("date_of_birth", addCredentials.date_of_birth);
    formdata.append("about_me", addCredentials.about_me);
    formdata.append("photo", addCredentials.photo);
    formdata.append("employee_ment_id", addCredentials.employee_ment_id);
    formdata.append("salary", addCredentials.salary);
    formdata.append("marital_status", addCredentials.marital_status);
    formdata.append("personal_email", addCredentials.personal_email);
    formdata.append("gander", ganderValue);

    var requestOptions = {
      method: "POST",
      body: formdata,
      redirect: "follow",
    };

    fetch(
      `${process.env.REACT_APP_API_BASE_URL}/admin/update-profile`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status === true) {
          handleAccept();
          setValidated(false);
          fetchData();
          // setForceUpdate(prevState => !prevState);
        } else {
        }
      })
      .catch((error) => {
        console.error("Error:", error.message);
      });
  };

  const handleChange = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }

    setValidatedPassword(true);
    var formdata = new FormData();
    formdata.append("old_password", changePassword.old_password);
    formdata.append("new_password", changePassword.new_password);
    formdata.append("confirm_password", changePassword.confirm_password);
    formdata.append("employee_id", changePassword.employee_id);

    var requestOptions = {
      method: "POST",
      body: formdata,
      redirect: "follow",
    };

    fetch(
      `${process.env.REACT_APP_API_BASE_URL}/admin/change-password`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status === true) {
          setChangePassword(changePassword);
          handlePassword();
        } else {
          setChangePassword({ ...changePassword, error: result.message });
        }
      })
      .catch((error) => console.log("error", error));
  };

  const handleFileChange = (e, value) => {
    // value={photo}
    const file = e.target.files[0];
    setAddCredentials({ ...addCredentials, photo: file });
  };

  const [paddresswiew, setPaddressview] = useState();
  const fetchData2 = useCallback(() => {
    var requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    fetch(
      `${process.env.REACT_APP_API_BASE_URL}/admin/get-permanent-address-detail?user_id=${id}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status === true) {
          setPaddressview(result.data);
        }
      })
      .catch((error) => console.log("error", error));
  }, [id]);

  useEffect(() => {
    fetchData2();
  }, [fetchData2]);

  const formatDate = (dateString) => {
    if (!dateString) return "Not added yet";

    // Parse the date string into a Date object
    const date = new Date(dateString);

    // Define an array of month names
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    // Extract day, month, and year
    const day = date.getDate();
    const month = monthNames[date.getMonth()];
    const year = date.getFullYear();

    // Format the date as desired
    const formattedDate = `${day} ${month}, ${year}`;

    return formattedDate;
  };

  useEffect(() => {
    var requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    fetch(
      `${process.env.REACT_APP_API_BASE_URL}/admin/employee-form`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status === true) {
          const {
       
            employment_statuses
        
          } = result.data;
          setEmploymentStatuses(employment_statuses);
        }
      })
      .catch((error) => console.log("error", error));
  }, [setEmploymentStatuses]);



if (addCredentials.status === 2) {
  console.log(addCredentials.status);
  localStorage.clear();

  window.location.href = "/";
} 

  return (
    <section className="left_width" style={{ marginLeft: `${menuWidth}px` }}>
      <div className="pt-4 w-100">
        <h4 className="pt-3 mt-5 mb-4">My Profile</h4>
      </div>
      <Row className="align-items-center attendance profile_mainpadding mx-0">
        <Col className="col-md-5 d-lg-flex justify-content-between align-items-center  ">
          {addCredentials.photo ? (
            <img
              src={addCredentials.photo}
              alt="profile"
              className="avatar m-2"
            />
          ) : (
            <img src="/avtaar.jpg" alt="profile" className="avatar m-2" />
          )}
          {/* {addCredentials.photo ?
 <img src={addCredentials.photo} alt='profile' className="avatar m-2"/>
 : 
 <img src={photo} alt='profile' className="avatar m-2"/>

} */}
          {/* //  <h1 className="avatar m-2 d-flex justify-content-center align-items-center">
//            {addCredentials.first_name.charAt(0)}
//             </h1> */}
          {/* <img src={photo} alt='profile' className="avatar m-2"/> */}
          {/* } */}
          <div className="width_css_web pl-2">
            <h4 className="h4">
              {addCredentials.first_name} {addCredentials.last_name}
            </h4>
            {addCredentials.status === 1 ? (
              <span className=" btn-success rounded-pill text-white p-1 px-3 text-white ">
                Active
              </span>
            ) : (
              <span className="bg-danger rounded-pill text-white p-1 px-3  text-white">
                Inactive
              </span>
            )}

            <h6 className=" p_css pt-2 mb-0">{addCredentials.email}</h6>
            <h6 className=" p_css pt-2 text-primary">
              {addCredentials.designation_id}
            </h6>
          </div>
        </Col>
        <Col className="border_css">
          <div className="d-flex align-items-center">
            <LiaPhoneSolid className="job_icon_css" />
            <div>
              <p className="mb-0 p_css">Contact:</p>
              <h5 className="m-0 p_css text-dark">
                {addCredentials.contact_number || "Not added yet"}
              </h5>
            </div>
          </div>
          <div className="d-flex align-items-center mt-4">
            <CiLocationOn className="job_icon_css" />
            <div>
              <p className="mb-0 p_css ">Address:</p>
              <h5 className="m-0 p_css text-dark">
                <div className="w-100 tab_css_setting text-dark">
                  <h5 className="m-0 p_css text-dark">
                    {paddresswiew && paddresswiew.address_detail.length > 0 ? (
                      <>{paddresswiew && paddresswiew.address_detail}...</>
                    ) : (
                      "Not added yet"
                    )}
                  </h5>
                </div>
              </h5>
            </div>
          </div>
        </Col>
        <Col className="">
          <div className="d-flex align-items-center">
            <CiCalendar className="job_icon_css" />
            <div>
              <p className="mb-0 p_css">Created:</p>
              <h5 className="m-0 p_css text-dark">
                {formatDate(addCredentials.joining_date) || "Not added yet"}
              </h5>
            </div>
          </div>
          <div className="d-flex align-items-center mt-4">
            <GoGift className="job_icon_css" />
            <div>
              <p className="mb-0 p_css">Date of birth:</p>
              <h5 className="m-0 p_css text-dark">
                {formatDate(addCredentials.date_of_birth) || "Not added yet"}
              </h5>
            </div>
          </div>
        </Col>
      </Row>
      <Tab.Container id="left-tabs-example" defaultActiveKey="first">
        <Row className="mt-4">
          <Col sm={3} className="h-auto">
            <Nav variant="pills" className="flex-column statistics p-3 h-auto">
              <div className="text-center  pt-5 mt-4 pb-3">
                <FiUser className="h2 text-primary" />
              </div>
              <Nav.Item>
                <Nav.Link
                  eventKey="first"
                  className="tab_css_setting d-flex justify-content-between"
                >
                  Personal Details <MdArrowForwardIos />
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  eventKey="attendance"
                  className="tab_css_setting d-flex justify-content-between"
                >
                  Change Password <MdArrowForwardIos />
                </Nav.Link>
              </Nav.Item>
              {/* <Nav.Item>
                <Nav.Link
                  eventKey="third"
                  className="tab_css_setting d-flex justify-content-between"
                >
                  Activity Log <MdArrowForwardIos />
                </Nav.Link>
              </Nav.Item> */}
            </Nav>
          </Col>
          <Col sm={9}>
            <Tab.Content className="attendance rounded">
              <Tab.Pane eventKey="first">
                <div className="d-lg-flex justify-content-between p-4 border-bottom">
                  <h5 className="h5">Personal Details</h5>
                  <button
                    className="btn btn-primary punch_btn"
                    onClick={handleEditClick}
                  >
                    Edit
                  </button>
                </div>

                <Form
                  className="p-4"
                  noValidate
                  validated={validated}
                  onSubmit={handleUpdate}
                >
                  <Form.Group
                    className="d-lg-flex mt-3"
                    controlId="exampleForm.ControlTextarea1"
                  >
                    <div className="width25">
                      <Form.Label className="sgt_admin">
                        Upload profile image
                      </Form.Label>
                    </div>
                    <div className="width75">
                      <Form.Control
                        type="file"
                        onChange={handleFileChange}
                        disabled={isEditing}
                      />
                    </div>
                  </Form.Group>
                  <Form.Group
                    className="d-lg-flex mt-3"
                    controlId="exampleForm.ControlTextarea1"
                  >
                    <div className="width25">
                      <Form.Label className="sgt_admin">First name</Form.Label>
                    </div>
                    <div className="width75">
                      <Form.Control
                        type="text"
                        value={addCredentials.first_name}
                        onChange={(e) =>
                          setAddCredentials({
                            ...addCredentials,
                            first_name: e.target.value,
                          })
                        }
                        disabled={isEditing}
                        readOnly
                      />
                    </div>
                  </Form.Group>
                  <Form.Group
                    className="d-lg-flex mt-3"
                    controlId="exampleForm.ControlTextarea1"
                  >
                    <div className="width25">
                      <Form.Label className="sgt_admin">Last name</Form.Label>
                    </div>
                    <div className="width75">
                      <Form.Control
                        type="text"
                        disabled={isEditing}
                        value={addCredentials.last_name}
                        onChange={(e) =>
                          setAddCredentials({
                            ...addCredentials,
                            last_name: e.target.value,
                          })
                        }
                        readOnly
                      />
                    </div>
                  </Form.Group>
                  <Form.Group
                    className="d-lg-flex mt-3"
                    controlId="exampleForm.ControlTextarea1"
                  >
                    <div className="width25 ">
                      <Form.Label className="sgt_admin">Email</Form.Label>
                    </div>
                    <div className="width75">
                      <Form.Control
                        type="text"
                        value={addCredentials.email}
                        disabled={isEditing}
                        onChange={(e) =>
                          setAddCredentials({
                            ...addCredentials,
                            email: e.target.value,
                          })
                        }
                        readOnly
                      />
                    </div>
                  </Form.Group>

                  <Form.Group
                    className="d-lg-flex mt-3"
                    controlId="exampleForm.ControlTextarea1"
                  >
                    <div className="width25 ">
                      <Form.Label className="sgt_admin">Personal Email</Form.Label>
                    </div>
                    <div className="width75">
                      <Form.Control
                        type="text"
                        value={addCredentials.personal_email}
                        disabled={isEditing}
                        onChange={(e) =>
                          setAddCredentials({
                            ...addCredentials,
                            personal_email: e.target.value,
                          })
                        }
                        
                      />
                    </div>
                  </Form.Group>
                  <Form.Group
                    className="d-lg-flex mt-3"
                    controlId="exampleForm.ControlTextarea1"
                  >
                    <div className="width25">
                      <Form.Label className="sgt_admin">Gender</Form.Label>
                    </div>
                    <div className="d-flex align-items-center">
                      <Form.Group
                        className="d-lg-flex mt-3"
                        controlId="exampleForm.ControlTextarea1"
                      >
                        <div className="d-flex align-items-center">
                          <Form.Check
                            type="radio"
                            id="male"
                            name="gander"
                            disabled={isEditing}
                            value={"Male"}
                            checked={
                              addCredentials.gander === "Male" ||
                              addCredentials.gander === 1
                                ? true
                                : false
                            }
                            onChange={(e) => {
                              setAddCredentials((prevState) => ({
                                ...prevState,
                                gander: e.target.value,
                              }));
                            }}
                            label="Male"
                          />
                          <Form.Check
                            type="radio"
                            id="female"
                            name="gander"
                            disabled={isEditing}
                            value={"Female"}
                            checked={
                              addCredentials.gander === "Female" ||
                              addCredentials.gander === 0
                                ? true
                                : false
                            }
                            onChange={(e) => {
                              setAddCredentials((prevState) => ({
                                ...prevState,
                                gander: e.target.value,
                              }));
                            }}
                            label="Female"
                            className="ms-3"
                          />
                        </div>
                      </Form.Group>
                    </div>
                  </Form.Group>

                  <Form.Group className="d-lg-flex mt-3" controlId="exampleForm.ControlTextarea1">
  <div className="width25">
    <Form.Label className="sgt_admin">Marital Status</Form.Label>
  </div>
  <div className="d-flex align-items-center">
    <Form.Check
      type="radio"
      id="married"
      name="marital_status"
      disabled={isEditing}
      value={"2"} // Married
      checked={addCredentials.marital_status === "2"}
      onChange={(e) => {
        setAddCredentials((prevState) => ({
          ...prevState,
          marital_status: e.target.value,
        }));
      }}
      label="Married"
    />
    <Form.Check
      type="radio"
      id="unmarried"
      name="marital_status"
      disabled={isEditing}
      value={"1"} // Unmarried
      checked={addCredentials.marital_status === "1"}
      onChange={(e) => {
        setAddCredentials((prevState) => ({
          ...prevState,
          marital_status: e.target.value,
        }));
      }}
      label="Unmarried"
      className="ms-3"
    />
  </div>
</Form.Group>
                  
                  <Form.Group
                    className="d-lg-flex mt-3"
                    controlId="exampleForm.ControlTextarea1"
                  >
                    <div className="width25">
                      <Form.Label className="sgt_admin">Status</Form.Label>
                    </div>
                    <div className="d-flex">
                      {addCredentials.status === 1 ? (
                        <>
                          <Form.Check
                            type="radio"
                            id="active"
                            label="Active"
                            name="radio3"
                            disabled={isEditing}
                            value={1}
                            checked={addCredentials.status === 1}
                            onChange={(e) => {
                              setAddCredentials((prevState) => ({
                                ...prevState,
                                status: e.target.value,
                              }));
                            }}
                          />
                        </>
                      ) : (
                        <>
                          <Form.Check
                            type="radio"
                            label="Inactive"
                            name="radio4"
                            id="inactive"
                            disabled={isEditing}
                            value={2}
                            checked={addCredentials.status === 2}
                            onChange={(e) => {
                              setAddCredentials((prevState) => ({
                                ...prevState,
                                status: e.target.value,
                              }));
                            }}
                          />
                        </>
                      )}
                    </div>
                  </Form.Group>
                  <Form.Group
                    className="d-lg-flex mt-3"
                    controlId="exampleForm.ControlTextarea1"
                  >
                    <div className="width25">
                      <Form.Label className="sgt_admin">
                        Emergency Contact number
                      </Form.Label>
                    </div>
                    <div className="width75">
                      <Form.Control
                        type="number"
                        maxLength={10}
                        value={addCredentials.emergency_contact}
                        disabled={isEditing}
                        onChange={(e) => {
                          const inputValue = e.target.value;

                          // Check if the input matches the pattern
                          if (!pattern.test(inputValue)) {
                            // If not, update the state with an error message
                            setAddCredentials({
                              ...addCredentials,
                              emergency_contact: inputValue.slice(0, 10),
                              error: "Please enter only numbers.",
                            });
                          } else {
                            // If it matches the pattern, update the state without an error message
                            setAddCredentials({
                              ...addCredentials,
                              emergency_contact: inputValue,
                              error: "",
                            });
                          }
                        }}
                        required
                      />
                    </div>
                  </Form.Group>
                  <Form.Group
                    className="d-lg-flex mt-3"
                    controlId="exampleForm.ControlTextarea1"
                  >
                    <div className="width25">
                      <Form.Label className="sgt_admin">
                        Contact number
                      </Form.Label>
                    </div>
                    <div className="width75">
                      <Form.Control
                        type="number"
                        maxLength={10}
                        value={addCredentials.contact_number}
                        disabled={isEditing}
                        onChange={(e) => {
                          const inputValue = e.target.value;

                          // Check if the input matches the pattern
                          if (!pattern.test(inputValue)) {
                            // If not, update the state with an error message
                            setAddCredentials({
                              ...addCredentials,
                              contact_number: inputValue.slice(0, 10),
                              error: "Please enter only numbers.",
                            });
                          } else {
                            // If it matches the pattern, update the state without an error message
                            setAddCredentials({
                              ...addCredentials,
                              contact_number: inputValue,
                              error: "",
                            });
                          }
                        }}
                        required
                      />

                      {/* <Form.Control type="text"
                       maxLength={10}  value={addCredentials.contact_number} disabled={isEditing} 
                      onChange={(e) => {
                        setAddCredentials({ ...addCredentials, contact_number: e.target.value });
                        
                         if (!pattern.test(e.target.value))
                         setAddCredentials({
                          ...addCredentials,
                          error: "Some error message", 
                        });
                       }}
                       required
                       /> */}
                    </div>
                  </Form.Group>
                  {/* <Form.Group
                    className="d-lg-flex mt-3"
                    controlId="exampleForm.ControlTextarea1"
                  >
                    <div className="width25">
                      <Form.Label className="sgt_admin">Address</Form.Label>
                    </div>
                    <div className="width75">
                      <Form.Control type="text"
                      value={addCredentials.address}
                       disabled={isEditing}
                       onChange={(e) =>
                        setAddCredentials({ ...addCredentials, address: e.target.value })}
                        required
                        />
                    </div>
                  </Form.Group> */}
                  <Form.Group
                    className="d-lg-flex mt-3"
                    controlId="exampleForm.ControlTextarea1"
                  >
                    <div className="width25">
                      <Form.Label className="sgt_admin">
                        Date of birth
                      </Form.Label>
                    </div>
                    <div className="width75">
                      <Form.Control
                        type="date"
                        value={addCredentials.date_of_birth}
                        disabled={isEditing}
                        onChange={(e) =>
                          setAddCredentials({
                            ...addCredentials,
                            date_of_birth: e.target.value,
                          })
                        }
                        required
                      />

                      {/* <input
        type="date"
        value={addCredentials.date_of_birth}
        disabled={isEditing}
        onChange={(e) => setAddCredentials({ ...addCredentials, date_of_birth: e.target.value })}
       
        className="form-control"
        required
      /> */}
                      {/* <div className="loginEye">dd-mm-yyyy</div> */}
                    </div>
                  </Form.Group>

                  <Form.Group
                    className="d-lg-flex mt-3"
                    controlId="exampleForm.ControlTextarea1"
                  >
                    <div className="width25">
                      <Form.Label className="sgt_admin">
                      Employment status
                      </Form.Label>
                    </div>
                    <div className="width75">
                    <Form.Select
  aria-label="Default select example"
  value={addCredentials.employee_ment_id}
  onChange={(e) => {
    setAddCredentials((prevState) => ({
      ...prevState,
      employee_ment_id: e.target.value,
    }));
  }}
  disabled
  readOnly
  required
  className="text-uppercase search_select_css"
>
  <option value="" disabled>
    Choose an employment status
  </option>
  {employment_statuses.map((item) => (
    <option key={item.id} value={item.id}>
      {item.name}
    </option>
  ))}
</Form.Select>

                </div>
                  </Form.Group>
                  <Form.Group
                    className="d-lg-flex mt-3"
                    controlId="exampleForm.ControlTextarea1"
                  >
                    <div className="width25">
                      <Form.Label className="sgt_admin">
                  Salary
                      </Form.Label>
                    </div>
                    <div className="width75">
                      <Form.Control
                        type="text"
                        value={addCredentials.salary}
                        disabled
                        readOnly
                        onChange={(e) =>
                          setAddCredentials({
                            ...addCredentials,
                            salary: e.target.value,
                          })
                        }
                        // required
                      />
                    </div>
                  </Form.Group>
                  <Form.Group
                    className="d-lg-flex mt-3"
                    controlId="exampleForm.ControlTextarea1"
                  >
                    <div className="width25 ">
                      <Form.Label className="sgt_admin">About me</Form.Label>
                    </div>
                    <div className="width75">
                      <Form.Control
                        as="textarea"
                        row={2}
                        type="text"
                        disabled={isEditing}
                        value={addCredentials.about_me}
                        onChange={(e) =>
                          setAddCredentials({
                            ...addCredentials,
                            about_me: e.target.value,
                          })
                        }
                      />
                    </div>
                  </Form.Group>
                  <div className="d-lg-flex mt-4">
                    <button
                      className="btn btn-primary punch_btn"
                      disabled={isEditing}
                      type="submit"
                    >
                      Save
                    </button>
                    {/* &nbsp; &nbsp;
                    <button
                      className="btn btn-secondary punch_btn"
                      disabled={isEditing}
                    >
                      Cancel
                    </button> */}
                  </div>
                </Form>
              </Tab.Pane>
              <Tab.Pane eventKey="attendance">
                <div className="p-4 border-bottom">
                  <h5 className="h5">Change Password</h5>
                </div>

                <Form
                  className="p-4"
                  noValidate
                  validated={validatedPassword}
                  onSubmit={handleChange}
                >
                  {changePassword.error && (
                    <Alert variant="danger">{changePassword.error}</Alert>
                  )}
                  <Form.Group
                    className="d-lg-flex mt-3"
                    controlId="exampleForm.ControlTextarea1"
                  >
                    <div className="width25 ">
                      <Form.Label className="sgt_admin">
                        Old password
                      </Form.Label>
                    </div>
                    <div className="width75">
                      <Form.Control
                        type={eyeold ? "text" : "password"}
                        value={changePassword.old_password}
                        onChange={(e) =>
                          setChangePassword({
                            ...changePassword,
                            old_password: e.target.value,
                          })
                        }
                        required
                      />
                      {eyeold ? (
                        <FaRegEyeSlash
                          className="loginEye"
                          onClick={() => setEyeold(false)}
                        />
                      ) : (
                        <FaRegEye
                          className="loginEye"
                          onClick={() => setEyeold(true)}
                        />
                      )}
                    </div>
                  </Form.Group>
                  <Form.Group
                    className="d-lg-flex mt-3"
                    controlId="exampleForm.ControlTextarea1"
                  >
                    <div className="width25 ">
                      <Form.Label className="sgt_admin">
                        New password
                      </Form.Label>
                    </div>
                    <div className="width75">
                      <Form.Control
                        type={eyenew ? "text" : "password"}
                        value={changePassword.new_password}
                        onChange={(e) =>
                          setChangePassword({
                            ...changePassword,
                            new_password: e.target.value,
                          })
                        }
                        required
                      />
                      {eyenew ? (
                        <FaRegEyeSlash
                          className="loginEye"
                          onClick={() => setEyenew(false)}
                        />
                      ) : (
                        <FaRegEye
                          className="loginEye"
                          onClick={() => setEyenew(true)}
                        />
                      )}
                      {/* <div className="employee_bg mt-3">
                        The password should contain one upper case, one lower
                        case, numbers, one special character ( +=#?!@$%^&*- ).
                        It should be a minimum of 8 characters.
                      </div> */}
                    </div>
                  </Form.Group>

                  <Form.Group
                    className="d-lg-flex mt-3"
                    controlId="exampleForm.ControlTextarea1"
                  >
                    <div className="width25 ">
                      <Form.Label className="sgt_admin">
                        Confirm password
                      </Form.Label>
                    </div>
                    <div className="width75">
                      <Form.Control
                        type={eyenew2 ? "text" : "password"}
                        value={changePassword.confirm_password}
                        onChange={(e) =>
                          setChangePassword({
                            ...changePassword,
                            confirm_password: e.target.value,
                          })
                        }
                        required
                      />
                      {eyenew2 ? (
                        <FaRegEyeSlash
                          className="loginEye"
                          onClick={() => setEyenew2(false)}
                        />
                      ) : (
                        <FaRegEye
                          className="loginEye"
                          onClick={() => setEyenew2(true)}
                        />
                      )}
                    </div>
                  </Form.Group>

                  <button
                    className="btn btn-primary punch_btn mt-4 mb-4"
                    type="submit"
                  >
                    Save
                  </button>
                </Form>
              </Tab.Pane>
            </Tab.Content>
          </Col>
        </Row>
      </Tab.Container>

      {/* ---------------success---------------------- */}
      <Modal
        show={accept}
        // onHide={handleAcceptClose}
        size="md"
        centered
        className="pt-5 pb-5"
      >
        <Modal.Body>
          <div className="text-center mt-5">
            <IoMdCheckmarkCircleOutline className="jobdisk_icon text-success" />
            <h5>Profile Update Successfully</h5>
          </div>
          <Modal.Footer className="border-0 justify-content-center  mb-4">
            <button
              className="btn btn-success puch_btn"
              onClick={() => window.location.reload()}
            >
              Okay
            </button>
          </Modal.Footer>
        </Modal.Body>
      </Modal>

      {/* ---------------success---------------------- */}
      <Modal
        show={acceptPassword}
        onHide={handlePasswordClose}
        size="md"
        centered
        className="pt-5 pb-5"
      >
        <Modal.Body>
          <div className="text-center mt-5">
            <IoMdCheckmarkCircleOutline className="jobdisk_icon text-success" />
            <h5>Password change Successfully</h5>
          </div>
          <Modal.Footer className="border-0 justify-content-center  mb-4">
            <button
              className="btn btn-success puch_btn"
              onClick={() => window.location.reload()}
            >
              Okay
            </button>
          </Modal.Footer>
        </Modal.Body>
      </Modal>
    </section>
  );
};
export default Profile;
