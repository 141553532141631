import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import { FiBookOpen } from "react-icons/fi";
import { Link } from "react-router-dom";

const AttendanceRequest = ({ menuWidth }) => {
/* eslint-disable */
  const [show, setShow] = useState(false);
  const [showInvite, setShowInvite] = useState(false);
  const [singleday, setSingleday] = useState(true);
  const [showAvailability, setShowAvailability] = useState(false);
  const [showLeave, setShowLeave] = useState(false);
  const handleClose = () => setShow(false);
  const handleInviteClose = () => setShowInvite(false);

  const toggleMultiday = () => {
    setSingleday(!singleday);
  };

  const tonggleAvailability = () => {
    setShowAvailability(!showAvailability);
  };
  const handleLeave = () => setShowLeave(true);
  const handleCloseleave = () => setShowLeave(false);
  return (
    <section className="left_width" style={{ marginLeft: `${menuWidth}px` }}>
      <div className="d-lg-flex justify-content-between align-items-center pt-4 pb-3 w-100">
        <h4 className="m-0">Attendance Request</h4>
        <div>
          <Link to="/Leave/settings" className="btn btn-success puch_btn">
            Settings
          </Link>
          &nbsp; &nbsp;
          <button className="btn btn-primary puch_btn" onClick={handleLeave}>
          Add Attendance
          </button>
        </div>
      </div>
    
      <div className="employee_table p-4 text-center">
        <div>
          <div className="table-responsive custom-scrollbar table-view-responsive py-primary">
            <table className="table mb-0">
              <thead>
                <tr>
                  <th track-by="0" className="datatable-th py-4 px-3 table_color ">
                    <span className="font-size-default">
                      <span>Profile</span>
                    </span>
                  </th>
                  <th track-by="1" className="datatable-th py-4 px-3 table_color">
                    <span className="font-size-default">
                      <span>Date & time</span>
                    </span>
                  </th>
                  <th track-by="2" className="datatable-th py-4 px-3 table_color">
                    <span className="font-size-default">
                      <span>Leave duration</span>
                    </span>
                  </th>
                  <th
                    track-by="3"
                    className="datatable-th py-4 px-3 table_color text-right"
                  >
                    <span className="font-size-default">Leave Type</span>
                  </th>
                  <th
                    track-by="3"
                    className="datatable-th py-4 px-3 table_color text-right"
                  >
                    <span className="font-size-default">Attachments</span>
                  </th>
                  <th
                    track-by="3"
                    className="datatable-th py-4 px-3 table_color text-right"
                  >
                    <span className="font-size-default">Activity</span>
                  </th>
                  <th
                    track-by="3"
                    className="datatable-th py-4 px-3 table_color text-right"
                  >
                    <span className="font-size-default">Actions</span>
                  </th>
                </tr>
              </thead>
              <tbody></tbody>
            </table>
            <span className="pt-5 pb-5">
              <img
                src="/no_data.svg"
                alt="no data"
                width="150px"
              />
              <h6>Nothing to show here</h6>
              <p>
                Please add a new entity or manage the data table to see the
                content here Thank you
              </p>
            </span>
          </div>
        </div>
      </div>

      {/* -------------------Add Employee---------------------------- */}

      <Modal show={show} onHide={handleClose} size="lg">
        <Modal.Header closeButton className="p-4  ">
          <Modal.Title>Add Employee</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <div className="d-flex align-items-center">
              <FiBookOpen className="text-warning h5" />
              &nbsp;&nbsp;
              <h6> No email settings found.</h6>
            </div>

            <div className="employee_bg">
              <ol>
                <li>
                  Please make sure you setup the cron job on your hosted server
                  as instructed on the <a href="/">documentation</a> for sending
                  emails.
                </li>
                <li>
                  For sending invitation you must set up email settings. Click{" "}
                  <a href="/">here</a> to add email settings.
                </li>
              </ol>
            </div>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-secondary puch_btn" onClick={handleClose}>
            Close
          </button>
        </Modal.Footer>
      </Modal>

         {/*---------------Add Attendance-------------------------*/}

         <Modal show={showLeave} onHide={handleCloseleave} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Add Attendance</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <div>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlTextarea1"
              >
                <Form.Label>Employee</Form.Label>
                <Form.Select aria-label="Default select example">
                  <option>Select an employee</option>
                  <option value="1">One</option>
                  <option value="2">Two</option>
                  <option value="3">Three</option>
                </Form.Select>
              </Form.Group>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlTextarea1"
              >
                <Form.Label>Punch in date and time</Form.Label>
                <Form.Control
                  type="date"
                  placeholder="Enter punch in date and time"
                />
              </Form.Group>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlTextarea1"
              >
                <Form.Label>Punch out date and time</Form.Label>
                <Form.Control
                  type="date"
                  placeholder="Enter punch out date and time"
                />
              </Form.Group>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlTextarea1"
              >
                <Form.Label>Reason note for manual entry</Form.Label>
                <Form.Control as="textarea" placeholder="Enter note" rows={3} />
              </Form.Group>
            </div>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-secondary puch_btn"
            onClick={handleCloseleave}
          >
            Cancel
          </button>
          <button
            className="btn btn-primary puch_btn"
            onClick={handleCloseleave}
          >
            Save
          </button>
        </Modal.Footer>
      </Modal>
    </section>
  );
};

export default AttendanceRequest;
