import React, { useCallback, useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
// import Modal from "react-bootstrap/Modal";
import Tab from "react-bootstrap/Tab";
import Nav from "react-bootstrap/Nav";
import { useAuth } from "../utils/Context";
import Select from "react-select";

const AttendanceDetails = ({ menuWidth }) => {
  const { id, role_id } = useAuth();
  // const [showLeave, setShowLeave] = useState(false);
  const [loading, setLoading] = useState(false);

  // const handleCloseleave = () => setShowLeave(false);

  const [data, setData] = useState({});
  const [search, setSearch] = useState("thisweek");

  const [data1, setData1] = useState([]);
  const [start_date, setStartDate] = useState("");
  const [end_date, setEndDate] = useState("");
  const [selectedEmployeeId, setSelectedEmployeeId] = useState();
  useEffect(() => {
    fetchData2();
  }, []);

  const fetchData2 = () => {
    var requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    fetch(
      `${process.env.REACT_APP_API_BASE_URL}/admin/all-employee-list`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status === true) {
          setData1(result.data);
        }
      })
      .catch((error) => console.log("error", error));
  };

  const fetchData = useCallback(() => {
    setLoading(true);
    if (selectedEmployeeId) {
      const requestOptions = {
        method: "GET",
        redirect: "follow",
      };

      fetch(
        `${process.env.REACT_APP_API_BASE_URL}/admin/attendance-list?search=${search}&employee_id=${selectedEmployeeId}&start_date=${start_date}&end_date=${end_date}`,
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          if (result.status === true) {
            setData(result.data);
          }
          setLoading(false);
        })
        .catch((error) => console.log("error", error));
    }
  }, [search, start_date, end_date, selectedEmployeeId]);

  useEffect(() => {
    if (role_id === "1") {
      if (selectedEmployeeId) {
        fetchData();
      }
    }
  }, [fetchData, role_id, selectedEmployeeId]);

  const handleSelectProject = (selectedOption) => {
    const selectedValue = selectedOption.value;
    setData((prevState) => ({
      ...prevState,
      user_id: selectedValue,
    }));
    setSelectedEmployeeId(selectedValue);
    fetchData();
  };

  const fetchData1 = useCallback(() => {
    setLoading(true);
    const requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    fetch(
      `${process.env.REACT_APP_API_BASE_URL}/admin/attendance-list?search=${search}&employee_id=${id}&start_date=${start_date}&end_date=${end_date}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status === true) {
          setData(result.data);
        }
        setLoading(false);
      })
      .catch((error) => console.log("error", error));
  }, [search, start_date, end_date, id]);

  useEffect(() => {
    if (role_id === "2") {
      fetchData1();
    }
  }, [fetchData1, role_id, start_date, end_date]);

  const handleTabChange = (selectedKey) => {
    setSearch(selectedKey);
    setStartDate(""); // Reset start date when changing tabs
    setEndDate(""); // Reset end date when changing tabs
  };

  const customStyles = {
    control: (base, state) => ({
      ...base,
      boxShadow: state.isFocused
        ? "0 0 0 0.1rem rgba(0, 123, 255, 0.25)"
        : null,
      borderColor: state.isFocused ? "#007bff" : base.borderColor,
    }),
  };

  let index = 0;
  let index1 = 0;
  let index2 = 0;
  let index3 = 0;
  let index4 = 0;

  const reload = () => {
    window.location.reload();
  };

  return (
    <section className="left_width" style={{ marginLeft: `${menuWidth}px` }}>
      <div className="d-lg-flex justify-content-between align-items-center pt-4 pb-3 w-100">
        <h4 className="m-0">Attendance Details</h4>
      </div>
      <div className="employee_table p-4 text-center">
        {role_id === "1" ? (
          <div className="row justify-content-between align-items-center">
            <div className="col-lg-11">
              <Select
                options={data1.map((item) => ({
                  value: item.id,
                  label: `${item.first_name} ${item.last_name}`,
                }))}
                styles={customStyles}
                placeholder="Select a Employee"
                onChange={handleSelectProject}
                className="text-uppercase search_select_css mt-2 mb-2"
                style={{ zIndex: "999" }}
                required
              />
            </div>
            <div className="col-lg-1">
              <button className="btn btn-primary puch_btn" onClick={reload}>
                Reset
              </button>
            </div>
          </div>
        ) : (
          ""
        )}
        <div>
          <Tab.Container id="left-tabs-example" defaultActiveKey="thisweek">
            <div className="d-lg-flex justify-content-between ">
              <div className="d-lg-flex justify-content-between align-items-center">
                From
                <Form.Control
                  type="date"
                  className="mx-2 h-75"
                  value={start_date}
                  onChange={(e) => setStartDate(e.target.value)}
                />
                To
                <Form.Control
                  type="date"
                  className="mx-2 h-75"
                  value={end_date}
                  onChange={(e) => setEndDate(e.target.value)}
                />
              </div>
              <Nav
                variant="pills"
                className="d-flex"
                onSelect={handleTabChange}
              >
                <Nav.Item>
                  <Nav.Link eventKey="today" className="tab_css_leave">
                    Today
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="thisweek" className="tab_css_leave">
                    This week
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="lastweek" className="tab_css_leave">
                    Last week
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="thismonth" className="tab_css_leave">
                    This month
                  </Nav.Link>
                </Nav.Item>

                <Nav.Item>
                  <Nav.Link eventKey="thisyear" className="tab_css_leave">
                    This year
                  </Nav.Link>
                </Nav.Item>
              </Nav>
            </div>
            <div className="row justify-content-between mt-4">
              <div className="col-lg-2 col-md-2 col-sm-12 attendance_box text-success">
                <h4 className="m-0">{data.totalpresentday || "00"}</h4>
                <h6 className="small m-0">Presents</h6>
              </div>
              <div className="col-lg-2 col-md-2 col-sm-12 attendance_box text-primary">
                <h4 className="m-0">{data.totalsaturdayeSunday || "00"}</h4>
                <h6 className="small m-0">Saturday Sunday</h6>
              </div>
              <div className="col-lg-2 col-md-2 col-sm-12 attendance_box text-danger">
                <h4 className="m-0">{data.totalleave || "00"}</h4>
                <h6 className="small m-0">Leaves</h6>
              </div>
              <div className="col-lg-2 col-md-2 col-sm-12 attendance_box text-warning">
                <h4 className="m-0">{data.total_working_hourse || "00"}</h4>
                <h6 className="small m-0">Working Hours</h6>
              </div>
              <div className="col-lg-2 col-md-2 col-sm-12 attendance_box text-success">
                <h4 className="m-0">{data.totalholiday || "00"}</h4>
                <h6 className="small m-0">Holidays</h6>
              </div>
            </div>

            <Tab.Content className="mt-2">
              <Tab.Pane eventKey="today">
                {loading ? (
                  <div className="spinner_height">
                    <div className="loader"></div>
                  </div>
                ) : (
                  <div className="table-responsive custom-scrollbar table-view-responsive py-primary">
                    <div className="">
                      <table className="table mb-0">
                        <thead>
                          <tr>
                            <th
                              className=" py-4 px-0 table_color_width"
                              style={{ width: "10px" }}
                            >
                              <span className="font-size-default ">S.No.</span>
                            </th>
                            <th className=" py-4 px-1 table_color text-right ">
                              <span className="font-size-default ">
                                Punch Date
                              </span>
                            </th>
                            <th track-by="2" className=" py-4 px-1 table_color">
                              <span className="font-size-default">
                                <span>Punch-in Time</span>
                              </span>
                            </th>
                            <th track-by="0" className=" py-4 px-1 table_color">
                              <span className="font-size-default">
                                <span>Punch-out Time</span>
                              </span>
                            </th>
                            <th track-by="2" className=" py-4 px-1 table_color">
                              <span className="font-size-default">
                                <span>Status</span>
                              </span>
                            </th>
                            <th track-by="1" className=" py-4 px-1 table_color">
                              <span className="font-size-default ">
                                <span>Punch-in note</span>
                              </span>
                            </th>
                            <th track-by="1" className=" py-4 px-1 table_color">
                              <span className="font-size-default ">
                                <span>Break Time</span>
                              </span>
                            </th>
                            <th className=" py-4 px-1 table_color text-right">
                              <span className="font-size-default">
                                Total Hours
                              </span>
                            </th>
                            <th className=" py-4 px-1 table_color text-right">
                              <span className="font-size-default">
                                Extra Time/Short Time
                              </span>
                            </th>
                            <th className=" py-4 px-1 table_color text-right">
                              <span className="font-size-default">
                                Work Period
                              </span>
                            </th>
                          </tr>
                        </thead>
                        {data.workingList ||
                          data.holidayList ||
                          data.saturdayeSundayList ||
                          data.leaveList ? (
                          <>
                            {data.workingList &&
                              data.workingList.map((item, index) => (
                                <tbody key={item.id || index}>
                                  <tr>
                                    <td>{++index}</td>
                                    {item.work_type === "holiday" ? (
                                      <td>{item.date || "-"}</td>
                                    ) : item.work_type === "leave" ? (
                                      <td  className="text-danger">{item.date || "-"}</td>
                                  
                                    ) : item.work_type === "saturday_sunday" ? (
                                      <td>{item.date || "-"}</td>
                                    ) : (
                                      <td>{item.punch_date || "-"}</td>
                                    )}
                                    {item.work_type === "holiday" ? (
                                      <td colSpan={2}>{item.name || "-"}</td>
                                    ) : item.work_type === "saturday_sunday" ? (
                                      <td colSpan={2}>{item.name || "-"}</td>
                                    ) : item.work_type === "leave" ? (
                                      <td colSpan={2}  className="text-danger">{item.leave_type || "-"}</td>
                                    ) : (
                                      <>
                                        <td>{item.in_time || "-"}</td>
                                        <td>{item.out_time || "-"}</td>
                                      </>
                                    )}
                                    {/* <td>{item.in_ip_data || "-"}</td> */}
                                    {/* <td>{item.work_type || "-"}</td> */}
                                    <td>
                                      {" "}
                                      <div
                                        className={
                                          item.work_type === "working"
                                            ? "present_btn"
                                            : item.work_type === "holiday"
                                              ? "holiday_btn"
                                              : item.work_type ===
                                                "saturday_sunday"
                                                ? "halfday_btn"
                                                : " "
                                        }
                                      >
                                        {item.name || ""}
                                      </div>
                                      <div
                                        className={
                                          item.work_type === "leave"
                                         ? "leave_btn"
                                                : " "
                                        }
                                      >
                                        {item.duration}
                                      </div>
                                    </td>
                                    {item.work_type === "holiday" ? (
                                      <td colSpan={5}>
                                        {item.description || "-"}
                                      </td>
                                    ) : item.work_type === "leave" ? (
                                      <td colSpan={5}  className="text-danger">{item.note || "-"}</td>
                                    ) : item.work_type === "saturday_sunday" ? (
                                      <td colSpan={5}></td>
                                    ) : (
                                      <>
                                        <td>{item.in_punch_note || "-"}</td>
                                        <td>{item.break_time || "-"}</td>
                                        {/* <td>{item.attendance_type || "-"}</td> */}
                                        <td>{item.total_time || "-"}</td>
                                        <td>{item.balance_time || "-"}</td>
                                        <td>{item.day_type || "-"}</td>
                                      </>
                                    )}
                                  </tr>
                                </tbody>
                              ))}

                            <tfoot>
                              <tr>
                                <td></td>
                                <th colSpan={2}>Total Time</th>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <th>{data.total_working_hourse}</th>
                                <td></td>
                                <td></td>
                              </tr>
                              <tr>
                                <td></td>
                                <th colSpan={2}>Total Break Time</th>
                                <td></td>
                                <td></td>
                                <td></td>
                                <th>{data.total_break_time}</th>
                                <td></td>
                                <td></td>
                                <td></td>
                              </tr>
                            </tfoot>
                          </>
                        ) : (
                          <tbody className="pt-5 pb-5">
                            <tr className="text-center">
                              <td colSpan="11" className="text-center">
                                <div>
                                  <img
                                    src="/no_data.svg"
                                    alt="no data"
                                    width="150px"
                                  />
                                  <h6>Nothing to show here</h6>
                                  <p className="small text-secondary">
                                    Please add a new entity or manage the data
                                    table to see the content here. Thank you.
                                  </p>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        )}
                      </table>
                    </div>
                  </div>
                )}
              </Tab.Pane>
              <Tab.Pane eventKey="thisweek">
                {loading ? (
                  <div className="spinner_height">
                    <div className="loader"></div>
                  </div>
                ) : (
                  <div className="table-responsive custom-scrollbar table-view-responsive py-primary">
                    <div className="">
                      <table className="table mb-0">
                        <thead>
                          <tr>
                            <th
                              className=" py-4 px-0 table_color_width"
                              style={{ width: "10px" }}
                            >
                              <span className="font-size-default ">S.No.</span>
                            </th>
                            <th className=" py-4 px-1 table_color text-right ">
                              <span className="font-size-default ">
                                Punch Date
                              </span>
                            </th>
                            <th track-by="2" className=" py-4 px-1 table_color">
                              <span className="font-size-default">
                                <span>Punch-in Time</span>
                              </span>
                            </th>
                            <th track-by="0" className=" py-4 px-1 table_color">
                              <span className="font-size-default">
                                <span>Punch-out Time</span>
                              </span>
                            </th>
                            <th track-by="2" className=" py-4 px-1 table_color">
                              <span className="font-size-default">
                                <span>Status</span>
                              </span>
                            </th>
                            <th track-by="1" className=" py-4 px-1 table_color">
                              <span className="font-size-default ">
                                <span>Punch-in note</span>
                              </span>
                            </th>
                            <th track-by="1" className=" py-4 px-1 table_color">
                              <span className="font-size-default ">
                                <span>Break Time</span>
                              </span>
                            </th>
                            <th className=" py-4 px-1 table_color text-right">
                              <span className="font-size-default">
                                Total Hours
                              </span>
                            </th>
                            <th className=" py-4 px-1 table_color text-right">
                              <span className="font-size-default">
                                Extra Time/Short Time
                              </span>
                            </th>
                            <th className=" py-4 px-1 table_color text-right">
                              <span className="font-size-default">
                                Work Period
                              </span>
                            </th>
                          </tr>
                        </thead>
                        {data.workingList ||
                          data.holidayList ||
                          data.saturdayeSundayList ||
                          data.leaveList ? (
                          <>
                            {data.workingList &&
                              data.workingList.map((item, index) => (
                                <tbody key={item.id || index}>
                                  <tr>
                                    <td>{++index1}</td>
                                    {item.work_type === "holiday" ? (
                                      <td>{item.date || "-"}</td>
                                    ) : item.work_type === "leave" ? (
                                      <td  className="text-danger">{item.date || "-"}</td>
                                    ) : item.work_type === "saturday_sunday" ? (
                                      <td>{item.date || "-"}</td>
                                    ) : (
                                      <td>{item.punch_date || "-"}</td>
                                    )}
                                    {item.work_type === "holiday" ? (
                                      <td colSpan={2}>{item.name || "-"}</td>
                                    ) : item.work_type === "saturday_sunday" ? (
                                      <td colSpan={2}>{item.name || "-"}</td>
                                    ) : item.work_type === "leave" ? (
                                      <td colSpan={2}  className="text-danger">{item.leave_type || "-"}</td>
                                    ) : (
                                      <>
                                        <td>{item.in_time || "-"}</td>
                                        <td>{item.out_time || "-"}</td>
                                      </>
                                    )}
                                    {/* <td>{item.in_ip_data || "-"}</td> */}
                                    {/* <td>{item.work_type || "-"}</td> */}
                                    <td>
                                      {" "}
                                      <div
                                        className={
                                          item.work_type === "working"
                                            ? "present_btn"
                                            : item.work_type === "holiday"
                                              ? "holiday_btn"
                                              : item.work_type ===
                                                "saturday_sunday"
                                                ? "halfday_btn"
                                                : " "
                                        }
                                      >
                                        {item.name || ""}
                                      </div>
                                      <div
                                        className={
                                          item.work_type === "leave"
                                         ? "leave_btn"
                                                : " "
                                        }
                                      >
                                        {item.duration}
                                      </div>
                                    </td>
                                    {item.work_type === "holiday" ? (
                                      <td colSpan={5}>
                                        {item.description || "-"}
                                      </td>
                                      ) : item.work_type === "leave" ? (
                                        <td colSpan={5}  className="text-danger">{item.note || "-"}</td>
                                    ) : item.work_type === "saturday_sunday" ? (
                                      <td colSpan={5}></td>
                                    ) : (
                                      <>
                                        <td>{item.in_punch_note || "-"}</td>
                                        <td>{item.break_time || "-"}</td>
                                        {/* <td>{item.attendance_type || "-"}</td> */}
                                        <td>{item.total_time || "-"}</td>
                                        <td>{item.balance_time || "-"}</td>
                                        <td>{item.day_type || "-"}</td>
                                      </>
                                    )}
                                  </tr>
                                </tbody>
                              ))}
                            <tfoot>
                              <tr>
                                <td></td>
                                <th colSpan={2}>Total Time</th>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <th>{data.total_working_hourse}</th>
                                <td></td>
                                <td></td>
                              </tr>
                              <tr>
                                <td></td>
                                <th colSpan={2}>Total Break Time</th>
                                <td></td>
                                <td></td>
                                <td></td>
                                <th>{data.total_break_time}</th>
                                <td></td>
                                <td></td>
                                <td></td>
                              </tr>
                            </tfoot>
                          </>
                        ) : (
                          <tbody className="pt-5 pb-5">
                            <tr className="text-center">
                              <td colSpan="11" className="text-center">
                                <div>
                                  <img
                                    src="/no_data.svg"
                                    alt="no data"
                                    width="150px"
                                  />
                                  <h6>Nothing to show here</h6>
                                  <p className="small text-secondary">
                                    Please add a new entity or manage the data
                                    table to see the content here. Thank you.
                                  </p>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        )}
                      </table>
                    </div>
                  </div>
                )}
              </Tab.Pane>
              <Tab.Pane eventKey="lastweek">
                {loading ? (
                  <div className="spinner_height">
                    <div className="loader"></div>
                  </div>
                ) : (
                  <div className="table-responsive custom-scrollbar table-view-responsive py-primary">
                    <div className="">
                      <table className="table mb-0">
                        <thead>
                          <tr>
                            <th
                              className=" py-4 px-0 table_color_width"
                              style={{ width: "10px" }}
                            >
                              <span className="font-size-default ">S.No.</span>
                            </th>
                            <th className=" py-4 px-1 table_color text-right ">
                              <span className="font-size-default ">
                                Punch Date
                              </span>
                            </th>
                            <th track-by="2" className=" py-4 px-1 table_color">
                              <span className="font-size-default">
                                <span>Punch-in Time</span>
                              </span>
                            </th>
                            <th track-by="0" className=" py-4 px-1 table_color">
                              <span className="font-size-default">
                                <span>Punch-out Time</span>
                              </span>
                            </th>
                            <th track-by="2" className=" py-4 px-1 table_color">
                              <span className="font-size-default">
                                <span>Status</span>
                              </span>
                            </th>
                            <th track-by="1" className=" py-4 px-1 table_color">
                              <span className="font-size-default ">
                                <span>Punch-in note</span>
                              </span>
                            </th>
                            <th track-by="1" className=" py-4 px-1 table_color">
                              <span className="font-size-default ">
                                <span>Break Time</span>
                              </span>
                            </th>
                            <th className=" py-4 px-1 table_color text-right">
                              <span className="font-size-default">
                                Total Hours
                              </span>
                            </th>
                            <th className=" py-4 px-1 table_color text-right">
                              <span className="font-size-default">
                                Extra Time/Short Time
                              </span>
                            </th>
                            <th className=" py-4 px-1 table_color text-right">
                              <span className="font-size-default">
                                Work Period
                              </span>
                            </th>
                          </tr>
                        </thead>
                        {data.workingList ||
                          data.holidayList ||
                          data.saturdayeSundayList ||
                          data.leaveList ? (
                          <>
                            {data.workingList &&
                              data.workingList.map((item, index) => (
                                <tbody key={item.id || index}>
                                  <tr>
                                    <td>{++index2}</td>
                                    {item.work_type === "holiday" ? (
                                      <td>{item.date || "-"}</td>
                                    ) : item.work_type === "leave" ? (
                                      <td  className="text-danger">{item.date || "-"}</td>
                                    ) : item.work_type === "saturday_sunday" ? (
                                      <td>{item.date || "-"}</td>
                                    ) : (
                                      <td>{item.punch_date || "-"}</td>
                                    )}
                                    {item.work_type === "holiday" ? (
                                      <td colSpan={2}>{item.name || "-"}</td>
                                    ) : item.work_type === "saturday_sunday" ? (
                                      <td colSpan={2}>{item.name || "-"}</td>
                                    ) : item.work_type === "leave" ? (
                                      <td colSpan={2}  className="text-danger">{item.leave_type || "-"}</td>
                                    ) : (
                                      <>
                                        <td>{item.in_time || "-"}</td>
                                        <td>{item.out_time || "-"}</td>
                                      </>
                                    )}
                                    {/* <td>{item.in_ip_data || "-"}</td> */}
                                    {/* <td>{item.work_type || "-"}</td> */}
                                    <td>
                                      {" "}
                                      <div
                                        className={
                                          item.work_type === "working"
                                            ? "present_btn"
                                            : item.work_type === "holiday"
                                              ? "holiday_btn"
                                              : item.work_type ===
                                                "saturday_sunday"
                                                ? "halfday_btn"
                                                : " "
                                        }
                                      >
                                        {item.name || " "}
                                      </div>
                                      <div
                                        className={
                                          item.work_type === "leave"
                                         ? "leave_btn"
                                                : " "
                                        }
                                      >
                                        {item.duration}
                                      </div>
                                    </td>
                                    {item.work_type === "holiday" ? (
                                      <td colSpan={5}>
                                        {item.description || "-"}
                                      </td>
                                      ) : item.work_type === "leave" ? (
                                        <td colSpan={5}  className="text-danger">{item.note || "-"}</td>
                                    ) : item.work_type === "saturday_sunday" ? (
                                      <td colSpan={5}></td>
                                    ) : (
                                      <>
                                        <td>{item.in_punch_note || "-"}</td>
                                        <td>{item.break_time || "-"}</td>
                                        {/* <td>{item.attendance_type || "-"}</td> */}
                                        <td>{item.total_time || "-"}</td>


                                        <td>{item.balance_time || "-"}</td>
                                        <td>{item.day_type || "-"}</td>
                                      </>
                                    )}
                                  </tr>
                                </tbody>
                              ))}
                            <tfoot>
                              <tr>
                                <td></td>
                                <th colSpan={2}>Total Time</th>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <th>{data.total_working_hourse}</th>
                                <td></td>
                                <td></td>
                              </tr>
                              <tr>
                                <td></td>
                                <th colSpan={2}>Total Break Time</th>
                                <td></td>
                                <td></td>
                                <td></td>
                                <th>{data.total_break_time}</th>
                                <td></td>
                                <td></td>
                                <td></td>
                              </tr>
                            </tfoot>
                          </>
                        ) : (
                          <tbody className="pt-5 pb-5">
                            <tr className="text-center">
                              <td colSpan="11" className="text-center">
                                <div>
                                  <img
                                    src="/no_data.svg"
                                    alt="no data"
                                    width="150px"
                                  />
                                  <h6>Nothing to show here</h6>
                                  <p className="small text-secondary">
                                    Please add a new entity or manage the data
                                    table to see the content here. Thank you.
                                  </p>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        )}
                      </table>
                    </div>
                  </div>
                )}
              </Tab.Pane>
              <Tab.Pane eventKey="thismonth">
                {loading ? (
                  <div className="spinner_height">
                    <div className="loader"></div>
                  </div>
                ) : (
                  <div className="table-responsive custom-scrollbar table-view-responsive py-primary">
                    <div className="">
                      <table className="table mb-0">
                        <thead>
                          <tr>
                            <th
                              className=" py-4 px-0 table_color_width"
                              style={{ width: "10px" }}
                            >
                              <span className="font-size-default ">S.No.</span>
                            </th>
                            <th className=" py-4 px-1 table_color text-right ">
                              <span className="font-size-default ">
                                Punch Date
                              </span>
                            </th>
                            <th track-by="2" className=" py-4 px-1 table_color">
                              <span className="font-size-default">
                                <span>Punch-in Time</span>
                              </span>
                            </th>
                            <th track-by="0" className=" py-4 px-1 table_color">
                              <span className="font-size-default">
                                <span>Punch-out Time</span>
                              </span>
                            </th>
                            <th track-by="2" className=" py-4 px-1 table_color">
                              <span className="font-size-default">
                                <span>Status</span>
                              </span>
                            </th>
                            <th track-by="1" className=" py-4 px-1 table_color">
                              <span className="font-size-default ">
                                <span>Punch-in note</span>
                              </span>
                            </th>
                            <th track-by="1" className=" py-4 px-1 table_color">
                              <span className="font-size-default ">
                                <span>Break Time</span>
                              </span>
                            </th>
                            <th className=" py-4 px-1 table_color text-right">
                              <span className="font-size-default">
                                Total Hours
                              </span>
                            </th>
                            <th className=" py-4 px-1 table_color text-right">
                              <span className="font-size-default">
                                Extra Time/Short Time
                              </span>
                            </th>
                            <th className=" py-4 px-1 table_color text-right">
                              <span className="font-size-default">
                                Work Period
                              </span>
                            </th>
                          </tr>
                        </thead>
                        {data.workingList ||
                          data.holidayList ||
                          data.saturdayeSundayList ||
                          data.leaveList ? (
                          <>
                            {data.workingList &&
                              data.workingList.map((item, index) => (
                                <tbody key={item.id || index}>
                                  <tr>
                                    <td>{++index3}</td>
                                    {item.work_type === "holiday" ? (
                                      <td>{item.date || "-"}</td>
                                    ) : item.work_type === "leave" ? (
                                      <td  className="text-danger">{item.date || "-"}</td>
                                    ) : item.work_type === "saturday_sunday" ? (
                                      <td>{item.date || "-"}</td>
                                    ) : (
                                      <td>{item.punch_date || "-"}</td>
                                    )}
                                    {item.work_type === "holiday" ? (
                                      <td colSpan={2}>{item.name || "-"}</td>
                                    ) : item.work_type === "saturday_sunday" ? (
                                      <td colSpan={2}>{item.name || "-"}</td>
                                    ) : item.work_type === "leave" ? (
                                      <td colSpan={2}  className="text-danger">{item.leave_type || "-"}</td>
                                    ) : (
                                      <>
                                        <td>{item.in_time || "-"}</td>
                                        <td>{item.out_time || "-"}</td>
                                      </>
                                    )}
                                    {/* <td>{item.in_ip_data || "-"}</td> */}
                                    {/* <td>{item.work_type || "-"}</td> */}
                                    <td>
                                      {" "}
                                      <div
                                        className={
                                          item.work_type === "working"
                                            ? "present_btn"
                                            : item.work_type === "holiday"
                                              ? "holiday_btn"
                                              : item.work_type ===
                                                "saturday_sunday"
                                                ? "halfday_btn"
                                                : " "
                                        }
                                      >
                                        {item.name || ""}
                                      </div>
                                      <div
                                        className={
                                          item.work_type === "leave"
                                         ? "leave_btn"
                                                : " "
                                        }
                                      >
                                        {item.duration}
                                      </div>
                                    </td>
                                    {item.work_type === "holiday" ? (
                                      <td colSpan={5}>
                                        {item.description || "-"}
                                      </td>
                                      ) : item.work_type === "leave" ? (
                                        <td colSpan={5}  className="text-danger">{item.note || "-"}</td>
                                    ) : item.work_type === "saturday_sunday" ? (
                                      <td colSpan={5}></td>
                                    ) : (
                                      <>
                                        <td>{item.in_punch_note || "-"}</td>
                                        <td>{item.break_time || "-"}</td>
                                        {/* <td>{item.attendance_type || "-"}</td> */}
                                        <td>{item.total_time || "-"}</td>
                                        <td>{item.balance_time || "-"}</td>
                                        <td>{item.day_type || "-"}</td>
                                      </>
                                    )}
                                  </tr>
                                </tbody>
                              ))}
                            <tfoot>
                              <tr>
                                <td></td>
                                <th colSpan={2}>Total Time</th>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <th>{data.total_working_hourse}</th>
                                <td></td>
                                <td></td>
                              </tr>
                              <tr>
                                <td></td>
                                <th colSpan={2}>Total Break Time</th>
                                <td></td>
                                <td></td>
                                <td></td>
                                <th>{data.total_break_time}</th>
                                <td></td>
                                <td></td>
                                <td></td>
                              </tr>
                            </tfoot>
                          </>
                        ) : (
                          <tbody className="pt-5 pb-5">
                            <tr className="text-center">
                              <td colSpan="11" className="text-center">
                                <div>
                                  <img
                                    src="/no_data.svg"
                                    alt="no data"
                                    width="150px"
                                  />
                                  <h6>Nothing to show here</h6>
                                  <p className="small text-secondary">
                                    Please add a new entity or manage the data
                                    table to see the content here. Thank you.
                                  </p>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        )}
                      </table>
                    </div>
                  </div>
                )}
              </Tab.Pane>
              <Tab.Pane eventKey="thisyear">
                {loading ? (
                  <div className="spinner_height">
                    <div className="loader"></div>
                  </div>
                ) : (
                  <div className="table-responsive custom-scrollbar table-view-responsive py-primary">
                    <div className="">
                      <table className="table mb-0">
                        <thead>
                          <tr>
                            <th
                              className=" py-4 px-0 table_color_width"
                              style={{ width: "10px" }}
                            >
                              <span className="font-size-default ">S.No.</span>
                            </th>
                            <th className=" py-4 px-1 table_color text-right ">
                              <span className="font-size-default ">
                                Punch Date
                              </span>
                            </th>
                            <th track-by="2" className=" py-4 px-1 table_color">
                              <span className="font-size-default">
                                <span>Punch-in Time</span>
                              </span>
                            </th>
                            <th track-by="0" className=" py-4 px-1 table_color">
                              <span className="font-size-default">
                                <span>Punch-out Time</span>
                              </span>
                            </th>
                            <th track-by="2" className=" py-4 px-1 table_color">
                              <span className="font-size-default">
                                <span>Status</span>
                              </span>
                            </th>
                            <th track-by="1" className=" py-4 px-1 table_color">
                              <span className="font-size-default ">
                                <span>Punch-in note</span>
                              </span>
                            </th>
                            <th track-by="1" className=" py-4 px-1 table_color">
                              <span className="font-size-default ">
                                <span>Break Time</span>
                              </span>
                            </th>
                            <th className=" py-4 px-1 table_color text-right">
                              <span className="font-size-default">
                                Total Hours
                              </span>
                            </th>
                            <th className=" py-4 px-1 table_color text-right">
                              <span className="font-size-default">
                                Extra Time/Short Time
                              </span>
                            </th>
                            <th className=" py-4 px-1 table_color text-right">
                              <span className="font-size-default">
                                Work Period
                              </span>
                            </th>
                          </tr>
                        </thead>
                        {data.workingList ||
                          data.holidayList ||
                          data.saturdayeSundayList ||
                          data.leaveList ? (
                          <>
                            {data.workingList &&
                              data.workingList.map((item, index) => (
                                <tbody key={item.id || index}>
                                  <tr>
                                    <td >{++index4}</td>
                                    {item.work_type === "holiday" ? (
                                      <td>{item.date || "-"}</td>
                                    ) : item.work_type === "leave" ? (
                                      <td className="text-danger">{item.date || "-"}</td>
                                    ) : item.work_type === "saturday_sunday" ? (
                                      <td>{item.date || "-"}</td>
                                    ) : (
                                      <td>{item.punch_date || "-"}</td>
                                    )}
                                    {item.work_type === "holiday" ? (
                                      <td colSpan={2}>{item.name || "-"}</td>
                                    ) : item.work_type === "saturday_sunday" ? (
                                      <td colSpan={2}>{item.name || "-"}</td>
                                    ) : item.work_type === "leave" ? (
                                      <td colSpan={2}  className="text-danger">{item.leave_type || "-"}</td>
                                    ) : (
                                      <>
                                        <td>{item.in_time || "-"}</td>
                                        <td>{item.out_time || "-"}</td>
                                      </>
                                    )}
                                    {/* <td>{item.in_ip_data || "-"}</td> */}
                                    {/* <td>{item.work_type || "-"}</td> */}
                                    <td>
                                      {" "}
                                      <div
                                        className={
                                          item.work_type === "working"
                                            ? "present_btn"
                                            : item.work_type === "holiday"
                                              ? "holiday_btn"
                                              : item.work_type ===
                                                "saturday_sunday"
                                                ? "halfday_btn"
                                                : " "
                                        }
                                      >
                                        {item.name || ""}
                                      </div>
                                      <div
                                        className={
                                          item.work_type === "leave"
                                         ? "leave_btn"
                                                : " "
                                        }
                                      >
                                        {item.duration}
                                      </div>
                                    </td>
                                    {item.work_type === "holiday" ? (
                                      <td colSpan={5}>
                                        {item.description || "-"}
                                      </td>
                                      ) : item.work_type === "leave" ? (
                                        <td colSpan={5}  className="text-danger">{item.note || "-"}</td>
                                    ) : item.work_type === "saturday_sunday" ? (
                                      <td colSpan={5}></td>
                                    ) : (
                                      <>
                                        <td>{item.in_punch_note || "-"}</td>
                                        <td>{item.break_time || "-"}</td>
                                        {/* <td>{item.attendance_type || "-"}</td> */}
                                        <td>{item.total_time || "-"}</td>


                                        <td>{item.balance_time || "-"}</td>
                                        <td>{item.day_type || "-"}</td>
                                      </>
                                    )}
                                  </tr>
                                </tbody>
                              ))}

                            <tfoot>
                              <tr>
                                <td></td>
                                <th colSpan={2}>Total Time</th>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <th>{data.total_working_hourse}</th>
                                <td></td>
                                <td></td>
                              </tr>
                              <tr>
                                <td></td>
                                <th colSpan={2}>Total Break Time</th>
                                <td></td>
                                <td></td>
                                <td></td>
                                <th>{data.total_break_time}</th>
                                <td></td>
                                <td></td>
                                <td></td>
                              </tr>
                            </tfoot>
                          </>
                        ) : (
                          <tbody className="pt-5 pb-5">
                            <tr className="text-center">
                              <td colSpan="11" className="text-center">
                                <div>
                                  <img
                                    src="/no_data.svg"
                                    alt="no data"
                                    width="150px"
                                  />
                                  <h6>Nothing to show here</h6>
                                  <p className="small text-secondary">
                                    Please add a new entity or manage the data
                                    table to see the content here. Thank you.
                                  </p>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        )}
                      </table>
                    </div>
                  </div>
                )}
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>
        </div>
      </div>

      {/*---------------Add Attendance-------------------------*/}
      {/* 
      <Modal show={showLeave} onHide={handleCloseleave} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Add Attendance</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <div>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlTextarea1"
              >
                <Form.Label>Employee</Form.Label>
                <Form.Select aria-label="Default select example">
                  <option>Select an employee</option>
                  <option value="1">One</option>
                  <option value="2">Two</option>
                  <option value="3">Three</option>
                </Form.Select>
              </Form.Group>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlTextarea1"
              >
                <Form.Label>Punch in date and time</Form.Label>
                <Form.Control
                  type="date"
                  placeholder="Enter punch in date and time"
                />
              </Form.Group>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlTextarea1"
              >
                <Form.Label>Punch out date and time</Form.Label>
                <Form.Control
                  type="date"
                  placeholder="Enter punch out date and time"
                />
              </Form.Group>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlTextarea1"
              >
                <Form.Label>Reason note for manual entry</Form.Label>
                <Form.Control as="textarea" placeholder="Enter note" rows={3} />
              </Form.Group>
            </div>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-secondary puch_btn"
            onClick={handleCloseleave}
          >
            Cancel
          </button>
          <button
            className="btn btn-primary puch_btn"
            onClick={handleCloseleave}
          >
            Save
          </button>
        </Modal.Footer>
      </Modal> */}
    </section>
  );
};
export default AttendanceDetails;
