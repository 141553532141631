

export const handleLogout = (setIsLoggedIn) => {

    const logoutUser = () => {
        // Clear localStorage and sessionStorage
        localStorage.clear();
        sessionStorage.clear();
        setIsLoggedIn(false);
        window.location.href = "/";
    };

    return logoutUser;
};