import React, { useEffect, useState } from "react";
import { Form, Spinner } from "react-bootstrap";
import { useAuth } from "../utils/Context";
import { useNavigate, useParams } from "react-router-dom";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const EditDailyWorkReport = ({ menuWidth }) => {
  const { id } = useAuth();
  const { work_report_id } = useParams();
  const [projectList, setProjectList] = useState([]);
  const [validated, setValidated] = useState(false);
/* eslint-disable */
const [selectedProject, setSelectedProject] = useState(null);
/* eslint-enable */
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [data, setData] = useState({
    work_report_id: "",
    user_id: "",
    project_id: "",
    from_time: "",
    to_time: "",
    description: "",
    status: "",
    remark: "",
  });

  const [isValid, setIsValid] = useState(true);

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_BASE_URL}/admin/project-list`, {
      method: "GET",
      redirect: "follow",
    })
      .then((response) => response.json())
      .then((result) => {
        if (result.status === true) {
          setProjectList(result.data);
        }
      })
      .catch((error) => console.log("error", error));
  }, []);

  useEffect(() => {
    fetch(
      `${process.env.REACT_APP_API_BASE_URL}/admin/find_work_report?id=${work_report_id}`,
      {
        method: "GET",
        redirect: "follow",
      }
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status === true) {
          setData(result.data);
          setSelectedProject({
            value: result.data.project_id,
            label: result.data.project_name,
          });
        }
      })
      .catch((error) => console.error(error));
  }, [work_report_id]);

  const handleChange = (content) => {
    setData((prevState) => ({ ...prevState, description: content }));
    setIsValid(!!content.trim());
  };

  const handleSubmit = (e) => {
    setLoading(true);
    e.preventDefault();
    setValidated(true);
    const formdata = new FormData();
    formdata.append("work_report_id", work_report_id);
    formdata.append("user_id", id);
    formdata.append("project_id", data.project_id);
    formdata.append("from_time", data.from_time);
    formdata.append("to_time", data.to_time);
    formdata.append("description", data.description);
    formdata.append("status", data.status);
    formdata.append("remark", data.remark);

    fetch(
      `${process.env.REACT_APP_API_BASE_URL}/admin/edit_work_report`,
      {
        method: "POST",
        body: formdata,
        redirect: "follow",
      }
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status === true) {
          navigate("/dailyworkreport");
          setValidated(false);
        }
        setLoading(false);
      })
      .catch((error) => console.error(error));
  };

  const goBack = () => {
    navigate(-1);
  };

  return (
    <section className="left_width" style={{ marginLeft: `${menuWidth}px` }}>
      <div className="py-4">
        <Form
          className="attendance px-4 pt-2 pb-4 my-5"
          noValidate
          validated={validated}
          onSubmit={handleSubmit}
        >
          <Form.Group className="mb-3" controlId="projectSelect">
            <Form.Label className="mt-3 editor_font">Project Name</Form.Label>
            <select
              className="text-uppercase search_select_css form-select"
              value={data.project_id}
              onChange={(e) => setData({ ...data, project_id: e.target.value })}
            >
              <option selected disabled value="">
                Select Project Name
              </option>
              {projectList.map((item) => (
                <option key={item.id} value={item.id}>
                  {item.project_name}
                </option>
              ))}
            </select>
            <Form.Control.Feedback type="invalid">
              Please select project.
            </Form.Control.Feedback>
          </Form.Group>
          <div className="d-lg-flex">
            <Form.Group className="mb-3 w-100" controlId="fromTime">
              <Form.Label className="editor_font">From Time</Form.Label>
              <Form.Control
                type="time"
                className="editor_font"
                value={data.from_time}
                onChange={(e) =>
                  setData({ ...data, from_time: e.target.value })
                }
                required
              />
              <Form.Control.Feedback type="invalid">
                Please select start time.
              </Form.Control.Feedback>
            </Form.Group>
            &nbsp;
            <Form.Group className="mb-3 w-100" controlId="toTime">
              <Form.Label className="editor_font">To Time</Form.Label>
              <Form.Control
                type="time"
                className="editor_font"
                value={data.to_time}
                onChange={(e) => setData({ ...data, to_time: e.target.value })}
                required
              />
              <Form.Control.Feedback type="invalid">
                Please select end time.
              </Form.Control.Feedback>
            </Form.Group>
          </div>
          <Form.Group
            className="mb-3"
            style={{ height: "215px" }}
            controlId="description"
          >
            <Form.Label className="editor_font">Description</Form.Label>
            <ReactQuill
              theme="snow"
              value={data.description}
              onChange={handleChange}
              style={{ height: "150px" }}
            />
            {!isValid && data.description.length === 0 && (
              <div type="invalid" className="text-danger small">
                Please enter report description.
              </div>
            )}
          </Form.Group>
          <Form.Group className="mb-3 mt-4" controlId="workStatus">
            <Form.Label className="editor_font">Work Status</Form.Label>
            <Form.Select
              aria-label="Default select example"
              className="search_select_css text-uppercase"
              value={data.status}
              onChange={(e) => setData({ ...data, status: e.target.value })}
              required
            >
              <option disabled value="">
                Work Status
              </option>
              <option value="1">Complete</option>
              <option value="2">Pending</option>
              <option value="3">Under Process</option>
            </Form.Select>
            <Form.Control.Feedback type="invalid">
              Please select work status.
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="mb-3 w-100" controlId="remark">
            <Form.Label className="editor_font">Remark</Form.Label>
            <Form.Control
              type="text"
              className="editor_font"
              value={data.remark}
              onChange={(e) => setData({ ...data, remark: e.target.value })}
            />
          </Form.Group>
          <div>
          {loading ? 
               <button className="btn btn-primary puch_btn" type="button">
               <Spinner animation="grow" size="sm" style={{ background: "#fff" }} />
               &nbsp;
               <Spinner animation="grow" size="sm" style={{ background: "#fff" }} />
             </button>
             :
              <button className="btn btn-primary puch_btn" type="submit">
                Save
              </button>
}
            {/* <button type="submit" className="btn btn-primary puch_btn">
              Save
            </button> */}
            &nbsp;
            <button
              type="button"
              className="btn btn-secondary puch_btn"
              onClick={goBack}
            >
              Back
            </button>
          </div>
        </Form>
      </div>
    </section>
  );
};

export default EditDailyWorkReport;
