import React, { useCallback, useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
// import { BsGrid1X2 } from "react-icons/bs";
import { FaEdit } from "react-icons/fa";
import { useAuth } from "../utils/Context";
import { Alert } from "react-bootstrap";
import { BsDownload } from "react-icons/bs";
import { RiDeleteBin5Line } from "react-icons/ri";
// import { Spinner } from "react-bootstrap";

const EmploymentStatus = ({ menuWidth }) => {
  const {id} = useAuth()
  const [show, setShow] = useState(false);
  const [showStatus, setShowStatus] = useState(false);
  const handleShow = () => setShow(true);
  const handlestatusClose = () => setShowStatus(false);
  const handlestatusShow = () => setShowStatus(true);
const [employment, setEmployment] = useState([]);
const[loading, setLoading] = useState(false)
const [validated, setValidated] = useState(false);
const [employmentDelete, setEmploymentDelete] = useState();
const handleClose = () => {
  setShow(false)
  setValidated(false)
  setAddstatus({
    id: '', 
  name: '',
  color_name: '',
  description: '',
  });
};
const[employmentid, setEmploymentid] = useState(null)

const [addStatus, setAddstatus] = useState({
  id: '', 
  name: '',
  color_name: '',
  description: '',
  error:''
});
const [updateStatus, setUpdateStatus] = useState({
  id: '', 
  name: '',
  color_name: '',
  description: '',
});

const handleAddSatus =(event)=> {
  event.preventDefault();
  const form = event.currentTarget;
  if (form.checkValidity() === false) {
    event.preventDefault();
    event.stopPropagation();
  }
  setValidated(true)
  var formdata = new FormData();
formdata.append("name", addStatus.name);
formdata.append("color_name", addStatus.color_name);
formdata.append("description", addStatus.description);

var requestOptions = {
  method: 'POST',
  body: formdata,
  redirect: 'follow'
};

fetch(`${process.env.REACT_APP_API_BASE_URL}/admin/employment-statuses-add`, requestOptions)
  .then(response => response.json())
  .then(result => {
    if(result.status === true){
      setAddstatus({
        error:"",
      })
      handleClose()
      fetchData()
    
  } else {
    setAddstatus({ ...addStatus, error: result.message });
  }
  })
  .catch(error => console.log('error', error));
}

useEffect(() => {
  if (employmentid) {

  var formdata = new FormData();
  formdata.append('id', employmentid);

var requestOptions = {
  method: 'POST',
  body: formdata,
  redirect: 'follow'
};

fetch(`${process.env.REACT_APP_API_BASE_URL}/admin/employment-statuses-view`, requestOptions)
  .then(response => response.json())
  .then(result => {
if(result.status === true){
  setUpdateStatus(result.data);
}
    })
  .catch(error => console.log('error', error));
  }
},[employmentid])



const fetchData = useCallback(() => {
  setLoading(true)
if(id) {
  var requestOptions = {
    method: 'GET',
    redirect: 'follow'
  };
  fetch(`${process.env.REACT_APP_API_BASE_URL}/admin/employment-statuses-list`, requestOptions)
    .then(response => response.json())
    .then(result => {
      if(result.status === true ){
        setEmployment(result.data)
      }
      setLoading(false)
    })
    .catch(error => console.log('error', error));
  }
},[id])

useEffect(()=>{
  fetchData()
},[fetchData])


const handleEditStatus =()=> {
  if (employmentid) {
  var formdata = new FormData();
  formdata.append("id", employmentid);
formdata.append("name", updateStatus.name);
formdata.append("color_name", updateStatus.color_name);
formdata.append("description", updateStatus.description);

var requestOptions = {
  method: 'POST',
  body: formdata,
  redirect: 'follow'
};

fetch(`${process.env.REACT_APP_API_BASE_URL}/admin/employment-statuses-add`, requestOptions)
  .then(response => response.json())
  .then(result => {
    if(result.status === true){
      handlestatusClose()
      fetchData()
    }
  })
  .catch(error => console.log('error', error));
}
}
const handleEditStatusopen = (employmentid) => {
  setEmploymentid(employmentid);
  handlestatusShow();
};

const [accept, setAccept] = useState(false);

const handleAccept = (employmentid) => {
  setEmploymentDelete({ employmentid });
  setAccept(true);
};
const handleAcceptClose = () => setAccept(false);

const handleDelete = (id) => {
  var requestOptions = {
    method: "GET",
    redirect: "follow",
  };

  fetch(
    `${process.env.REACT_APP_API_BASE_URL}/admin/delete-employment?employment_id=${id}`,
    requestOptions
  )
    .then((response) => response.json())
    .then((result) => {
      if (result.status === true) {
        setEmploymentDelete(employmentDelete);
        fetchData();
        handleAcceptClose();
      }
    })
    .catch((error) => console.log("error", error));
};


  return (
    <section className="left_width" style={{ marginLeft: `${menuWidth}px` }}>
      <div className="d-lg-flex justify-content-between align-items-center pt-4 pb-3 w-100">
        <h4>Employment Status</h4>
        <div>
          <button className="btn btn-primary puch_btn" onClick={handleShow}>
          Add Employment Status
          </button>
        </div>
      </div>
      <div>
        <div className="table-responsive custom-scrollbar table-view-responsive employee_table py-primary">
        {!loading ?
          <table className="table mb-0">
            <thead>
              <tr>
                <th track-by="0" className="datatable-th py-2 px-3 table_color ">
                  <span className="font-size-default">
                    <span>Name</span>
                  </span>
                </th>
                <th track-by="1" className="datatable-th py-2 px-3 table_color">
                  <span className="font-size-default">
                    <span>Preview</span>
                  </span>
                </th>
                <th track-by="2" className="datatable-th py-2 px-3 table_color">
                  <span className="font-size-default">
                    <span>Description</span>
                  </span>
                </th>
                
                <th
                  track-by="3"
                  className="datatable-th py-2 px-3 table_color text-right"
                >
                  <span className="font-size-default">Actions</span>
                </th>
              </tr>
            </thead>
            {employment.length > 0 ?
            <tbody>
            {employment && employment.map((item)=>
              <tr key={item.id}>
                <td className="py-2 px-3">
                  <span className="">{item.name}</span>
                </td>
                <td className="py-2 px-3">
                  <span>
                    <span  className={`rounded-pill py-1 px-2 text-white p_css ${
                     item.color_name === "purple" ? 'bg-danger' :
                     item.color_name === "success" ? 'bg-success' :
                     item.color_name === 'warning' ? 'bg-warning' : 
                     item.color_name === 'danger' ? 'bg-danger' : 
                     item.color_name === 'primary' ? 'bg-primary' : 
                     ' '
                     }`}>
                      {item.name}
                    </span>
                  </span>
                </td>
                <td className="py-2 px-3">
                  <span>{item.description || "-"}</span>
                </td>
                <td className="py-2 px-3">
                     <FaEdit onClick={()=>handleEditStatusopen(item.id)} className="left_icon_css"/>
                     &nbsp; &nbsp; &nbsp;
                          <span className="hover_btn">
                            <RiDeleteBin5Line
                              onClick={() => handleAccept(item.id)}
                              className="left_icon_css text-danger"
                            />
                          </span>
                </td>
              </tr>
              )}  
            </tbody>
            :
            <tbody>
                  <tr className="text-center">
            <td colSpan="4" >
            <span className="pt-5 pb-5">
              <img
                src="/no_data.svg"
                alt="no data"
                width="150px"
              />
              <h6>Nothing to show here</h6>
              <p className="small text-secondary">
                Please add a new entity or manage the data table to see the
                content here <br/>Thank you
              </p>
            </span>

            </td>
                </tr>
</tbody>}
          </table>
             :
             <div  className="spinner_height">
                     {/* <Spinner animation="border" size="lg"/> */}
                     <div className="loader"></div>
                     </div>
            }
        </div>
      </div>

      {/*-------------------Add-Employement-status----------------------------*/}

      <Modal show={show} onHide={handleClose} size="md">
        <Modal.Header closeButton className="p-4">
          <Modal.Title>Add Employment Status</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form noValidate validated={validated} onSubmit={handleAddSatus}>
            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlTextarea1"
            >
              <Form.Label>Name</Form.Label>
              <Form.Control 
              type="text" 
              placeholder="Enter Name"
          
               value={addStatus.name}  
               onChange={(e) =>
               setAddstatus({ ...addStatus, name: e.target.value })} 
               required
               />
                  <Form.Control.Feedback type="invalid">
            Please enter name.
          </Form.Control.Feedback>
            </Form.Group>

            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlTextarea1"
            >
              <Form.Label>Color value</Form.Label>
              <Form.Select value={addStatus.color_name}  
               onChange={(e) =>
               setAddstatus({ ...addStatus, color_name: e.target.value })}
               required
               >
                <option selected  disabled value=''>Select a color value</option>
                <option value='purple'>Purple</option>
                <option value='success'>Green</option>
                <option value='warning'>Yellow</option>
                <option value='danger'>Red</option>
                <option value='primary'>Blue</option>
              </Form.Select>
              <Form.Control.Feedback type="invalid">
            Please select color.
          </Form.Control.Feedback>
            </Form.Group>
            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlTextarea1"
            >
              <Form.Label>Description</Form.Label>
              <Form.Control as="textarea"
               placeholder="Enter note"
                rows={3} 
                value={addStatus.description}
                onChange={(e) => setAddstatus({ ...addStatus, description: e.target.value })}
                />
            </Form.Group>
            {addStatus.error && (
              <Alert variant="danger">{addStatus.error}</Alert>
            )}
        <Modal.Footer>
          <button className="btn btn-secondary puch_btn" type="button" onClick={handleClose}>
            Cancel
          </button>
          <button className="btn btn-primary puch_btn"  type="submit">
            Save
          </button>
        </Modal.Footer>
        </Form>
        </Modal.Body>
      </Modal>
      {/* -------------------Edit-Employement-status--------------------------- */}

      <Modal show={showStatus} onHide={handlestatusClose} size="md">
        <Modal.Header closeButton className="p-4  ">
          <Modal.Title>Edit Employment Status</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlTextarea1"
            >
              <Form.Label>Name</Form.Label>
              <Form.Control type="text"
               placeholder="Enter Amount"
               value={updateStatus.name}
               onChange={(e)=>
                setUpdateStatus({ ...updateStatus, name: e.target.value })}
               />
            </Form.Group>

            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlTextarea1"
              
            >
            <Form.Label>Color value</Form.Label>
              <Form.Select  value={updateStatus.color_name}  
               onChange={(e) =>
                setUpdateStatus({ ...updateStatus, color_name: e.target.value })}>
               <option value='purple'>Purple</option>
                <option value='success'>Green</option>
                <option value='warning'>Yellow</option>
                <option value='danger'>Red</option>
                <option value='primary'>Blue</option>
              </Form.Select>
            </Form.Group>

{/* <div className="status_color my-2">
                  
                    <span  className={`rounded-pill py-1 px-2 text-white p_css ${
                     updateStatus.color_name === "purple" ? 'bg-danger' :
                     updateStatus.color_name === "success" ? 'bg-success' :
                     updateStatus.color_name === "Info" ? 'bg-info' :
                     updateStatus.color_name === 'warning' ? 'bg-warning' : 
                     updateStatus.color_name === 'warning' ? 'bg-warning' : 
                     updateStatus.color_name === 'warning' ? 'bg-warning' : 
                     ' '
                     }`}>
                          {updateStatus.name}
                    </span> &nbsp;  This will be the badge of the employee</div> */}

            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlTextarea1"
            >
              <Form.Label>Description</Form.Label>
              <Form.Control as="textarea" 
              placeholder="Enter note" 
              rows={3} 
              value={updateStatus.description}
             onChange={(e) => setUpdateStatus({ ...updateStatus,description:e.target.value })}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-secondary puch_btn" onClick={handlestatusClose}>
            Cancel
          </button>
          <button className="btn btn-primary puch_btn"  onClick={handleEditStatus}>
            Save
          </button>
        </Modal.Footer>
      </Modal>



        {/* ----------------delete popup ----------------*/}
        <Modal
        show={accept}
        onHide={handleAcceptClose}
        size="md"
        centered
        className="pt-5 pb-5"
      >
        <Modal.Body>
          <div className="text-center mt-5">
            <BsDownload className="jobdisk_icon" />
            <h5>Are you sure?</h5>
            <p>You are going to delete this Employment</p>
          </div>
          <Modal.Footer className="border-0 justify-content-center  mb-4">
            <button
              className="btn btn-secondary puch_btn"
              onClick={handleAcceptClose}
            >
              No
            </button>
            <button
              className="btn btn_color puch_btn"
              onClick={() => handleDelete(employmentDelete.employmentid)}
            >
              Yes
            </button>
          </Modal.Footer>
        </Modal.Body>
      </Modal>
    </section>
  );
};
export default EmploymentStatus;
