import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import { FiBookOpen } from "react-icons/fi";
import Tab from "react-bootstrap/Tab";
import Nav from "react-bootstrap/Nav";
import { Dropdown } from "react-bootstrap";
import ReactApexChart from "react-apexcharts";

const AttendanceSummary = ({ menuWidth }) => {
  /* eslint-disable */
  const [show, setShow] = useState(false);
  const [showInvite, setShowInvite] = useState(false);
  const [singleday, setSingleday] = useState(true);
  const [showAvailability, setShowAvailability] = useState(false);
  const [showLeave, setShowLeave] = useState(false);
  //   const [multiday, setMultiday] = useState(false);
  const handleClose = () => setShow(false);

  const handleInviteClose = () => setShowInvite(false);

  const toggleMultiday = () => {
    setSingleday(!singleday);
  };

  //   const toggleSingleday = () => {
  //     setMultiday(!multiday);
  //   };

  const tonggleAvailability = () => {
    setShowAvailability(!showAvailability);
  };
  const handleLeave = () => setShowLeave(true);
  const handleCloseleave = () => setShowLeave(false);

  const [chartOptions, setChartOptions] = useState({
    chart: {
      width: 600,
      type: "donut",
    },
    plotOptions: {
      pie: {
        startAngle: -90,
        endAngle: 270,
      },
    },
    dataLabels: {
      enabled: false,
    },
    fill: {
      type: "gradient",
    },
    legend: {
      formatter: function (val, opts) {
        return val + " - " + opts.w.globals.series[opts.seriesIndex];
      },
    },

    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
          },
          legend: {
            position: "bottom",
          },
          
        },
      },
    ],
    labels: ["Early", "Late", "Regular",'On leave'],
  });

  const [chartSeries, setChartSeries] = useState([0+'day',0+'day', 0+'day', 0+'day']);


  const [summary, setSummary] = useState('');

  
  return (
    <section className="left_width p-2" style={{ marginLeft: `${menuWidth}px` }}>
      <div className="d-lg-flex justify-content-between align-items-center pt-4 w-100">
        <h4>Summary</h4>
        <div>
          <button className="btn btn-success puch_btn mt-1">Export All</button>
          &nbsp; &nbsp;
          <button className="btn btn-success puch_btn mt-1">Export</button>
          &nbsp; &nbsp;
          <button
            className="btn btn-primary puch_btn mt-1"
            onClick={handleLeave}
          >
            Add Attendance
          </button>
        </div>
      </div>

      <div>
        <Dropdown>
          <Dropdown.Toggle
            variant="success"
            id="dropdown-basic"
            className="tab_css"
          >
            Users
          </Dropdown.Toggle>

          <Dropdown.Menu className="" width="280px">
            <input type="search" className="tab_css border" />
            <Dropdown.Item href="/action-2" className="dropdown_text">
              sgt
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>
      <div className="employee_table p-4 text-center h-auto">
        <div>
          
          <Tab.Container id="left-tabs-example" defaultActiveKey="today">
            <div className="d-flex justify-content-between ">
              
              <Nav variant="pills" className="d-flex">
                <Nav.Item>
                  <Nav.Link eventKey="today" className="tab_css_leave">
                    Today
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="second" className="tab_css_leave">
                    This week
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="lastweek" className="tab_css_leave">
                    Last week
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="second" className="tab_css_leave">
                    This month
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="second" className="tab_css_leave">
                    Last month
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="second" className="tab_css_leave">
                    This year
                  </Nav.Link>
                </Nav.Item>
              </Nav>
            </div>
            <Tab.Content className="border-top">
              <Tab.Pane eventKey="today">
                <div className="d-flex m-4">
                  <h6 className="name_width">SG</h6>
                  {/* <span className="status bg-success"></span> */}
                  <div className="mx-2">
                    <p className="text-primary m-0 text_css">SGT</p>
                    <small className="m-0">Main Department, EMP-1</small>
                  </div>
                </div>

                <div className=" d-lg-flex justify-content-between mb-3 mt-3">
                  <div className="box_css_leave">
                    <h6 className="m-0">09:30:00</h6>
                    <p className="m-0">Total schedule hour</p>
                  </div>
                  <div className="box_css_leave">
                    <h6 className="m-0">00:00:00</h6>
                    <p className="m-0">Total work availability</p>
                  </div>
                  <div className="box_css_leave">
                    <h6 className="m-0 text-danger">0.00%</h6>
                    <p className="m-0">Pending request</p>
                  </div>
                </div>
                <div className=" d-lg-flex justify-content-between mb-2">
                  <div className="box_css_leave">
                    <h6 className="m-0">00:00:00</h6>
                    <h6 className="m-0">(0.00 h)</h6>
                    <p className="m-0">Total active hour</p>
                  </div>
                  <div className="box_css_leave">
                    <h6 className="m-0 text-danger">-9:30:00</h6>
                    <h6 className="m-0 text-danger">(-9.50 h)</h6>
                    <p className="m-0">Balance (Lack)</p>
                  </div>
                  <div className="box_css_leave">
                    <h6 className="m-0 text-danger">Absent</h6>

                    <p className="m-0">Average Behaviour</p>
                  </div>
                </div>

                <div className="table-responsive custom-scrollbar table-view-responsive  py-primary">
                  <table className="table mb-0">
                    <thead>
                      <tr>
                        <th
                          track-by="1"
                          className="datatable-th py-4 px-3 table_color"
                        >
                          <span className="font-size-default">
                            <span>Date & time</span>
                          </span>
                        </th>
                        <th
                          track-by="2"
                          className="datatable-th py-4 px-3 table_color"
                        >
                          <span className="font-size-default">
                            <span>Leave duration</span>
                          </span>
                        </th>
                        <th
                          track-by="3"
                          className="datatable-th py-4 px-3 table_color text-right"
                        >
                          <span className="font-size-default">Leave Type</span>
                        </th>
                        <th
                          track-by="3"
                          className="datatable-th py-4 px-3 table_color text-right"
                        >
                          <span className="font-size-default">Attachments</span>
                        </th>
                        <th
                          track-by="0"
                          className="datatable-th py-4 px-3 table_color "
                        >
                          <span className="font-size-default">
                            <span>Status</span>
                          </span>
                        </th>
                        <th
                          track-by="3"
                          className="datatable-th py-4 px-3 table_color text-right"
                        >
                          <span className="font-size-default">Activity</span>
                        </th>
                        <th
                          track-by="3"
                          className="datatable-th py-4 px-3 table_color text-right"
                        >
                          <span className="font-size-default">Actions</span>
                        </th>
                      </tr>
                    </thead>
                    <tbody></tbody>
                  </table>
                  <div className="pt-5 pb-5">
                    <img
                      src="https://hrm.bigzero.co.in/images/no_data.svg"
                      alt="no data"
                      width="150px"
                    />
                    <h6>Nothing to show here</h6>
                    <p>
                      Please add a new entity or manage the data table to see
                      the content here Thank you
                    </p>
                  </div>
                </div>
              </Tab.Pane>
              <Tab.Pane eventKey="lastweek">
                <div className="d-flex m-4">
                  <h6 className="name_width">SG</h6>
                  {/* <span className="status bg-success"></span> */}
                  <div className="mx-2">
                    <p className="text-primary m-0 text_css">SGT</p>
                    <small className="m-0">Main Department, EMP-1</small>
                  </div>
                </div>

                <div className=" d-lg-flex justify-content-between mb-3 mt-3 align-items-center">
                  <div id="chart">
                    <ReactApexChart
                      options={chartOptions}
                      series={chartSeries}
                      type="donut"
                      width={300}
                    />
                  </div>
                  <div className=" d-lg-flex flex-wrap justify-content-between mb-3 mt-3">
                    <div className="box_css_leave">
                      {summary&& (<h6 className="m-0">{summary.scheduled}</h6>)}
                      <p className="m-0">Total schedule hour</p>
                    </div>
                    <div className="box_css_leave">
                    {summary&& (<h6 className="m-0">{summary.paid_leave}</h6>)}
                      <p className="m-0">Leave hour (paid)</p>
                    </div>
                    <div className="box_css_leave">
                    {summary&& (<h6 className="m-0">{summary.percentage}</h6>)}
                      <p className="m-0">Total work availability</p>
                    </div>
                    <div className="box_css_leave">
                    {summary&& (<h6 className="m-0">{summary.worked}</h6>)}
                      <h6 className="m-0">(0.00 h)</h6>
                      <p className="m-0">Total active hour</p>
                    </div>
                    <div className="box_css_leave">
                    {summary&& (<h6 className="m-0">{summary.balance}</h6>)}
                      <h6 className="m-0 text-danger">(-9.50 h)</h6>
                      <p className="m-0">Balance (Lack)</p>
                    </div>
                    <div className="box_css_leave">
                      
                      {summary&& (<h6 className="m-0 text-danger">{summary.average}</h6>)}
                      <p className="m-0">Average Behaviour</p>
                    </div>
                  </div>
                </div>

                <div className="table-responsive custom-scrollbar table-view-responsive  py-primary">
                  <table className="table mb-0">
                    <thead>
                      <tr>
                        <th
                          track-by="1"
                          className="datatable-th py-4 px-3 table_color"
                        >
                          <span className="font-size-default">
                            <span>Date & time</span>
                          </span>
                        </th>
                        <th
                          track-by="2"
                          className="datatable-th py-4 px-3 table_color"
                        >
                          <span className="font-size-default">
                            <span>Leave duration</span>
                          </span>
                        </th>
                        <th
                          track-by="3"
                          className="datatable-th py-4 px-3 table_color text-right"
                        >
                          <span className="font-size-default">Leave Type</span>
                        </th>
                        <th
                          track-by="3"
                          className="datatable-th py-4 px-3 table_color text-right"
                        >
                          <span className="font-size-default">Attachments</span>
                        </th>
                        <th
                          track-by="0"
                          className="datatable-th py-4 px-3 table_color "
                        >
                          <span className="font-size-default">
                            <span>Status</span>
                          </span>
                        </th>
                        <th
                          track-by="3"
                          className="datatable-th py-4 px-3 table_color text-right"
                        >
                          <span className="font-size-default">Activity</span>
                        </th>
                        <th
                          track-by="3"
                          className="datatable-th py-4 px-3 table_color text-right"
                        >
                          <span className="font-size-default">Actions</span>
                        </th>
                      </tr>
                    </thead>
                    <tbody>

<tr>
  <td></td>
  <td></td>
  <td></td>
  <td></td>
  <td></td>
  <td></td>
  <td></td>
</tr>


                    </tbody>
                  </table>
                  {/* <div className="pt-5 pb-5">
                    <img
                      src="https://hrm.bigzero.co.in/images/no_data.svg"
                      alt="no data"
                      width="150px"
                    />
                    <h6>Nothing to show here</h6>
                    <p>
                      Please add a new entity or manage the data table to see
                      the content here Thank you
                    </p>
                  </div> */}
                </div>
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>
        </div>
      </div>

      {/* -------------------Add Employee---------------------------- */}

      <Modal show={show} onHide={handleClose} size="lg">
        <Modal.Header closeButton className="p-4  ">
          <Modal.Title>Add Employee</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <div className="d-flex align-items-center">
              <FiBookOpen className="text-warning h5" />
              &nbsp;&nbsp;
              <h6> No email settings found.</h6>
            </div>

            <div className="employee_bg">
              <ol>
                <li>
                  Please make sure you setup the cron job on your hosted server
                  as instructed on the <a href="/">documentation</a> for sending
                  emails.
                </li>
                <li>
                  For sending invitation you must set up email settings. Click{" "}
                  <a href="/">here</a> to add email settings.
                </li>
              </ol>
            </div>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-secondary puch_btn" onClick={handleClose}>
            Close
          </button>
        </Modal.Footer>
      </Modal>

      {/*---------------Assign Leave-------------------------*/}
      <Modal show={showLeave} onHide={handleCloseleave} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Assign leave</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <p>
              Leave availability{" "}
              {showAvailability === true ? (
                <span className="text-primary" onClick={tonggleAvailability}>
                  Hide
                </span>
              ) : (
                <span className="text-primary" onClick={tonggleAvailability}>
                  Show
                </span>
              )}
            </p>
            {showAvailability && (
              <div>No available leaves for this employee</div>
            )}
            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlTextarea1"
            >
              <Form.Label>Leave Type</Form.Label>
              <Form.Select aria-label="Default select example">
                <option>Chose a Teave Type</option>
                <option value="1">One</option>
                <option value="2">Two</option>
                <option value="3">Three</option>
              </Form.Select>
            </Form.Group>

            <Form.Group className="mb-3 d-flex">
              <Form.Label>Age (Leave duration)</Form.Label>

              <Form.Check
                type="radio"
                label="Single Day"
                name="singleday"
                onClick={toggleMultiday}
              />

              <Form.Check
                type="radio"
                label="Multi Day"
                name="singleday"
                onClick={toggleMultiday}
              />
              <Form.Check
                type="radio"
                label="Half Day"
                name="singleday"
                onClick={toggleMultiday}
              />
              <Form.Check type="radio" label="Hours" name="singleday" />
            </Form.Group>

            {singleday === true ? (
              <div>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlTextarea1"
                >
                  <Form.Label>Date</Form.Label>
                  <Form.Control type="date" placeholder="" />
                </Form.Group>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlTextarea1"
                >
                  <Form.Label>Reason note</Form.Label>
                  <Form.Control
                    as="textarea"
                    placeholder="Enter note"
                    rows={3}
                  />
                </Form.Group>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlTextarea1"
                >
                  <Form.Label>Attachments</Form.Label>
                  <Form.Control type="file" placeholder="Upload File" />
                </Form.Group>
              </div>
            ) : (
              <div>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlTextarea1"
                >
                  <Form.Label>Start Date</Form.Label>
                  <Form.Control type="date" placeholder="" />
                </Form.Group>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlTextarea1"
                >
                  <Form.Label>End Date</Form.Label>
                  <Form.Control type="date" placeholder="" />
                </Form.Group>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlTextarea1"
                >
                  <Form.Label>Reason note</Form.Label>
                  <Form.Control
                    as="textarea"
                    placeholder="Enter note"
                    rows={3}
                  />
                </Form.Group>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlTextarea1"
                >
                  <Form.Label>Attachments</Form.Label>
                  <Form.Control type="file" placeholder="Upload File" />
                </Form.Group>
              </div>
            )}
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-secondary puch_btn"
            onClick={handleCloseleave}
          >
            Cancel
          </button>
          <button
            className="btn btn-primary puch_btn"
            onClick={handleCloseleave}
          >
            Save
          </button>
        </Modal.Footer>
      </Modal>

      {/* -------------------Invite Employee---------------------------- */}

      <Modal show={showInvite} onHide={handleInviteClose} size="lg">
        <Modal.Header closeButton className="p-4  ">
          <Modal.Title>Invite Employee</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <div className="d-flex align-items-center">
              <FiBookOpen className="text-warning h5" />
              &nbsp;&nbsp;
              <h6> No email settings found.</h6>
            </div>

            <div className="employee_bg">
              <ol>
                <li>
                  Please make sure you setup the cron job on your hosted server
                  as instructed on the <a href="/">documentation</a> for sending
                  emails.
                </li>
                <li>
                  For sending invitation you must set up email settings. Click{" "}
                  <a href="/">here</a> to add email settings.
                </li>
              </ol>
            </div>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-secondary puch_btn"
            onClick={handleInviteClose}
          >
            Close
          </button>
        </Modal.Footer>
      </Modal>
    </section>
  );
};

export default AttendanceSummary;
