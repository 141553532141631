import React, { useCallback, useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import { BsDownload } from "react-icons/bs";
import { FaEdit } from "react-icons/fa";
import { RiDeleteBin5Line } from "react-icons/ri";
import { useAuth } from "../utils/Context";
import { Alert, Spinner } from "react-bootstrap";
// import { Spinner } from "react-bootstrap";

const Designation = ({ menuWidth }) => {
  const { id } = useAuth();
  const [show, setShow] = useState(false);
  const [showStatus, setShowStatus] = useState(false);
  const handleShow = () => setShow(true);
  const [loading, setLoading] = useState(false);

  const handleClose = () => {
    setShow(false);
    setValidated(false);
    setAddDesignation({
      name: "",
      description: "",
    });
  };
  const [addDesignation, setAddDesignation] = useState({
    name: "",
    description: "",
    error:""
  });

  const [updateDesignation, setUpdateDesignation] = useState({
    id: "",
    name: "",
    description: "",
  });

  const [accept, setAccept] = useState(false);

  const handleAccept = (editDesignation) => {
    setDesignation({ editDesignation });
    setAccept(true);
  };
  const handleAcceptClose = () => setAccept(false);

  const handlestatusClose = () => setShowStatus(false);
  const handlestatusShow = () => setShowStatus(true);

  const [designation, setDesignation] = useState([]);

  const [validated, setValidated] = useState(false);

  const fetchData = useCallback(() => {
    setLoading(true);
    if (id) {
      var requestOptions = {
        method: "GET",
        redirect: "follow",
      };

      fetch(
        `${process.env.REACT_APP_API_BASE_URL}/admin/designation-list`,
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          if (result.status === true) {
            setDesignation(result.data);
          }
          setLoading(false);
        })
        .catch((error) => console.log("error", error));
    }
  },[id])
  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleAddDesignation = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    setValidated(true);
    var formdata = new FormData();
    formdata.append("name", addDesignation.name);
    formdata.append("description", addDesignation.description);

    var requestOptions = {
      method: "POST",
      body: formdata,
      redirect: "follow",
    };

    fetch(
      `${process.env.REACT_APP_API_BASE_URL}/admin/designation-add`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status === true) {
          setAddDesignation({
            name: "",
            description: "",
            error:""
          });
          fetchData();
          handleClose();
          setValidated(false);
  
        } else {
          setAddDesignation({ ...addDesignation, error: result.message });
        }
      })
      .catch((error) => console.log("error", error));
  };
  const handleDelete = (id) => {
    var requestOptions = {
      method: "DELETE",
      redirect: "follow",
    };

    fetch(
      `${process.env.REACT_APP_API_BASE_URL}/admin/designation-delete/${id}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status === true) {
          setDesignation(designation);
          fetchData();
          handleAcceptClose();
        }
      })
      .catch((error) => console.log("error", error));
  };

  const [editDesignation, setEditDesignation] = useState();

  useEffect(() => {
    if (editDesignation) {
      var requestOptions = {
        method: "POST",
        redirect: "follow",
      };

      fetch(
        `${process.env.REACT_APP_API_BASE_URL}/admin/designation-view?id=${editDesignation}`,
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          if (result.status === true) {
            setUpdateDesignation(result.data);
          }
        })
        .catch((error) => console.log("error", error));
    }
  }, [editDesignation]);

  const handleEditDesignation = () => {
    var formdata = new FormData();
    formdata.append("id", editDesignation);
    formdata.append("name", updateDesignation.name);
    formdata.append("description", updateDesignation.description);

    var requestOptions = {
      method: "POST",
      body: formdata,
      redirect: "follow",
    };

    fetch(
      `${process.env.REACT_APP_API_BASE_URL}/admin/designation-add`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status === true) {
          fetchData();
          handlestatusClose();
        }
      })
      .catch((error) => console.log("error", error));
  };

  const handleEditDesignationopen = (editDesignationid) => {
    setEditDesignation(editDesignationid);
    handlestatusShow();
  };

  return (
    <section className="left_width" style={{ marginLeft: `${menuWidth}px` }}>
      <div className="d-flex justify-content-between align-items-center pt-4 w-100">
        <h4 class="m-0">Designation</h4>
        <div>
          <button className="btn btn-primary puch_btn" onClick={handleShow}>
            Add Designation
          </button>
        </div>
      </div>
      {/* <div className="d-flex justify-content-between">
        <div className="puch_btn p-2 h-25 ">
          <BsGrid1X2 />
        </div>
        <div>
          <input type="search" className="search_css" />
        </div>
      </div> */}
      <div>
        <div className="table-responsive custom-scrollbar bg-white table-view-responsive employee_table mt-3">
          {!loading ? (
            <table className="table table_color mb-0 w-100">
              <thead>
                <tr>
                  <th
                    track-by="0"
                    className="datatable-th py-2 px-3 table_color"
                  >
                    <span className="font-size-default">
                      <span>S.No.</span>
                    </span>
                  </th>
                  <th
                    track-by="0"
                    className="datatable-th py-2 px-3 table_color"
                  >
                    <span className="font-size-default">
                      <span>Name</span>
                    </span>
                  </th>
                  <th
                    track-by="1"
                    className="datatable-th py-2 px-3 table_color"
                  >
                    <span className="font-size-default">
                      <span>Description</span>
                    </span>
                  </th>
                  <th
                    track-by="3"
                    className="datatable-th py-2 text-right px-3 table_color"
                  >
                    <span className="font-size-default">Actions</span>
                  </th>
                </tr>
              </thead>
              {designation && designation.length > 0 ? (
                <tbody>
                  {designation &&
                    designation.map((item, index) => (
                      <tr key={item.id}>
                        <td className="px-3">{index + 1}</td>
                        <td className="px-3">{item.name}</td>
                        <td className="px-3">{item.description}</td>
                        <td className="px-3">
                          <span className="hover_btn">
                            <FaEdit
                              onClick={() => handleEditDesignationopen(item.id)}
                              className="left_icon_css"
                            />
                          </span>
                          &nbsp; &nbsp; &nbsp;
                          <span className="hover_btn">
                            <RiDeleteBin5Line
                              onClick={() => handleAccept(item.id)}
                              className="left_icon_css text-danger"
                            />
                          </span>
                        </td>
                      </tr>
                    ))}
                </tbody>
              ) : (
                <tbody>
                  <tr className="text-center">
                    <td colSpan="4">
                      <span className="pt-5 pb-5">
                        <img src="/no_data.svg" alt="no data" width="150px" />
                        <h6>Nothing to show here</h6>
                        <p className="small text-secondary">
                          Please add a new entity or manage the data table to
                          see the content here <br />
                          Thank you
                        </p>
                      </span>
                    </td>
                  </tr>
                </tbody>
              )}
            </table>
          ) : (
            <div className="spinner_height">
              <div className="loader"></div>
            </div>
          )}
        </div>
      </div>

      {/* -------------------Add Designation---------------------------- */}

      <Modal show={show} onHide={handleClose} size="md">
        <Modal.Header closeButton className="p-4  ">
          <Modal.Title>Add Designation</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form
            noValidate
            validated={validated}
            onSubmit={handleAddDesignation}
          >
            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlTextarea1"
            >
              <Form.Label>Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Name"
                value={addDesignation.name}
                onChange={(e) =>
                  setAddDesignation({ ...addDesignation, name: e.target.value })
                }
                required
              />
              <Form.Control.Feedback type="invalid">
                Please enter name.
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlTextarea1"
            >
              <Form.Label>Description</Form.Label>
              <Form.Control
                as="textarea"
                placeholder="Add description here"
                rows={3}
                value={addDesignation.description}
                onChange={(e) =>
                  setAddDesignation({
                    ...addDesignation,
                    description: e.target.value,
                  })
                }
              />
            </Form.Group>
            {addDesignation.error && (
              <Alert variant="danger">{addDesignation.error}</Alert>
            )}

            <Modal.Footer>
              <button
                className="btn btn-secondary puch_btn"
                onClick={handleClose}
                type="button"
              >
                Cancel
              </button>
              {loading ? (
                <button className="btn btn-primary puch_btn" type="button">
                  <Spinner
                    animation="grow"
                    size="sm"
                    style={{ background: "#fff" }}
                  />
                  &nbsp;
                  <Spinner
                    animation="grow"
                    size="sm"
                    style={{ background: "#fff" }}
                  />
                </button>
              ) : (
                <button className="btn btn-primary puch_btn" type="submit">
                  Save
                </button>
              )}
            
            </Modal.Footer>
          </Form>
        </Modal.Body>
      </Modal>

      {/* -------------------Edit Designation---------------------------- */}

      <Modal show={showStatus} onHide={handlestatusClose} size="md">
        <Modal.Header closeButton className="p-4  ">
          <Modal.Title>Edit Designation</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlTextarea1"
            >
              <Form.Label>Name</Form.Label>
              <Form.Control
                type="text"
                value={updateDesignation.name}
                onChange={(e) =>
                  setUpdateDesignation({
                    ...updateDesignation,
                    name: e.target.value,
                  })
                }
              />
            </Form.Group>
            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlTextarea1"
            >
              <Form.Label>Description</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                value={updateDesignation.description}
                onChange={(e) =>
                  setUpdateDesignation({
                    ...updateDesignation,
                    description: e.target.value,
                  })
                }
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-secondary puch_btn"
            onClick={handlestatusClose}
          >
            Cancel
          </button>
          <button
            className="btn btn-primary puch_btn"
            onClick={handleEditDesignation}
          >
            Save
          </button>
        </Modal.Footer>
      </Modal>

      {/* ----------------delete popup ----------------*/}
      <Modal
        show={accept}
        onHide={handleAcceptClose}
        size="md"
        centered
        className="pt-5 pb-5"
      >
        <Modal.Body>
          <div className="text-center mt-5">
            <BsDownload className="jobdisk_icon" />
            <h5>Are you sure?</h5>
            <p>You are going to delete this Designation</p>
          </div>
          <Modal.Footer className="border-0 justify-content-center  mb-4">
            <button
              className="btn btn-secondary puch_btn"
              onClick={handleAcceptClose}
            >
              No
            </button>
            <button
              className="btn btn_color puch_btn"
              onClick={() => handleDelete(designation.editDesignation)}
            >
              Yes
            </button>
          </Modal.Footer>
        </Modal.Body>
      </Modal>
    </section>
  );
};
export default Designation;
