import React, { useState, useEffect, useCallback } from "react";
import Tab from "react-bootstrap/Tab";
import Nav from "react-bootstrap/Nav";
import { Col, Form, Pagination, Row, Spinner } from "react-bootstrap";
import { MdArrowForwardIos } from "react-icons/md";
import { FiUser } from "react-icons/fi";
import { CiCalendar, CiLocationOn } from "react-icons/ci";
import { GoGift } from "react-icons/go";
import { useAuth } from '../utils/Context';
import { LiaPhoneSolid } from "react-icons/lia";
import { FiPhone } from "react-icons/fi";
import { BiHomeAlt } from "react-icons/bi";
import Modal from "react-bootstrap/Modal";
import { IoLocationOutline } from "react-icons/io5";
import { useParams } from "react-router-dom";
import { IoMdCheckmarkCircleOutline } from "react-icons/io";

const EmployeeDetails = ({ menuWidth }) => {
  const { role_id } = useAuth();
  const { employee_id, id } = useParams();
  const [isEditing, setIsEditing] = useState(true);
  const [dalylog, setDalylog] = useState([]);
  const [address, setAddress] = useState(false);
  const [caddressPopup, setCaddressPopup] = useState(false);
  const [editaddress, setEditAddress] = useState(false);
  const [editcaddressPopup, setEditCaddressPopup] = useState(false);
  const pattern = new RegExp(/^\d{1,10}$/);
  const [bankEdit, setBankEdit] = useState(false);
  const handleEditBankShow = () => setBankEdit(true);
  const [validated, setValidated] = useState(false);
  const [employment_statuses, setEmploymentStatuses] = useState([]);
  const [loadingId, setLoadingId] = useState(null);
  const handleEditBankClose = () => {
    setBankEdit(false);
    setValidatedBank(false);
  };
  const handleEditAddressShow = () => setEditAddress(true);
  const handleEditAddressClose = () => {
    setValidatedPaddress(false);
    setEditAddress(false);
  };

  const [start_date, setStartDate] = useState("");
  const [end_date, setEndDate] = useState("");

  const handleeditcaddressShow = () => setEditCaddressPopup(true);
  const handleEditCaddressClose = () => {
    setValidatedCaddress(false);
    setEditCaddressPopup(false);
  };
  const [accept, setAccept] = useState(false);
  const handleAccept = () => setAccept(true);
  const handleAcceptClose = () => setAccept(false);
  const [addCredentials, setAddCredentials] = useState({
    first_name: "",
    last_name: "",
    contact_number: "",
    gander: "",
    employee_id: id,
    photo: "",
    about_me: "",
    date_of_birth: "",
    address: "",
    emergency_contact: "",
    status: "",
    employee_ment_id: "",
    marital_status: "",
    salary: "",
    error: "",
  });
  const handleAddressShow = () => setAddress(true);
  const handlecaddressShow = () => setCaddressPopup(true);
  const handleEditClick = () => {
    setIsEditing(false);
  };

  const fetchprofile = useCallback(() => {
    if (role_id) {
      var formdata = new FormData();
      formdata.append("employee_id", id);

      var requestOptions = {
        method: "POST",
        body: formdata,
        redirect: "follow",
      };

      fetch(
        `${process.env.REACT_APP_API_BASE_URL}/admin/profile`,
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          if (result.status === true) {
            const gander = result.data.gander === 1 ? "Male" : "Female";
            setAddCredentials((prevState) => ({
              ...prevState,
              first_name: result.data.first_name ? result.data.first_name : "",
              last_name: result.data.last_name ? result.data.last_name : "",
              email: result.data.email ? result.data.email : "",
              personal_email: result.data.personal_email ? result.data.personal_email : "",
              contact_number: result.data.contact_number
                ? result.data.contact_number
                : "",
              gander: gander,
              employee_id: result.data.employee_id
                ? result.data.employee_id
                : "",
              emergency_contact: result.data.emergency_contact
                ? result.data.emergency_contact
                : "",
              status: result.data.status ? result.data.status : "",
              photo: result.data.photo ? result.data.photo : "",
              joining_date: result.data.joining_date
                ? result.data.joining_date
                : "",
              address: result.data.address ? result.data.address : "",
              about_me: result.data.about_me ? result.data.about_me : "",
              date_of_birth: result.data.date_of_birth
                ? result.data.date_of_birth
                : "",
              department_id: result.data.department_id
                ? result.data.department_id
                : "",
              designation_id: result.data.designation_id
                ? result.data.designation_id
                : "",
              employee_ment_id: result.data.employee_ment_id
                ? result.data.employee_ment_id
                : "",
              salary: result.data.salary
                ? result.data.salary
                : "",
              marital_status: result.data.marital_status
                ? result.data.marital_status
                : "",
            }));
          }
        })
        .catch((error) => console.log("error", error));
    }
  }, [id, role_id])

  useEffect(() => {
    fetchprofile();
  }, [role_id, employee_id, fetchprofile]);

  const handleUpdate = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    setValidated(true);
    var formdata = new FormData();
    const ganderValue = addCredentials.gander === "Male" ? 1 : 2;
    formdata.append("employee_id", id);
    formdata.append("first_name", addCredentials.first_name);
    formdata.append("last_name", addCredentials.last_name);
    formdata.append("contact_number", addCredentials.contact_number);
    formdata.append("address", addCredentials.address);
    formdata.append("emergency_contact", addCredentials.emergency_contact);
    formdata.append("status", addCredentials.status);
    formdata.append("date_of_birth", addCredentials.date_of_birth);
    formdata.append("about_me", addCredentials.about_me);
    formdata.append("photo", addCredentials.photo);
    formdata.append("employee_ment_id", addCredentials.employee_ment_id);
    formdata.append("salary", addCredentials.salary);
    formdata.append("marital_status", addCredentials.marital_status);
    formdata.append("department_id", addCredentials.department_id);
    formdata.append("personal_email", addCredentials.personal_email);
    formdata.append("gander", ganderValue);



    var requestOptions = {
      method: "POST",
      body: formdata,
      redirect: "follow",
    };

    fetch(
      `${process.env.REACT_APP_API_BASE_URL}/admin/update-profile`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status === true) {
          // alert(result.message);
          handleAccept();
          fetchprofile();
          setValidated(false);
        } else {
          // setAddCredentials({ ...addCredentials, error: result.message });
          // alert(result.message);
        }
      })
      .catch((error) => {
        console.error("Error:", error.message);
      });
  };
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setAddCredentials({ ...addCredentials, photo: file });
  };

  const [bank, setBank] = useState();
  const handleBankShow = () => setBank(true);
  const [validatedBank, setValidatedBank] = useState(false);
  const [validatedPaddress, setValidatedPaddress] = useState(false);
  const [validatedCaddress, setValidatedCaddress] = useState(false);

  const handleBankClose = () => {
    setBank(false);
    setValidatedBank(false);
    setAddBankDetails({
      employee_id: id,
      account_holder_name: "",
      account_number: "",
      account_title: "",
      bank_name: "",
      branch_name: "",
      tax_payer_id: "",
      bank_code: "",
    });
  };

  const handleAddressClose = () => {
    setValidatedPaddress(false);
    setAddress(false);
    setPaddress({
      user_id: id,
      address_detail: "",
      area: "",
      city: "",
      state: "",
      zip_code: "",
      country: "",
      phone_number: "",
    });
  };

  const handleCaddressClose = () => {
    setValidatedCaddress(false);
    setCaddressPopup(false);
    setCaddress({
      user_id: id,
      address_detail: "",
      area: "",
      city: "",
      state: "",
      zip_code: "",
      country: "",
      phone_number: "",
    });
  };

  const [addbankdetails, setAddBankDetails] = useState({
    employee_id: id,
    account_holder_name: "",
    account_number: "",
    account_title: "",
    bank_name: "",
    branch_name: "",
    tax_payer_id: "",
    bank_code: "",
  });

  const addBank = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }

    setValidatedBank(true);
    var formdata = new FormData();
    formdata.append("bank_name", addbankdetails.bank_name);
    formdata.append("employee_id", addbankdetails.employee_id);
    formdata.append("account_holder_name", addbankdetails.account_holder_name);
    formdata.append("account_number", addbankdetails.account_number);
    formdata.append("account_title", addbankdetails.account_title);
    formdata.append("branch_name", addbankdetails.branch_name);
    formdata.append("tax_payer_id", addbankdetails.tax_payer_id);
    formdata.append("bank_code", addbankdetails.bank_code);

    var requestOptions = {
      method: "POST",
      body: formdata,
      redirect: "follow",
    };

    fetch(
      `${process.env.REACT_APP_API_BASE_URL}/admin/bank-detail`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status === true) {
          setAddBankDetails({
            employee_id: id,
            account_holder_name: "",
            account_number: "",
            account_title: "",
            bank_name: "",
            branch_name: "",
            tax_payer_id: "",
            bank_code: "",
          });
          handleBankClose();
          fetchData();
        }
      })
      .catch((error) => console.log("error", error));
  };
  const downloadPdf = (id, user_id, name, create_payslip) => {
    setLoadingId(id);
    setLoading(true);
    var requestOptions = {
      method: "GET",
      responseType: "blob",
      redirect: "follow",
    };

    fetch(
      `${process.env.REACT_APP_API_BASE_URL}/admin/get_payslip_pdf?user_id=${user_id}&payslip_id=${id}`,
      requestOptions
    )
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.blob();
      })
      .then((blob) => {
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `payslip_${name}_${create_payslip}.pdf`);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
        window.URL.revokeObjectURL(url);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error downloading PDF:", error);
      });
  };
  const [loading, setLoading] = useState(false);
  const [searchPage, setSearchPage] = useState("thismonth");
  const [listPayslip, setListPayslip] = useState({});

  useEffect(() => {
    var requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    fetch(
      `${process.env.REACT_APP_API_BASE_URL}/admin/get_payslips?user_id=${id}&time_duration=${searchPage}&start_date=${start_date}&end_date=${end_date}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status === true) {
          setListPayslip(result.data);
        }
      })
      .catch((error) => console.log("error", error));
  }, [id, searchPage, start_date, end_date]);

  useEffect(() => {
    var requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    fetch(
      `${process.env.REACT_APP_API_BASE_URL}/admin/attendance-request-list?user_id=${id}&start_date=${start_date}&end_date=${end_date}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status === true) {
          setDalylog(result.data);
        }
      })
      .catch((error) => console.log("error", error));
  }, [id, start_date, end_date]);

  const [leave, setLeave] = useState([]);

  useEffect(() => {
    var requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    fetch(
      `${process.env.REACT_APP_API_BASE_URL}/admin/leave_requests?users=${id}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status === true) {
          setLeave(result.data);
        }
      })
      .catch((error) => console.log("error", error));
  }, [id]);

  const [bankDetails, setBankDetails] = useState({
    employee_id: id,
    account_holder_name: "",
    account_number: "",
    account_title: "",
    bank_name: "",
    branch_name: "",
    tax_payer_id: "null",
    bank_code: "",
  });
  const updateBank = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }

    setValidatedBank(true);
    var formdata = new FormData();
    formdata.append("bank_name", bankDetails.bank_name);
    formdata.append("employee_id", id);
    formdata.append("account_holder_name", bankDetails.account_holder_name);
    formdata.append("account_number", bankDetails.account_number);
    formdata.append("account_title", bankDetails.account_title);
    formdata.append("branch_name", bankDetails.branch_name);
    formdata.append("tax_payer_id", bankDetails.tax_payer_id);
    formdata.append("bank_code", bankDetails.bank_code);

    var requestOptions = {
      method: "POST",
      body: formdata,
      redirect: "follow",
    };

    fetch(
      `${process.env.REACT_APP_API_BASE_URL}/admin/bank-detail`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status === true) {
          handleEditBankClose();
          setBankDetails({
            employee_id: id,
            account_holder_name: "",
            account_number: "",
            account_title: "",
            bank_name: "",
            branch_name: "",
            tax_payer_id: "",
            bank_code: "",
          });

          fetchData();
          setValidatedBank(false);
        }
      })
      .catch((error) => console.log("error", error));
  };
  const fetchData = useCallback(() => {
    var requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    fetch(
      `${process.env.REACT_APP_API_BASE_URL}/admin/get_bank_details?user_id=${id}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status === true) {
          setBankDetails(result.data);
        }
      })
      .catch((error) => console.log("error", error));
  }, [id]);
  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const [paddress, setPaddress] = useState({
    user_id: id,
    address_detail: "",
    area: "",
    city: "",
    state: "",
    zip_code: "",
    country: "",
    phone_number: "",
  });
  const handlePaddressAdd = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    setValidatedPaddress(true);

    var formdata = new FormData();
    formdata.append("user_id", paddress.user_id);
    formdata.append("address_detail", paddress.address_detail);
    formdata.append("area", paddress.area);
    formdata.append("city", paddress.city);
    formdata.append("state", paddress.state);
    formdata.append("zip_code", paddress.zip_code);
    formdata.append("country", paddress.country);
    formdata.append("phone_number", paddress.phone_number);

    var requestOptions = {
      method: "POST",
      body: formdata,
      redirect: "follow",
    };

    fetch(
      `${process.env.REACT_APP_API_BASE_URL}/admin/permanent-address-detail`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status === true) {
          // alert(result.message);
          fetchData2();
          handleAddressClose();
        }
      })
      .catch((error) => console.log("error", error));
  };

  const [paddresswiew, setPaddressview] = useState({
    user_id: id,
    address_detail: "",
    area: "",
    city: "",
    state: "",
    zip_code: "",
    country: "",
    phone_number: "",
  });

  const fetchData2 = useCallback(() => {
    var requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    fetch(
      `${process.env.REACT_APP_API_BASE_URL}/admin/get-permanent-address-detail?user_id=${id}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status === true) {
          setPaddressview(result.data);
        }
      })
      .catch((error) => console.log("error", error));
  }, [id]);

  useEffect(() => {
    fetchData2();
  }, [fetchData2]);

  const handlePaddressEdit = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    setValidatedPaddress(true);

    var formdata = new FormData();
    formdata.append("user_id", paddresswiew.user_id);
    formdata.append("address_detail", paddresswiew.address_detail);
    formdata.append("area", paddresswiew.area);
    formdata.append("city", paddresswiew.city);
    formdata.append("state", paddresswiew.state);
    formdata.append("zip_code", paddresswiew.zip_code);
    formdata.append("country", paddresswiew.country);
    formdata.append("phone_number", paddresswiew.phone_number);

    var requestOptions = {
      method: "POST",
      body: formdata,
      redirect: "follow",
    };

    fetch(
      `${process.env.REACT_APP_API_BASE_URL}/admin/permanent-address-detail`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status === true) {
          fetchData2();
          handleEditAddressClose();

          setValidatedPaddress(false);
        }
      })
      .catch((error) => console.log("error", error));
  };
  const [caddress, setCaddress] = useState({
    user_id: id,
    address_detail: "",
    area: "",
    city: "",
    state: "",
    zip_code: "",
    country: "",
    phone_number: "",
  });
  const handleCaddressAdd = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    setValidatedCaddress(true);
    var formdata = new FormData();
    formdata.append("user_id", caddress.user_id);
    formdata.append("address_detail", caddress.address_detail);
    formdata.append("area", caddress.area);
    formdata.append("city", caddress.city);
    formdata.append("state", caddress.state);
    formdata.append("zip_code", caddress.zip_code);
    formdata.append("country", caddress.country);
    formdata.append("phone_number", caddress.phone_number);

    var requestOptions = {
      method: "POST",
      body: formdata,
      redirect: "follow",
    };

    fetch(
      `${process.env.REACT_APP_API_BASE_URL}/admin/current-address-detail`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status === true) {
          handleCaddressClose(true);
          fetchData3();
        }
      })
      .catch((error) => console.log("error", error));
  };

  const [caddresswiew, setCaddressview] = useState({
    user_id: id,
    address_detail: "",
    area: "",
    city: "",
    state: "",
    zip_code: "",
    country: "",
    phone_number: "",
  });

  const fetchData3 = useCallback(() => {
    var requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    fetch(
      `${process.env.REACT_APP_API_BASE_URL}/admin/get-current-address-detail?user_id=${id}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status === true) {
          setCaddressview(result.data);
        }
      })
      .catch((error) => console.log("error", error));
  }, [id]);

  useEffect(() => {
    fetchData3();
  }, [fetchData3]);

  const handleCaddressEdit = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    setValidatedCaddress(true);
    var formdata = new FormData();
    formdata.append("user_id", caddresswiew.user_id);
    formdata.append("address_detail", caddresswiew.address_detail);
    formdata.append("area", caddresswiew.area);
    formdata.append("city", caddresswiew.city);
    formdata.append("state", caddresswiew.state);
    formdata.append("zip_code", caddresswiew.zip_code);
    formdata.append("country", caddresswiew.country);
    formdata.append("phone_number", caddresswiew.phone_number);

    var requestOptions = {
      method: "POST",
      body: formdata,
      redirect: "follow",
    };

    fetch(
      `${process.env.REACT_APP_API_BASE_URL}/admin/current-address-detail`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status === true) {
          handleEditCaddressClose();
          fetchData3();
          setValidatedCaddress(false);
        }
      })
      .catch((error) => console.log("error", error));
  };

  const formatDate = (dateString) => {
    if (!dateString) return "Not added yet";

    // Parse the date string into a Date object
    const date = new Date(dateString);

    // Define an array of month names
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    // Extract day, month, and year
    const day = date.getDate();
    const month = monthNames[date.getMonth()];
    const year = date.getFullYear();

    // Format the date as desired
    const formattedDate = `${day} ${month}, ${year}`;

    return formattedDate;
  };
  useEffect(() => {
    var requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    fetch(
      `${process.env.REACT_APP_API_BASE_URL}/admin/employee-form`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status === true) {
          const {

            employment_statuses

          } = result.data;
          setEmploymentStatuses(employment_statuses);
        }
      })
      .catch((error) => console.log("error", error));
  }, [setEmploymentStatuses]);

  // const [currentPage, setCurrentPage] = useState(1);
  // const projectsPerPage = 6;

  // const handlePageChange = (page) => {
  //   setCurrentPage(page);
  // };

  // const totalPages = Math.ceil(dalylog && dalylog.length / projectsPerPage);
  // const indexOfLastProject = currentPage * projectsPerPage;
  // const indexOfFirstProject = indexOfLastProject - projectsPerPage;
  // const currentProjects = dalylog && dalylog.slice(
  //   indexOfFirstProject,
  //   indexOfLastProject
  // );


  const [currentPage, setCurrentPage] = useState(1);
  const projectsPerPage = 6;

  // Handle page change
  const handlePageChange = (page) => {
    if (page >= 1 && page <= totalPages) {
      setCurrentPage(page);
    }
  };

  // Total pages calculation
  const totalPages = dalylog ? Math.ceil(dalylog.length / projectsPerPage) : 0;

  // Indexes for slicing
  const indexOfLastProject = currentPage * projectsPerPage;
  const indexOfFirstProject = indexOfLastProject - projectsPerPage;

  // Get current projects
  const currentProjects = dalylog ? dalylog.slice(indexOfFirstProject, indexOfLastProject) : [];
  return (
    <section className="left_width" style={{ marginLeft: `${menuWidth}px` }}>
      <div className="pt-4 w-100">
        <h4 className="pt-3 mt-5 mb-4">Employee Details</h4>
      </div>
      <Row className="align-items-center attendance profile_mainpadding mx-0">
        <Col className="col-md-5 d-lg-flex justify-content-between align-items-center  ">
          {addCredentials.photo ? (
            <img
              src={addCredentials.photo}
              alt="profile"
              className="avatar m-2"
            />
          ) : (
            <img src="/avtaar.jpg" alt="profile" className="avatar m-2" />
          )}
          <div className="width_css_web pl-2">
            <h4 className="h4">{addCredentials.first_name} {addCredentials.last_name}</h4>

            {addCredentials.status === 1 ? (
              <span className=" btn-success rounded-pill text-white p-1 px-3 text-white ">
                Active
              </span>
            ) : (
              <span className=" bg-danger rounded-pill text-white p-1 px-3  text-white">
                Inactive
              </span>
            )}
            <h6 className=" p_css pt-2 mb-0">{addCredentials.email}</h6>
            <h6 className=" p_css pt-2 text-primary">
              {addCredentials.designation_id}
            </h6>
          </div>
        </Col>
        <Col className="border_css">
          <div className="d-flex align-items-center">
            <LiaPhoneSolid className="job_icon_css" />
            <div>
              <p className="mb-0 p_css">Contact:</p>
              <h5 className="m-0 p_css text-dark">
                {addCredentials.contact_number || "Not added yet"}
              </h5>
            </div>
          </div>
          <div className="d-flex align-items-center mt-4">
            <CiLocationOn className="job_icon_css" />
            <div>
              <p className="mb-0 p_css ">Address:</p>
              <h5 className="m-0 p_css text-dark">
                {paddresswiew.address_detail &&
                  paddresswiew.address_detail.length > 0 ? (
                  <>
                    {paddresswiew.address_detail && paddresswiew.address_detail}
                    ...
                  </>
                ) : (
                  "Not added yet"
                )}
              </h5>
            </div>
          </div>
        </Col>
        <Col className="">
          <div className="d-flex align-items-center">
            <CiCalendar className="job_icon_css" />
            <div>
              <p className="mb-0 p_css">Created:</p>
              <h5 className="m-0 p_css text-dark">
                {formatDate(addCredentials.joining_date) || "Not added yet"}
              </h5>
            </div>
          </div>
          <div className="d-flex align-items-center mt-4">
            <GoGift className="job_icon_css" />
            <div>
              <p className="mb-0 p_css">Date of birth:</p>
              <h5 className="m-0 p_css text-dark">
                {formatDate(addCredentials.date_of_birth) || "Not added yet"}
              </h5>
            </div>
          </div>
        </Col>
      </Row>
      <Tab.Container id="left-tabs-example" defaultActiveKey="first">
        <Row className="mt-4">
          <Col sm={3} className="h-auto">
            <Nav variant="pills" className="flex-column statistics p-3 h-auto">
              <div className="text-center  pt-5 mt-4 pb-3">
                <FiUser className="h2 text-primary" />
              </div>
              <Nav.Item>
                <Nav.Link
                  eventKey="first"
                  className="tab_css_setting d-flex justify-content-between"
                >
                  Personal Details <MdArrowForwardIos />
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  eventKey="attendance"
                  className="tab_css_setting d-flex justify-content-between"
                >
                  Attendance <MdArrowForwardIos />
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  eventKey="third"
                  className="tab_css_setting d-flex justify-content-between"
                >
                  Leave <MdArrowForwardIos />
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  eventKey="payslip"
                  className="tab_css_setting d-flex justify-content-between"
                >
                  Payslip <MdArrowForwardIos />
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  eventKey="bankdetails"
                  className="tab_css_setting d-flex justify-content-between"
                >
                  Bank Details <MdArrowForwardIos />
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  eventKey="address"
                  className="tab_css_setting d-flex justify-content-between"
                >
                  Address Details <MdArrowForwardIos />
                </Nav.Link>
              </Nav.Item>
            </Nav>
          </Col>
          <Col sm={9}>
            <Tab.Content className="attendance rounded">
              <Tab.Pane eventKey="first">
                <div className="d-lg-flex justify-content-between p-4 border-bottom">
                  <h5 className="h5">Personal Details</h5>
                  <button
                    className="btn btn-primary punch_btn"
                    onClick={handleEditClick}
                  >
                    Edit
                  </button>
                </div>
                <Form
                  className="p-4"
                  noValidate
                  validated={validated}
                  onSubmit={handleUpdate}
                >
                  <Form.Group
                    className="d-lg-flex mt-3"
                    controlId="exampleForm.ControlTextarea1"
                  >
                    <div className="width25">
                      <Form.Label className="sgt_admin">
                        Upload profile image
                      </Form.Label>
                    </div>
                    <div className="width75">
                      <Form.Control
                        type="file"
                        onChange={handleFileChange}
                        disabled={isEditing}
                      />
                    </div>
                  </Form.Group>
                  <Form.Group
                    className="d-lg-flex mt-3"
                    controlId="exampleForm.ControlTextarea1"
                  >
                    <div className="width25">
                      <Form.Label className="sgt_admin">First name</Form.Label>
                    </div>
                    <div className="width75">
                      <Form.Control
                        type="text"
                        value={addCredentials.first_name}
                        onChange={(e) =>
                          setAddCredentials({
                            ...addCredentials,
                            first_name: e.target.value,
                          })
                        }
                        disabled={isEditing}

                      />
                    </div>
                  </Form.Group>
                  <Form.Group
                    className="d-lg-flex mt-3"
                    controlId="exampleForm.ControlTextarea1"
                  >
                    <div className="width25">
                      <Form.Label className="sgt_admin">Last name</Form.Label>
                    </div>
                    <div className="width75">
                      <Form.Control
                        type="text"
                        disabled={isEditing}
                        value={addCredentials.last_name}
                        onChange={(e) =>
                          setAddCredentials({
                            ...addCredentials,
                            last_name: e.target.value,
                          })
                        }

                      />
                    </div>
                  </Form.Group>
                  <Form.Group
                    className="d-lg-flex mt-3"
                    controlId="exampleForm.ControlTextarea1"
                  >
                    <div className="width25 ">
                      <Form.Label className="sgt_admin">Email</Form.Label>
                    </div>
                    <div className="width75">
                      <Form.Control
                        type="text"
                        value={addCredentials.email}
                        disabled={isEditing}
                        onChange={(e) =>
                          setAddCredentials({
                            ...addCredentials,
                            email: e.target.value,
                          })
                        }

                      />
                    </div>
                  </Form.Group>

                  <Form.Group
                    className="d-lg-flex mt-3"
                    controlId="exampleForm.ControlTextarea1"
                  >
                    <div className="width25 ">
                      <Form.Label className="sgt_admin">Personal Email</Form.Label>
                    </div>
                    <div className="width75">
                      <Form.Control
                        type="text"
                        value={addCredentials.personal_email}
                        disabled={isEditing}
                        onChange={(e) =>
                          setAddCredentials({
                            ...addCredentials,
                            personal_email: e.target.value,
                          })
                        }
required
                      />
                    </div>
                  </Form.Group>
                  <Form.Group
                    className="d-lg-flex mt-3"
                    controlId="exampleForm.ControlTextarea1"
                  >
                    <div className="width25">
                      <Form.Label className="sgt_admin">Gender</Form.Label>
                    </div>
                    <div className="d-flex align-items-center">
                      <Form.Check
                        type="radio"
                        id="male"
                        name="gander"
                        disabled={isEditing}
                        value={"Male"}
                        checked={
                          addCredentials.gander === "Male" ||
                            addCredentials.gander === 1
                            ? true
                            : false
                        }
                        onChange={(e) => {
                          setAddCredentials((prevState) => ({
                            ...prevState,
                            gander: e.target.value,
                          }));
                        }}
                        label="Male"
                      />
                      <Form.Check
                        type="radio"
                        id="female"
                        name="gander"
                        disabled={isEditing}
                        value={"Female"}
                        checked={
                          addCredentials.gander === "Female" ||
                            addCredentials.gander === 0
                            ? true
                            : false
                        }
                        onChange={(e) => {
                          setAddCredentials((prevState) => ({
                            ...prevState,
                            gander: e.target.value,
                          }));
                        }}
                        label="Female"
                        className="ms-3"
                      />
                    </div>
                  </Form.Group>


                  <Form.Group className="d-lg-flex mt-3" controlId="exampleForm.ControlTextarea1">
                    <div className="width25">
                      <Form.Label className="sgt_admin">Marital Status</Form.Label>
                    </div>
                    <div className="d-flex align-items-center">
                      <Form.Check
                        type="radio"
                        id="married"
                        name="marital_status"
                        disabled={isEditing}
                        value={"2"} // Married
                        checked={addCredentials.marital_status === "2"}
                        onChange={(e) => {
                          setAddCredentials((prevState) => ({
                            ...prevState,
                            marital_status: e.target.value,
                          }));
                        }}
                        label="Married"
                      />
                      <Form.Check
                        type="radio"
                        id="unmarried"
                        name="marital_status"
                        disabled={isEditing}
                        value={"1"} // Unmarried
                        checked={addCredentials.marital_status === "1"}
                        onChange={(e) => {
                          setAddCredentials((prevState) => ({
                            ...prevState,
                            marital_status: e.target.value,
                          }));
                        }}
                        label="Unmarried"
                        className="ms-3"
                      />
                    </div>
                  </Form.Group>
                  {/* <Form.Group
                    className="d-lg-flex mt-3"
                    controlId="exampleForm.ControlTextarea1"
                  >
                    <div className="width25">
                      <Form.Label className="sgt_admin">Status</Form.Label>
                    </div>
                    <div className="d-flex">
                      <Form.Check
                        type="radio"
                        // aria-label="radio 1"
                        label="Active"
                        name="radio3"
                        id="active"
                        disabled={isEditing}
                        value={1}
                        checked={addCredentials.status === '1' ? true
                          : false}
                        onChange={(e) => {
                          setAddCredentials((prevState) => ({
                            ...prevState,
                            status: e.target.value,
                          }));
                        }}
                      />
                      &nbsp;&nbsp;
                      <Form.Check
                        type="radio"
                        // aria-label="radio 1"
                        label="Inactive"
                        name="radio4"
                        id="inactive"
                        disabled={isEditing}
                        value={2}
                        checked={addCredentials.status === '2' ? true
                        : false}
                        onChange={(e) => {
                          setAddCredentials((prevState) => ({
                            ...prevState,
                            status: e.target.value,
                          }));
                        }}
                      />
                    </div>
                  </Form.Group> */}

                  <Form.Group
                    className="d-lg-flex mt-3"
                    controlId="exampleForm.ControlTextarea1"
                  >
                    <div className="width25">
                      <Form.Label className="sgt_admin">Status</Form.Label>
                    </div>
                    <div className="d-flex">
                      <Form.Check
                        type="radio"
                        label="Active"
                        name="radio3"
                        id="active"
                        disabled={isEditing}
                        value={1}
                        checked={addCredentials.status === 1}
                        onChange={(e) => {
                          setAddCredentials((prevState) => ({
                            ...prevState,
                            status: Number(e.target.value),
                          }));
                        }}
                      />
                      &nbsp;&nbsp;
                      <Form.Check
                        type="radio"
                        label="Inactive"
                        name="radio4"
                        id="inactive"
                        disabled={isEditing}
                        value={2}
                        checked={addCredentials.status === 2}
                        onChange={(e) => {
                          setAddCredentials((prevState) => ({
                            ...prevState,
                            status: Number(e.target.value),
                          }));
                        }}
                      />
                    </div>
                  </Form.Group>
                  <Form.Group
                    className="d-lg-flex mt-3"
                    controlId="exampleForm.ControlTextarea1"
                  >
                    <div className="width25">
                      <Form.Label className="sgt_admin">
                        Emergency Contact number
                      </Form.Label>
                    </div>
                    <div className="width75">
                      <Form.Control
                        type="number"
                        maxLength={10}
                        value={addCredentials.emergency_contact}
                        disabled={isEditing}
                        onChange={(e) => {
                          const inputValue = e.target.value;

                          // Check if the input matches the pattern
                          if (!pattern.test(inputValue)) {
                            // If not, update the state with an error message
                            setAddCredentials({
                              ...addCredentials,
                              emergency_contact: inputValue.slice(0, 10),
                              error: "Please enter only numbers.",
                            });
                          } else {
                            // If it matches the pattern, update the state without an error message
                            setAddCredentials({
                              ...addCredentials,
                              emergency_contact: inputValue,
                              error: "",
                            });
                          }
                        }}
                        required
                      />
                    </div>
                  </Form.Group>
                  <Form.Group
                    className="d-lg-flex mt-3"
                    controlId="exampleForm.ControlTextarea1"
                  >
                    <div className="width25">
                      <Form.Label className="sgt_admin">
                        Contact number
                      </Form.Label>
                    </div>
                    <div className="width75">
                      <Form.Control
                        type="number"
                        maxLength={10}
                        value={addCredentials.contact_number}
                        disabled={isEditing}
                        onChange={(e) => {
                          const inputValue = e.target.value;

                          // Check if the input matches the pattern
                          if (!pattern.test(inputValue)) {
                            // If not, update the state with an error message
                            setAddCredentials({
                              ...addCredentials,
                              contact_number: inputValue.slice(0, 10),
                              error: "Please enter only numbers.",
                            });
                          } else {
                            // If it matches the pattern, update the state without an error message
                            setAddCredentials({
                              ...addCredentials,
                              contact_number: inputValue,
                              error: "",
                            });
                          }
                        }}
                        required
                      />
                    </div>
                  </Form.Group>
                  <Form.Group
                    className="d-lg-flex mt-3"
                    controlId="exampleForm.ControlTextarea1"
                  >
                    <div className="width25">
                      <Form.Label className="sgt_admin">
                        Date of birth
                      </Form.Label>
                    </div>
                    <div className="width75">
                      <Form.Control
                        type="date"
                        value={addCredentials.date_of_birth}
                        disabled={isEditing}
                        onChange={(e) =>
                          setAddCredentials({
                            ...addCredentials,
                            date_of_birth: e.target.value,
                          })
                        }
                        required
                      />
                    </div>
                  </Form.Group>
                  <Form.Group
                    className="d-lg-flex mt-3"
                    controlId="exampleForm.ControlTextarea1"
                  >
                    <div className="width25">
                      <Form.Label className="sgt_admin">
                        Employment status
                      </Form.Label>
                    </div>
                    <div className="width75">
                      <Form.Select
                        aria-label="Default select example"
                        value={addCredentials.employee_ment_id}
                        onChange={(e) => {
                          setAddCredentials((prevState) => ({
                            ...prevState,
                            employee_ment_id: e.target.value,
                          }));
                        }}
                        disabled={isEditing}
                        required
                        className="text-uppercase search_select_css"
                      >
                        <option value="" disabled>
                          Choose an employment status
                        </option>
                        {employment_statuses.map((item) => (
                          <option key={item.id} value={item.id}>
                            {item.name}
                          </option>
                        ))}
                      </Form.Select>

                    </div>
                  </Form.Group>
                  <Form.Group
                    className="d-lg-flex mt-3"
                    controlId="exampleForm.ControlTextarea1"
                  >
                    <div className="width25">
                      <Form.Label className="sgt_admin">
                        Salary
                      </Form.Label>
                    </div>
                    <div className="width75">
                      <Form.Control
                        type="text"
                        value={addCredentials.salary}
                        disabled={isEditing}
                        onChange={(e) =>
                          setAddCredentials({
                            ...addCredentials,
                            salary: e.target.value,
                          })
                        }
                      // required
                      />
                    </div>
                  </Form.Group>
                  <Form.Group
                    className="d-lg-flex mt-3"
                    controlId="exampleForm.ControlTextarea1"
                  >
                    <div className="width25 ">
                      <Form.Label className="sgt_admin">About me</Form.Label>
                    </div>
                    <div className="width75">
                      <Form.Control
                        as="textarea"
                        row={2}
                        type="text"
                        disabled={isEditing}
                        value={addCredentials.about_me}
                        onChange={(e) =>
                          setAddCredentials({
                            ...addCredentials,
                            about_me: e.target.value,
                          })
                        }
                      />
                    </div>
                  </Form.Group>
                  <div className="d-lg-flex mt-4">
                    <button
                      className="btn btn-primary punch_btn"
                      disabled={isEditing}
                      type="submit"
                    >
                      Save
                    </button>
                    {/* &nbsp; &nbsp;
                    <button
                      className="btn btn-secondary punch_btn"
                      disabled={isEditing}
                    >
                      Cancel
                    </button> */}
                  </div>
                </Form>
              </Tab.Pane>
              <Tab.Pane eventKey="attendance">
                <div className=" p-4 border-bottom">
                  <h5 className="h5">Attendance</h5>
                </div>
                <div className="text-center">
                  <div className="d-lg-flex justify-content-between align-items-center mt-3 p-3">
                    From
                    <Form.Control
                      type="date"
                      className="mx-2 h-75"
                      value={start_date}
                      onChange={(e) => setStartDate(e.target.value)}
                    />
                    To
                    <Form.Control
                      type="date"
                      className="mx-2 h-75"
                      value={end_date}
                      onChange={(e) => setEndDate(e.target.value)}
                    />
                  </div>
                  <div className="table-responsive custom-scrollbar table-view-responsive  py-primary">
                    <table className="table mb-0">
                      <thead>
                        <tr>
                          <th track-by="2" className=" py-4 px-1 table_color">
                            <span className="font-size-default">
                              <span>Date</span>
                            </span>
                          </th>
                          <th track-by="0" className=" py-4 px-1 table_color">
                            <span className="font-size-default">
                              <span>Punched in</span>
                            </span>
                          </th>
                          {/* <th track-by="1" className=" py-4 px-1 table_color">
                            <span className="font-size-default">
                              <span>In Geolocation</span>
                            </span>
                          </th> */}
                          <th track-by="2" className=" py-4 px-1 table_color">
                            <span className="font-size-default">
                              <span>Punched Out</span>
                            </span>
                          </th>
                          {/* <th className=" py-4 px-1 table_color text-right">
                            <span className="font-size-default">
                              Out Geolocation
                            </span>
                          </th> */}
                          <th className=" py-4 px-1 table_color text-right">
                            <span className="font-size-default">
                              Total hours
                            </span>
                          </th>
                        </tr>
                      </thead>
                      {dalylog && dalylog.length > 0 ? (
                        <tbody>
                          {currentProjects && currentProjects.map((attendance, index) => (
                            <tr key={attendance.id}>
                              <td> {attendance.punch_date || "-"}</td>
                              <td>{attendance.punch_in || "Not yet"}</td>
                              {/* <td>{attendance.in_ip_data || "-"}</td> */}
                              <td>{attendance.punch_out || "Not yet"}</td>
                              {/* <td>{attendance.out_ip_data || "-"}</td> */}
                              <td>{attendance.total_hourse || "-"}</td>
                            </tr>
                          ))}
                        </tbody>
                      ) : (
                        <tbody className="pt-5 pb-5">
                          <tr className="text-center">
                            <td colSpan="6" className="text-center">
                              <img
                                src="/no_data.svg"
                                alt="no data"
                                width="150px"
                              />
                              <h6 className="test-center">
                                Nothing to show here
                              </h6>
                              <p className="test-center small text-secondary">
                                Please add a new entity or manage the data table
                                to see the content here Thank you
                              </p>
                            </td>
                          </tr>
                        </tbody>
                      )}
                    </table>
                    <Pagination className="mt-2 float-right">
                      <Pagination.First onClick={() => handlePageChange(1)} />
                      <Pagination.Prev
                        onClick={() => handlePageChange(currentPage - 1)}
                        disabled={currentPage === 1}
                      />
                      {[...Array(totalPages)].map((_, index) => {

                        if (
                          index + 1 === 1 ||
                          index + 1 === totalPages ||
                          index + 1 === currentPage ||
                          index + 1 === currentPage - 1 ||
                          index + 1 === currentPage + 1
                        ) {
                          return (
                            <Pagination.Item
                              key={index + 1}
                              active={index + 1 === currentPage}
                              onClick={() => handlePageChange(index + 1)}
                            >
                              {index + 1}
                            </Pagination.Item>
                          );
                        } else if (
                          index + 1 === currentPage - 2 ||
                          index + 1 === currentPage + 2
                        ) {
                          return <Pagination.Ellipsis key={index + 1} />;
                        }
                        return null;
                      })}
                      <Pagination.Next
                        onClick={() => handlePageChange(currentPage + 1)}
                        disabled={currentPage === totalPages}
                      />
                      <Pagination.Last onClick={() => handlePageChange(totalPages)} />
                    </Pagination>
                  </div>
                </div>
              </Tab.Pane>
              <Tab.Pane eventKey="third">
                <div className="p-4 border-bottom">
                  <h5 className="h5">Leave</h5>
                </div>
                <div className="table-responsive custom-scrollbar table-view-responsive py-primary">
                  <table className="table mb-0">
                    <thead>
                      <tr>
                        <th
                          track-by="1"
                          className="datatable-th py-4 px-3 table_color_css"
                        >
                          <span className="font-size-default">
                            <span>Date & time</span>
                          </span>
                        </th>
                        <th
                          track-by="2"
                          className="datatable-th py-4 px-3 table_color"
                        >
                          <span className="font-size-default">
                            <span>Leave duration</span>
                          </span>
                        </th>
                        <th
                          track-by="3"
                          className="datatable-th py-4 px-3 table_color text-right"
                        >
                          <span className="font-size-default">Leave Type</span>
                        </th>
                        {/* <th
                                track-by="3"
                                className="datatable-th py-4 px-3 table_color text-right"
                              >
                                <span className="font-size-default">
                                  Attachments
                                </span>
                              </th> */}
                        {/* <th
                                track-by="3"
                                className="datatable-th py-4 px-3 table_color text-right"
                              >
                                <span className="font-size-default">
                                  Activity
                                </span>
                              </th> */}
                        <th
                          track-by="3"
                          className="datatable-th py-4 px-3 table_color text-right"
                        >
                          <span className="font-size-default">status</span>
                        </th>
                      </tr>
                    </thead>
                    {leave.length > 0 ? (
                      <tbody>
                        {leave &&
                          leave.map((item) => (
                            <tr key={item.leave_id}>
                              <td>
                                {item.leave_start_date} to {item.leave_end_date}
                              </td>
                              <td>{item.leave_duration}</td>
                              <td>{item.leave_type}</td>
                              <td>{item.status}</td>
                            </tr>
                          ))}
                      </tbody>
                    ) : (
                      <tbody className="pt-5 pb-5">
                        <tr className="text-center">
                          <td colSpan="7" className="text-center">
                            <img
                              src="/no_data.svg"
                              alt="no data"
                              width="150px"
                            />
                            <h6 className="test-center">
                              Nothing to show here
                            </h6>
                            <p className="test-center small text-secondary">
                              Please add a new entity or manage the data table
                              to see the content here Thank you
                            </p>
                          </td>
                        </tr>
                      </tbody>
                    )}
                  </table>
                </div>
              </Tab.Pane>
              <Tab.Pane eventKey="payslip">
                <div className="p-4 border-bottom">
                  <h5 className="h5">Payslip</h5>
                </div>
                <Tab.Container
                  id="left-tabs-example"
                  defaultActiveKey="thismonth"
                >
                  <div className="d-lg-flex justify-content-between px-3">
                    <div className="d-lg-flex justify-content-between align-items-center tab_css_leave">
                      From
                      <Form.Control
                        type="date"
                        className="tab_css_leave mx-2"
                      //  onChange={(e) => {
                      //   setLeaveAssign((prevState) => ({
                      //         ...prevState,
                      //         leave_type: e.target.value,
                      //       }));
                      //     }}
                      />
                      To
                      <Form.Control
                        type="date"
                        className="tab_css_leave mx-2"
                      />
                    </div>
                    <Nav
                      variant="pills"
                      className="d-flex justify-content-left"
                      onSelect={(selectedKey) => setSearchPage(selectedKey)}
                    >
                      <Nav.Item>
                        <Nav.Link
                          eventKey="thismonth"
                          className="tab_css_leave p-2"
                        >
                          This month
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link
                          eventKey="lastmonth"
                          className="tab_css_leave p-2"
                        >
                          Last month
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link
                          eventKey="thisyear"
                          className="tab_css_leave p-2"
                        >
                          This year
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link
                          eventKey="lastyear"
                          className="tab_css_leave p-2"
                        >
                          Last year
                        </Nav.Link>
                      </Nav.Item>

                      <Nav.Item>
                        <Nav.Link
                          eventKey="total"
                          className="tab_css_leave p-2"
                        >
                          Total
                        </Nav.Link>
                      </Nav.Item>
                    </Nav>
                  </div>
                  <Tab.Content>
                    <Tab.Pane eventKey="thismonth">
                      <div className="table-responsive custom-scrollbar table-view-responsive py-primary">
                        <table className="table mb-0">
                          <thead>
                            <tr>
                              <th
                                track-by="1"
                                className="datatable-th py-4 px-3 table_color"
                              >
                                <span className="font-size-default">
                                  <span>Month</span>
                                </span>
                              </th>
                              <th
                                track-by="2"
                                className="datatable-th py-4 px-3 table_color"
                              >
                                <span className="font-size-default">
                                  <span>Leave</span>
                                </span>
                              </th>
                              <th
                                track-by="3"
                                className="datatable-th py-4 px-3 table_color text-right"
                              >
                                <span className="font-size-default">PF</span>
                              </th>
                              <th
                                track-by="3"
                                className="datatable-th py-4 px-3 table_color text-right"
                              >
                                <span className="font-size-default">
                                  Other Deduction
                                </span>
                              </th>

                              <th
                                track-by="3"
                                className="datatable-th py-4 px-3 table_color text-right"
                              >
                                <span className="font-size-default">
                                  Create&nbsp;Date
                                </span>
                              </th>
                              <th
                                track-by="3"
                                className="datatable-th py-4 px-3 table_color text-right"
                              >
                                <span className="font-size-default">
                                  Salary
                                </span>
                              </th>
                              <th
                                track-by="3"
                                className="datatable-th py-4 px-3 table_color text-right"
                              >
                                <span className="font-size-default">
                                  Net&nbsp;Salary
                                </span>
                              </th>
                              <th
                                track-by="3"
                                className="datatable-th py-4 px-3 table_color text-right"
                              >
                                <span className="font-size-default">
                                  Details
                                </span>
                              </th>
                            </tr>
                          </thead>
                          {listPayslip.length > 0 ? (
                            <tbody>
                              {listPayslip &&
                                listPayslip.map((item) => (
                                  <tr key={item.id}>
                                    <td className="px-3">{item.month}</td>
                                    <td className="px-3">{item.leave}</td>
                                    <td className="px-3">{item.pf}</td>

                                    <td className="px-3">
                                      {item.custome_price}
                                    </td>
                                    <td className="px-3">
                                      {item.create_payslip}
                                    </td>
                                    <td className="px-3">
                                      {item.basic_sallery}
                                    </td>
                                    <td className="px-3">{item.pay_total}</td>
                                    <td>
                                      <button
                                        type="button"
                                        className="btn btn-secondary puch_btn"
                                        onClick={() =>
                                          downloadPdf(
                                            item.id,
                                            item.user_id,
                                            item.name,
                                            item.create_payslip
                                          )
                                        }
                                        disabled={
                                          loading && item.id === loadingId
                                        }
                                      >
                                        {loading && item.id === loadingId ? (
                                          <>
                                            <Spinner
                                              animation="grow"
                                              size="sm"
                                              style={{ background: "#fff" }}
                                            />
                                            &nbsp;
                                            <Spinner
                                              animation="grow"
                                              size="sm"
                                              style={{ background: "#fff" }}
                                            />
                                          </>
                                        ) : (
                                          "View"
                                        )}
                                      </button>
                                    </td>
                                  </tr>
                                ))}
                            </tbody>
                          ) : (
                            <tbody>
                              <tr className="text-center">
                                <td colSpan="9">
                                  <span className="pt-5 pb-5">
                                    <img
                                      src="/no_data.svg"
                                      alt="no data"
                                      width="150px"
                                    />
                                    <h6>Nothing to show here</h6>
                                    <p className="small text-secondary">
                                      Please add a new entity or manage the data
                                      table to see the content here <br />
                                      Thank you
                                    </p>
                                  </span>
                                </td>
                              </tr>
                            </tbody>
                          )}
                        </table>
                      </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey="lastmonth">
                      <div className="table-responsive custom-scrollbar table-view-responsive py-primary">
                        <table className="table mb-0">
                          <thead>
                            <tr>
                              <th
                                track-by="1"
                                className="datatable-th py-4 px-3 table_color"
                              >
                                <span className="font-size-default">
                                  <span>Month</span>
                                </span>
                              </th>
                              <th
                                track-by="2"
                                className="datatable-th py-4 px-3 table_color"
                              >
                                <span className="font-size-default">
                                  <span>Leave</span>
                                </span>
                              </th>
                              <th
                                track-by="3"
                                className="datatable-th py-4 px-3 table_color text-right"
                              >
                                <span className="font-size-default">PF</span>
                              </th>
                              <th
                                track-by="3"
                                className="datatable-th py-4 px-3 table_color text-right"
                              >
                                <span className="font-size-default">
                                  Other Deduction
                                </span>
                              </th>
                              <th
                                track-by="3"
                                className="datatable-th py-4 px-3 table_color text-right"
                              >
                                <span className="font-size-default">
                                  Create&nbsp;Date
                                </span>
                              </th>
                              <th
                                track-by="3"
                                className="datatable-th py-4 px-3 table_color text-right"
                              >
                                <span className="font-size-default">
                                  Salary
                                </span>
                              </th>
                              <th
                                track-by="3"
                                className="datatable-th py-4 px-3 table_color text-right"
                              >
                                <span className="font-size-default">
                                  Net&nbsp;Salary
                                </span>
                              </th>
                              <th
                                track-by="3"
                                className="datatable-th py-4 px-3 table_color text-right"
                              >
                                <span className="font-size-default">
                                  Details
                                </span>
                              </th>
                            </tr>
                          </thead>
                          {listPayslip.length > 0 ? (
                            <tbody>
                              {listPayslip &&
                                listPayslip.map((item) => (
                                  <tr key={item.id}>
                                    <td className="px-3">{item.month}</td>
                                    <td className="px-3">{item.leave}</td>
                                    <td className="px-3">{item.pf}</td>
                                    <td className="px-3">
                                      {item.custome_price}
                                    </td>
                                    <td className="px-3">
                                      {item.create_payslip}
                                    </td>
                                    <td className="px-3">
                                      {item.basic_sallery}
                                    </td>
                                    <td className="px-3">{item.pay_total}</td>
                                    <td>
                                      <button
                                        type="button"
                                        className="btn btn-secondary puch_btn"
                                        onClick={() =>
                                          downloadPdf(
                                            item.id,
                                            item.user_id,
                                            item.name,
                                            item.create_payslip
                                          )
                                        }
                                        disabled={
                                          loading && item.id === loadingId
                                        }
                                      >
                                        {loading && item.id === loadingId ? (
                                          <>
                                            <Spinner
                                              animation="grow"
                                              size="sm"
                                              style={{ background: "#fff" }}
                                            />
                                            &nbsp;
                                            <Spinner
                                              animation="grow"
                                              size="sm"
                                              style={{ background: "#fff" }}
                                            />
                                          </>
                                        ) : (
                                          "View"
                                        )}
                                      </button>
                                    </td>
                                  </tr>
                                ))}
                            </tbody>
                          ) : (
                            <tbody>
                              <tr className="text-center">
                                <td colSpan="8">
                                  <span className="pt-5 pb-5">
                                    <img
                                      src="/no_data.svg"
                                      alt="no data"
                                      width="150px"
                                    />
                                    <h6>Nothing to show here</h6>
                                    <p className="small text-secondary">
                                      Please add a new entity or manage the data
                                      table to see the content here <br />
                                      Thank you
                                    </p>
                                  </span>
                                </td>
                              </tr>
                            </tbody>
                          )}
                        </table>
                      </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey="thisyear">
                      <div className="table-responsive custom-scrollbar table-view-responsive py-primary">
                        <table className="table mb-0">
                          <thead>
                            <tr>
                              <th
                                track-by="1"
                                className="datatable-th py-4 px-3 table_color"
                              >
                                <span className="font-size-default">
                                  <span>Month</span>
                                </span>
                              </th>
                              <th
                                track-by="2"
                                className="datatable-th py-4 px-3 table_color"
                              >
                                <span className="font-size-default">
                                  <span>Leave</span>
                                </span>
                              </th>
                              <th
                                track-by="3"
                                className="datatable-th py-4 px-3 table_color text-right"
                              >
                                <span className="font-size-default">PF</span>
                              </th>
                              <th
                                track-by="3"
                                className="datatable-th py-4 px-3 table_color text-right"
                              >
                                <span className="font-size-default">
                                  Other Deduction
                                </span>
                              </th>
                              <th
                                track-by="3"
                                className="datatable-th py-4 px-3 table_color text-right"
                              >
                                <span className="font-size-default">
                                  Create&nbsp;Date
                                </span>
                              </th>
                              <th
                                track-by="3"
                                className="datatable-th py-4 px-3 table_color text-right"
                              >
                                <span className="font-size-default">
                                  Salary
                                </span>
                              </th>
                              <th
                                track-by="3"
                                className="datatable-th py-4 px-3 table_color text-right"
                              >
                                <span className="font-size-default">
                                  Net&nbsp;Salary
                                </span>
                              </th>
                              <th
                                track-by="3"
                                className="datatable-th py-4 px-3 table_color text-right"
                              >
                                <span className="font-size-default">
                                  Details
                                </span>
                              </th>
                            </tr>
                          </thead>
                          {listPayslip.length > 0 ? (
                            <tbody>
                              {listPayslip &&
                                listPayslip.map((item) => (
                                  <tr key={item.id}>
                                    <td className="px-3">{item.month}</td>
                                    <td className="px-3">{item.leave}</td>
                                    <td className="px-3">{item.pf}</td>
                                    <td className="px-3">
                                      {item.custome_price}
                                    </td>
                                    <td className="px-3">
                                      {item.create_payslip}
                                    </td>
                                    <td className="px-3">
                                      {item.basic_sallery}
                                    </td>
                                    <td className="px-3">{item.pay_total}</td>
                                    <td>
                                      <button
                                        type="button"
                                        className="btn btn-secondary puch_btn"
                                        onClick={() =>
                                          downloadPdf(
                                            item.id,
                                            item.user_id,
                                            item.name,
                                            item.create_payslip
                                          )
                                        }
                                        disabled={
                                          loading && item.id === loadingId
                                        }
                                      >
                                        {loading && item.id === loadingId ? (
                                          <>
                                            <Spinner
                                              animation="grow"
                                              size="sm"
                                              style={{ background: "#fff" }}
                                            />
                                            &nbsp;
                                            <Spinner
                                              animation="grow"
                                              size="sm"
                                              style={{ background: "#fff" }}
                                            />
                                          </>
                                        ) : (
                                          "View"
                                        )}
                                      </button>
                                    </td>
                                  </tr>
                                ))}
                            </tbody>
                          ) : (
                            <tbody>
                              <tr className="text-center">
                                <td colSpan="8">
                                  <span className="pt-5 pb-5">
                                    <img
                                      src="/no_data.svg"
                                      alt="no data"
                                      width="150px"
                                    />
                                    <h6>Nothing to show here</h6>
                                    <p className="small text-secondary">
                                      Please add a new entity or manage the data
                                      table to see the content here <br />
                                      Thank you
                                    </p>
                                  </span>
                                </td>
                              </tr>
                            </tbody>
                          )}
                        </table>
                      </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey="lastyear">
                      <div className="table-responsive custom-scrollbar table-view-responsive py-primary">
                        <table className="table mb-0">
                          <thead>
                            <tr>
                              <th
                                track-by="1"
                                className="datatable-th py-4 px-3 table_color"
                              >
                                <span className="font-size-default">
                                  <span>Month</span>
                                </span>
                              </th>
                              <th
                                track-by="2"
                                className="datatable-th py-4 px-3 table_color"
                              >
                                <span className="font-size-default">
                                  <span>Leave</span>
                                </span>
                              </th>
                              <th
                                track-by="3"
                                className="datatable-th py-4 px-3 table_color text-right"
                              >
                                <span className="font-size-default">PF</span>
                              </th>
                              <th
                                track-by="3"
                                className="datatable-th py-4 px-3 table_color text-right"
                              >
                                <span className="font-size-default">
                                  Other Deduction
                                </span>
                              </th>
                              <th
                                track-by="3"
                                className="datatable-th py-4 px-3 table_color text-right"
                              >
                                <span className="font-size-default">
                                  Create&nbsp;Date
                                </span>
                              </th>
                              <th
                                track-by="3"
                                className="datatable-th py-4 px-3 table_color text-right"
                              >
                                <span className="font-size-default">
                                  Salary
                                </span>
                              </th>
                              <th
                                track-by="3"
                                className="datatable-th py-4 px-3 table_color text-right"
                              >
                                <span className="font-size-default">
                                  Net&nbsp;Salary
                                </span>
                              </th>
                              <th
                                track-by="3"
                                className="datatable-th py-4 px-3 table_color text-right"
                              >
                                <span className="font-size-default">
                                  Details
                                </span>
                              </th>
                            </tr>
                          </thead>
                          {listPayslip.length > 0 ? (
                            <tbody>
                              {listPayslip &&
                                listPayslip.map((item) => (
                                  <tr key={item.id}>
                                    <td className="px-3">{item.month}</td>
                                    <td className="px-3">{item.leave}</td>
                                    <td className="px-3">{item.pf}</td>
                                    <td className="px-3">
                                      {item.custome_price}
                                    </td>
                                    <td className="px-3">
                                      {item.create_payslip}
                                    </td>
                                    <td className="px-3">
                                      {item.basic_sallery}
                                    </td>
                                    <td className="px-3">{item.pay_total}</td>
                                    <td>
                                      <button
                                        type="button"
                                        className="btn btn-secondary puch_btn"
                                        onClick={() =>
                                          downloadPdf(
                                            item.id,
                                            item.user_id,
                                            item.name,
                                            item.create_payslip
                                          )
                                        }
                                        disabled={
                                          loading && item.id === loadingId
                                        }
                                      >
                                        {loading && item.id === loadingId ? (
                                          <>
                                            <Spinner
                                              animation="grow"
                                              size="sm"
                                              style={{ background: "#fff" }}
                                            />
                                            &nbsp;
                                            <Spinner
                                              animation="grow"
                                              size="sm"
                                              style={{ background: "#fff" }}
                                            />
                                          </>
                                        ) : (
                                          "View"
                                        )}
                                      </button>
                                    </td>
                                  </tr>
                                ))}
                            </tbody>
                          ) : (
                            <tbody>
                              <tr className="text-center">
                                <td colSpan="8">
                                  <span className="pt-5 pb-5">
                                    <img
                                      src="/no_data.svg"
                                      alt="no data"
                                      width="150px"
                                    />
                                    <h6>Nothing to show here</h6>
                                    <p className="small text-secondary">
                                      Please add a new entity or manage the data
                                      table to see the content here <br />
                                      Thank you
                                    </p>
                                  </span>
                                </td>
                              </tr>
                            </tbody>
                          )}
                        </table>
                      </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey="total">
                      <div className="table-responsive custom-scrollbar table-view-responsive py-primary">
                        <table className="table mb-0">
                          <thead>
                            <tr>
                              <th
                                track-by="1"
                                className="datatable-th py-4 px-3 table_color"
                              >
                                <span className="font-size-default">
                                  <span>Month</span>
                                </span>
                              </th>
                              <th
                                track-by="2"
                                className="datatable-th py-4 px-3 table_color"
                              >
                                <span className="font-size-default">
                                  <span>Leave</span>
                                </span>
                              </th>
                              <th
                                track-by="3"
                                className="datatable-th py-4 px-3 table_color text-right"
                              >
                                <span className="font-size-default">PF</span>
                              </th>
                              <th
                                track-by="3"
                                className="datatable-th py-4 px-3 table_color text-right"
                              >
                                <span className="font-size-default">
                                  Other Deduction
                                </span>
                              </th>
                              <th
                                track-by="3"
                                className="datatable-th py-4 px-3 table_color text-right"
                              >
                                <span className="font-size-default">
                                  Create&nbsp;Date
                                </span>
                              </th>
                              <th
                                track-by="3"
                                className="datatable-th py-4 px-3 table_color text-right"
                              >
                                <span className="font-size-default">
                                  Salary
                                </span>
                              </th>
                              <th
                                track-by="3"
                                className="datatable-th py-4 px-3 table_color text-right"
                              >
                                <span className="font-size-default">
                                  Net&nbsp;Salary
                                </span>
                              </th>
                              <th
                                track-by="3"
                                className="datatable-th py-4 px-3 table_color text-right"
                              >
                                <span className="font-size-default">
                                  Details
                                </span>
                              </th>
                            </tr>
                          </thead>
                          {listPayslip.length > 0 ? (
                            <tbody>
                              {listPayslip &&
                                listPayslip.map((item) => (
                                  <tr key={item.id}>
                                    <td className="px-3">{item.month}</td>
                                    <td className="px-3">{item.leave}</td>
                                    <td className="px-3">{item.pf}</td>
                                    <td className="px-3">
                                      {item.custome_price}
                                    </td>
                                    <td className="px-3">
                                      {item.create_payslip}
                                    </td>
                                    <td className="px-3">
                                      {item.basic_sallery}
                                    </td>
                                    <td className="px-3">{item.pay_total}</td>
                                    <td>
                                      <button
                                        type="button"
                                        className="btn btn-secondary puch_btn"
                                        onClick={() =>
                                          downloadPdf(
                                            item.id,
                                            item.user_id,
                                            item.name,
                                            item.create_payslip
                                          )
                                        }
                                        disabled={
                                          loading && item.id === loadingId
                                        }
                                      >
                                        {loading && item.id === loadingId ? (
                                          <>
                                            <Spinner
                                              animation="grow"
                                              size="sm"
                                              style={{ background: "#fff" }}
                                            />
                                            &nbsp;
                                            <Spinner
                                              animation="grow"
                                              size="sm"
                                              style={{ background: "#fff" }}
                                            />
                                          </>
                                        ) : (
                                          "View"
                                        )}
                                      </button>
                                    </td>
                                  </tr>
                                ))}
                            </tbody>
                          ) : (
                            <tbody>
                              <tr className="text-center">
                                <td colSpan="8">
                                  <span className="pt-5 pb-5">
                                    <img
                                      src="/no_data.svg"
                                      alt="no data"
                                      width="150px"
                                    />
                                    <h6>Nothing to show here</h6>
                                    <p className="small text-secondary">
                                      Please add a new entity or manage the data
                                      table to see the content here <br />
                                      Thank you
                                    </p>
                                  </span>
                                </td>
                              </tr>
                            </tbody>
                          )}
                        </table>
                      </div>
                    </Tab.Pane>
                  </Tab.Content>
                </Tab.Container>
              </Tab.Pane>
              <Tab.Pane eventKey="bankdetails">
                <div className=" p-4 border-bottom">
                  <h5 className="h5">Bank Details</h5>
                </div>
                <div className="table-responsive custom-scrollbar table-view-responsive py-primary px-4 ">
                  <table className="table mb-0">
                    <tbody>
                      {bankDetails.account_holder_name.length > 0 ? (
                        <tr>
                          <td className="py-4 px-3">
                            <tr className="w-100">
                              <th className="w_75">Account holder name :</th>
                              <td>{bankDetails.account_holder_name}</td>
                            </tr>
                            <tr className="w-100">
                              <th className="w_75">Account number :</th>
                              <td>{bankDetails.account_number}</td>
                            </tr>
                            <tr className="w-100">
                              <th className="w_75">Account title :</th>
                              <td>{bankDetails.account_title}</td>
                            </tr>
                            <tr className="w-100">
                              <th className="w_75">Bank name :</th>
                              <td>{bankDetails.bank_name}</td>
                            </tr>
                            <tr className="w-100">
                              <th className="w_75">Branch name :</th>
                              <td>{bankDetails.branch_name}</td>
                            </tr>
                            <tr className="w-100">
                              <th className="w_75">Bank code :</th>
                              <td>{bankDetails.bank_code}</td>
                            </tr>
                            {bankDetails.tax_payer_id && (
                              <tr className="w-100">
                                <th className="w_75">Tax payer id :</th>
                                <td>{bankDetails.tax_payer_id}</td>
                              </tr>
                            )}
                          </td>
                          <td>
                            <button
                              className="puch_btn btn btn-primary"
                              onClick={() => handleEditBankShow(id)}
                            >
                              Edit
                            </button>
                          </td>
                        </tr>
                      ) : (
                        <tr>
                          <td>
                            <div className="d-lg-flex justify-content-between mt-5">
                              <div className=" tab_css_setting">
                                Not added yet
                              </div>
                              <div>
                                <button
                                  className="puch_btn btn btn-primary"
                                  onClick={handleBankShow}
                                >
                                  Add
                                </button>
                              </div>
                            </div>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>

                {/* <div className="d-lg-flex justify-content-between mt-5">
                  <div className="w-25 tab_css_setting text-dark">
                  Account holder name :
                  </div>
                  <div className="w-50 text-center tab_css_setting">
              
                    Not added yet
                  </div>
                  <div className="w-25">
                    <button
                      className="puch_btn btn btn-primary"
                      onClick={handleAddressShow}
                    >
                      Add
                    </button>
                  </div>
                </div> */}
              </Tab.Pane>
              <Tab.Pane eventKey="address">
                <div className=" border-bottom p-4">
                  <h5 className="h5">Address Details</h5>
                </div>
                <div className="d-lg-flex justify-content-between mt-5">
                  <div className="w-25 tab_css_setting text-dark">
                    <BiHomeAlt className="h5 text-primary mx-2" />
                    Permanent address
                  </div>
                  <div className="w-50 tab_css_setting text-dark">
                    {(paddresswiew && paddresswiew.address_detail) ||
                      "Not added yet"}
                    ,{paddresswiew && paddresswiew.area},
                    {paddresswiew && paddresswiew.city},
                    {paddresswiew && paddresswiew.state},
                    {paddresswiew && paddresswiew.zip_code},
                    {paddresswiew && paddresswiew.country},
                    <div>
                      <FiPhone color="text-primary" />
                      {paddresswiew && paddresswiew.phone_number}{" "}
                    </div>
                  </div>
                  <div className="w-25">
                    {!paddresswiew.address_detail.length > 0 ? (
                      <button
                        className="puch_btn btn btn-primary"
                        onClick={handleAddressShow}
                      >
                        Add
                      </button>
                    ) : (
                      <button
                        className="puch_btn btn btn-primary"
                        onClick={() => handleEditAddressShow(id)}
                      >
                        Edit
                      </button>
                    )}
                  </div>
                </div>
                <div className="d-lg-flex justify-content-between mt-5">
                  <div className="w-25 tab_css_setting text-dark">
                    <IoLocationOutline className="text-primary h5 mx-2" />
                    Current address
                  </div>
                  <div className="w-50  tab_css_setting text-dark">
                    {(caddresswiew && caddresswiew.address_detail) ||
                      "Not added yet"}
                    ,{caddresswiew && caddresswiew.area},
                    {caddresswiew && caddresswiew.city},
                    {caddresswiew && caddresswiew.state},
                    {caddresswiew && caddresswiew.zip_code},
                    {caddresswiew && caddresswiew.country},
                    <div>
                      <FiPhone color="text-primary" />
                      {caddresswiew && caddresswiew.phone_number}{" "}
                    </div>
                  </div>
                  <div className="w-25">
                    {!caddresswiew.address_detail.length > 0 ? (
                      <button
                        className="puch_btn btn btn-primary"
                        onClick={handlecaddressShow}
                      >
                        Add
                      </button>
                    ) : (
                      <button
                        className="puch_btn btn btn-primary"
                        onClick={() => handleeditcaddressShow()}
                      >
                        Edit
                      </button>
                    )}
                  </div>
                </div>
              </Tab.Pane>
            </Tab.Content>
          </Col>
        </Row>
      </Tab.Container>

      <Modal
        show={bank}
        onHide={handleBankClose}
        size="lg"
        className="pt-5 pb-5"
      >
        <Modal.Header closeButton>
          <Modal.Title>Add Bank Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form noValidate validated={validatedBank} onSubmit={addBank}>
            <Form.Group className="mb-3 d-lg-flex align-items-center">
              <Form.Label className="bank_label">Bank name</Form.Label>
              <div className="w-100">
                <Form.Control
                  type="text"
                  placeholder="Add bank name"
                  value={addbankdetails.bank_name}
                  onChange={(e) =>
                    setAddBankDetails({
                      ...addbankdetails,
                      bank_name: e.target.value,
                    })
                  }
                  required
                />
                <Form.Control.Feedback type="invalid">
                  Please enter bank name.
                </Form.Control.Feedback>
              </div>
            </Form.Group>
            <Form.Group className="mb-3 d-lg-flex align-items-center">
              <Form.Label className="bank_label">Bank code</Form.Label>
              <div className="w-100">
                <Form.Control
                  type="text"
                  placeholder="Add bank code"
                  value={addbankdetails.bank_code}
                  onChange={(e) =>
                    setAddBankDetails({
                      ...addbankdetails,
                      bank_code: e.target.value,
                    })
                  }
                  required
                />
                <Form.Control.Feedback type="invalid">
                  Please enter bank code.
                </Form.Control.Feedback>
              </div>
            </Form.Group>

            <Form.Group className="mb-3 d-lg-flex align-items-center">
              <Form.Label className="bank_label">Account title</Form.Label>
              <div className="w-100">
                <Form.Control
                  type="text"
                  placeholder="Add account title"
                  value={addbankdetails.account_title}
                  onChange={(e) =>
                    setAddBankDetails({
                      ...addbankdetails,
                      account_title: e.target.value,
                    })
                  }
                  required
                />
                <Form.Control.Feedback type="invalid">
                  Please enter bank account title.
                </Form.Control.Feedback>
              </div>
            </Form.Group>
            <Form.Group className="mb-3 d-lg-flex align-items-center">
              <Form.Label className="bank_label">Branch name</Form.Label>
              <div className="w-100">
                <Form.Control
                  type="text"
                  placeholder="Add branch name"
                  value={addbankdetails.branch_name}
                  onChange={(e) =>
                    setAddBankDetails({
                      ...addbankdetails,
                      branch_name: e.target.value,
                    })
                  }
                  required
                />
                <Form.Control.Feedback type="invalid">
                  Please enter branch name.
                </Form.Control.Feedback>
              </div>
            </Form.Group>
            <Form.Group className="mb-3 d-lg-flex align-items-center">
              <Form.Label className="bank_label">
                Account holder name
              </Form.Label>
              <div className="w-100">
                <Form.Control
                  type="text"
                  placeholder="Add account holder name"
                  className="input_label"
                  value={addbankdetails.account_holder_name}
                  onChange={(e) =>
                    setAddBankDetails({
                      ...addbankdetails,
                      account_holder_name: e.target.value,
                    })
                  }
                  required
                />
                <Form.Control.Feedback type="invalid">
                  Please enter account holder name.
                </Form.Control.Feedback>
              </div>
            </Form.Group>
            <Form.Group className="mb-3 d-lg-flex align-items-center">
              <Form.Label className="bank_label">Account number</Form.Label>
              <div className="w-100">
                <Form.Control
                  type="text"
                  placeholder="Add account number"
                  value={addbankdetails.account_number}
                  onChange={(e) =>
                    setAddBankDetails({
                      ...addbankdetails,
                      account_number: e.target.value,
                    })
                  }
                  required
                />
                <Form.Control.Feedback type="invalid">
                  Please enter bank account number.
                </Form.Control.Feedback>
              </div>
            </Form.Group>
            <Form.Group className="mb-3 d-lg-flex align-items-center">
              <Form.Label className="bank_label">Tax payer id</Form.Label>
              <div className="w-100">
                <Form.Control
                  type="text"
                  placeholder="Add tax payer id"
                  value={addbankdetails.tax_payer_id}
                  onChange={(e) =>
                    setAddBankDetails({
                      ...addbankdetails,
                      tax_payer_id: e.target.value,
                    })
                  }
                />
                <Form.Control.Feedback type="invalid">
                  Please enter tax payer id.
                </Form.Control.Feedback>
              </div>
            </Form.Group>
            <Modal.Footer>
              <button
                className="btn btn-secondary puch_btn"
                onClick={handleBankClose}
                type="button"
              >
                Cancel
              </button>
              <button className="btn btn-primary puch_btn" type="submit">
                Save
              </button>
            </Modal.Footer>
          </Form>
        </Modal.Body>
      </Modal>

      {/*--------------Edit bank details-------------------------*/}

      <Modal
        show={bankEdit}
        onHide={handleEditBankClose}
        size="lg"
        className="pt-5 pb-5"
      >
        <Modal.Header closeButton>
          <Modal.Title>Edit Bank Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form noValidate validated={validatedBank} onSubmit={updateBank}>
            <Form.Group className="mb-3 d-lg-flex align-items-center">
              <Form.Label className="bank_label">Bank name</Form.Label>
              <div className="w-100">
                <Form.Control
                  type="text"
                  placeholder="Add bank name"
                  value={bankDetails.bank_name}
                  onChange={(e) =>
                    setBankDetails({
                      ...bankDetails,
                      bank_name: e.target.value,
                    })
                  }
                  required
                />
                <Form.Control.Feedback type="invalid">
                  Please enter bank name.
                </Form.Control.Feedback>
              </div>
            </Form.Group>
            <Form.Group className="mb-3 d-lg-flex align-items-center">
              <Form.Label className="bank_label">Bank code</Form.Label>
              <div className="w-100">
                <Form.Control
                  type="text"
                  placeholder="Add bank code"
                  value={bankDetails.bank_code}
                  onChange={(e) =>
                    setBankDetails({
                      ...bankDetails,
                      bank_code: e.target.value,
                    })
                  }
                  required
                />
                <Form.Control.Feedback type="invalid">
                  Please enter bank code.
                </Form.Control.Feedback>
              </div>
            </Form.Group>

            <Form.Group className="mb-3 d-lg-flex align-items-center">
              <Form.Label className="bank_label">Account title</Form.Label>
              <div className="w-100">
                <Form.Control
                  type="text"
                  placeholder="Add account title"
                  value={bankDetails.account_title}
                  onChange={(e) =>
                    setBankDetails({
                      ...bankDetails,
                      account_title: e.target.value,
                    })
                  }
                  required
                />
                <Form.Control.Feedback type="invalid">
                  Please enter bank account title.
                </Form.Control.Feedback>
              </div>
            </Form.Group>
            <Form.Group className="mb-3 d-lg-flex align-items-center">
              <Form.Label className="bank_label">Branch name</Form.Label>
              <div className="w-100">
                <Form.Control
                  type="text"
                  placeholder="Add branch name"
                  value={bankDetails.branch_name}
                  onChange={(e) =>
                    setBankDetails({
                      ...bankDetails,
                      branch_name: e.target.value,
                    })
                  }
                  required
                />
                <Form.Control.Feedback type="invalid">
                  Please enter branch name.
                </Form.Control.Feedback>
              </div>
            </Form.Group>
            <Form.Group className="mb-3 d-lg-flex align-items-center">
              <Form.Label className="bank_label">
                Account holder name
              </Form.Label>
              <div className="w-100">
                <Form.Control
                  type="text"
                  placeholder="Add account holder name"
                  className="input_label"
                  value={bankDetails.account_holder_name}
                  onChange={(e) =>
                    setBankDetails({
                      ...bankDetails,
                      account_holder_name: e.target.value,
                    })
                  }
                  required
                />
                <Form.Control.Feedback type="invalid">
                  Please enter account holder name.
                </Form.Control.Feedback>
              </div>
            </Form.Group>
            <Form.Group className="mb-3 d-lg-flex align-items-center">
              <Form.Label className="bank_label">Account number</Form.Label>
              <div className="w-100">
                <Form.Control
                  type="text"
                  placeholder="Add account number"
                  value={bankDetails.account_number}
                  onChange={(e) =>
                    setBankDetails({
                      ...bankDetails,
                      account_number: e.target.value,
                    })
                  }
                  required
                />
                <Form.Control.Feedback type="invalid">
                  Please enter bank account number.
                </Form.Control.Feedback>
              </div>
            </Form.Group>
            <Form.Group className="mb-3 d-lg-flex align-items-center">
              <Form.Label className="bank_label">Tax payer id</Form.Label>
              <div className="w-100">
                <Form.Control
                  type="text"
                  placeholder="Add tax payer id"
                  value={bankDetails.tax_payer_id}
                  onChange={(e) =>
                    setBankDetails({
                      ...bankDetails,
                      tax_payer_id: e.target.value,
                    })
                  }
                />
              </div>
            </Form.Group>
            <Modal.Footer>
              <button
                className="btn btn-secondary puch_btn"
                onClick={handleEditBankClose}
                type="button"
              >
                Cancel
              </button>
              <button className="btn btn-primary puch_btn" type="submit">
                Save
              </button>
            </Modal.Footer>
          </Form>
        </Modal.Body>
      </Modal>

      {/*--------------add address-------------------------*/}
      <Modal
        show={address}
        onHide={handleAddressClose}
        size="lg"
        className="pt-5 pb-5"
      >
        <Modal.Header closeButton>
          <Modal.Title>Add Permanent address</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form
            noValidate
            validated={validatedPaddress}
            onSubmit={handlePaddressAdd}
          >
            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlTextarea1"
            >
              <Form.Label>Address Details</Form.Label>
              <Form.Control
                as="textarea"
                placeholder="Add address details here"
                rows={2}
                value={paddress.address_detail}
                onChange={(e) =>
                  setPaddress({ ...paddress, address_detail: e.target.value })
                }
                required
              />
            </Form.Group>
            <div className="d-flex justify-content-between">
              <Form.Group
                className="mb-3 w-100"
                controlId="exampleForm.ControlTextarea1"
              >
                <Form.Label>Area</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter area "
                  value={paddress.area}
                  onChange={(e) =>
                    setPaddress({ ...paddress, area: e.target.value })
                  }
                  required
                />
              </Form.Group>
              &nbsp; &nbsp; &nbsp;
              <Form.Group
                className="mb-3 w-100"
                controlId="exampleForm.ControlTextarea1"
              >
                <Form.Label>City</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter city"
                  value={paddress.city}
                  onChange={(e) =>
                    setPaddress({ ...paddress, city: e.target.value })
                  }
                  required
                />
              </Form.Group>
            </div>
            <div className="d-flex justify-content-between">
              <Form.Group
                className="mb-3 w-100"
                controlId="exampleForm.ControlTextarea1"
              >
                <Form.Label>State</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter state"
                  value={paddress.state}
                  onChange={(e) =>
                    setPaddress({ ...paddress, state: e.target.value })
                  }
                  required
                />
              </Form.Group>
              &nbsp; &nbsp; &nbsp;
              <Form.Group
                className="mb-3 w-100"
                controlId="exampleForm.ControlTextarea1"
              >
                <Form.Label>Zip code</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter zip code"
                  value={paddress.zip_code}
                  onChange={(e) =>
                    setPaddress({ ...paddress, zip_code: e.target.value })
                  }
                  required
                />
              </Form.Group>
            </div>

            <div className="d-flex justify-content-between">
              <Form.Group
                className="mb-3 w-100"
                controlId="exampleForm.ControlTextarea1"
              >
                <Form.Label>Country</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter country"
                  value={paddress.country}
                  onChange={(e) =>
                    setPaddress({ ...paddress, country: e.target.value })
                  }
                  required
                />
              </Form.Group>
              &nbsp; &nbsp; &nbsp;
              <Form.Group
                className="mb-3 w-100"
                controlId="exampleForm.ControlTextarea1"
              >
                <Form.Label>Phone number</Form.Label>

                <Form.Control
                  type="text"
                  placeholder="Enter phone number"
                  value={paddress.phone_number}
                  onChange={(e) =>
                    setPaddress({ ...paddress, phone_number: e.target.value })
                  }
                  required
                />
              </Form.Group>
            </div>

            <Modal.Footer>
              <button
                className="btn btn-secondary puch_btn"
                onClick={handleAddressClose}
                type="button"
              >
                Cancel
              </button>
              <button className="btn btn-primary puch_btn" type="submit">
                Save
              </button>
            </Modal.Footer>
          </Form>
        </Modal.Body>
      </Modal>
      {/*--------------add address-------------------------*/}
      <Modal
        show={caddressPopup}
        onHide={handleCaddressClose}
        size="lg"
        className="pt-5 pb-5"
      >
        <Modal.Header closeButton>
          <Modal.Title>Add Current address</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form
            noValidate
            validated={validatedCaddress}
            onSubmit={handleCaddressAdd}
          >
            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlTextarea1"
            >
              <Form.Label>Address Details</Form.Label>
              <Form.Control
                as="textarea"
                placeholder="Add address details here"
                rows={2}
                value={caddress.address_detail}
                onChange={(e) =>
                  setCaddress({ ...caddress, address_detail: e.target.value })
                }
                required
              />
            </Form.Group>
            <div className="d-flex justify-content-between">
              <Form.Group
                className="mb-3 w-100"
                controlId="exampleForm.ControlTextarea1"
              >
                <Form.Label>Area</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter area "
                  value={caddress.area}
                  onChange={(e) =>
                    setCaddress({ ...caddress, area: e.target.value })
                  }
                  required
                />
              </Form.Group>
              &nbsp; &nbsp; &nbsp;
              <Form.Group
                className="mb-3 w-100"
                controlId="exampleForm.ControlTextarea1"
              >
                <Form.Label>City</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter city"
                  value={caddress.city}
                  onChange={(e) =>
                    setCaddress({ ...caddress, city: e.target.value })
                  }
                  required
                />
              </Form.Group>
            </div>
            <div className="d-flex justify-content-between">
              <Form.Group
                className="mb-3 w-100"
                controlId="exampleForm.ControlTextarea1"
              >
                <Form.Label>State</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter state"
                  value={caddress.state}
                  onChange={(e) =>
                    setCaddress({ ...caddress, state: e.target.value })
                  }
                  required
                />
              </Form.Group>
              &nbsp; &nbsp; &nbsp;
              <Form.Group
                className="mb-3 w-100"
                controlId="exampleForm.ControlTextarea1"
              >
                <Form.Label>Zip code</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter zip code"
                  value={caddress.zip_code}
                  onChange={(e) =>
                    setCaddress({ ...caddress, zip_code: e.target.value })
                  }
                  required
                />
              </Form.Group>
            </div>

            <div className="d-flex justify-content-between">
              <Form.Group
                className="mb-3 w-100"
                controlId="exampleForm.ControlTextarea1"
              >
                <Form.Label>Country</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter country"
                  value={caddress.country}
                  onChange={(e) =>
                    setCaddress({ ...caddress, country: e.target.value })
                  }
                  required
                />
              </Form.Group>
              &nbsp; &nbsp; &nbsp;
              <Form.Group
                className="mb-3 w-100"
                controlId="exampleForm.ControlTextarea1"
              >
                <Form.Label>Phone number</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter phone number"
                  value={caddress.phone_number}
                  onChange={(e) =>
                    setCaddress({ ...caddress, phone_number: e.target.value })
                  }
                  required
                />
              </Form.Group>
            </div>
            <Modal.Footer>
              <button
                className="btn btn-secondary puch_btn"
                onClick={handleCaddressClose}
                type="button"
              >
                Cancel
              </button>
              <button className="btn btn-primary puch_btn" type="submit">
                Save
              </button>
            </Modal.Footer>
          </Form>
        </Modal.Body>
      </Modal>
      {/*--------------edit address-------------------------*/}
      <Modal
        show={editcaddressPopup}
        onHide={handleEditCaddressClose}
        size="lg"
        className="pt-5 pb-5"
      >
        <Modal.Header closeButton>
          <Modal.Title>Edit Current address</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form
            noValidate
            validated={validatedCaddress}
            onSubmit={handleCaddressEdit}
          >
            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlTextarea1"
            >
              <Form.Label>Address Details</Form.Label>
              <Form.Control
                as="textarea"
                placeholder="Add address details here"
                rows={2}
                value={caddresswiew.address_detail}
                onChange={(e) =>
                  setCaddressview({
                    ...caddresswiew,
                    address_detail: e.target.value,
                  })
                }
                required
              />
            </Form.Group>
            <div className="d-flex justify-content-between">
              <Form.Group
                className="mb-3 w-100"
                controlId="exampleForm.ControlTextarea1"
              >
                <Form.Label>Area</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter area "
                  value={caddresswiew.area}
                  onChange={(e) =>
                    setCaddressview({ ...caddresswiew, area: e.target.value })
                  }
                  required
                />
              </Form.Group>
              &nbsp; &nbsp; &nbsp;
              <Form.Group
                className="mb-3 w-100"
                controlId="exampleForm.ControlTextarea1"
              >
                <Form.Label>City</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter city"
                  value={caddresswiew.city}
                  onChange={(e) =>
                    setCaddressview({ ...caddresswiew, city: e.target.value })
                  }
                  required
                />
              </Form.Group>
            </div>
            <div className="d-flex justify-content-between">
              <Form.Group
                className="mb-3 w-100"
                controlId="exampleForm.ControlTextarea1"
              >
                <Form.Label>State</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter state"
                  value={caddresswiew.state}
                  onChange={(e) =>
                    setCaddressview({ ...caddresswiew, state: e.target.value })
                  }
                  required
                />
              </Form.Group>
              &nbsp; &nbsp; &nbsp;
              <Form.Group
                className="mb-3 w-100"
                controlId="exampleForm.ControlTextarea1"
              >
                <Form.Label>Zip code</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter zip code"
                  value={caddresswiew.zip_code}
                  onChange={(e) =>
                    setCaddressview({
                      ...caddresswiew,
                      zip_code: e.target.value,
                    })
                  }
                  required
                />
              </Form.Group>
            </div>

            <div className="d-flex justify-content-between">
              <Form.Group
                className="mb-3 w-100"
                controlId="exampleForm.ControlTextarea1"
              >
                <Form.Label>Country</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter country"
                  value={caddresswiew.country}
                  onChange={(e) =>
                    setCaddressview({
                      ...caddresswiew,
                      country: e.target.value,
                    })
                  }
                  required
                />
              </Form.Group>
              &nbsp; &nbsp; &nbsp;
              <Form.Group
                className="mb-3 w-100"
                controlId="exampleForm.ControlTextarea1"
              >
                <Form.Label>Phone number</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter phone number"
                  value={caddresswiew.phone_number}
                  onChange={(e) =>
                    setCaddressview({
                      ...caddresswiew,
                      phone_number: e.target.value,
                    })
                  }
                  required
                />
              </Form.Group>
            </div>
            <Modal.Footer>
              <button
                className="btn btn-secondary puch_btn"
                onClick={handleEditCaddressClose}
                type="button"
              >
                Cancel
              </button>
              <button className="btn btn-primary puch_btn" type="submit">
                Save
              </button>
            </Modal.Footer>
          </Form>
        </Modal.Body>
      </Modal>

      {/*--------------edit address-------------------------*/}
      <Modal
        show={editaddress}
        onHide={handleEditAddressClose}
        size="lg"
        className="pt-5 pb-5"
      >
        <Modal.Header closeButton>
          <Modal.Title>Edit Permanent address</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form
            noValidate
            validated={validatedPaddress}
            onSubmit={handlePaddressEdit}
          >
            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlTextarea1"
            >
              <Form.Label>Address Details</Form.Label>
              <Form.Control
                as="textarea"
                placeholder="Add address details here"
                rows={2}
                value={paddresswiew.address_detail}
                onChange={(e) =>
                  setPaddressview({
                    ...paddresswiew,
                    address_detail: e.target.value,
                  })
                }
                required
              />
            </Form.Group>
            <div className="d-flex justify-content-between">
              <Form.Group
                className="mb-3 w-100"
                controlId="exampleForm.ControlTextarea1"
              >
                <Form.Label>Area</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter area"
                  value={paddresswiew.area}
                  onChange={(e) =>
                    setPaddressview({ ...paddresswiew, area: e.target.value })
                  }
                  required
                />
              </Form.Group>
              &nbsp; &nbsp; &nbsp;
              <Form.Group
                className="mb-3 w-100"
                controlId="exampleForm.ControlTextarea1"
              >
                <Form.Label>City</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter city"
                  value={paddresswiew.city}
                  onChange={(e) =>
                    setPaddressview({ ...paddresswiew, city: e.target.value })
                  }
                  required
                />
              </Form.Group>
            </div>
            <div className="d-flex justify-content-between">
              <Form.Group
                className="mb-3 w-100"
                controlId="exampleForm.ControlTextarea1"
              >
                <Form.Label>State</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter state"
                  value={paddresswiew.state}
                  onChange={(e) =>
                    setPaddressview({ ...paddresswiew, state: e.target.value })
                  }
                  required
                />
              </Form.Group>
              &nbsp; &nbsp; &nbsp;
              <Form.Group
                className="mb-3 w-100"
                controlId="exampleForm.ControlTextarea1"
              >
                <Form.Label>Zip code</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter zip code"
                  value={paddresswiew.zip_code}
                  onChange={(e) =>
                    setPaddressview({
                      ...paddresswiew,
                      zip_code: e.target.value,
                    })
                  }
                  required
                />
              </Form.Group>
            </div>
            <div className="d-flex justify-content-between">
              <Form.Group
                className="mb-3 w-100"
                controlId="exampleForm.ControlTextarea1"
              >
                <Form.Label>Country</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter country"
                  value={paddresswiew.country}
                  onChange={(e) =>
                    setPaddressview({
                      ...paddresswiew,
                      country: e.target.value,
                    })
                  }
                  required
                />
              </Form.Group>
              &nbsp; &nbsp; &nbsp;
              <Form.Group
                className="mb-3 w-100"
                controlId="exampleForm.ControlTextarea1"
              >
                <Form.Label>Phone number</Form.Label>

                <Form.Control
                  type="text"
                  placeholder="Enter phone number"
                  value={paddresswiew.phone_number}
                  onChange={(e) =>
                    setPaddressview({
                      ...paddresswiew,
                      phone_number: e.target.value,
                    })
                  }
                  required
                />
              </Form.Group>
            </div>
            <Modal.Footer>
              <button
                className="btn btn-secondary puch_btn"
                onClick={handleEditAddressClose}
                type="button"
              >
                Cancel
              </button>
              <button className="btn btn-primary puch_btn" type="submit">
                Save
              </button>
            </Modal.Footer>
          </Form>
        </Modal.Body>
      </Modal>
      {/* ---------------success---------------------- */}
      <Modal
        show={accept}
        onHide={handleAcceptClose}
        size="md"
        centered
        className="pt-5 pb-5"
      >
        <Modal.Body>
          <div className="text-center mt-5">
            <IoMdCheckmarkCircleOutline className="jobdisk_icon text-success" />
            <h5>Profile Update Successfully</h5>
          </div>
          <Modal.Footer className="border-0 justify-content-center  mb-4">
            <button
              className="btn btn-success puch_btn"
              onClick={handleAcceptClose}
            >
              Okay
            </button>
          </Modal.Footer>
        </Modal.Body>
      </Modal>
    </section>
  );
};

export default EmployeeDetails;
