import React, { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import { FiFilter } from "react-icons/fi";
import { useAuth } from "../utils/Context";

const DailyLog = ({ menuWidth }) => {
  const {id,role_id} = useAuth()
  const [show, setShow] = useState(false);

  const [showLeave, setShowLeave] = useState(false);
  const handleClose = () => setShow(false);
  const handleCloseleave = () => setShowLeave(false);

const [dalylog, setDalylog] = useState([])
useEffect(() => {
 if (role_id === '1'){
  var requestOptions = {
    method: 'GET',
    redirect: 'follow'
  };
  
  fetch(`${process.env.REACT_APP_API_BASE_URL}/admin/attendance-daily-log-list`, requestOptions)
    .then(response => response.json())
    .then(result => {
      if(result.status === true) {
        setDalylog(result.data)
      }
    })
    .catch(error => console.log('error', error));
  }
}, [role_id]);


useEffect(() => {
  if (role_id === '2'){
  var requestOptions = {
    method: "GET",
    redirect: "follow",
  };

  fetch(`${process.env.REACT_APP_API_BASE_URL}/admin/attendance-daily-log-list?user_id=${id}`,requestOptions)
    .then((response) => response.json())
    .then((result) => {
      if (result.status === true) {
        setDalylog(result.data);
      }
    })
    .catch((error) => console.log("error", error));
  }
}, [id,role_id]);


const convertToAmPm = (time) => {
  if (time) {
      // Split the time into hours and minutes
      const [hours, minutes] = time.split(':');
      // Create a new Date object with today's date and the provided time
      const date = new Date();
      date.setHours(hours);
      date.setMinutes(minutes);
      // Format the time in AM/PM format
      const formattedTime = date.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true });
      return formattedTime;
  } else {
      return "Invalid time"; // or any other default value or error handling you prefer
  }
};
  return (
    <section className="left_width " style={{ marginLeft: `${menuWidth}px` }}>
      <div className="d-lg-flex justify-content-between align-items-center pt-4 pb-3 w-100">
        <h4 className="m-0">Daily Log</h4>
        <div className="d-lg-flex">
        </div>
      </div>
      <div className="d-lg-flex justify-content-between">
      </div>
      <div className="employee_table p-4 text-center">
        <div>
          <div className="table-responsive custom-scrollbar table-view-responsive py-primary">
            <table className="table mb-0">
              <thead>
                <tr>
                  <th
                    track-by="0" className=" py-4 px-1 table_color text-left">
                    Name
                  </th>
                  <th track-by="2" className=" py-4 px-1 table_color">
                     Designation
                  </th>
                  <th track-by="0" className=" py-4 px-1 table_color">
                     Punched In
                  </th>
                 <th track-by="1" className=" py-4 px-1 table_color">
                     In&nbsp;Geolocation
                  </th>
                  <th className=" py-4 px-1 table_color text-right">
                   Punch-in Note
                  </th> 
                  <th track-by="2" className=" py-4 px-1 table_color">
                     Punched Out
                  </th>
                <th className=" py-4 px-1 table_color text-right">
                    Out&nbsp;Geolocation
                  </th> 
                  <th className=" py-4 px-1 table_color text-right">
                    Punch-out Note
                  </th>
                  <th className=" py-4 px-1 table_color text-right">
                    Break Time
                  </th>
                  <th className="py-4 px-1 table_color text-right">
                   Total Hours
                  </th>
                  <th className=" py-4 px-1 table_color text-right">
                    Extra Time/Short Time
                  </th>
                  <th className=" py-4 px-1 table_color text-right">
                    Work Period
                  </th>
                </tr>
              </thead>

              {dalylog && dalylog.length > 0 ?
              <tbody>
              {dalylog && dalylog.map((attendance, index)=>
                <tr key={attendance.id || index}>
                  <td className="text-center">
            
            <div>{attendance.user_name}</div>
            </td>
                  <td> {attendance.designation || 'Not yet'}</td>
                  <td>{attendance.punch_in ? convertToAmPm(attendance.punch_in) : 'Not yet'}</td>
               <td>{attendance.in_ip_data || 'Not yet'}</td> 
               <td>{attendance.in_punch_note || '-'}</td>
               
             
              <td>{attendance.punch_out  ? convertToAmPm(attendance.punch_out)  : 'Not yet'}</td>
            
             <td>{attendance.out_ip_data  || 'Not yet'}</td> 
             <td>{attendance.out_punch_note || '-'}</td>
             <td>{attendance.total_break_time || '-'}</td>
            <td>{attendance.total_hourse || 'Not yet'}</td>
            <td>{attendance.total_remaining_time || '-'}</td>
            <td>{attendance.work_period || 'Not yet'}</td>
            <td></td>
            <td></td>
                  </tr>
                     )}
              </tbody>
           :
           <tbody>
                  <tr className="text-center">
            <td colSpan="12" >
            <span className="pt-5 pb-5">
              <img
                src="/no_data.svg"
                alt="no data"
                width="150px"
              />
              <h6>Nothing to show here</h6>
              <p className="small text-secondary">
                Please add a new entity or manage the data table to see the
                content here <br/>Thank you
              </p>
            </span>

            </td>
                </tr>
</tbody>
            }
            </table>
          </div>
        </div>
      </div>

      {/* ---------------------Export------------------------- */}

      <Modal show={show} onHide={handleClose} size="md" centered>
        <Modal.Body>
          <Form className="export_popup pt-5 pb-5">
            <FiFilter className="export_icon" />
            <h4>No attendance data found.</h4>
            <p>Please filter the attendance data first.</p>
            <button
              className="btn btn-secondary puch_btn"
              onClick={handleClose}
            >
              Okey
            </button>
          </Form>
        </Modal.Body>
      </Modal>
      
      {/*---------------Add Attendance-------------------------*/}

      <Modal show={showLeave} onHide={handleCloseleave} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Add Attendance</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <div>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlTextarea1"
              >
                <Form.Label>Employee</Form.Label>
                <Form.Select aria-label="Default select example">
                  <option>Select an employee</option>
                  <option value="1">One</option>
                  <option value="2">Two</option>
                  <option value="3">Three</option>
                </Form.Select>
              </Form.Group>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlTextarea1"
              >
                <Form.Label>Punch in date and time</Form.Label>
                <Form.Control
                  type="date"
                  placeholder="Enter punch in date and time"
                />
              </Form.Group>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlTextarea1"
              >
                <Form.Label>Punch out date and time</Form.Label>
                <Form.Control
                  type="date"
                  placeholder="Enter punch out date and time"
                />
              </Form.Group>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlTextarea1"
              >
                <Form.Label>Reason note for manual entry</Form.Label>
                <Form.Control as="textarea" placeholder="Enter note" rows={3} />
              </Form.Group>
            </div>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-secondary puch_btn"
            onClick={handleCloseleave}
          >
            Cancel
          </button>
          <button
            className="btn btn-primary puch_btn"
            onClick={handleCloseleave}
          >
            Save
          </button>
        </Modal.Footer>
      </Modal>
    </section>
  );
};

export default DailyLog;
