import React, { useCallback, useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import { BsDownload} from "react-icons/bs";
import { useAuth } from "../utils/Context";
import { RiDeleteBin5Line } from "react-icons/ri";
import { FaEdit } from "react-icons/fa";
import { Alert, Spinner } from "react-bootstrap";

const Holiday = ({ menuWidth }) => {

  const { role_id } = useAuth();
  const [show, setShow] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const handleEditShow = () => setShowEdit(true);
  const handleShow = () => setShow(true);

  const [start_date, setStartDate] = useState("");
  const [end_date, setEndDate] = useState("");
  // const handleClose = () => setShow(false);
  const handleClose = () => {
    // Close the modal without resetting form data
    setShow(false);
    setValidated(false)
    setAddHoliday({
      name: "",
      start_date: "",
      end_date: "",
      repeats_anually: "",
      description: "",
  });
};

const handleEditClose = () => {
  // Close the modal without resetting form data
  setShowEdit(false);
};
  const [loading, setLoading] = useState(false);
  const [validated, setValidated] = useState(false);
  const [accept, setAccept] = useState(false);

  const handleAccept = (holiday_id) => {
    setDeleted({ holiday_id });
    setAccept(true);
  };
  const handleAcceptClose = () => setAccept(false);
  const [addHoliday, setAddHoliday] = useState({
    name: "",
    start_date: "",
    end_date: "",
    repeats_anually: "",
    description: "",
  });
  const handleAddholiday = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }

    setValidated(true);
    var formdata = new FormData();
    // formdata.append("holiday_id",addHoliday.holiday_id);
    formdata.append("name", addHoliday.name);
    formdata.append("start_date", addHoliday.start_date);
    formdata.append("end_date", addHoliday.end_date);
    formdata.append("repeats_anually", addHoliday.repeats_anually);
    formdata.append("description", addHoliday.description);

    var requestOptions = {
      method: "POST",
      body: formdata,
      redirect: "follow",
    };

    fetch(
      `${process.env.REACT_APP_API_BASE_URL}/admin/new_holiday`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status === true) {
          handleClose();
          fetchData();
        }
        else {
          setAddHoliday({ ...addHoliday, error: result.message });
        }
      })
      .catch((error) => console.log("error", error));
  };

  const [getHoliday, setGetHoliday] = useState([]);


  const fetchData = useCallback(() => {
    setLoading(true);
    var requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    fetch(
      `${process.env.REACT_APP_API_BASE_URL}/admin/get_holidays?start_date=${start_date}&end_date=${end_date}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status === true) {
          setGetHoliday(result.data);
        }
        setLoading(false);
      })
      .catch((error) => console.log("error", error));
    }, [start_date,end_date]); 

  useEffect(() => {
    fetchData();
  }, [start_date,end_date,fetchData]);

  const [deleted, setDeleted] = useState({
    holiday_id: "",
  });


  const [editHoliday, setEditHoliday] = useState(null);

  const[update, setUpdate] = useState({
    holiday_id:"",
      name: "",
      start_date: "",
      end_date: "",
      repeats_anually: "",
      description: "",
  })

  const fetchData1 = useCallback(() => {
  // const formdata = new FormData();
if(editHoliday) {
  const requestOptions = {
    method: "GET",
    // body: formdata,
    redirect: "follow"
  };
  
  fetch(`${process.env.REACT_APP_API_BASE_URL}/admin/find_holidays?holiday_id=${editHoliday}`, requestOptions)
    .then((response) => response.json())
    .then((result) => {
      if (result.status === true) {
     setUpdate(result.data)
      }
    })
 
    .catch((error) => console.error(error));
  }
},[editHoliday])


useEffect(() => {
  fetchData1();
}, [fetchData1]);


const handleholidayEdit = () => {
  var formdata = new FormData();
  formdata.append("holiday_id",editHoliday);
  formdata.append("name", update.name);
  formdata.append("start_date", update.start_date);
  formdata.append("end_date", update.end_date);
  formdata.append("repeats_anually", update.repeats_anually);
  formdata.append("description", update.description || '');

  var requestOptions = {
    method: "POST",
    body: formdata,
    redirect: "follow",
  };

  fetch(
    `${process.env.REACT_APP_API_BASE_URL}/admin/edit_holiday`,
    requestOptions
  )
    .then((response) => response.json())
    .then((result) => {
      if (result.status === true) {
        handleEditClose();
        fetchData();
      }
    })
    .catch((error) => console.log("error", error));
};


  const handleDelete = () => {
    const formdata = new FormData();
    formdata.append("holiday_id", deleted.holiday_id);

    const requestOptions = {
      method: "POST",
      body: formdata,
      redirect: "follow",
    };

    fetch(
      `${process.env.REACT_APP_API_BASE_URL}/admin/remove_holiday`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status === true) {
          setDeleted(result.data);
          fetchData();
          handleAcceptClose();
        }
      })
      .catch((error) => console.error(error));
  };


  const handleEditHoliday = (holiday) => {
    // Set the holiday to be edited and open the modal
    setEditHoliday(holiday);
    handleEditShow(true);
  };


  const reload = () => {
    window.location.reload();
  };


  return (
    <section className="left_width" style={{ marginLeft: `${menuWidth}px` }}>
      <div className="d-flex justify-content-between align-items-center pt-4 mb-3 w-100">
        <h4 className="m-0">Holiday</h4>
        <div>
          {role_id === "1" ? (
            <button className="btn btn-primary puch_btn" onClick={handleShow}>
              Add Holiday
            </button>
          ) : (
            ""
          )}
        </div>
      </div>

      <div className="employee_table p-4 text-center">
      <div className="d-lg-flex justify-content-between align-items-center">
                From
                <Form.Control type="date" className="mx-2 h-75" value={start_date}  onChange={(e) => setStartDate(e.target.value)} />
                To
                <Form.Control type="date" className="mx-2 h-75" value={end_date} onChange={(e) => setEndDate(e.target.value)} />
                <button className="btn btn-primary puch_btn" onClick={reload}>
            Reset
          </button>
            </div>
             
        <div>
          <div className="table-responsive custom-scrollbar table-view-responsive py-primary">
            {!loading ? (
              <table className="table mb-0">
                <thead>
                  <tr>
                  <th
                      track-by="0"
                      className="datatable-th py-4 px-3 table_color_width text-start"
                    >
                      <span className="font-size-default">
                        <span>S.No.</span>
                      </span>
                    </th>
                    <th
                      track-by="0"
                      className="datatable-th py-4 px-3 table_color"
                    >
                      <span className="font-size-default">
                        <span>Name</span>
                      </span>
                    </th>
                    <th
                      track-by="1"
                      className="datatable-th py-4 px-3 table_color"
                    >
                      <span className="font-size-default">
                        <span>Start Date</span>
                      </span>
                    </th>
                    <th
                      track-by="2"
                      className="datatable-th py-4 px-3 table_color"
                    >
                      <span className="font-size-default">
                        <span>End Date</span>
                      </span>
                    </th>
                    {/* <th
                      track-by="3"
                      className="datatable-th py-4 px-3 table_color text-right"
                    >
                      <span className="font-size-default">
                        Repeats annually
                      </span>
                    </th> */}
                    <th
                      track-by="3"
                      className="datatable-th py-4 px-3 table_color_css "
                    >
                      <span className="font-size-default text-start">Description</span>
                    </th>
                    {role_id === "1" ? (
                      <th
                        track-by="3"
                        className="datatable-th py-4 px-3 table_color text-right"
                      >
                        <span className="font-size-default">Action</span>
                      </th>
                    ) : (
                      ""
                    )}
                  </tr>
                </thead>
                {getHoliday.length > 0 ? (
                  <tbody>
                    {getHoliday &&
                      getHoliday.map((item) => (
                        <tr key={item.holiday_id}>
                             <td className="px-3 text-start" width="10px">{item.sr_no}</td>
                          <td className="text-center">{item.name}</td>
                          <td className="text-center">{item.start_date}</td>
                          <td className="text-center">{item.end_date}</td>
                       
                          <td className="text-center">{item.description}</td>
                          {role_id === "1" ? (
                            <td className="text-center">
                              <div className="d-flex justify-content-center">
                                <span className="hover_btn">
                                  <FaEdit
                                     onClick={()=>handleEditHoliday(item.holiday_id)}
                                    className="left_icon_css"
                                  />
                                </span>
                                &nbsp; &nbsp;
                                <span className="hover_btn">
                                  <RiDeleteBin5Line
                                    onClick={() =>
                                      handleAccept(item.holiday_id)
                                    }
                                    className="left_icon_css text-danger"
                                  />
                                </span>
                              </div>
                            </td>
                          ) : (
                            ""
                          )}
                        </tr>
                      ))}
                  </tbody>
                ) : (
                  <tbody>
                    <tr className="text-center">
                      <td colSpan="6">
                        <span className="pt-5 pb-5">
                          <img src="/no_data.svg" alt="no data" width="150px" />
                          <h6>Nothing to show here</h6>
                          <p className="small text-secondary">
                            Please add a new entity or manage the data table to
                            see the content here <br />
                            Thank you
                          </p>
                        </span>
                      </td>
                    </tr>
                  </tbody>
                )}
              </table>
            ) : (
              <div className="spinner_height">
                <div className="loader"></div>
              </div>
            )}
          </div>
        </div>
      </div>
      {/* -------------------Add-holiday--------------------------- */}

      <Modal show={show} onHide={handleClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Add Holiday</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form noValidate validated={validated} onSubmit={handleAddholiday}>
            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlTextarea1"
            >
              <Form.Label>Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Name"
                value={addHoliday.name}
                onChange={(e) =>
                  setAddHoliday({ ...addHoliday, name: e.target.value })
                }
                required
              />
            </Form.Group>
            <div className="d-lg-flex justify-content-between">
              <Form.Group
                className="mb-3 w-100"
                controlId="exampleForm.ControlTextarea1"
              >
                <Form.Label>Start date</Form.Label>
                <Form.Control
                  type="Date"
                  placeholder="Enter Name"
                  value={addHoliday.start_date}
                  onChange={(e) =>
                    setAddHoliday({ ...addHoliday, start_date: e.target.value })
                  }
                  required
                />
              </Form.Group>
              &nbsp;&nbsp;&nbsp;
              <Form.Group
                className="mb-3 w-100"
                controlId="exampleForm.ControlTextarea1"
              >
                <Form.Label>End date</Form.Label>
                <Form.Control
                  type="Date"
                  placeholder="Enter Name"
                  value={addHoliday.end_date}
                  onChange={(e) =>
                    setAddHoliday({ ...addHoliday, end_date: e.target.value })
                  }
                  required
                />
              </Form.Group>
            </div>
            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlTextarea1"
            >
              <Form.Label>Description</Form.Label>
              <Form.Control
                as="textarea"
                placeholder="Enter description here"
                rows={2}
                value={addHoliday.description}
                onChange={(e) =>
                  setAddHoliday({ ...addHoliday, description: e.target.value })
                }
                // required
              />
            </Form.Group>
            <Form.Group
              className="mb-3 d-none"
              controlId="exampleForm.ControlTextarea1"
            >
              <input
                className="form-check-input"
                type="checkbox"
                checked={addHoliday.repeats_anually ? 1 : 0}
                onChange={(e) =>
                  setAddHoliday({
                    ...addHoliday,
                    repeats_anually: e.target.checked ? 1 : 0,
                  })
                }
              />
              &nbsp;
              <Form.Label>Repeats annually</Form.Label>
            </Form.Group>
            {addHoliday.error && (
              <Alert variant="danger">{addHoliday.error}</Alert>
            )}
            <Modal.Footer>
              <button
                className="btn btn-secondary puch_btn"
                onClick={handleClose}
                type="button"
              >
                Cancel
              </button>

              {loading ? (
                <button className="btn btn-primary puch_btn" type="button">
                  <Spinner
                    animation="grow"
                    size="sm"
                    style={{ background: "#fff" }}
                  />
                  &nbsp;
                  <Spinner
                    animation="grow"
                    size="sm"
                    style={{ background: "#fff" }}
                  />
                </button>
              ) : (
                <button className="btn btn-primary puch_btn" type="submit">
                  Save
                </button>
              )}
            </Modal.Footer>
          </Form>
        </Modal.Body>
      </Modal>

  {/* -------------------Edit-holiday--------------------------- */}

  <Modal show={showEdit} onHide={handleEditClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Edit Holiday</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleholidayEdit}>
            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlTextarea1"
            >
              <Form.Label>Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Name"
                value={update.name}
                onChange={(e) =>
                  setUpdate({ ...update, name: e.target.value })
                }
                required
              />
            </Form.Group>
            <div className="d-lg-flex justify-content-between">
              <Form.Group
                className="mb-3 w-100"
                controlId="exampleForm.ControlTextarea1"
              >
                <Form.Label>Start date</Form.Label>
                <Form.Control
                  type="Date"
                  placeholder="Enter Name"
                  value={update && update.start_date}
                  onChange={(e) =>
                    setUpdate({ ...update, start_date: e.target.value })
                  }
                  required
                />
              </Form.Group>
              &nbsp;&nbsp;&nbsp;
              <Form.Group
                className="mb-3 w-100"
                controlId="exampleForm.ControlTextarea1"
              >
                <Form.Label>End date</Form.Label>
                <Form.Control
                  type="Date"
                  placeholder="Enter Name"
                  value={update && update.end_date}
                  onChange={(e) =>
                    setUpdate({ ...update, end_date: e.target.value })
                  }
                  required
                />
              </Form.Group>
            </div>
            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlTextarea1"
            >
              <Form.Label>Description</Form.Label>
              <Form.Control
                as="textarea"
                placeholder="Enter description here"
                rows={2}
                value={update && update.description}
                onChange={(e) =>
                  setUpdate({ ...update, description: e.target.value })
                }
                // required
              />
            </Form.Group>
            <Form.Group
              className="mb-3 d-none"
              controlId="exampleForm.ControlTextarea1"
            >
              <input
                className="form-check-input"
                type="checkbox"
                value={update && update.repeats_anually}
                checked={update && update.repeats_anually ? 1 : 0}
                onChange={(e) =>
                  setUpdate({
                    ...update,
                    repeats_anually: e.target.checked ? 1 : 0,
                  })
                }
            
              />
              &nbsp;
              <Form.Label>Repeats annually</Form.Label>
            </Form.Group>

            <Modal.Footer>
              <button
                className="btn btn-secondary puch_btn"
                onClick={handleEditClose}
                type="button"
              >
                Cancel
              </button>
              <button className="btn btn-primary puch_btn" type="submit">
                Save
              </button>
            </Modal.Footer>
          </Form>
        </Modal.Body>
      </Modal>



      {/* ----------------delete popup ----------------*/}
      <Modal
        show={accept}
        onHide={handleAcceptClose}
        size="md"
        centered
        className="pt-5 pb-5"
      >
        <Modal.Body>
          <div className="text-center mt-5">
            <BsDownload className="jobdisk_icon" />
            <h5>Are you sure?</h5>
            <p>You are going to delete this holiday</p>
          </div>
          <Modal.Footer className="border-0 justify-content-center  mb-4">
            <button
              className="btn btn-secondary puch_btn"
              onClick={handleAcceptClose}
            >
              No
            </button>

            <button
              className="btn btn_color puch_btn"
              onClick={() => handleDelete(deleted.holiday_id)}
            >
              Yes
            </button>
          </Modal.Footer>
        </Modal.Body>
      </Modal>
    </section>
  );
};

export default Holiday;
