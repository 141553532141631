import React, { useState } from "react";
import { Container, Form, Spinner, Alert } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { FaRegEye, FaRegEyeSlash } from "react-icons/fa6";

const UpdatePassword = () => {
  const { email } = useParams();
  const navigate = useNavigate();
  const [eyeSH1, setEyeSH1] = useState(false);
  const [eyeSH, setEyeSH] = useState(false);
  const [loading, setLoading] = useState(false);
  const [validated, setValidated] = useState(false);
  const [error, setError] = useState("");
  const [oldPassword, setOldPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
// const[email, setEmail] = useState("")
//   const handleEmailChange = (event) => {
//     setEmail(event.target.value);
//   };

  const handleUpdatePassword = (event) => {
    event.preventDefault();
    setValidated(true);

    if (event.currentTarget.checkValidity() === false) {
      // Form is invalid, do nothing
      return;
    }

    // Form is valid, proceed with password update
    setLoading(true);

    const requestOptions = {
      method: "GET",
      redirect: "follow"
    };

    fetch(`${process.env.REACT_APP_API_BASE_URL}/forgot-password-update?email=${email}&new_password=${oldPassword}&confirm_password=${confirmPassword}`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setLoading(false);
        if (result.status === true) {
          setValidated(false);
          navigate('/');
          setError(result.message)
        } else {
          setError(result.message || "An error occurred while updating password.");
        }
      })
      .catch((error) => {
        setLoading(false);
        setError("An error occurred while updating password.");
        console.error(error);
      });
  };

  const currentYear = new Date().getFullYear();

  return (
    
    <section className="bg_color">
      <Container className="container-fluid">
        <Form className="min_height" noValidate validated={validated} onSubmit={handleUpdatePassword}>
          <div className="max_width">
            <div className="bg-white p-4 rounded">
              <div className="text-center mb-4">
                <img
                  src="softgen-log-1.png"
                  alt=""
                  className="text-center logo m-auto"
                />
              </div>
              <div className="mt-3">
                <label>New Password</label>
                <input
                  type={eyeSH1 ? "text" : "password"}
                  placeholder="Enter your password"
                  className="form-control"
                  value={oldPassword}
                  onChange={(e) => setOldPassword(e.target.value)}
                  required
                />
                {eyeSH1 ? (
                  <FaRegEyeSlash role={'button'} className="loginEye" onClick={() => setEyeSH1(false)} />
                ) : (
                  <FaRegEye role={'button'} className="loginEye" onClick={() => setEyeSH1(true)} />
                )}
                <Form.Control.Feedback type="invalid">
                  Please enter password.
                </Form.Control.Feedback>
              </div>
              <div className="mt-3">
                <label>Confirm New Password</label>
                <input
                  type={eyeSH ? "text" : "password"}
                  placeholder="Enter your password"
                  className="form-control"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  required
                />
                {eyeSH ? (
                  <FaRegEyeSlash role={'button'} className="loginEye" onClick={() => setEyeSH(false)} />
                ) : (
                  <FaRegEye role={'button'} className="loginEye" onClick={() => setEyeSH(true)} />
                )}
                <Form.Control.Feedback type="invalid">
                  Please enter password.
                </Form.Control.Feedback>
              </div>
              <div className="pt-2">
                {error && <Alert variant="danger">{error}</Alert>}
              </div>
              <div>
                {loading ? (
                  <button className="btn btn-primary w-100 mt-3" type="button">
                    <Spinner animation="grow" size="sm" style={{ background: "#fff" }} />
                    &nbsp;
                    <Spinner animation="grow" size="sm" style={{ background: "#fff" }} />
                    &nbsp;
                    <Spinner animation="grow" size="sm" style={{ background: "#fff" }} />
                  </button>
                ) : (
                  <button className="btn btn-primary w-100 mt-3" type="submit">
                    Update
                  </button>
                )}
              </div>
              <div className=" w-100 mt-3 text-center">
                <a href="/" className="bluish-text text-center">
                 Login
                </a>
              </div>
            </div>
            <p className="text-center mt-4 text-white">
              Copyright @ {currentYear} by Softgen Technologies 
            </p>
          </div>
        </Form>
      </Container>
    </section>
  );
};

export default UpdatePassword;
