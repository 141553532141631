import React, { useState } from "react";
import { Alert, Container, Spinner,Form, Modal } from "react-bootstrap";

const ForgetPassword = () => {
  const currentYear = new Date().getFullYear();
  const [email, setEmail] = useState("");

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };
  const [loading, setLoading] = useState(false);
  const [accept, setAccept] = useState(false);
  // const [error, setError] = useState("");
  const handleAcceptClose = () => setAccept(false);
  const handleAcceptShow = () => setAccept(true);
  const [errorMessage, setErrorMessage] = useState("");
  const [validated, setValidated] = useState(false);

  const handleChangepasswrd = (event) => {
    event.preventDefault(); // prevent the form from submitting and page refreshing
    setValidated(true)
    setLoading(true)
    const requestOptions = {
      method: "GET",
      redirect: "follow"
    };

    fetch(`${process.env.REACT_APP_API_BASE_URL}/forgot_password?email=${email}`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.status === true) {
          handleAcceptShow();
          setErrorMessage(result.message);
          setValidated(false)
          setLoading(false)
        }
        else{
          setErrorMessage(result.message);
          setLoading(false)
        }
      })
      .catch((error) => console.error(error));
  };
  return (
    <section className="bg_color">
      <Container className="">
        <Form className="min_height" noValidate validated={validated}  onSubmit={handleChangepasswrd}>
          <div className="max_width">
            <div className="bg-white p-4 rounded">
              <div className="text-center mb-4">
                <img
                  src="softgen-log-1.png"
                  alt=""
                  className="logo m-auto"
                />
              </div>
              <h6 className="text-center mb-0">Forget Password</h6>

              <div className="mt-3">
                <label>Email</label>
                <input
                  type="email"
                  id=""
                  placeholder="Enter your email"
                  className="form-control"
                  value={email}
                  onChange={handleEmailChange}
                  required
                />
                  <Form.Control.Feedback type="invalid">
                  Please enter email.
                  </Form.Control.Feedback>
              </div>
              <div className="mt-2">
              {errorMessage && <Alert variant="danger">{errorMessage}</Alert>}
              </div>
              {loading ? (
                  <button className="btn btn-primary w-100 mt-3" type="button">
                    <Spinner animation="grow" size="sm" style={{ background: "#fff" }} />
                    &nbsp;
                    <Spinner animation="grow" size="sm" style={{ background: "#fff" }} />
                    &nbsp;
                    <Spinner animation="grow" size="sm" style={{ background: "#fff" }} />
                  </button>
                ) : (
              <button className="btn btn-primary w-100 mt-3" type="submit">
                Submit
              </button>)}
              <div className="w-100 mt-3  text-center">
                <a href="/" className="bluish-text text-center">
                  Back to login
                </a>
              </div>
            </div>
            <p className="text-center mt-4 text-white">
              Copyright @ {currentYear} by Softgen Technologies
            </p>
          </div>
        </Form>
      </Container>

      
   {/* ----------------Message popup ----------------*/}
   <Modal
        show={accept}
        onHide={handleAcceptClose}
        size="md"
        centered
        className="pt-5 pb-5"
      >
        <Modal.Body>
          <div className="text-center mt-5">

            <p>{errorMessage}</p>
          </div>
          <Modal.Footer className="border-0 justify-content-center  mb-4">
          <a href="/"
              className="btn btn-success puch_btn"
              // onClick={handleAcceptClose}
            >
              Thank You
            </a>
          </Modal.Footer>
        </Modal.Body>
      </Modal>
    </section>
  );
};
export default ForgetPassword;
