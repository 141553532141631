import React, { useCallback, useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import Tab from "react-bootstrap/Tab";
import Nav from "react-bootstrap/Nav";
import Modal from "react-bootstrap/Modal";
import { Col, Pagination, Row, Spinner } from "react-bootstrap";
import { MdArrowForwardIos } from "react-icons/md";
import { BiHomeAlt } from "react-icons/bi";
import { IoLocationOutline } from "react-icons/io5";
import { FiPhone } from "react-icons/fi";
import { FiUser } from "react-icons/fi";
import { useAuth } from "../utils/Context";
import { LiaPhoneSolid } from "react-icons/lia";
import { CiCalendar, CiLocationOn } from "react-icons/ci";
import { GoGift } from "react-icons/go";

const JobDesk = ({ menuWidth }) => {
  const { id,  employee_id, role_id } = useAuth();
  const [address, setAddress] = useState(false);
  const [editaddress, setEditAddress] = useState(false);
  const [caddressPopup, setCaddressPopup] = useState(false);
  const [editcaddressPopup, setEditCaddressPopup] = useState(false);
  const [dalylog, setDalylog] = useState([]);
  const handleAddressShow = () => setAddress(true);
  const handlecaddressShow = () => setCaddressPopup(true);
  const handleEditAddressShow = () => setEditAddress(true);
  const pattern = new RegExp(/^\d{1,10}$/);
  const [start_date, setStartDate] = useState("");
  const [end_date, setEndDate] = useState("");

  const [start_datePay, setStartDatePay] = useState("");
  const [end_datePay, setEndDatePay] = useState("");

  const [loadingId, setLoadingId] = useState(null);

  const handleEditAddressClose = () => {
    setValidatedPaddress(false);
    setEditAddress(false);
  };
  const handleeditcaddressShow = () => setEditCaddressPopup(true);

  const handleEditCaddressClose = () => {
    setValidatedCaddress(false);
    setEditCaddressPopup(false);
  };
  const [bankEdit, setBankEdit] = useState(false);
  const handleEditBankShow = () => setBankEdit(true);

  const handleEditBankClose = () => {
    setBankEdit(false);
    setValidatedBank(false);
  };
  const [bank, setBank] = useState(false);

  const handleBankClose = () => {
    setBank(false);
    setValidatedBank(false);
    setAddBankDetails({
      employee_id: id,
      account_holder_name: "",
      account_number: "",
      account_title: "",
      bank_name: "",
      branch_name: "",
      tax_payer_id: "",
      bank_code: "",
    });
  };

  const handleAddressClose = () => {
    // Close the modal without resetting form data
    setValidatedPaddress(false);
    setAddress(false);
    setPaddress({
      user_id: id,
      address_detail: "",
      area: "",
      city: "",
      state: "",
      zip_code: "",
      country: "",
      phone_number: "",
    });
  };

  const handleCaddressClose = () => {
    // Close the modal without resetting form data
    setValidatedCaddress(false);
    setCaddressPopup(false);
    setCaddress({
      user_id: id,
      address_detail: "",
      area: "",
      city: "",
      state: "",
      zip_code: "",
      country: "",
      phone_number: "",
    });
  };
  const handleBankShow = () => setBank(true);
  const [validatedBank, setValidatedBank] = useState(false);
  const [validatedPaddress, setValidatedPaddress] = useState(false);
  const [validatedCaddress, setValidatedCaddress] = useState(false);
  const [addbankdetails, setAddBankDetails] = useState({
    employee_id: id,
    account_holder_name: "",
    account_number: "",
    account_title: "",
    bank_name: "",
    branch_name: "",
    tax_payer_id: "",
    bank_code: "",
  });
  const addBank = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }

    setValidatedBank(true);
    var formdata = new FormData();
    formdata.append("bank_name", addbankdetails.bank_name);
    formdata.append("employee_id", addbankdetails.employee_id);
    formdata.append("account_holder_name", addbankdetails.account_holder_name);
    formdata.append("account_number", addbankdetails.account_number);
    formdata.append("account_title", addbankdetails.account_title);
    formdata.append("branch_name", addbankdetails.branch_name);
    formdata.append("tax_payer_id", addbankdetails.tax_payer_id);
    formdata.append("bank_code", addbankdetails.bank_code);

    var requestOptions = {
      method: "POST",
      body: formdata,
      redirect: "follow",
    };

    fetch(
      `${process.env.REACT_APP_API_BASE_URL}/admin/bank-detail`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status === true) {
          handleBankClose();
          setAddBankDetails({
            employee_id: id,
            account_holder_name: "",
            account_number: "",
            account_title: "",
            bank_name: "",
            branch_name: "",
            tax_payer_id: "",
            bank_code: "",
          });

          fetchData();
        }
      })
      .catch((error) => console.log("error", error));
  };

  const downloadPdf = (id, user_id, name, create_payslip) => {
    setLoadingId(id);
    setLoading(true);
    var requestOptions = {
      method: "GET",
      responseType: "blob",
      redirect: "follow",
    };

    fetch(
      `${process.env.REACT_APP_API_BASE_URL}/admin/get_payslip_pdf?user_id=${user_id}&payslip_id=${id}`,
      requestOptions
    )
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.blob();
      })
      .then((blob) => {
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `payslip_${name}_${create_payslip}.pdf`);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
        window.URL.revokeObjectURL(url);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error downloading PDF:", error);
      });
  };
  const [loading, setLoading] = useState(false);
  const [searchPage, setSearchPage] = useState("thismonth");
  const [listPayslip, setListPayslip] = useState({});

  useEffect(() => {
    if(id) {
    var requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    fetch(
      `${process.env.REACT_APP_API_BASE_URL}/admin/get_payslips?user_id=${id}&time_duration=${searchPage}&start_date=${start_datePay}&end_date=${end_datePay}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status === true) {
          setListPayslip(result.data);
        }
      
      })
   
      .catch((error) => console.log("error", error));
    }
  }, [searchPage, start_datePay, end_datePay, id]);

  useEffect(() => {
    if(id)  {
    var requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    fetch(
      `${process.env.REACT_APP_API_BASE_URL}/admin/attendance-request-list?user_id=${id}&start_date=${start_date}&end_date=${end_date}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status === true) {
          setDalylog(result.data);
        }
      })
      .catch((error) => console.log("error", error));
    }
  }, [id, start_date, end_date]);

  const [bankDetails, setBankDetails] = useState({
    employee_id: id,
    account_holder_name: "",
    account_number: "",
    account_title: "",
    bank_name: "",
    branch_name: "",
    tax_payer_id: "",
    bank_code: "",
  });

  const fetchData = useCallback(() => {
    if(id) {
    var requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    fetch(
      `${process.env.REACT_APP_API_BASE_URL}/admin/get_bank_details?user_id=${id}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status === true) {
          setBankDetails(result.data);
        }
      })
      .catch((error) => console.log("error", error));
    }
  }, [id]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const updateBank = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }

    setValidatedBank(true);
    var formdata = new FormData();
    formdata.append("bank_name", bankDetails.bank_name);
    formdata.append("employee_id", id);
    formdata.append("account_holder_name", bankDetails.account_holder_name);
    formdata.append("account_number", bankDetails.account_number);
    formdata.append("account_title", bankDetails.account_title);
    formdata.append("branch_name", bankDetails.branch_name);
    formdata.append("tax_payer_id", bankDetails.tax_payer_id || '');
    formdata.append("bank_code", bankDetails.bank_code);

    var requestOptions = {
      method: "POST",
      body: formdata,
      redirect: "follow",
    };

    fetch(
      `${process.env.REACT_APP_API_BASE_URL}/admin/bank-detail`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status === true) {
          handleEditBankClose();
          setBankDetails({
            employee_id: id,
            account_holder_name: "",
            account_number: "",
            account_title: "",
            bank_name: "",
            branch_name: "",
            tax_payer_id: "",
            bank_code: "",
          });

          fetchData();
          setValidatedBank(false);
        }
      })
      .catch((error) => console.log("error", error));
  };

  const [paddress, setPaddress] = useState({
    user_id: id,
    address_detail: "",
    area: "",
    city: "",
    state: "",
    zip_code: "",
    country: "",
    phone_number: "",
  });

  const handlePaddressAdd = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    setValidatedPaddress(true);

    var formdata = new FormData();
    formdata.append("user_id", paddress.user_id);
    formdata.append("address_detail", paddress.address_detail);
    formdata.append("area", paddress.area);
    formdata.append("city", paddress.city);
    formdata.append("state", paddress.state);
    formdata.append("zip_code", paddress.zip_code);
    formdata.append("country", paddress.country);
    formdata.append("phone_number", paddress.phone_number);

    var requestOptions = {
      method: "POST",
      body: formdata,
      redirect: "follow",
    };

    fetch(
      `${process.env.REACT_APP_API_BASE_URL}/admin/permanent-address-detail`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status === true) {
          fetchData2();
          handleAddressClose();
          setPaddress({
            user_id: id,
            address_detail: "",
            area: "",
            city: "",
            state: "",
            zip_code: "",
            country: "",
            phone_number: "",
          });
          setValidatedPaddress(false);
        }
      })
      .catch((error) => console.log("error", error));
  };

  const [paddresswiew, setPaddressview] = useState({
    user_id: id,
    address_detail: "",
    area: "",
    city: "",
    state: "",
    zip_code: "",
    country: "",
    phone_number: "",
  });

  const fetchData2 = useCallback(() => {
    var requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    fetch(
      `${process.env.REACT_APP_API_BASE_URL}/admin/get-permanent-address-detail?user_id=${id}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status === true) {
          setPaddressview(result.data);
        }
      })
      .catch((error) => console.log("error", error));
  }, [id]);

  useEffect(() => {
    fetchData2();
  }, [fetchData2]);

  const handlePaddressEdit = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    setValidatedPaddress(true);

    var formdata = new FormData();
    formdata.append("user_id", paddresswiew.user_id);
    formdata.append("address_detail", paddresswiew.address_detail);
    formdata.append("area", paddresswiew.area);
    formdata.append("city", paddresswiew.city);
    formdata.append("state", paddresswiew.state);
    formdata.append("zip_code", paddresswiew.zip_code);
    formdata.append("country", paddresswiew.country);
    formdata.append("phone_number", paddresswiew.phone_number);

    var requestOptions = {
      method: "POST",
      body: formdata,
      redirect: "follow",
    };

    fetch(
      `${process.env.REACT_APP_API_BASE_URL}/admin/permanent-address-detail`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status === true) {
          fetchData2();
          handleEditAddressClose();

          setValidatedPaddress(false);
        }
      })
      .catch((error) => console.log("error", error));
  };

  const [caddress, setCaddress] = useState({
    user_id: id,
    address_detail: "",
    area: "",
    city: "",
    state: "",
    zip_code: "",
    country: "",
    phone_number: "",
  });

  const handleCaddressAdd = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    setValidatedCaddress(true);
    var formdata = new FormData();
    formdata.append("user_id", caddress.user_id);
    formdata.append("address_detail", caddress.address_detail);
    formdata.append("area", caddress.area);
    formdata.append("city", caddress.city);
    formdata.append("state", caddress.state);
    formdata.append("zip_code", caddress.zip_code);
    formdata.append("country", caddress.country);
    formdata.append("phone_number", caddress.phone_number);
  
    var requestOptions = {
      method: "POST",
      body: formdata,
      redirect: "follow",
    };
  
    fetch(`${process.env.REACT_APP_API_BASE_URL}/admin/current-address-detail`, requestOptions)
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then((result) => {
        if (result.status === true) {
          handleCaddressClose(true);
          fetchData3();
          setValidatedCaddress(false);
        }
      })
      .catch((error) => console.log("error", error));
  };
  
  const [caddresswiew, setCaddressview] = useState({
    user_id: id,
    address_detail: "",
    area: "",
    city: "",
    state: "",
    zip_code: "",
    country: "",
    phone_number: "",
  });

  const fetchData3 = useCallback(() => {
    var requestOptions = {
      method: "GET",
      redirect: "follow",
    };
  
    fetch(
      `${process.env.REACT_APP_API_BASE_URL}/admin/get-current-address-detail?user_id=${id}`,
      requestOptions
    )
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then((result) => {
        if (result && result.status === true) {
          setCaddressview(result.data);
        }
      })
      .catch((error) => console.log("error", error));
  }, [id]);
  

  useEffect(() => {
    fetchData3();
  }, [fetchData3]);

  const handleCaddressEdit = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    setValidatedCaddress(true);
    var formdata = new FormData();
    formdata.append("user_id", caddresswiew.user_id);
    formdata.append("address_detail", caddresswiew.address_detail);
    formdata.append("area", caddresswiew.area);
    formdata.append("city", caddresswiew.city);
    formdata.append("state", caddresswiew.state);
    formdata.append("zip_code", caddresswiew.zip_code);
    formdata.append("country", caddresswiew.country);
    formdata.append("phone_number", caddresswiew.phone_number);

    var requestOptions = {
      method: "POST",
      body: formdata,
      redirect: "follow",
    };

    fetch(
      `${process.env.REACT_APP_API_BASE_URL}/admin/current-address-detail`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status === true) {
          handleEditCaddressClose();
          fetchData3();
          setValidatedCaddress(false);
        }
      })
      .catch((error) => console.log("error", error));
  };

  const [addCredentials, setAddCredentials] = useState({});
  useEffect(() => {
    if (role_id) {
      const fetchData = () => {
        var formdata = new FormData();
        formdata.append("employee_id", id);

        var requestOptions = {
          method: "POST",
          body: formdata,
          redirect: "follow",
        };

        fetch(
          `${process.env.REACT_APP_API_BASE_URL}/admin/profile`,
          requestOptions
        )
          .then((response) => response.json())
          .then((result) => {
            if (result.status === true) {
              setAddCredentials(result.data);

              // document.cookie = `photo=${result.data.photo}`;
            }
          })
          .catch((error) => console.log("error", error));
      };
      fetchData();
    }
  }, [id,role_id, employee_id]);

  const formatDate = (dateString) => {
    if (!dateString) return "Not added yet";
    const date = new Date(dateString);
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    const day = date.getDate();
    const month = monthNames[date.getMonth()];
    const year = date.getFullYear();
    const formattedDate = `${day} ${month}, ${year}`;
    return formattedDate;
  };

  // const [currentPage, setCurrentPage] = useState(1);
  // const projectsPerPage = 6;

  // const handlePageChange = (page) => {
  //   setCurrentPage(page);
  // };

  // const totalPages = Math.ceil(dalylog && dalylog.length / projectsPerPage);
  // const indexOfLastProject = currentPage * projectsPerPage;
  // const indexOfFirstProject = indexOfLastProject - projectsPerPage;
  // const currentProjects = dalylog && dalylog.slice(
  //   indexOfFirstProject,
  //   indexOfLastProject
  // );


  const [currentPage, setCurrentPage] = useState(1);
  const projectsPerPage = 6;

  // Handle page change
  const handlePageChange = (page) => {
      if (page >= 1 && page <= totalPages) {
          setCurrentPage(page);
      }
  };

  // Total pages calculation
  const totalPages = dalylog ? Math.ceil(dalylog.length / projectsPerPage) : 0;

  // Indexes for slicing
  const indexOfLastProject = currentPage * projectsPerPage;
  const indexOfFirstProject = indexOfLastProject - projectsPerPage;

  // Get current projects
  const currentProjects = dalylog ? dalylog.slice(indexOfFirstProject, indexOfLastProject) : [];

  return (
    <section className="left_width" style={{ marginLeft: `${menuWidth}px` }}>
      <div className="d-flex justify-content-between align-items-center pt-4 w-100 pb-3">
        <h4 className="m-0">Job Desk</h4>
      </div>
      <Row className="align-items-center attendance profile_mainpadding mx-0">
        <Col className="col-md-5 d-lg-flex justify-content-between align-items-center  ">
          {addCredentials.photo ? (
            <img
              src={addCredentials.photo}
              alt="profile"
              className="avatar m-2"
            />
          ) : (
            <img src="/avtaar.jpg" alt="profile" className="avatar m-2" />
          )}
          <div className="width_css_web pl-2">
            <h4 className="h4">{addCredentials.first_name} {addCredentials.last_name}</h4>
            {!addCredentials.status === 1 ? (
              <span className=" bg-danger rounded-pill text-white p-1 px-3  text-white">
                Inactive
              </span>
            ) : (
              <span className=" btn-success rounded-pill text-white p-1 px-3 text-white ">
                Active
              </span>
            )}
            <h6 className=" p_css pt-2 mb-0">{addCredentials.email}</h6>
            <h6 className=" p_css pt-2 text-primary">{addCredentials.designation_id} </h6>
          </div>
        </Col>
        <Col className="border_css">
          <div className="d-flex align-items-center">
            <LiaPhoneSolid className="job_icon_css" />
            <div>
              <p className="mb-0 p_css">Contact:</p>
              <h5 className="m-0 p_css text-dark">
                {addCredentials.contact_number || "Not added yet"}
              </h5>
            </div>
          </div>
          <div className="d-flex align-items-center mt-4">
            <CiLocationOn className="job_icon_css" />
            <div>
              <p className="mb-0 p_css ">Address:</p>
              <h5 className="m-0 p_css text-dark">
                {paddresswiew && paddresswiew.address_detail &&
                paddresswiew.address_detail.length > 0 ? (
                  <>
                    {paddresswiew.address_detail && paddresswiew.address_detail}
                    ...
                  </>
                ) : (
                  "Not added yet"
                )}
              </h5>
            </div>
          </div>
        </Col>
        <Col className="">
          <div className="d-flex align-items-center">
            <CiCalendar className="job_icon_css" />
            <div>
              <p className="mb-0 p_css">Created:</p>
              <h5 className="m-0 p_css text-dark">
                {formatDate(addCredentials.joining_date) || "Not added yet"}
              </h5>
            </div>
          </div>
          <div className="d-flex align-items-center mt-4">
            <GoGift className="job_icon_css" />
            <div>
              <p className="mb-0 p_css">Date of birth:</p>
              <h5 className="m-0 p_css text-dark">
                {formatDate(addCredentials.date_of_birth) || "Not added yet"}
              </h5>
            </div>
          </div>
        </Col>
      </Row>
      <Tab.Container id="left-tabs-example" defaultActiveKey="attendance">
        <Row className="mt-4">
          <Col sm={3} className="h-auto">
            <Nav variant="pills" className="flex-column statistics p-3 h-auto">
              <div className="text-center  pt-5 mt-4 pb-3">
                <FiUser className="h2 text-primary " />
              </div>
              <Nav.Item>
                <Nav.Link
                  eventKey="attendance"
                  className="tab_css_setting d-flex justify-content-between"
                >
                  Attendance <MdArrowForwardIos />
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  eventKey="payslip"
                  className="tab_css_setting d-flex justify-content-between"
                >
                  Payslip <MdArrowForwardIos />
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  eventKey="bankdetails"
                  className="tab_css_setting d-flex justify-content-between"
                >
                  Bank Details <MdArrowForwardIos />
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  eventKey="address"
                  className="tab_css_setting d-flex justify-content-between"
                >
                  Address Details <MdArrowForwardIos />
                </Nav.Link>
              </Nav.Item>
            </Nav>
          </Col>
          <Col sm={9}>
            <Tab.Content className="attendance p-4 rounded">
              <Tab.Pane eventKey="attendance">
                <div className=" my-3 border-bottom">
                  <h5 className="h5">Attendance</h5>
                </div>
                <div className="text-center">
                  <div className="d-lg-flex justify-content-between align-items-center">
                    From
                    <Form.Control
                      type="date"
                      className="mx-2 h-75"
                      value={start_date}
                      onChange={(e) => setStartDate(e.target.value)}
                    />
                    To
                    <Form.Control
                      type="date"
                      className="mx-2 h-75"
                      value={end_date}
                      onChange={(e) => setEndDate(e.target.value)}
                    />
                  </div>
                  <div className="table-responsive custom-scrollbar table-view-responsive  py-primary">
                    <table className="table mb-0 w-100">
                      <thead>
                        <tr>
                          <th  className=" py-3 px-1 table_color">Date</th>
                          <th className=" py-3 px-1 table_color">Punched In</th>
                          <th className=" py-3 px-1 table_color ">Punch-in Note</th>
                          <th className=" py-3 px-1 table_color">Punched Out</th>
                          <th className=" py-3 px-1 table_color text-right ">Punch-out note</th>
                          <th className=" py-3 px-1 table_color text-right">Total Hours</th>
                        </tr>
                      </thead>
                      {currentProjects && currentProjects.length > 0 ? (
                        <tbody>
                          {
                          //  dalylog && dalylog.map((attendance, index)=> (
                            currentProjects && currentProjects.map((attendance, index)=> (
                              <tr key={attendance.id || index}>
                                <td> {attendance.punch_date || "-"}</td>
                                <td>{attendance.punch_in || "Not yet"}</td>
                                <td className="column-width-100">{attendance.in_punch_note || "-"}</td>
                                <td>{attendance.punch_out || "Not yet"}</td>
                                <td className="column-width-100">{attendance.out_punch_note || "-"}</td>
                                <td>{attendance.total_hourse || "-"}</td>
                              </tr>
                            ))}
                        </tbody>
                     ) : (
                        <tbody className="pt-5 pb-5">
                          <tr className="text-center">
                            <td colSpan="7" className="text-center">
                              <div>
                                <img
                                  src="/no_data.svg"
                                  alt="no data"
                                  width="150px"
                                />
                                <h6>Nothing to show here</h6>
                                <p className="small text-secondary">
                                  Please add a new entity or manage the data
                                  table to see the content here. Thank you.
                                </p>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      )} 
                    </table>

                    <Pagination className="mt-2 float-right">
            <Pagination.First onClick={() => handlePageChange(1)} />
            <Pagination.Prev
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1}
            />
            {[...Array(totalPages)].map((_, index) => {
              // Display only a fixed number of page numbers before and after the current page
              if (
                index + 1 === 1 || // Display the first page
                index + 1 === totalPages || // Display the last page
                index + 1 === currentPage || // Display the current page
                index + 1 === currentPage - 1 || // Display the page before the current page
                index + 1 === currentPage + 1 // Display the page after the current page
              ) {
                return (
                  <Pagination.Item
                    key={index + 1}
                    active={index + 1 === currentPage}
                    onClick={() => handlePageChange(index + 1)}
                  >
                    {index + 1}
                  </Pagination.Item>
                );
              } else if (
                index + 1 === currentPage - 2 || // Display ellipsis two pages before the current page
                index + 1 === currentPage + 2 // Display ellipsis two pages after the current page
              ) {
                return <Pagination.Ellipsis key={index + 1} />;
              }
              return null;
            })}
            <Pagination.Next
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={currentPage === totalPages}
            />
            <Pagination.Last onClick={() => handlePageChange(totalPages)} />
          </Pagination> 

                  </div>
                </div>
              </Tab.Pane>
              <Tab.Pane eventKey="payslip">
                <div className=" my-3 border-bottom">
                  <h5 className="h5">Payslip</h5>
                </div>
                <Tab.Container
                  id="left-tabs-example"
                  defaultActiveKey="thismonth"
                >
                  <div className="d-flex justify-content-end align-items-baseline">
                    {/* <div className="d-lg-flex justify-content-between align-items-center tab_css_leave">
                      From
                      <Form.Control
                        type="date"
                        className="tab_css_leave mx-2"
                        value={start_datePay}
                        onChange={(e) => setStartDatePay(e.target.value)}
                      />
                      To
                      <Form.Control
                        type="date"
                        className="tab_css_leave mx-2"
                        value={end_datePay}
                        onChange={(e) => setEndDatePay(e.target.value)}
                      />
                    </div> */}
                    <Nav
                      variant="pills"
                      className="d-flex p-0 m-0 justify-content-left"
                      onSelect={(selectedKey) => setSearchPage(selectedKey)}
                    >
                      <Nav.Item>
                        <Nav.Link
                          eventKey="thismonth"
                          className="tab_css_leave px-2"
                        >
                          This month
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link
                          eventKey="lastmonth"
                          className="tab_css_leave px-2"
                        >
                          Last month
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link
                          eventKey="thisyear"
                          className="tab_css_leave px-2"
                        >
                          This year
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link
                          eventKey="lastyear"
                          className="tab_css_leave px-2"
                        >
                          Last year
                        </Nav.Link>
                      </Nav.Item>

                      <Nav.Item>
                        <Nav.Link
                          eventKey="total"
                          className="tab_css_leave px-2"
                        >
                          Total
                        </Nav.Link>
                      </Nav.Item>
                    </Nav>
                  </div>
                  <Tab.Content>
                    <Tab.Pane eventKey="thismonth">
                      <div className="table-responsive custom-scrollbar table-view-responsive py-primary">
                        <table className="table mb-0">
                          <thead>
                            <tr>
                              <th className=" p-3 table_color ">Profile</th>
                              <th className=" p-3 table_color">Month</th>
                              <th className=" p-3 table_color">Leave</th>
                              <th className=" p-3 table_color text-right">Pf</th>
                              <th className=" p-3 table_color text-right">Create&nbsp;Date</th>
                              <th className=" p-3 table_color text-right"> Salary</th>
                              <th className=" p-3 table_color text-right">Net&nbsp;Salary</th>
                              <th className=" p-3 table_color text-right">Details</th>
                            </tr>
                          </thead>
                          {listPayslip && listPayslip.length > 0 ? (
                            <tbody>
                              {listPayslip &&
                                listPayslip.map(item => (
                                  <tr key={item.id}>
                                    <td className="px-3 ">{item.name}</td>
                                    <td className="px-3">{item.month}</td>
                                    <td className="px-3">{item.leave}</td>
                                    <td className="px-3">{item.pf}</td>
                                    <td className="px-3">{item.create_payslip}</td>
                                    <td className="px-3"> {item.basic_sallery}</td>
                                    <td className="px-3">{item.pay_total}</td>
                                    <td>
                                      <button
                                        type="button"
                                        className="btn btn-secondary puch_btn"
                                        onClick={() =>
                                          downloadPdf(
                                            item.id,
                                            item.user_id,
                                            item.name,
                                            item.create_payslip
                                          )
                                        }
                                        disabled={
                                          loading && item.id === loadingId
                                        }
                                      >
                                        {loading && item.id === loadingId ? (
                                          <>
                                            <Spinner
                                              animation="grow"
                                              size="sm"
                                              style={{ background: "#fff" }}
                                            />
                                            &nbsp;
                                            <Spinner
                                              animation="grow"
                                              size="sm"
                                              style={{ background: "#fff" }}
                                            />
                                          </>
                                        ) : (
                                          "View"
                                        )}
                                      </button>
                                    </td>
                                  </tr>
                                ))}
                            </tbody>
                          ) : (
                            <tbody>
                              <tr className="text-center">
                                <td colSpan="9">
                                  <span className="pt-5 pb-5">
                                    <img
                                      src="/no_data.svg"
                                      alt="no data"
                                      width="150px"
                                    />
                                    <h6>Nothing to show here</h6>
                                    <p className="small text-secondary">
                                      Please add a new entity or manage the data
                                      table to see the content here <br />
                                      Thank you
                                    </p>
                                  </span>
                                </td>
                              </tr>
                            </tbody>
                          )} 
                        </table>
                      </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey="lastmonth">
                      <div className="table-responsive custom-scrollbar table-view-responsive py-primary">
                      <table className="table mb-0">
                          <thead>
                            <tr>
                              <th className=" p-3 table_color ">Profile</th>
                              <th className=" p-3 table_color">Month</th>
                              <th className=" p-3 table_color">Leave</th>
                              <th className=" p-3 table_color text-right">Pf</th>
                              <th className=" p-3 table_color text-right">Create&nbsp;Date</th>
                              <th className=" p-3 table_color text-right"> Salary</th>
                              <th className=" p-3 table_color text-right">Net&nbsp;Salary</th>
                              <th className=" p-3 table_color text-right">Details</th>
                            </tr>
                          </thead>
                          {listPayslip && listPayslip.length > 0 ? (
                            <tbody>
                              {listPayslip &&
                                listPayslip.map(item => (
                                  <tr key={item.id}>
                                    <td className="px-3 ">{item.name}</td>
                                    <td className="px-3">{item.month}</td>
                                    <td className="px-3">{item.leave}</td>
                                    <td className="px-3">{item.pf}</td>
                                    <td className="px-3">{item.create_payslip}</td>
                                    <td className="px-3"> {item.basic_sallery}</td>
                                    <td className="px-3">{item.pay_total}</td>
                                    <td>
                                      <button
                                        type="button"
                                        className="btn btn-secondary puch_btn"
                                        onClick={() =>
                                          downloadPdf(
                                            item.id,
                                            item.user_id,
                                            item.name,
                                            item.create_payslip
                                          )
                                        }
                                        disabled={
                                          loading && item.id === loadingId
                                        }
                                      >
                                        {loading && item.id === loadingId ? (
                                          <>
                                            <Spinner
                                              animation="grow"
                                              size="sm"
                                              style={{ background: "#fff" }}
                                            />
                                            &nbsp;
                                            <Spinner
                                              animation="grow"
                                              size="sm"
                                              style={{ background: "#fff" }}
                                            />
                                          </>
                                        ) : (
                                          "View"
                                        )}
                                      </button>
                                    </td>
                                  </tr>
                                ))}
                            </tbody>
                          ) : (
                            <tbody>
                              <tr className="text-center">
                                <td colSpan="9">
                                  <span className="pt-5 pb-5">
                                    <img
                                      src="/no_data.svg"
                                      alt="no data"
                                      width="150px"
                                    />
                                    <h6>Nothing to show here</h6>
                                    <p className="small text-secondary">
                                      Please add a new entity or manage the data
                                      table to see the content here <br />
                                      Thank you
                                    </p>
                                  </span>
                                </td>
                              </tr>
                            </tbody>
                          )}
                        </table>
                      </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey="thisyear">
                      <div className="table-responsive custom-scrollbar table-view-responsive py-primary">
                      <table className="table mb-0">
                          <thead>
                            <tr>
                              <th className=" p-3 table_color ">Profile</th>
                              <th className=" p-3 table_color">Month</th>
                              <th className=" p-3 table_color">Leave</th>
                              <th className=" p-3 table_color text-right">Pf</th>
                              <th className=" p-3 table_color text-right">Create&nbsp;Date</th>
                              <th className=" p-3 table_color text-right"> Salary</th>
                              <th className=" p-3 table_color text-right">Net&nbsp;Salary</th>
                              <th className=" p-3 table_color text-right">Details</th>
                            </tr>
                          </thead>
                         {listPayslip && listPayslip.length > 0 ? (
                            <tbody>
                              {listPayslip &&
                                listPayslip.map((item) => (
                                  <tr key={item.id}>
                                    <td className="px-3 ">{item.name}</td>
                                    <td className="px-3">{item.month}</td>
                                    <td className="px-3">{item.leave}</td>
                                    <td className="px-3">{item.pf}</td>
                                    <td className="px-3">{item.create_payslip}</td>
                                    <td className="px-3"> {item.basic_sallery}</td>
                                    <td className="px-3">{item.pay_total}</td>
                                    <td>
                                      <button
                                        type="button"
                                        className="btn btn-secondary puch_btn"
                                        onClick={() =>
                                          downloadPdf(
                                            item.id,
                                            item.user_id,
                                            item.name,
                                            item.create_payslip
                                          )
                                        }
                                        disabled={
                                          loading && item.id === loadingId
                                        }
                                      >
                                        {loading && item.id === loadingId ? (
                                          <>
                                            <Spinner
                                              animation="grow"
                                              size="sm"
                                              style={{ background: "#fff" }}
                                            />
                                            &nbsp;
                                            <Spinner
                                              animation="grow"
                                              size="sm"
                                              style={{ background: "#fff" }}
                                            />
                                          </>
                                        ) : (
                                          "View"
                                        )}
                                      </button>
                                    </td>
                                  </tr>
                                ))}
                            </tbody>
                          ) : (
                            <tbody>
                              <tr className="text-center">
                                <td colSpan="9">
                                  <span className="pt-5 pb-5">
                                    <img
                                      src="/no_data.svg"
                                      alt="no data"
                                      width="150px"
                                    />
                                    <h6>Nothing to show here</h6>
                                    <p className="small text-secondary">
                                      Please add a new entity or manage the data
                                      table to see the content here <br />
                                      Thank you
                                    </p>
                                  </span>
                                </td>
                              </tr>
                            </tbody>
                          )}
                        </table>
                      </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey="lastyear">
                      <div className="table-responsive custom-scrollbar table-view-responsive py-primary">
                      <table className="table mb-0">
                          <thead>
                            <tr>
                              <th className=" p-3 table_color ">Profile</th>
                              <th className=" p-3 table_color">Month</th>
                              <th className=" p-3 table_color">Leave</th>
                              <th className=" p-3 table_color text-right">Pf</th>
                              <th className=" p-3 table_color text-right">Create&nbsp;Date</th>
                              <th className=" p-3 table_color text-right"> Salary</th>
                              <th className=" p-3 table_color text-right">Net&nbsp;Salary</th>
                              <th className=" p-3 table_color text-right">Details</th>
                            </tr>
                          </thead>
                           {listPayslip && listPayslip.length > 0 ? (
                            <tbody>
                              {listPayslip &&
                                listPayslip.map((item) => (
                                  <tr key={item.id}>
                                    <td className="px-3 ">{item.name}</td>
                                    <td className="px-3">{item.month}</td>
                                    <td className="px-3">{item.leave}</td>
                                    <td className="px-3">{item.pf}</td>
                                    <td className="px-3">{item.create_payslip}</td>
                                    <td className="px-3"> {item.basic_sallery}</td>
                                    <td className="px-3">{item.pay_total}</td>
                                    <td>
                                      <button
                                        type="button"
                                        className="btn btn-secondary puch_btn"
                                        onClick={() =>
                                          downloadPdf(
                                            item.id,
                                            item.user_id,
                                            item.name,
                                            item.create_payslip
                                          )
                                        }
                                        disabled={
                                          loading && item.id === loadingId
                                        }
                                      >
                                        {loading && item.id === loadingId ? (
                                          <>
                                            <Spinner
                                              animation="grow"
                                              size="sm"
                                              style={{ background: "#fff" }}
                                            />
                                            &nbsp;
                                            <Spinner
                                              animation="grow"
                                              size="sm"
                                              style={{ background: "#fff" }}
                                            />
                                          </>
                                        ) : (
                                          "View"
                                        )}
                                      </button>
                                    </td>
                                  </tr>
                                ))}
                            </tbody>
                          ) : (
                            <tbody>
                              <tr className="text-center">
                                <td colSpan="9">
                                  <span className="pt-5 pb-5">
                                    <img
                                      src="/no_data.svg"
                                      alt="no data"
                                      width="150px"
                                    />
                                    <h6>Nothing to show here</h6>
                                    <p className="small text-secondary">
                                      Please add a new entity or manage the data
                                      table to see the content here <br />
                                      Thank you
                                    </p>
                                  </span>
                                </td>
                              </tr>
                            </tbody>
                          )} 
                        </table>
                      </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey="total">
                      <div className="table-responsive custom-scrollbar table-view-responsive py-primary">
                      <table className="table mb-0">
                          <thead>
                            <tr>
                              <th className=" p-3 table_color ">Profile</th>
                              <th className=" p-3 table_color">Month</th>
                              <th className=" p-3 table_color">Leave</th>
                              <th className=" p-3 table_color text-right">Pf</th>
                              <th className=" p-3 table_color text-right">Create&nbsp;Date</th>
                              <th className=" p-3 table_color text-right"> Salary</th>
                              <th className=" p-3 table_color text-right">Net&nbsp;Salary</th>
                              <th className=" p-3 table_color text-right">Details</th>
                            </tr>
                          </thead>
                        {listPayslip && listPayslip.length > 0 ? (
                            <tbody>
                              {listPayslip &&
                                listPayslip.map(item => (
                                  <tr key={item.id}>
                                    <td className="px-3 ">{item.name}</td>
                                    <td className="px-3">{item.month}</td>
                                    <td className="px-3">{item.leave}</td>
                                    <td className="px-3">{item.pf}</td>
                                    <td className="px-3">{item.create_payslip}</td>
                                    <td className="px-3"> {item.basic_sallery}</td>
                                    <td className="px-3">{item.pay_total}</td>
                                    <td>
                                      <button
                                        type="button"
                                        className="btn btn-secondary puch_btn"
                                        onClick={() =>
                                          downloadPdf(
                                            item.id,
                                            item.user_id,
                                            item.name,
                                            item.create_payslip
                                          )
                                        }
                                        disabled={
                                          loading && item.id === loadingId
                                        }
                                      >
                                        {loading && item.id === loadingId ? (
                                          <>
                                            <Spinner
                                              animation="grow"
                                              size="sm"
                                              style={{ background: "#fff" }}
                                            />
                                            &nbsp;
                                            <Spinner
                                              animation="grow"
                                              size="sm"
                                              style={{ background: "#fff" }}
                                            />
                                          </>
                                        ) : (
                                          "View"
                                        )}
                                      </button>
                                    </td>
                                  </tr>
                                ))}
                            </tbody>
                          ) : (
                            <tbody>
                              <tr className="text-center">
                                <td colSpan="9">
                                  <span className="pt-5 pb-5">
                                    <img
                                      src="/no_data.svg"
                                      alt="no data"
                                      width="150px"
                                    />
                                    <h6>Nothing to show here</h6>
                                    <p className="small text-secondary">
                                      Please add a new entity or manage the data
                                      table to see the content here <br />
                                      Thank you
                                    </p>
                                  </span>
                                </td>
                              </tr>
                            </tbody>
                          )} 
                        </table>
                      </div>
                    </Tab.Pane>
                  </Tab.Content>
                </Tab.Container>
              </Tab.Pane>
              <Tab.Pane eventKey="bankdetails">
                <div className=" my-3 border-bottom">
                  <h5 className="h5">Bank Details</h5>
                </div>
                <div className="table-responsive custom-scrollbar table-view-responsive py-primary">
                  <table className="table mb-0">
                    <tbody>
                      { bankDetails.account_holder_name && bankDetails.account_holder_name.length > 0 ? (
                        <tr>
                          <td className="py-4 px-3">
                            <table>
                              <tbody>
                            <tr className="w-100">
                              <th className="w_75">Account holder name :</th>
                              <td>{bankDetails.account_holder_name && bankDetails.account_holder_name}</td>
                            </tr>
                       
                            <tr className="w-100">
                              <th className="w_75">Account number :</th>
                              <td>{bankDetails.account_number && bankDetails.account_number}</td>
                            </tr>
                            <tr className="w-100">
                              <th className="w_75">Account title :</th>
                              <td>{bankDetails.account_title && bankDetails.account_title}</td>
                            </tr>
                            <tr className="w-100">
                              <th className="w_75">Bank name :</th>
                              <td>{bankDetails.bank_name && bankDetails.bank_name}</td>
                            </tr>
                            <tr className="w-100">
                              <th className="w_75">Branch name :</th>
                              <td>{bankDetails.branch_name && bankDetails.branch_name}</td>
                            </tr>
                            <tr className="w-100">
                              <th className="w_75">Bank code :</th>
                              <td>{bankDetails.bank_code && bankDetails.bank_code}</td>
                            </tr>
                            { bankDetails.tax_payer_id  && (
                              <tr className="w-100">
                                <th className="w_75">Tax payer id :</th>
                                <td>{ bankDetails.tax_payer_id || '' }</td>
                              </tr>
                            )}
                            </tbody>
                                 </table>
                          </td>
                          <td className="py-4">
                            <button
                              className="puch_btn btn btn-primary"
                              onClick={() => handleEditBankShow(id)}
                            >
                              Edit
                            </button>
                          </td>
                        </tr>
                      ) : (
                        <tr>
                          <td>
                            <div className="d-lg-flex justify-content-between mt-5">
                              <div className=" tab_css_setting">
                                Not added yet
                              </div>
                              <div>
                                <button
                                  className="puch_btn btn btn-primary"
                                  onClick={handleBankShow}
                                >
                                  Add
                                </button>
                              </div>
                            </div>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
               
              </Tab.Pane>
              <Tab.Pane eventKey="address">
                <div className=" my-3 border-bottom">
                  <h5 className="h5">Address Details</h5>
                </div>
                <div className="d-lg-flex justify-content-between mt-5">
                  <div className="w-25 tab_css_setting text-dark">
                    <BiHomeAlt className="h5 text-primary mx-2" />
                    Permanent address
                  </div>
                  {paddresswiew && paddresswiew.address_detail && paddresswiew.address_detail.length > 0 ? (
                    <div className="w-50 tab_css_setting text-dark">
                      {paddresswiew
                        ? paddresswiew.address_detail
                        : " Not added yet"}
                      ,{paddresswiew && paddresswiew.area},
                      {paddresswiew && paddresswiew.city},
                      {paddresswiew && paddresswiew.state},
                      {paddresswiew && paddresswiew.zip_code},
                      {paddresswiew && paddresswiew.country},
                      <div>
                        <FiPhone color="text-primary" />
                        {paddresswiew && paddresswiew.phone_number}{" "}
                      </div>
                    </div>
                  ) : (
                    <div className="w-50  tab_css_setting text-dark">
                      Not added yet
                    </div>
                  )}

                  <div className="w-25">
                    {!paddresswiew.address_detail ? (
                      <button
                        className="puch_btn btn btn-primary"
                        onClick={handleAddressShow}
                      >
                        Add
                      </button>
                    ) : (
                      <button
                        className="puch_btn btn btn-primary"
                        onClick={() => handleEditAddressShow(id)}
                      >
                        Edit
                      </button>
                    )}
                  </div>
                </div>
                <div className="d-lg-flex justify-content-between mt-5">
                  <div className="w-25 tab_css_setting text-dark">
                    <IoLocationOutline className="text-primary h5 mx-2" />
                    Current address
                  </div>
                  {caddresswiew && caddresswiew.address_detail && caddresswiew.address_detail.length > 0 ? (
                    <div className="w-50  tab_css_setting text-dark">
                      {caddresswiew && caddresswiew.address_detail},
                      {caddresswiew && caddresswiew.area},
                      {caddresswiew && caddresswiew.city},
                      {caddresswiew && caddresswiew.state},
                      {caddresswiew && caddresswiew.zip_code},
                      {caddresswiew && caddresswiew.country},
                      <div>
                        <FiPhone color="text-primary" />
                        {caddresswiew && caddresswiew.phone_number}{" "}
                      </div>
                    </div>
                  ) : (
                    <div className="w-50  tab_css_setting text-dark">
                      Not added yet
                    </div>
                  )}
                  <div className="w-25">
                    {!caddresswiew.address_detail ? (
                      <button
                        className="puch_btn btn btn-primary"
                        onClick={handlecaddressShow}
                      >
                        Add
                      </button>
                    ) : (
                      <button
                        className="puch_btn btn btn-primary"
                        onClick={() => handleeditcaddressShow(id)}
                      >
                        Edit
                      </button>
                    )}
                  </div>
                </div>
              </Tab.Pane>
            </Tab.Content>
          </Col>
        </Row>
      </Tab.Container>

      {/*--------------add bank details-------------------------*/}

      <Modal
        show={bank}
        onHide={handleBankClose}
        size="lg"
        className="pt-5 pb-5"
      >
        <Modal.Header closeButton>
          <Modal.Title>Add Bank Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form noValidate validated={validatedBank} onSubmit={addBank}>
            <Form.Group className="mb-3 d-lg-flex align-items-center">
              <Form.Label className="bank_label">Bank name</Form.Label>
              <div className="w-100">
                <Form.Control
                  type="text"
                  placeholder="Add bank name"
                  value={addbankdetails.bank_name}
                  onChange={(e) =>
                    setAddBankDetails({
                      ...addbankdetails,
                      bank_name: e.target.value,
                    })
                  }
                  required
                />
                <Form.Control.Feedback type="invalid">
                  Please enter bank name.
                </Form.Control.Feedback>
              </div>
            </Form.Group>
            <Form.Group className="mb-3 d-lg-flex align-items-center">
              <Form.Label className="bank_label">Bank code</Form.Label>
              <div className="w-100">
                <Form.Control
                  type="text"
                  placeholder="Add bank code"
                  value={addbankdetails.bank_code}
                  onChange={(e) =>
                    setAddBankDetails({
                      ...addbankdetails,
                      bank_code: e.target.value,
                    })
                  }
                  required
                />
                <Form.Control.Feedback type="invalid">
                  Please enter bank code.
                </Form.Control.Feedback>
              </div>
            </Form.Group>

            <Form.Group className="mb-3 d-lg-flex align-items-center">
              <Form.Label className="bank_label">Account title</Form.Label>
              <div className="w-100">
                <Form.Control
                  type="text"
                  placeholder="Add account title"
                  value={addbankdetails.account_title}
                  onChange={(e) =>
                    setAddBankDetails({
                      ...addbankdetails,
                      account_title: e.target.value,
                    })
                  }
                  required
                />
                <Form.Control.Feedback type="invalid">
                  Please enter bank account title.
                </Form.Control.Feedback>
              </div>
            </Form.Group>
            <Form.Group className="mb-3 d-lg-flex align-items-center">
              <Form.Label className="bank_label">Branch name</Form.Label>
              <div className="w-100">
                <Form.Control
                  type="text"
                  placeholder="Add branch name"
                  value={addbankdetails.branch_name}
                  onChange={(e) =>
                    setAddBankDetails({
                      ...addbankdetails,
                      branch_name: e.target.value,
                    })
                  }
                  required
                />
                <Form.Control.Feedback type="invalid">
                  Please enter branch name.
                </Form.Control.Feedback>
              </div>
            </Form.Group>
            <Form.Group className="mb-3 d-lg-flex align-items-center">
              <Form.Label className="bank_label">
                Account holder name
              </Form.Label>
              <div className="w-100">
                <Form.Control
                  type="text"
                  placeholder="Add account holder name"
                  className="input_label"
                  value={addbankdetails.account_holder_name}
                  onChange={(e) =>
                    setAddBankDetails({
                      ...addbankdetails,
                      account_holder_name: e.target.value,
                    })
                  }
                  required
                />
                <Form.Control.Feedback type="invalid">
                  Please enter account holder name.
                </Form.Control.Feedback>
              </div>
            </Form.Group>
            <Form.Group className="mb-3 d-lg-flex align-items-center">
              <Form.Label className="bank_label">Account number</Form.Label>
              <div className="w-100">
                <Form.Control
                  type="text"
                  placeholder="Add account number"
                  value={addbankdetails.account_number}
                  onChange={(e) =>
                    setAddBankDetails({
                      ...addbankdetails,
                      account_number: e.target.value,
                    })
                  }
                  required
                />
                <Form.Control.Feedback type="invalid">
                  Please enter bank account number.
                </Form.Control.Feedback>
              </div>
            </Form.Group>
            <Form.Group className="mb-3 d-lg-flex align-items-center">
              <Form.Label className="bank_label">Tax payer id</Form.Label>
              <div className="w-100">
                <Form.Control
                  type="text"
                  placeholder="Add tax payer id"
                  value={addbankdetails.tax_payer_id}
                  onChange={(e) =>
                    setAddBankDetails({
                      ...addbankdetails,
                      tax_payer_id: e.target.value,
                    })
                  }
                />
              </div>
            </Form.Group>
            <Modal.Footer>
              <button
                className="btn btn-secondary puch_btn"
                onClick={handleBankClose}
                type="button"
              >
                Cancel
              </button>
              <button className="btn btn-primary puch_btn" type="submit">
                Save
              </button>
            </Modal.Footer>
          </Form>
        </Modal.Body>
      </Modal>

      {/*--------------Edit bank details-------------------------*/}

      <Modal
        show={bankEdit}
        onHide={handleEditBankClose}
        size="lg"
        className="pt-5 pb-5"
      >
        <Modal.Header closeButton>
          <Modal.Title>Edit Bank Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form noValidate validated={validatedBank} onSubmit={updateBank}>
            <Form.Group className="mb-3 d-lg-flex align-items-center">
              <Form.Label className="bank_label">Bank name</Form.Label>
              <div className="w-100">
                <Form.Control
                  type="text"
                  placeholder="Add bank name"
                  value={bankDetails.bank_name}
                  onChange={(e) =>
                    setBankDetails({
                      ...bankDetails,
                      bank_name: e.target.value,
                    })
                  }
                  required
                />
                <Form.Control.Feedback type="invalid">
                  Please enter bank name.
                </Form.Control.Feedback>
              </div>
            </Form.Group>
            <Form.Group className="mb-3 d-lg-flex align-items-center">
              <Form.Label className="bank_label">Bank code</Form.Label>
              <div className="w-100">
                <Form.Control
                  type="text"
                  placeholder="Add bank code"
                  value={bankDetails.bank_code}
                  onChange={(e) =>
                    setBankDetails({
                      ...bankDetails,
                      bank_code: e.target.value,
                    })
                  }
                  required
                />
                <Form.Control.Feedback type="invalid">
                  Please enter bank code.
                </Form.Control.Feedback>
              </div>
            </Form.Group>

            <Form.Group className="mb-3 d-lg-flex align-items-center">
              <Form.Label className="bank_label">Account title</Form.Label>
              <div className="w-100">
                <Form.Control
                  type="text"
                  placeholder="Add account title"
                  value={bankDetails.account_title}
                  onChange={(e) =>
                    setBankDetails({
                      ...bankDetails,
                      account_title: e.target.value,
                    })
                  }
                  required
                />
                <Form.Control.Feedback type="invalid">
                  Please enter bank account title.
                </Form.Control.Feedback>
              </div>
            </Form.Group>
            <Form.Group className="mb-3 d-lg-flex align-items-center">
              <Form.Label className="bank_label">Branch name</Form.Label>
              <div className="w-100">
                <Form.Control
                  type="text"
                  placeholder="Add branch name"
                  value={bankDetails.branch_name}
                  onChange={(e) =>
                    setBankDetails({
                      ...bankDetails,
                      branch_name: e.target.value,
                    })
                  }
                  required
                />
                <Form.Control.Feedback type="invalid">
                  Please enter branch name.
                </Form.Control.Feedback>
              </div>
            </Form.Group>
            <Form.Group className="mb-3 d-lg-flex align-items-center">
              <Form.Label className="bank_label">
                Account holder name
              </Form.Label>
              <div className="w-100">
                <Form.Control
                  type="text"
                  placeholder="Add account holder name"
                  className="input_label"
                  value={bankDetails.account_holder_name}
                  onChange={(e) =>
                    setBankDetails({
                      ...bankDetails,
                      account_holder_name: e.target.value,
                    })
                  }
                  required
                />
                <Form.Control.Feedback type="invalid">
                  Please enter account holder name.
                </Form.Control.Feedback>
              </div>
            </Form.Group>
            <Form.Group className="mb-3 d-lg-flex align-items-center">
              <Form.Label className="bank_label">Account number</Form.Label>
              <div className="w-100">
                <Form.Control
                  type="text"
                  placeholder="Add account number"
                  value={bankDetails.account_number}
                  onChange={(e) =>
                    setBankDetails({
                      ...bankDetails,
                      account_number: e.target.value,
                    })
                  }
                  required
                />
                <Form.Control.Feedback type="invalid">
                  Please enter bank account number.
                </Form.Control.Feedback>
              </div>
            </Form.Group>
            <Form.Group className="mb-3 d-lg-flex align-items-center">
              <Form.Label className="bank_label">Tax payer id</Form.Label>
              <div className="w-100">
                <Form.Control
                  type="text"
                  placeholder="Add tax payer id"
                  value={bankDetails.tax_payer_id}
                  onChange={(e) =>
                    setBankDetails({
                      ...bankDetails,
                      tax_payer_id: e.target.value,
                    })
                  }
                />
              </div>
            </Form.Group>
            <Modal.Footer>
              <button
                className="btn btn-secondary puch_btn"
                onClick={handleEditBankClose}
                type="button"
              >
                Cancel
              </button>
              <button className="btn btn-primary puch_btn" type="submit">
                Save
              </button>
            </Modal.Footer>
          </Form>
        </Modal.Body>
      </Modal>
      {/*--------------add address-------------------------*/}
      <Modal
        show={address}
        onHide={handleAddressClose}
        size="lg"
        className="pt-5 pb-5"
      >
        <Modal.Header closeButton>
          <Modal.Title>Add Permanent address</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form
            noValidate
            validated={validatedPaddress}
            onSubmit={handlePaddressAdd}
          >
            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlTextarea1"
            >
              <Form.Label>Address Details</Form.Label>
              <Form.Control
                as="textarea"
                placeholder="Add address details here"
                rows={2}
                value={paddress.address_detail}
                onChange={(e) =>
                  setPaddress({ ...paddress, address_detail: e.target.value })
                }
                required
              />
            </Form.Group>
            <div className="d-flex justify-content-between">
              <Form.Group
                className="mb-3 w-100"
                controlId="exampleForm.ControlTextarea1"
              >
                <Form.Label>Area</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter area "
                  value={paddress.area}
                  onChange={(e) =>
                    setPaddress({ ...paddress, area: e.target.value })
                  }
                  required
                />
              </Form.Group>
              &nbsp; &nbsp; &nbsp;
              <Form.Group
                className="mb-3 w-100"
                controlId="exampleForm.ControlTextarea1"
              >
                <Form.Label>City</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter city"
                  value={paddress.city}
                  onChange={(e) =>
                    setPaddress({ ...paddress, city: e.target.value })
                  }
                  required
                />
              </Form.Group>
            </div>
            <div className="d-flex justify-content-between">
              <Form.Group
                className="mb-3 w-100"
                controlId="exampleForm.ControlTextarea1"
              >
                <Form.Label>State</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter state"
                  value={paddress.state}
                  onChange={(e) =>
                    setPaddress({ ...paddress, state: e.target.value })
                  }
                  required
                />
              </Form.Group>
              &nbsp; &nbsp; &nbsp;
              <Form.Group
                className="mb-3 w-100"
                controlId="exampleForm.ControlTextarea1"
              >
                <Form.Label>Zip code</Form.Label>
                {/* <Form.Control 
                type="text" 
                placeholder="Enter zip code"
                value={paddress.zip_code}
                onChange={(e) =>
                  setPaddress({ ...paddress, zip_code: e.target.value })
                }
                required
                 /> */}
                <Form.Control
                  type="number"
                  maxLength={10}
                  value={paddress.zip_code}
                  placeholder="Enter zip code"
                  onChange={(e) => {
                    const inputValue = e.target.value;
                    // Check if the input matches the pattern
                    if (!pattern.test(inputValue)) {
                      // If not, update the state with an error message
                      setPaddress({
                        ...paddress,
                        zip_code: inputValue.slice(0, 6),
                        error: "Please enter only numbers.",
                      });
                    } else {
                      // If it matches the pattern, update the state without an error message
                      setPaddress({
                        ...paddress,
                        zip_code: inputValue,
                        error: "",
                      });
                    }
                  }}
                  required
                />
              </Form.Group>
            </div>

            <div className="d-flex justify-content-between">
              <Form.Group
                className="mb-3 w-100"
                controlId="exampleForm.ControlTextarea1"
              >
                <Form.Label>Country</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter country"
                  value={paddress.country}
                  onChange={(e) =>
                    setPaddress({ ...paddress, country: e.target.value })
                  }
                  required
                />
              </Form.Group>
              &nbsp; &nbsp; &nbsp;
              <Form.Group
                className="mb-3 w-100"
                controlId="exampleForm.ControlTextarea1"
              >
                <Form.Label>Phone number</Form.Label>

                {/* <Form.Control 
                type="text" 
                placeholder="Enter phone number"
                value={paddress.phone_number}
                onChange={(e) =>
                  setPaddress({ ...paddress, phone_number: e.target.value })
                }
                required
                 /> */}

                <Form.Control
                  type="number"
                  maxLength={10}
                  value={paddress.phone_number}
                  placeholder="Enter phone number"
                  onChange={(e) => {
                    const inputValue = e.target.value;
                    // Check if the input matches the pattern
                    if (!pattern.test(inputValue)) {
                      // If not, update the state with an error message
                      setPaddress({
                        ...paddress,
                        phone_number: inputValue.slice(0, 10),
                        error: "Please enter only numbers.",
                      });
                    } else {
                      // If it matches the pattern, update the state without an error message
                      setPaddress({
                        ...paddress,
                        phone_number: inputValue,
                        error: "",
                      });
                    }
                  }}
                  required
                />
              </Form.Group>
            </div>

            <Modal.Footer>
              <button
                className="btn btn-secondary puch_btn"
                onClick={handleAddressClose}
                type="button"
              >
                Cancel
              </button>
              <button className="btn btn-primary puch_btn" type="submit">
                Save
              </button>
            </Modal.Footer>
          </Form>
        </Modal.Body>
      </Modal>

      {/*--------------edit address-------------------------*/}
      <Modal
        show={editaddress}
        onHide={handleEditAddressClose}
        size="lg"
        className="pt-5 pb-5"
      >
        <Modal.Header closeButton>
          <Modal.Title>Edit Permanent address</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form
            noValidate
            validated={validatedPaddress}
            onSubmit={handlePaddressEdit}
          >
            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlTextarea1"
            >
              <Form.Label>Address Details</Form.Label>
              <Form.Control
                as="textarea"
                placeholder="Add address details here"
                rows={2}
                value={paddresswiew.address_detail}
                onChange={(e) =>
                  setPaddressview({
                    ...paddresswiew,
                    address_detail: e.target.value,
                  })
                }
                required
              />
            </Form.Group>
            <div className="d-flex justify-content-between">
              <Form.Group
                className="mb-3 w-100"
                controlId="exampleForm.ControlTextarea1"
              >
                <Form.Label>Area</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter area"
                  value={paddresswiew.area}
                  onChange={(e) =>
                    setPaddressview({ ...paddresswiew, area: e.target.value })
                  }
                  required
                />
              </Form.Group>
              &nbsp; &nbsp; &nbsp;
              <Form.Group
                className="mb-3 w-100"
                controlId="exampleForm.ControlTextarea1"
              >
                <Form.Label>City</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter city"
                  value={paddresswiew.city}
                  onChange={(e) =>
                    setPaddressview({ ...paddresswiew, city: e.target.value })
                  }
                  required
                />
              </Form.Group>
            </div>
            <div className="d-flex justify-content-between">
              <Form.Group
                className="mb-3 w-100"
                controlId="exampleForm.ControlTextarea1"
              >
                <Form.Label>State</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter state"
                  value={paddresswiew.state}
                  onChange={(e) =>
                    setPaddressview({ ...paddresswiew, state: e.target.value })
                  }
                  required
                />
              </Form.Group>
              &nbsp; &nbsp; &nbsp;
              <Form.Group
                className="mb-3 w-100"
                controlId="exampleForm.ControlTextarea1"
              >
                <Form.Label>Zip code</Form.Label>
                {/* <Form.Control 
                type="text" 
                placeholder="Enter zip code"
                value={paddresswiew.zip_code}
                onChange={(e) =>
                  setPaddressview({ ...paddresswiew, zip_code: e.target.value })
                }
                required
                 /> */}

                <Form.Control
                  type="number"
                  maxLength={10}
                  value={paddresswiew.zip_code}
                  placeholder="Enter zip code"
                  onChange={(e) => {
                    const inputValue = e.target.value;
                    // Check if the input matches the pattern
                    if (!pattern.test(inputValue)) {
                      // If not, update the state with an error message
                      setPaddressview({
                        ...paddresswiew,
                        zip_code: inputValue.slice(0, 6),
                        error: "Please enter only numbers.",
                      });
                    } else {
                      // If it matches the pattern, update the state without an error message
                      setPaddressview({
                        ...paddresswiew,
                        zip_code: inputValue,
                        error: "",
                      });
                    }
                  }}
                  required
                />
              </Form.Group>
            </div>

            <div className="d-flex justify-content-between">
              <Form.Group
                className="mb-3 w-100"
                controlId="exampleForm.ControlTextarea1"
              >
                <Form.Label>Country</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter country"
                  value={paddresswiew.country}
                  onChange={(e) =>
                    setPaddressview({
                      ...paddresswiew,
                      country: e.target.value,
                    })
                  }
                  required
                />
              </Form.Group>
              &nbsp; &nbsp; &nbsp;
              <Form.Group
                className="mb-3 w-100"
                controlId="exampleForm.ControlTextarea1"
              >
                <Form.Label>Phone number</Form.Label>

                {/* <Form.Control 
                type="text" 
                placeholder="Enter phone number"
                value={paddresswiew.phone_number}
                onChange={(e) =>
                  setPaddressview({ ...paddresswiew, phone_number: e.target.value })
                }
                required
                 /> */}

                <Form.Control
                  type="number"
                  placeholder="Enter phone number"
                  maxLength={10}
                  value={paddresswiew.phone_number}
                  onChange={(e) => {
                    const inputValue = e.target.value;

                    // Check if the input matches the pattern
                    if (!pattern.test(inputValue)) {
                      // If not, update the state with an error message
                      setPaddressview({
                        ...paddresswiew,
                        phone_number: inputValue.slice(0, 10),
                        error: "Please enter only numbers.",
                      });
                    } else {
                      // If it matches the pattern, update the state without an error message
                      setPaddressview({
                        ...paddresswiew,
                        phone_number: inputValue,
                        error: "",
                      });
                    }
                  }}
                  required
                />
              </Form.Group>
            </div>

            <Modal.Footer>
              <button
                className="btn btn-secondary puch_btn"
                onClick={handleEditAddressClose}
                type="button"
              >
                Cancel
              </button>
              <button className="btn btn-primary puch_btn" type="submit">
                Save
              </button>
            </Modal.Footer>
          </Form>
        </Modal.Body>
      </Modal>

      {/*--------------add address-------------------------*/}
      <Modal
        show={caddressPopup}
        onHide={handleCaddressClose}
        size="lg"
        className="pt-5 pb-5"
      >
        <Modal.Header closeButton>
          <Modal.Title>Add Current address</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form
            noValidate
            validated={validatedCaddress}
            onSubmit={handleCaddressAdd}
          >
            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlTextarea1"
            >
              <Form.Label>Address Details</Form.Label>
              <Form.Control
                as="textarea"
                placeholder="Add address details here"
                rows={2}
                value={caddress.address_detail}
                onChange={(e) =>
                  setCaddress({ ...caddress, address_detail: e.target.value })
                }
                required
              />
            </Form.Group>
            <div className="d-flex justify-content-between">
              <Form.Group
                className="mb-3 w-100"
                controlId="exampleForm.ControlTextarea1"
              >
                <Form.Label>Area</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter area "
                  value={caddress.area}
                  onChange={(e) =>
                    setCaddress({ ...caddress, area: e.target.value })
                  }
                  required
                />
              </Form.Group>
              &nbsp; &nbsp; &nbsp;
              <Form.Group
                className="mb-3 w-100"
                controlId="exampleForm.ControlTextarea1"
              >
                <Form.Label>City</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter city"
                  value={caddress.city}
                  onChange={(e) =>
                    setCaddress({ ...caddress, city: e.target.value })
                  }
                  required
                />
              </Form.Group>
            </div>
            <div className="d-flex justify-content-between">
              <Form.Group
                className="mb-3 w-100"
                controlId="exampleForm.ControlTextarea1"
              >
                <Form.Label>State</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter state"
                  value={caddress.state}
                  onChange={(e) =>
                    setCaddress({ ...caddress, state: e.target.value })
                  }
                  required
                />
              </Form.Group>
              &nbsp; &nbsp; &nbsp;
              <Form.Group
                className="mb-3 w-100"
                controlId="exampleForm.ControlTextarea1"
              >
                <Form.Label>Zip code</Form.Label>
                {/* <Form.Control 
                type="text" 
                placeholder="Enter zip code"
                value={caddress.zip_code}
                onChange={(e) =>
                  setCaddress({ ...caddress, zip_code: e.target.value })
                }
                required
                 /> */}

                <Form.Control
                  type="number"
                  maxLength={10}
                  value={caddress.zip_code}
                  placeholder="Enter zip code"
                  onChange={(e) => {
                    const inputValue = e.target.value;
                    // Check if the input matches the pattern
                    if (!pattern.test(inputValue)) {
                      // If not, update the state with an error message
                      setCaddress({
                        ...caddress,
                        zip_code: inputValue.slice(0, 6),
                        error: "Please enter only numbers.",
                      });
                    } else {
                      // If it matches the pattern, update the state without an error message
                      setCaddress({
                        ...caddress,
                        zip_code: inputValue,
                        error: "",
                      });
                    }
                  }}
                  required
                />
              </Form.Group>
            </div>

            <div className="d-flex justify-content-between">
              <Form.Group
                className="mb-3 w-100"
                controlId="exampleForm.ControlTextarea1"
              >
                <Form.Label>Country</Form.Label>

                <Form.Control
                  type="text"
                  placeholder="Enter country"
                  value={caddress.country}
                  onChange={(e) =>
                    setCaddress({ ...caddress, country: e.target.value })
                  }
                  required
                />
              </Form.Group>
              &nbsp; &nbsp; &nbsp;
              <Form.Group
                className="mb-3 w-100"
                controlId="exampleForm.ControlTextarea1"
              >
                <Form.Label>Phone number</Form.Label>
                {/* <Form.Control 
                type="text" 
                placeholder="Enter phone number"
                value={caddress.phone_number}
                onChange={(e) =>
                  setCaddress({ ...caddress, phone_number: e.target.value })
                }
                required

                 /> */}

                <Form.Control
                  type="number"
                  maxLength={10}
                  value={caddress.phone_number}
                  placeholder="Enter phone number"
                  onChange={(e) => {
                    const inputValue = e.target.value;

                    // Check if the input matches the pattern
                    if (!pattern.test(inputValue)) {
                      // If not, update the state with an error message
                      setCaddress({
                        ...caddress,
                        phone_number: inputValue.slice(0, 10),
                        error: "Please enter only numbers.",
                      });
                    } else {
                      // If it matches the pattern, update the state without an error message
                      setCaddress({
                        ...caddress,
                        phone_number: inputValue,
                        error: "",
                      });
                    }
                  }}
                  required
                />
              </Form.Group>
            </div>

            <Modal.Footer>
              <button
                className="btn btn-secondary puch_btn"
                onClick={handleCaddressClose}
                type="button"
              >
                Cancel
              </button>
              <button className="btn btn-primary puch_btn" type="submit">
                Save
              </button>
            </Modal.Footer>
          </Form>
        </Modal.Body>
      </Modal>

      {/*--------------edit address-------------------------*/}
      <Modal
        show={editcaddressPopup}
        onHide={handleEditCaddressClose}
        size="lg"
        className="pt-5 pb-5"
      >
        <Modal.Header closeButton>
          <Modal.Title>Edit Current address</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form
            noValidate
            validated={validatedCaddress}
            onSubmit={handleCaddressEdit}
          >
            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlTextarea1"
            >
              <Form.Label>Address Details</Form.Label>
              <Form.Control
                as="textarea"
                placeholder="Add address details here"
                rows={2}
                value={caddresswiew.address_detail}
                onChange={(e) =>
                  setCaddressview({
                    ...caddresswiew,
                    address_detail: e.target.value,
                  })
                }
                required
              />
            </Form.Group>
            <div className="d-flex justify-content-between">
              <Form.Group
                className="mb-3 w-100"
                controlId="exampleForm.ControlTextarea1"
              >
                <Form.Label>Area</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter area "
                  value={caddresswiew.area}
                  onChange={(e) =>
                    setCaddressview({ ...caddresswiew, area: e.target.value })
                  }
                  required
                />
              </Form.Group>
              &nbsp; &nbsp; &nbsp;
              <Form.Group
                className="mb-3 w-100"
                controlId="exampleForm.ControlTextarea1"
              >
                <Form.Label>City</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter city"
                  value={caddresswiew.city}
                  onChange={(e) =>
                    setCaddressview({ ...caddresswiew, city: e.target.value })
                  }
                  required
                />
              </Form.Group>
            </div>
            <div className="d-flex justify-content-between">
              <Form.Group
                className="mb-3 w-100"
                controlId="exampleForm.ControlTextarea1"
              >
                <Form.Label>State</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter state"
                  value={caddresswiew.state}
                  onChange={(e) =>
                    setCaddressview({ ...caddresswiew, state: e.target.value })
                  }
                  required
                />
              </Form.Group>
              &nbsp; &nbsp; &nbsp;
              <Form.Group
                className="mb-3 w-100"
                controlId="exampleForm.ControlTextarea1"
              >
                <Form.Label>Zip code</Form.Label>
                {/* <Form.Control 
                type="text" 
                placeholder="Enter zip code"
                value={caddresswiew.zip_code}
                onChange={(e) =>
                  setCaddressview({ ...caddresswiew, zip_code: e.target.value })
                }
                required
                 /> */}

                <Form.Control
                  type="number"
                  maxLength={6}
                  placeholder="Enter zip code"
                  value={caddresswiew.zip_code}
                  onChange={(e) => {
                    const inputValue = e.target.value;

                    // Check if the input matches the pattern
                    if (!pattern.test(inputValue)) {
                      // If not, update the state with an error message
                      setCaddressview({
                        ...caddresswiew,
                        zip_code: inputValue.slice(0, 6),
                        error: "Please enter only numbers.",
                      });
                    } else {
                      // If it matches the pattern, update the state without an error message
                      setCaddressview({
                        ...caddresswiew,
                        zip_code: inputValue,
                        error: "",
                      });
                    }
                  }}
                  required
                />
              </Form.Group>
            </div>

            <div className="d-flex justify-content-between">
              <Form.Group
                className="mb-3 w-100"
                controlId="exampleForm.ControlTextarea1"
              >
                <Form.Label>Country</Form.Label>
                {/* <Form.Select type="text" placeholder="Select country">
                  <option>Ind</option>
                  <option>Pak</option>
                  <option>Other</option>
                </Form.Select> */}
                <Form.Control
                  type="text"
                  placeholder="Enter country"
                  value={caddresswiew.country}
                  onChange={(e) =>
                    setCaddressview({
                      ...caddresswiew,
                      country: e.target.value,
                    })
                  }
                  required
                />
              </Form.Group>
              &nbsp; &nbsp; &nbsp;
              <Form.Group
                className="mb-3 w-100"
                controlId="exampleForm.ControlTextarea1"
              >
                <Form.Label>Phone number</Form.Label>
                {/* <Form.Control 
                type="text" 
                placeholder="Enter phone number"
                value={caddresswiew.phone_number}
                onChange={(e) =>
                  setCaddressview({ ...caddresswiew, phone_number: e.target.value })
                }
                required

                 /> */}

                <Form.Control
                  type="number"
                  maxLength={10}
                  value={caddresswiew.phone_number}
                  placeholder="Enter phone number"
                  onChange={(e) => {
                    const inputValue = e.target.value;

                    // Check if the input matches the pattern
                    if (!pattern.test(inputValue)) {
                      // If not, update the state with an error message
                      setCaddressview({
                        ...caddresswiew,
                        phone_number: inputValue.slice(0, 10),
                        error: "Please enter only numbers.",
                      });
                    } else {
                      // If it matches the pattern, update the state without an error message
                      setCaddressview({
                        ...caddresswiew,
                        phone_number: inputValue,
                        error: "",
                      });
                    }
                  }}
                  required
                />
              </Form.Group>
            </div>

            <Modal.Footer>
              <button
                className="btn btn-secondary puch_btn"
                onClick={handleEditCaddressClose}
                type="button"
              >
                Cancel
              </button>
              <button className="btn btn-primary puch_btn" type="submit">
                Save
              </button>
            </Modal.Footer>
          </Form>
        </Modal.Body>
      </Modal>
    </section>
  );
};
export default JobDesk;
