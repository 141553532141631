import React, {  useState } from "react";
import { Container, Form, Spinner, Alert } from "react-bootstrap";
import { useAuth } from "../utils/Context";
import { useNavigate } from "react-router-dom";
import { FaRegEye, FaRegEyeSlash } from "react-icons/fa6";

const Login = () => {
  
  const { setIsLoggedIn } = useAuth();
  const navigate = useNavigate();
  const [eyeSH, setEyeSH] = useState(false);
  const [loading, setLoading] = useState(false);
  const [validated, setValidated] = useState(false);
  const [error, setError] = useState("");
  const [credentials, setCredentials] = useState({
    email: "",
    password: "",
    error: "",
  });

  const onButtonClick = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }

    setValidated(true);
    setLoading(true);
    var formdata = new FormData();
    formdata.append("email", credentials.email);
    formdata.append("password", credentials.password);
    var requestOptions = {
      method: "POST",
      body: formdata,
      redirect: "follow",
    };

    fetch(`${process.env.REACT_APP_API_BASE_URL}/login`, requestOptions)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((result) => {
        if (result.status === true) {
          // Store user data in localStorage
          localStorage.setItem("id", result.data.id);
          localStorage.setItem("employee_id", result.data.employee_id);
          localStorage.setItem("role_id", result.data.role_id);
          localStorage.setItem("first_name", result.data.first_name);
          localStorage.setItem("last_name", result.data.last_name);
          localStorage.setItem("role_name", result.data.role_name);
          // localStorage.setItem("photo", result.data.photo);
          localStorage.setItem('isLoggedIn', 'true');
          sessionStorage.setItem('isLoggedIn', 'true');
          setIsLoggedIn(true);
          // setLoggedIn(true);
          navigate('/');
          window.location.reload();
        } else {
          setError(result.message);
        }
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setError("An error occurred. Please try again later.");
        setLoading(false);
      });
  };
  const currentYear = new Date().getFullYear();
  
  return (
    <section className="bg_color">
      <Container className="container-fluid">
        <Form className="min_height" noValidate validated={validated} onSubmit={onButtonClick}>
          <div className="max_width">
            <div className="bg-white p-4 rounded">
              <div className="text-center mb-4">
                <img
                  src="softgen-log-1.png"
                  alt=""
                  className="text-center logo m-auto"
                />
              </div>
              <h3 className="text-center mb-0">Login</h3>
              {/* <h6 className="text-center mb-0">Hi!</h6>
              <label className="text-center d-block">
                Login to your dashboard
              </label> */}
              <div className="mt-3">
                <label>Email</label>
                <input
                  type="email"
                  placeholder="Enter your email"
                  className="form-control"
                  value={credentials.email}
                  onChange={(e) =>
                    setCredentials({ ...credentials, email: e.target.value })
                  }
                  required
                />
                <Form.Control.Feedback type="invalid">
                  Please enter email address.
                </Form.Control.Feedback>
              </div>
              <div className="mt-3">
                <label>Password</label>
                <input
                  type={eyeSH ? "text" : "password"}
                  placeholder="Enter your password"
                  className="form-control"
                  value={credentials.password}
                  onChange={(e) =>
                    setCredentials({ ...credentials, password: e.target.value })
                  }
                  required
                />
                {eyeSH ? (
                  <FaRegEyeSlash role={'button'} className="loginEye" onClick={() => setEyeSH(false)} />
                ) : (
                  <FaRegEye role={'button'} className="loginEye" onClick={() => setEyeSH(true)} />
                )}
                <Form.Control.Feedback type="invalid">
                  Please enter password.
                </Form.Control.Feedback>
              </div>
              <div className="mt-2">
              {error && <Alert variant="danger">{error}</Alert>}
              </div>
              <div>
                {loading ? (
                  <button className="btn btn-primary w-100 mt-3" type="button">
                    <Spinner animation="grow" size="sm" style={{ background: "#fff" }} />
                    &nbsp;
                    <Spinner animation="grow" size="sm" style={{ background: "#fff" }} />
                    &nbsp;
                    <Spinner animation="grow" size="sm" style={{ background: "#fff" }} />
                    &nbsp;
                    <Spinner animation="grow" size="sm" style={{ background: "#fff" }} />
                  </button>
                ) : (
                  <button className="btn btn-primary w-100 mt-3" type="submit">
                    Login
                  </button>
                )}
              </div>
              <div className=" w-100 mt-3 text-center">
                  <a href="ForgetPassword" className="bluish-text text-center">
                    Forget Password?
                  </a>
              </div>
            </div>
            <p className="text-center mt-4 text-white">
              Copyright @ {currentYear} by Softgen Technologies 
            </p>
          </div>
        </Form>
      </Container>
    </section>
  );
};

export default Login;
