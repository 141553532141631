import React, { useCallback, useEffect, useState } from "react";
// import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import Tab from "react-bootstrap/Tab";
import Nav from "react-bootstrap/Nav";
import { IoMdCheckmarkCircleOutline } from "react-icons/io";
import { BsDownload } from "react-icons/bs";
import { Pagination } from "react-bootstrap";

const LeaveStatus = ({ menuWidth }) => {

  const [leaveStatus, setLeaveStatus] = useState([]);
  const [rejected, setRejected] = useState(false);
  const handleRejectedClose = () => setRejected(false);
  const [accept, setAccept] = useState(false);
  const handleAcceptClose = () => setAccept(false);
  // const [data, setData] = useState([]);

  const [date] = useState();
  const [search, setSearch] = useState("today");

  const fetchData1 = useCallback(() => {
    const formattedDate = date ? date.toISOString().split("T")[0] : "";
    var requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    fetch(
      `${process.env.REACT_APP_API_BASE_URL}/admin/leave_status?timeline=${search}&request_date=${formattedDate}&department =&user=`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status === true) {
          setLeaveStatus(result.data);
        }
      })
      .catch((error) => console.log("error", error));
  }, [search, date]);

  useEffect(() => {
    fetchData1();
  }, [fetchData1]);

  const [currentPage, setCurrentPage] = useState(1);
  const projectsPerPage = 7;
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const totalPages = Math.ceil(leaveStatus.length / projectsPerPage);
  const indexOfLastProject = currentPage * projectsPerPage;
  const indexOfFirstProject = indexOfLastProject - projectsPerPage;
  const currentProjects = leaveStatus.slice(indexOfFirstProject, indexOfLastProject);
  

  const formatDate = (dateString) => {
    if (!dateString) return "Not added yet";

    // Parse the date string into a Date object
    const date = new Date(dateString);

    // Define an array of month names
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    // Extract day, month, and year
    const day = date.getDate();
    const month = monthNames[date.getMonth()];
    const year = date.getFullYear();

    // Format the date as desired
    const formattedDate = `${day} ${month}, ${year}`;

    return formattedDate;
  };
  return (
    
    <section className="left_width" style={{ marginLeft: `${menuWidth}px` }}>
      <div className="d-flex justify-content-between align-items-center pt-4 pb-3 w-100">
        <h4 className="m-0">Leave Status</h4>
      </div>
      <div className="employee_table p-4 text-center">
        <div>
          <Tab.Container id="left-tabs-example" defaultActiveKey="today">
            <div className="d-lg-flex justify-content-between ">
              <div className="d-lg-flex justify-content-between  align-items-center">
                {/* From
                <Form.Control
                  type="date"
                  className="mx-2"
                  //  onChange={(e) => {
                  //   setLeaveAssign((prevState) => ({
                  //         ...prevState,
                  //         leave_type: e.target.value,
                  //       }));
                  //     }}
                />
                To
                <Form.Control type="date" className="mx-2" /> */}
              </div>
              <Nav
                variant="pills"
                className="d-lg-flex"
                onSelect={(selectedKey) => setSearch(selectedKey)}
              >
                <Nav.Item>
                  <Nav.Link eventKey="today" className="tab_css_leave">
                    Today
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="week" className="tab_css_leave">
                    This week
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="last_week" className="tab_css_leave">
                    Last week
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="month" className="tab_css_leave">
                    This month
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="last_month" className="tab_css_leave">
                    Last month
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="this_year" className="tab_css_leave">
                    This year
                  </Nav.Link>
                </Nav.Item>
              </Nav>
            </div>
            <Tab.Content>
              <Tab.Pane eventKey="today">
                <div className="table-responsive custom-scrollbar table-view-responsive py-primary">
                  <table className="table mb-0">
                    <thead>
                      <tr>
                        <th className="py-4 px-3 table_color ">Profile</th>
                        <th className="py-4 px-3 table_color ">Date & time</th>
                        <th className="py-4 px-3 table_color ">Leave duration</th>
                        <th className="py-4 px-3 table_color ">Leave Type</th>
                        <th className="py-4 px-3 table_color ">Note</th>
                        <th className="py-4 px-3 table_color text-right">Apply Date </th>
                      </tr>
                    </thead>
                    {leaveStatus.length > 0 ? (
                      <tbody>
                        {leaveStatus &&
                          leaveStatus.map((item) => (
                            <tr key={item.leave_id}>
                              <td className="text-center">
                                {item.employee_name}
                              </td>
                              <td>
                              {formatDate(item.leave_start_date)} to{" "}
                              {formatDate(item.leave_end_date)}
                              </td>
                              <td>{item.leave_duration}</td>
                              <td>{item.leave_type}</td>
                              <td>{item.note}</td>
                              <td>{item.created_at}</td>
                            </tr>
                          ))}
                      </tbody>
                    ) : (
                      <tbody className="pt-5 pb-5">
                        <tr>
                          <td colSpan="6">
                            <img
                              src="/no_data.svg"
                              alt="no data"
                              width="150px"
                            />
                            <h6>Nothing to show here</h6>
                            <p>
                              Please add a new entity or manage the data table
                              to see the content here Thank you
                            </p>
                          </td>
                        </tr>
                      </tbody>
                    )}
                  </table>
                </div>
              </Tab.Pane>
              <Tab.Pane eventKey="week">
                <div className="table-responsive custom-scrollbar table-view-responsive py-primary">
                <table className="table mb-0">
                    <thead>
                      <tr>
                        <th className="py-4 px-3 table_color ">Profile</th>
                        <th className="py-4 px-3 table_color ">Date & time</th>
                        <th className="py-4 px-3 table_color ">Leave duration</th>
                        <th className="py-4 px-3 table_color ">Leave Type</th>
                        <th className="py-4 px-3 table_color ">Note</th>
                        <th className="py-4 px-3 table_color text-right">Apply Date </th>
                      </tr>
                    </thead>
                    {leaveStatus.length > 0 ? (
                      <tbody>
                        {leaveStatus &&
                          leaveStatus.map((item) => (
                            <tr key={item.leave_id}>
                              <td className="text-center">
                                {item.employee_name}
                              </td>
                              <td>
                              {formatDate(item.leave_start_date)} to{" "}
                              {formatDate(item.leave_end_date)}
                              </td>
                              <td>{item.leave_duration}</td>
                              <td>{item.leave_type}</td>
                              <td>{item.note}</td>
                              <td>{item.created_at}</td>
                            </tr>
                          ))}
                      </tbody>
                    ) : (
                      <tbody className="pt-5 pb-5">
                        <tr>
                          <td colSpan="6">
                            <img
                              src="/no_data.svg"
                              alt="no data"
                              width="150px"
                            />
                            <h6>Nothing to show here</h6>
                            <p>
                              Please add a new entity or manage the data table
                              to see the content here Thank you
                            </p>
                          </td>
                        </tr>
                      </tbody>
                    )}
                  </table>
                </div>
              </Tab.Pane>
              <Tab.Pane eventKey="last_week">
                <div className="table-responsive custom-scrollbar table-view-responsive py-primary">
                <table className="table mb-0">
                    <thead>
                      <tr>
                        <th className="py-4 px-3 table_color ">Profile</th>
                        <th className="py-4 px-3 table_color ">Date & time</th>
                        <th className="py-4 px-3 table_color ">Leave duration</th>
                        <th className="py-4 px-3 table_color ">Leave Type</th>
                        <th className="py-4 px-3 table_color ">Note</th>
                        <th className="py-4 px-3 table_color text-right">Apply Date </th>
                      </tr>
                    </thead>
                    {leaveStatus.length > 0 ? (
                      <tbody>
                        {leaveStatus &&
                          leaveStatus.map((item) => (
                            <tr key={item.leave_id}>
                              <td className="text-center">
                                {item.employee_name}
                              </td>
                              <td>
                              {formatDate(item.leave_start_date)} to{" "}
                              {formatDate(item.leave_end_date)}
                              </td>
                              <td>{item.leave_duration}</td>
                              <td>{item.leave_type}</td>
                              <td>{item.note}</td>
                              <td>{item.created_at}</td>
                            </tr>
                          ))}
                      </tbody>
                    ) : (
                      <tbody className="pt-5 pb-5">
                        <tr>
                          <td colSpan="6">
                            <img
                              src="/no_data.svg"
                              alt="no data"
                              width="150px"
                            />
                            <h6>Nothing to show here</h6>
                            <p>
                              Please add a new entity or manage the data table
                              to see the content here Thank you
                            </p>
                          </td>
                        </tr>
                      </tbody>
                    )}
                  </table>
                </div>
              </Tab.Pane>
              <Tab.Pane eventKey="month">
                <div className="table-responsive custom-scrollbar table-view-responsive py-primary">
                <table className="table mb-0">
                    <thead>
                      <tr>
                        <th className="py-4 px-3 table_color ">Profile</th>
                        <th className="py-4 px-3 table_color ">Date & time</th>
                        <th className="py-4 px-3 table_color ">Leave duration</th>
                        <th className="py-4 px-3 table_color ">Leave Type</th>
                        <th className="py-4 px-3 table_color ">Note</th>
                        <th className="py-4 px-3 table_color text-right">Apply Date </th>
                      </tr>
                    </thead>
                    {currentProjects.length > 0 ? (
                      <tbody>
                        {currentProjects &&
                          currentProjects.map((item) => (
                            <tr key={item.leave_id}>
                              <td className="text-center">
                                {item.employee_name}
                              </td>
                              <td>
                              {formatDate(item.leave_start_date)} to{" "}
                              {formatDate(item.leave_end_date)}
                              </td>
                              <td>{item.leave_duration}</td>
                              <td>{item.leave_type}</td>
                              <td>{item.note}</td>
                              <td>{item.created_at}</td>
                            </tr>
                          ))}
                      </tbody>
                    ) : (
                      <tbody className="pt-5 pb-5">
                        <tr>
                          <td colSpan="6">
                            <img
                              src="/no_data.svg"
                              alt="no data"
                              width="150px"
                            />
                            <h6>Nothing to show here</h6>
                            <p>
                              Please add a new entity or manage the data table
                              to see the content here Thank you
                            </p>
                          </td>
                        </tr>
                      </tbody>
                    )}
                  </table>
                  <Pagination className="mt-2 float-right">
            <Pagination.First onClick={() => handlePageChange(1)} />
            <Pagination.Prev
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1}
            />
            {[...Array(totalPages)].map((_, index) => {
              // Display only a fixed number of page numbers before and after the current page
              if (
                index + 1 === 1 || // Display the first page
                index + 1 === totalPages || // Display the last page
                index + 1 === currentPage || // Display the current page
                index + 1 === currentPage - 1 || // Display the page before the current page
                index + 1 === currentPage + 1 // Display the page after the current page
              ) {
                return (
                  <Pagination.Item
                    key={index + 1}
                    active={index + 1 === currentPage}
                    onClick={() => handlePageChange(index + 1)}
                  >
                    {index + 1}
                  </Pagination.Item>
                );
              } else if (
                index + 1 === currentPage - 2 || // Display ellipsis two pages before the current page
                index + 1 === currentPage + 2 // Display ellipsis two pages after the current page
              ) {
                return <Pagination.Ellipsis key={index + 1} />;
              }
              return null;
            })}
            <Pagination.Next
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={currentPage === totalPages}
            />
            <Pagination.Last onClick={() => handlePageChange(totalPages)} />
          </Pagination>
                </div>
              </Tab.Pane>
              <Tab.Pane eventKey="last_month">
                <div className="table-responsive custom-scrollbar table-view-responsive py-primary">
                <table className="table mb-0">
                    <thead>
                      <tr>
                        <th className="py-4 px-3 table_color ">Profile</th>
                        <th className="py-4 px-3 table_color ">Date & time</th>
                        <th className="py-4 px-3 table_color ">Leave duration</th>
                        <th className="py-4 px-3 table_color ">Leave Type</th>
                        <th className="py-4 px-3 table_color ">Note</th>
                        <th className="py-4 px-3 table_color text-right">Apply Date </th>
                      </tr>
                    </thead>
                    {currentProjects.length > 0 ? (
                      <tbody>
                        {currentProjects &&
                          currentProjects.map((item) => (
                            <tr key={item.leave_id}>
                              <td className="text-center">
                                {item.employee_name}
                              </td>
                              <td>
                              {formatDate(item.leave_start_date)} to{" "}
                              {formatDate(item.leave_end_date)}
                              </td>
                              <td>{item.leave_duration}</td>
                              <td>{item.leave_type}</td>
                              <td>{item.note}</td>
                              <td>{item.created_at}</td>
                            </tr>
                          ))}
                      </tbody>
                    ) : (
                      <tbody className="pt-5 pb-5">
                        <tr>
                          <td colSpan="6">
                            <img
                              src="/no_data.svg"
                              alt="no data"
                              width="150px"
                            />
                            <h6>Nothing to show here</h6>
                            <p>
                              Please add a new entity or manage the data table
                              to see the content here Thank you
                            </p>
                          </td>
                        </tr>
                      </tbody>
                    )}
                  </table>
                  <Pagination className="mt-2 float-right">
            <Pagination.First onClick={() => handlePageChange(1)} />
            <Pagination.Prev
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1}
            />
            {[...Array(totalPages)].map((_, index) => {
              // Display only a fixed number of page numbers before and after the current page
              if (
                index + 1 === 1 || // Display the first page
                index + 1 === totalPages || // Display the last page
                index + 1 === currentPage || // Display the current page
                index + 1 === currentPage - 1 || // Display the page before the current page
                index + 1 === currentPage + 1 // Display the page after the current page
              ) {
                return (
                  <Pagination.Item
                    key={index + 1}
                    active={index + 1 === currentPage}
                    onClick={() => handlePageChange(index + 1)}
                  >
                    {index + 1}
                  </Pagination.Item>
                );
              } else if (
                index + 1 === currentPage - 2 || // Display ellipsis two pages before the current page
                index + 1 === currentPage + 2 // Display ellipsis two pages after the current page
              ) {
                return <Pagination.Ellipsis key={index + 1} />;
              }
              return null;
            })}
            <Pagination.Next
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={currentPage === totalPages}
            />
            <Pagination.Last onClick={() => handlePageChange(totalPages)} />
          </Pagination>
                </div>
              </Tab.Pane>
              <Tab.Pane eventKey="this_year">
                <div className="table-responsive custom-scrollbar table-view-responsive py-primary">
                <table className="table mb-0">
                    <thead>
                      <tr>
                        <th className="py-4 px-3 table_color ">Profile</th>
                        <th className="py-4 px-3 table_color ">Date & time</th>
                        <th className="py-4 px-3 table_color ">Leave duration</th>
                        <th className="py-4 px-3 table_color ">Leave Type</th>
                        <th className="py-4 px-3 table_color ">Note</th>
                        <th className="py-4 px-3 table_color text-right">Apply Date </th>
                      </tr>
                    </thead>
                    {currentProjects.length > 0 ? (
                      <tbody>
                        {currentProjects &&
                          currentProjects.map((item) => (
                            <tr key={item.leave_id}>
                              <td className="text-center">
                                {item.employee_name}
                              </td>
                              <td>
                                   {formatDate(item.leave_start_date)} to{" "}
                              {formatDate(item.leave_end_date)}
                              </td>
                              <td>{item.leave_duration}</td>
                              <td>{item.leave_type}</td>
                              <td>{item.note}</td>
                              <td>{item.created_at}</td>
                            </tr>
                          ))}
                      </tbody>
                    ) : (
                      <tbody className="pt-5 pb-5">
                        <tr>
                          <td colSpan="6">
                            <img
                              src="/no_data.svg"
                              alt="no data"
                              width="150px"
                            />
                            <h6>Nothing to show here</h6>
                            <p>
                              Please add a new entity or manage the data table
                              to see the content here Thank you
                            </p>
                          </td>
                        </tr>
                      </tbody>
                    )}
                  </table>
                  <Pagination className="mt-2 float-right">
            <Pagination.First onClick={() => handlePageChange(1)} />
            <Pagination.Prev
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1}
            />
            {[...Array(totalPages)].map((_, index) => {
              // Display only a fixed number of page numbers before and after the current page
              if (
                index + 1 === 1 || // Display the first page
                index + 1 === totalPages || // Display the last page
                index + 1 === currentPage || // Display the current page
                index + 1 === currentPage - 1 || // Display the page before the current page
                index + 1 === currentPage + 1 // Display the page after the current page
              ) {
                return (
                  <Pagination.Item
                    key={index + 1}
                    active={index + 1 === currentPage}
                    onClick={() => handlePageChange(index + 1)}
                  >
                    {index + 1}
                  </Pagination.Item>
                );
              } else if (
                index + 1 === currentPage - 2 || // Display ellipsis two pages before the current page
                index + 1 === currentPage + 2 // Display ellipsis two pages after the current page
              ) {
                return <Pagination.Ellipsis key={index + 1} />;
              }
              return null;
            })}
            <Pagination.Next
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={currentPage === totalPages}
            />
            <Pagination.Last onClick={() => handlePageChange(totalPages)} />
          </Pagination>
                </div>
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>
        </div>
      </div>
      {/* ----------------rejected popup ----------------*/}
      <Modal
        show={rejected}
        onHide={handleRejectedClose}
        size="md"
        centered
        className="pt-5 pb-5"
      >
        <Modal.Body>
          <div className="text-center mt-5">
            <BsDownload className="jobdisk_icon" />
            <h5>Are you sure?</h5>
            <p>You are going to reject this leave request</p>
          </div>
          <Modal.Footer className="border-0 justify-content-center  mb-4">
            <button
              className="btn btn-secondary puch_btn"
              onClick={handleRejectedClose}
            >
              No
            </button>
            <button
              className="btn btn_color puch_btn"
              onClick={handleRejectedClose}
            >
              Yes
            </button>
          </Modal.Footer>
        </Modal.Body>
      </Modal>
      {/* ----------------accept popup ----------------*/}
      <Modal
        show={accept}
        onHide={handleAcceptClose}
        size="md"
        centered
        className="pt-5 pb-5"
      >
        <Modal.Body>
          <div className="text-center mt-5">
            <IoMdCheckmarkCircleOutline className="jobdisk_icon text-success" />
            <h5>Are you sure?</h5>
            <p>You are going to approve this leave request</p>
          </div>
          <Modal.Footer className="border-0 justify-content-center  mb-4">
            <button
              className="btn btn-secondary puch_btn"
              onClick={handleAcceptClose}
            >
              No
            </button>
            <button
              className="btn btn-success puch_btn"
              onClick={handleAcceptClose}
            >
              Yes
            </button>
          </Modal.Footer>
        </Modal.Body>
      </Modal>
    </section>
  );
};
export default LeaveStatus;