import React, { useEffect, useState } from "react";
import { Alert, Form, Spinner } from "react-bootstrap";
import { useAuth } from "../utils/Context";
import { useNavigate} from "react-router-dom";
import Select from "react-select";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; 

const DailyWorkReport = ({ menuWidth }) => {
  const { id } = useAuth();
  const [projectList, setProjectList] = useState([]);
  const [validated, setValidated] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const goBack = () => {
    navigate(-1); 
  };
  useEffect(() => {
    var requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    fetch(`${process.env.REACT_APP_API_BASE_URL}/admin/project-list`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.status === true) {
          setProjectList(result.data);
        }
      })
      .catch((error) => console.log("error", error));
  },[]);
  const [error, setError] = useState("");
  const [data, setData] = useState({
    project_id: '',
    from_time: '',
    to_time: '',
    description: '',
    status: '',
    remark: ''
  });

  const handleAddReport = (event) => {
    setLoading(true);
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    setValidated(true);
    var formdata = new FormData();
    formdata.append("user_id", id);
    formdata.append("project_id", data.project_id);
    formdata.append("from_time", data.from_time);
    formdata.append("to_time", data.to_time);
    formdata.append("description", data.description);
    formdata.append("status", data.status);
    formdata.append("remark", data.remark);

    var requestOptions = {
      method: 'POST',
      body: formdata,
      redirect: 'follow'
    };

    fetch(`${process.env.REACT_APP_API_BASE_URL}/admin/submit_work_report`, requestOptions)
      .then(response => response.json())
      .then(result => {
        if (result.status === true) {
          setData(result.data);
          navigate("/dailyworkreport")
        }
        setError(result.message);
        setLoading(false);
      })
      .catch(error => console.log('error', error));
  }
  const handleSelectProject = selectedOption => {
    const selectedValue = selectedOption.value; 
    setData(prevState => ({
      ...prevState,
      project_id: selectedValue,
    }));
  };

  const customStyles = {
    control: (base, state) => ({
      ...base,
      boxShadow: state.isFocused
        ? "0 0 0 0.1rem rgba(0, 123, 255, 0.25)"
        : null,
      borderColor: state.isFocused ? "#007bff" : base.borderColor,
      
    }),
  };
  const [isValid, setIsValid] = useState(true);
  // const editorRef = useRef(null);

  const handleChange = (content, editor) => {
    setData({ ...data, description: content });
    setIsValid(!!content.trim());
  };





  return (

    <section className="left_width" style={{ marginLeft: `${menuWidth}px` }}>
      <div className="py-4">
        <Form className=" attendance px-4 pt-2 pb-4  my-5 " noValidate  validated={validated}  onSubmit={handleAddReport}>
          <Form.Group className="mb-3 " controlId="exampleForm.ControlTextarea1">
            <Form.Label className="mt-3 editor_font">Project Name</Form.Label>
            <Select
              options={projectList.map(item => ({
                value: item.id,
                label: item.project_name,
              }))}
              styles={customStyles}
              placeholder="Select a Project"
              onChange={handleSelectProject}
              className="text-uppercase search_select_css"
              required
            />
            <Form.Control.Feedback type="invalid">
              Please select project.
            </Form.Control.Feedback>
          </Form.Group>
          <div className="d-lg-flex">
            <Form.Group
              className="mb-3 w-100"
              controlId="exampleForm.ControlTextarea1"
            >
              <Form.Label className="editor_font">From Time</Form.Label>
              <Form.Control type="time"
                placeholder=""
                className="editor_font"
                value={data.from_time}
                onChange={(e) =>
                  setData({ ...data, from_time: e.target.value })
                }
                required
              />
              <Form.Control.Feedback type="invalid">
                Please select start time.
              </Form.Control.Feedback>
            </Form.Group>
            &nbsp;
            <Form.Group
              className="mb-3 w-100"
              controlId="exampleForm.ControlTextarea1"
            >
              <Form.Label className="editor_font">To Time</Form.Label>
              <Form.Control type="time"
                placeholder=""
                className="editor_font"
                value={data.to_time}
                onChange={(e) =>
                  setData({ ...data, to_time: e.target.value })
                }
                required
              />
              <Form.Control.Feedback type="invalid">
                Please select end time.
              </Form.Control.Feedback>
            </Form.Group>
          </div>

          <Form.Group className="mb-3" style={{ height: '215px' }}   controlId="exampleForm.ControlTextarea1">
            <Form.Label className="editor_font" >Description</Form.Label>
            


<ReactQuill
      theme="snow"
      value={data.description}
      onChange={handleChange}
      style={{ height: '150px'}}
    />



     {!isValid && data.description.length === 0 && (
      <div type="invalid" className="text-danger small">
          Please enter report description.
            </div>
              )}



          </Form.Group>
          <Form.Group className="mb-3 mt-4" controlId="exampleForm.ControlTextarea1">
            <Form.Label className="editor_font">Work Status</Form.Label>
            <Form.Select aria-label="Default select example"
              className="search_select_css text-uppercase"
              onChange={(e) =>
                setData({ ...data, status: e.target.value })}
                required
            >
              <option disabled selected value=""  className="search_select_css">Work Status</option>
              <option value="1"  className="search_select_css">Complete</option>
              <option value="2"  className="search_select_css">Pending</option>
              <option value="3"  className="search_select_css">Under Process</option>
            </Form.Select>
            <Form.Control.Feedback type="invalid">
              Please select work status.
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group
            className="mb-3 w-100"
            controlId="exampleForm.ControlTextarea1"
          >
            <Form.Label className="editor_font">Remark</Form.Label>
            <Form.Control type="text" placeholder="" className="editor_font"
              value={data.remark}
              onChange={(e) =>
                setData({ ...data, remark: e.target.value })}
            />
          </Form.Group>
          <div>
          {error && <Alert variant="danger">{error}</Alert>}

          {loading ? 
               <button className="btn btn-primary puch_btn" type="button">
               <Spinner animation="grow" size="sm" style={{ background: "#fff" }} />
               &nbsp;
               <Spinner animation="grow" size="sm" style={{ background: "#fff" }} />
             
             </button>
             :
              <button className="btn btn-primary puch_btn" type="submit">
                Save
              </button>
}
            {/* <button type="submit" className="btn btn-primary puch_btn">
              Save
            </button> */}
            &nbsp;
            <button type="button" className="btn btn-secondary puch_btn" onClick={goBack}>
              Back
            </button>
          </div>
        </Form>
      </div>
    </section>
  );
};

export default DailyWorkReport;
