import './App.css';
import Login from './pages/Login';
import ForgetPassword from './pages/ForgetPassword';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import LeftMenu from './components/LeftMenu';
import Dashboard from './pages/Dashboard';
import JobDesk from './components/JobDesk';
import { useEffect, useState } from 'react';
import EmployeeList from './components/EmployeeList';
import Designation from './components/Designation';
import EmploymentStatus from './components/EmploymentStatus';
import LeaveStatus from './components/LeaveStatus';
import LeaveRequest from './components/LeaveRequest';
import Summary from './components/Summary';
import DailyLog from './components/DailyLog';
import AttendanceRequest from './components/AttendanceRequest';
import AttendanceDetails from './components/AttendanceDetails';
import AttendanceSummary from './components/AttendanceSummary';
import Payslip from './components/Payslip';
import UsersandRole from './components/UsersandRole';
import Departments from './components/Departments';
import Holiday from './components/Holiday';
import Announcements from './components/Announcements';
import Profile from './pages/Profile';
import ProjectDetails from './components/ProjectDetails';
import DailyWorkReport from './components/DailyWorkReport';
import AddDailyWorkReport from './components/AddDailyWorkReport';
import Notification from './components/Notification';
import { useAuth } from './utils/Context';
import EmployeeLeaveRequest from './components/EmployeeLeaveRequest';
import EmployeeLeaveStatus from './components/EmployeeLeaveStatus';
import EmployeeSummary from './components/employeeSummary';
import EditDailyWorkReport from './components/EditDailyWorkReport';
import EmployeeDetails from './components/EmployeeDetails';
import Movement from './components/Movement';
import UpdatePassword from './pages/UpdatePassword';
import AllEmployeeReport from './components/AllEmployeeReport';
import AutoLogout from './utils/AutoLogout';
import LeaveAprove from './pages/LeaveAprove';

function App() {

  const { isLoggedIn,loggedIn,role_id,employee_id,id, setLoggedIn, setRole_id, setEmployee_id,setId, setRole_name ,setData,setFirst_name,setPhoto,setLast_name } = useAuth()
  const [menuWidth, setMenuWidth] = useState(230);
  // const [forceUpdate, setForceUpdate] = useState(false);

  const toggleMenuWidth = () => {
    if (window.innerWidth <= 768) {
      setMenuWidth((prevWidth) => (prevWidth === 0 ? 0 : 0));
    } else {
      setMenuWidth((prevWidth) => (prevWidth === 230 ? 65 : 230));
    }
  };

  useEffect(() => {
    const uid = localStorage.getItem("id")
    const uuserid = localStorage.getItem("employee_id")
    const uname = localStorage.getItem("first_name")
    const uroleid = localStorage.getItem("role_id")
    const urolename = localStorage.getItem("role_name")
    const ulastname = localStorage.getItem("last_name")
    const uphoto = localStorage.getItem("photo")
    if (uuserid && uroleid) {
      setEmployee_id(uuserid)
      setRole_id(uroleid)
      setId(uid)
      setFirst_name(uname)
      setRole_name(urolename)
      setPhoto(uphoto)
      setLast_name(ulastname)
      setLoggedIn(true)
    }
  }, [setLoggedIn, loggedIn, setEmployee_id,setRole_id, setId,setData,setRole_name,setFirst_name,setLast_name,setPhoto])




  const [addCredentials, setAddCredentials] = useState({})


  useEffect(() => {
    if (role_id) {
      const fetchData = () => {
        var formdata = new FormData();
        formdata.append("employee_id", id);

        var requestOptions = {
          method: "POST",
          body: formdata,
          redirect: "follow",
        };

        fetch(
          `${process.env.REACT_APP_API_BASE_URL}/admin/profile`,
          requestOptions
        )
          .then((response) => response.json())
          .then((result) => {
            if (result.status === true) {
              // localStorage.setItem("photo", result.data.photo);
              const gander = result.data.gander === 1 ? "Male" : "Female";
              setAddCredentials((prevState) => ({
                ...prevState,
                first_name: result.data.first_name
                  ? result.data.first_name
                  : "",
                last_name: result.data.last_name ? result.data.last_name : "",
                email: result.data.email ? result.data.email : "",
                contact_number: result.data.contact_number
                  ? result.data.contact_number
                  : "",
                gander: gander,
                emergency_contact: result.data.emergency_contact
                  ? result.data.emergency_contact
                  : "",
                status: result.data.status ? result.data.status : "",
                employee_id: result.data.employee_id
                  ? result.data.employee_id
                  : "",
                photo: result.data.photo ? result.data.photo : "",
                joining_date: result.data.joining_date
                  ? result.data.joining_date
                  : "",
                address: result.data.address ? result.data.address : "",
                about_me: result.data.about_me ? result.data.about_me : "",
                date_of_birth: result.data.date_of_birth
                  ? result.data.date_of_birth
                  : "",
                department_id: result.data.department_id
                  ? result.data.department_id
                  : "",
                designation_id: result.data.designation_id
                  ? result.data.designation_id
                  : "",
                employee_ment_id: result.data.employee_ment_id
                  ? result.data.employee_ment_id
                  : "",
                  // employee_ment_id: result.data.employee_ment_id
                  // ? result.data.employee_ment_id
                  // : "",
                  salary: result.data.salary
                  ? result.data.salary
                  : "",
                  marital_status: result.data.marital_status
                  ? result.data.marital_status
                  : "",
              }));
              // document.cookie = `photo=${result.data.photo}`;
            }
          })
          .catch((error) => console.log("error", error));
      };
      fetchData();
    }
  }, [role_id, id, employee_id]);


  if (addCredentials.status === 2) {
    console.log(addCredentials.status);
    localStorage.clear();
    window.location.href = "/";
  } 
  
  return (
    <BrowserRouter>
    
          <AutoLogout />

    {!isLoggedIn ?  
          <Routes>
             <Route path="/leaveaproved" element={<LeaveAprove />} />
          <Route path="/" element={<Login />} />
          <Route path="/forgetpassword" element={<ForgetPassword />} />
          <Route path="/updatepassword/:email" element={<UpdatePassword />} />
        </Routes>
        :
          <div className="screens-container">
            <LeftMenu menuWidth={menuWidth} toggleMenuWidth={toggleMenuWidth} />
            <div className='screens-section-container'>
              <Routes>
                <Route path="" element={<Dashboard menuWidth={menuWidth} />} />
                <Route path="/jobdisk" element={<JobDesk menuWidth={menuWidth} />} />
                <Route path="/employee/list" element={<EmployeeList menuWidth={menuWidth} />} />
                <Route path="/employee/designation" element={<Designation menuWidth={menuWidth} />} />
                <Route path="/employee/employmentstatus" element={<EmploymentStatus menuWidth={menuWidth} />} />
                <Route path="/my-profile" element={<Profile menuWidth={menuWidth} />} />
                <Route path="/employee-details/:id" element={<EmployeeDetails menuWidth={menuWidth}/>} />
                {/* --------------work details------------------- */}
                
                <Route path="/projectdetails" element={<ProjectDetails menuWidth={menuWidth} />} />
                <Route path="/notification" element={<Notification menuWidth={menuWidth} />} />
                <Route path="/dailyworkreport" element={<DailyWorkReport menuWidth={menuWidth} />} />
                <Route path="/employeedailyworkreport" element={<AllEmployeeReport menuWidth={menuWidth} />} />
                <Route path="/adddailyworkreport" element={<AddDailyWorkReport menuWidth={menuWidth} />} />
                <Route path="/movement" element={<Movement menuWidth={menuWidth} />} />
                <Route path="/editdailyworkreport/:work_report_id" element={<EditDailyWorkReport menuWidth={menuWidth} />} />

                {/* --------------Leave-------------------- */}

                <Route path="/leave/status" element={<LeaveStatus menuWidth={menuWidth} />} />
                <Route path="/leave/request" element={<LeaveRequest menuWidth={menuWidth} />} />
                <Route path="/leave/employeestatus" element={<EmployeeLeaveStatus menuWidth={menuWidth} />} />
                <Route path="/leave/employeerequest" element={<EmployeeLeaveRequest menuWidth={menuWidth} />} />
                <Route path="/leave/employeesummary" element={<EmployeeSummary menuWidth={menuWidth} />} />
                <Route path="/leave/summary" element={<Summary menuWidth={menuWidth} />} />

                {/* --------------Attendance-------------------- */}

                <Route path="/attendance/dailylog" element={<DailyLog menuWidth={menuWidth} />} />
                <Route path="/attendance/request" element={<AttendanceRequest menuWidth={menuWidth} />} />
                <Route path="/attendance/details" element={<AttendanceDetails menuWidth={menuWidth} />} />
                <Route path="/attendance/summary" element={<AttendanceSummary menuWidth={menuWidth} />} />

                {/* --------------payroll -------------------- */}

                <Route path="/payroll/payslip" element={<Payslip menuWidth={menuWidth} />} />

                {/* ------------------Administration-------------- */}

                <Route path="/administration/users&role" element={<UsersandRole menuWidth={menuWidth} />} />
                <Route path="/administration/departments" element={<Departments menuWidth={menuWidth} />} />
                <Route path="holiday" element={<Holiday menuWidth={menuWidth} />} />
                <Route path="/administration/holiday" element={<Holiday menuWidth={menuWidth} />} />
                <Route path="/administration/announcements" element={<Announcements menuWidth={menuWidth} />} />
              </Routes>
            </div>
          </div>
        }
      <div>
      </div>
    </BrowserRouter>
  );
}
export default App;
