import React, { useCallback, useEffect, useState } from "react";
import Tab from "react-bootstrap/Tab";
import Nav from "react-bootstrap/Nav";
import { useAuth } from "../utils/Context";
import { Form } from "react-bootstrap";

const EmployeeSummary = ({ menuWidth }) => {
  const { id } = useAuth();
  const [search, setSearch] = useState("today");
  const [start_date, setStartDate] = useState("");
  const [end_date, setEndDate] = useState("");
  // const [date, setDate] = useState();
  const [leaveSummary, setLeaveSummary] = useState([]);



  const fetchData = useCallback(() => {
    // const formattedDate = date ? date.toISOString().split("T")[0] : "";
    const requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    fetch(
      `${process.env.REACT_APP_API_BASE_URL}/admin/get_leave_summary?user_id=${id}&timeline=${search}&start_date=${start_date}&end_date=${end_date}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status === true) {
          setLeaveSummary(result.data);
        }
      })
      .catch((error) => console.error(error));
  },[start_date,end_date,search,id])

  useEffect(() => {
    fetchData();
  }, [search, start_date, end_date,fetchData]);

  const handleTabChange = (selectedKey) => {
    setSearch(selectedKey);
    setStartDate(""); // Reset start date when changing tabs
    setEndDate(""); // Reset end date when changing tabs
  };

  return (
    <section className="left_width" style={{ marginLeft: `${menuWidth}px` }}>
      <div className="d-lg-flex justify-content-between align-items-center pt-4 pb-3 w-100">
        <h4 className="m-0">Leave Summary</h4>
      </div>
      <div className="employee_table p-4 text-center">
        <div>
          <Tab.Container id="left-tabs-example" defaultActiveKey="today">
            <div className="d-lg-flex justify-content-between ">
              <div className="d-lg-flex justify-content-between align-items-center">
                From
                <Form.Control
                  type="date"
                  className="mx-2 h-75"
                  value={start_date}
                  onChange={(e) => setStartDate(e.target.value)}
                />
                To
                <Form.Control
                  type="date"
                  className="mx-2 h-75"
                  value={end_date}
                  onChange={(e) => setEndDate(e.target.value)}
                />
              </div>
              <Nav
                variant="pills"
                className="d-lg-flex"
                onSelect={handleTabChange}
              >
                <Nav.Item>
                  <Nav.Link eventKey="today" className="tab_css_leave p-2">
                    Today
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="week" className="tab_css_leave p-2">
                    This week
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="last_week" className="tab_css_leave p-2">
                    Last week
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="month" className="tab_css_leave p-2">
                    This month
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="last_month" className="tab_css_leave p-2">
                    Last month
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="this_year" className="tab_css_leave p-2">
                    This year
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="last_year" className="tab_css_leave p-2">
                    Last year
                  </Nav.Link>
                </Nav.Item>
              </Nav>
            </div>
            <Tab.Content>
              <Tab.Pane eventKey="today">
                <div className="table-responsive custom-scrollbar table-view-responsive py-primary">
                  <table className="table mb-0">
                    <thead>
                      <tr>
                        <th className="py-4 px-3 table_color ">Date & Time</th>
                        <th className="py-4 px-3 table_color ">
                          Leave Duration
                        </th>
                        <th className="py-4 px-3 table_color ">Leave Type</th>
                        <th className="py-4 px-3 table_color ">Note</th>
                        <th className="py-4 px-3 table_color ">Status</th>
                        <th className="py-4 px-3 table_color">Apply Date </th>
                      </tr>
                    </thead>
                    {leaveSummary.length > 0 ? (
                      <tbody>
                        {leaveSummary &&
                          leaveSummary.map((item) => (
                            <tr key={item.leave_id}>
                              <td>From {item.date}</td>
                              <td>{item.leave_duration}</td>
                              <td>{item.leave_type}</td>
                              <td>{item.note}</td>
                              <td>
                                <span
                                  className={`rounded-pill py-1 px-2 text-white p_css ${
                                    item.status === "Pending"
                                      ? "bg-warning"
                                      : item.status === "Approved"
                                      ? "bg-success"
                                      : item.status === "Rejected"
                                      ? "bg-danger"
                                      : " "
                                  }`}
                                >
                                  {item.status}
                                </span>
                              </td>
                              <td>{item.created_at}</td>
                            </tr>
                          ))}
                      </tbody>
                    ) : (
                      <tbody className="pt-5 pb-5">
                        <tr>
                          <td colSpan="6">
                            <img
                              src="/no_data.svg"
                              alt="no data"
                              width="150px"
                            />
                            <h6>Nothing to show here</h6>
                            <p>
                              Please add a new entity or manage the data table
                              to see the content here Thank you
                            </p>
                          </td>
                        </tr>
                      </tbody>
                    )}
                  </table>
                </div>
              </Tab.Pane>
              <Tab.Pane eventKey="week">
                <div className="table-responsive custom-scrollbar table-view-responsive py-primary">
                <table className="table mb-0">
                    <thead>
                      <tr>
                        <th className="py-4 px-3 table_color ">Date & Time</th>
                        <th className="py-4 px-3 table_color ">
                          Leave Duration
                        </th>
                        <th className="py-4 px-3 table_color ">Leave Type</th>
                        <th className="py-4 px-3 table_color ">Note</th>
                        <th className="py-4 px-3 table_color ">Status</th>
                        <th className="py-4 px-3 table_color">Apply Date </th>
                      </tr>
                    </thead>
                    {leaveSummary.length > 0 ? (
                      <tbody>
                        {leaveSummary &&
                          leaveSummary.map((item) => (
                            <tr key={item.leave_id}>
                              <td>From {item.date}</td>
                              <td>{item.leave_duration}</td>
                              <td>{item.leave_type}</td>
                              <td>{item.note}</td>
                              <td>
                                <span
                                  className={`rounded-pill py-1 px-2 text-white p_css ${
                                    item.status === "Pending"
                                      ? "bg-warning"
                                      : item.status === "Approved"
                                      ? "bg-success"
                                      : item.status === "Rejected"
                                      ? "bg-danger"
                                      : " "
                                  }`}
                                >
                                  {item.status}
                                </span>
                              </td>
                              <td>{item.created_at}</td>
                            </tr>
                          ))}
                      </tbody>
                    ) : (
                      <tbody className="pt-5 pb-5">
                        <tr>
                          <td colSpan="6">
                            <img
                              src="/no_data.svg"
                              alt="no data"
                              width="150px"
                            />
                            <h6>Nothing to show here</h6>
                            <p>
                              Please add a new entity or manage the data table
                              to see the content here Thank you
                            </p>
                          </td>
                        </tr>
                      </tbody>
                    )}
                  </table>
                </div>
              </Tab.Pane>
              <Tab.Pane eventKey="last_week">
                <div className="table-responsive custom-scrollbar table-view-responsive py-primary">
                <table className="table mb-0">
                    <thead>
                      <tr>
                        <th className="py-4 px-3 table_color ">Date & Time</th>
                        <th className="py-4 px-3 table_color ">
                          Leave Duration
                        </th>
                        <th className="py-4 px-3 table_color ">Leave Type</th>
                        <th className="py-4 px-3 table_color ">Note</th>
                        <th className="py-4 px-3 table_color ">Status</th>
                        <th className="py-4 px-3 table_color">Apply Date </th>
                      </tr>
                    </thead>
                    {leaveSummary.length > 0 ? (
                      <tbody>
                        {leaveSummary &&
                          leaveSummary.map((item) => (
                            <tr key={item.leave_id}>
                              <td>From {item.date}</td>
                              <td>{item.leave_duration}</td>
                              <td>{item.leave_type}</td>
                              <td>{item.note}</td>
                              <td>
                                <span
                                  className={`rounded-pill py-1 px-2 text-white p_css ${
                                    item.status === "Pending"
                                      ? "bg-warning"
                                      : item.status === "Approved"
                                      ? "bg-success"
                                      : item.status === "Rejected"
                                      ? "bg-danger"
                                      : " "
                                  }`}
                                >
                                  {item.status}
                                </span>
                              </td>
                              <td>{item.created_at}</td>
                            </tr>
                          ))}
                      </tbody>
                    ) : (
                      <tbody className="pt-5 pb-5">
                        <tr>
                          <td colSpan="6">
                            <img
                              src="/no_data.svg"
                              alt="no data"
                              width="150px"
                            />
                            <h6>Nothing to show here</h6>
                            <p>
                              Please add a new entity or manage the data table
                              to see the content here Thank you
                            </p>
                          </td>
                        </tr>
                      </tbody>
                    )}
                  </table>
                </div>
              </Tab.Pane>
              <Tab.Pane eventKey="month">
                <div className="table-responsive custom-scrollbar table-view-responsive py-primary">
                <table className="table mb-0">
                    <thead>
                      <tr>
                        <th className="py-4 px-3 table_color ">Date & Time</th>
                        <th className="py-4 px-3 table_color ">
                          Leave Duration
                        </th>
                        <th className="py-4 px-3 table_color ">Leave Type</th>
                        <th className="py-4 px-3 table_color ">Note</th>
                        <th className="py-4 px-3 table_color ">Status</th>
                        <th className="py-4 px-3 table_color">Apply Date </th>
                      </tr>
                    </thead>
                    {leaveSummary.length > 0 ? (
                      <tbody>
                        {leaveSummary &&
                          leaveSummary.map((item) => (
                            <tr key={item.leave_id}>
                              <td>From {item.date}</td>
                              <td>{item.leave_duration}</td>
                              <td>{item.leave_type}</td>
                              <td>{item.note}</td>
                              <td>
                                <span
                                  className={`rounded-pill py-1 px-2 text-white p_css ${
                                    item.status === "Pending"
                                      ? "bg-warning"
                                      : item.status === "Approved"
                                      ? "bg-success"
                                      : item.status === "Rejected"
                                      ? "bg-danger"
                                      : " "
                                  }`}
                                >
                                  {item.status}
                                </span>
                              </td>
                              <td>{item.created_at}</td>
                            </tr>
                          ))}
                      </tbody>
                    ) : (
                      <tbody className="pt-5 pb-5">
                        <tr>
                          <td colSpan="6">
                            <img
                              src="/no_data.svg"
                              alt="no data"
                              width="150px"
                            />
                            <h6>Nothing to show here</h6>
                            <p>
                              Please add a new entity or manage the data table
                              to see the content here Thank you
                            </p>
                          </td>
                        </tr>
                      </tbody>
                    )}
                  </table>
                </div>
              </Tab.Pane>
              <Tab.Pane eventKey="last_month">
                <div className="table-responsive custom-scrollbar table-view-responsive py-primary">
                <table className="table mb-0">
                    <thead>
                      <tr>
                        <th className="py-4 px-3 table_color ">Date & Time</th>
                        <th className="py-4 px-3 table_color ">
                          Leave Duration
                        </th>
                        <th className="py-4 px-3 table_color ">Leave Type</th>
                        <th className="py-4 px-3 table_color ">Note</th>
                        <th className="py-4 px-3 table_color ">Status</th>
                        <th className="py-4 px-3 table_color">Apply Date </th>
                      </tr>
                    </thead>
                    {leaveSummary.length > 0 ? (
                      <tbody>
                        {leaveSummary &&
                          leaveSummary.map((item) => (
                            <tr key={item.leave_id}>
                              <td>From {item.date}</td>
                              <td>{item.leave_duration}</td>
                              <td>{item.leave_type}</td>
                              <td>{item.note}</td>
                              <td>
                                <span
                                  className={`rounded-pill py-1 px-2 text-white p_css ${
                                    item.status === "Pending"
                                      ? "bg-warning"
                                      : item.status === "Approved"
                                      ? "bg-success"
                                      : item.status === "Rejected"
                                      ? "bg-danger"
                                      : " "
                                  }`}
                                >
                                  {item.status}
                                </span>
                              </td>
                              <td>{item.created_at}</td>
                            </tr>
                          ))}
                      </tbody>
                    ) : (
                      <tbody className="pt-5 pb-5">
                        <tr>
                          <td colSpan="6">
                            <img
                              src="/no_data.svg"
                              alt="no data"
                              width="150px"
                            />
                            <h6>Nothing to show here</h6>
                            <p>
                              Please add a new entity or manage the data table
                              to see the content here Thank you
                            </p>
                          </td>
                        </tr>
                      </tbody>
                    )}
                  </table>
                </div>
              </Tab.Pane>
              <Tab.Pane eventKey="this_year">
                <div className="table-responsive custom-scrollbar table-view-responsive py-primary">
                <table className="table mb-0">
                    <thead>
                      <tr>
                        <th className="py-4 px-3 table_color ">Date & Time</th>
                        <th className="py-4 px-3 table_color ">
                          Leave Duration
                        </th>
                        <th className="py-4 px-3 table_color ">Leave Type</th>
                        <th className="py-4 px-3 table_color ">Note</th>
                        <th className="py-4 px-3 table_color ">Status</th>
                        <th className="py-4 px-3 table_color">Apply Date </th>
                      </tr>
                    </thead>
                    {leaveSummary.length > 0 ? (
                      <tbody>
                        {leaveSummary &&
                          leaveSummary.map((item) => (
                            <tr key={item.leave_id}>
                              <td>From {item.date}</td>
                              <td>{item.leave_duration}</td>
                              <td>{item.leave_type}</td>
                              <td>{item.note}</td>
                              <td>
                                <span
                                  className={`rounded-pill py-1 px-2 text-white p_css ${
                                    item.status === "Pending"
                                      ? "bg-warning"
                                      : item.status === "Approved"
                                      ? "bg-success"
                                      : item.status === "Rejected"
                                      ? "bg-danger"
                                      : " "
                                  }`}
                                >
                                  {item.status}
                                </span>
                              </td>
                              <td>{item.created_at}</td>
                            </tr>
                          ))}
                      </tbody>
                    ) : (
                      <tbody className="pt-5 pb-5">
                        <tr>
                          <td colSpan="6">
                            <img
                              src="/no_data.svg"
                              alt="no data"
                              width="150px"
                            />
                            <h6>Nothing to show here</h6>
                            <p>
                              Please add a new entity or manage the data table
                              to see the content here Thank you
                            </p>
                          </td>
                        </tr>
                      </tbody>
                    )}
                  </table>
                </div>
              </Tab.Pane>
              <Tab.Pane eventKey="last_year">
                <div className="table-responsive custom-scrollbar table-view-responsive py-primary">
                <table className="table mb-0">
                    <thead>
                      <tr>
                        <th className="py-4 px-3 table_color ">Date & Time</th>
                        <th className="py-4 px-3 table_color ">
                          Leave Duration
                        </th>
                        <th className="py-4 px-3 table_color ">Leave Type</th>
                        <th className="py-4 px-3 table_color ">Note</th>
                        <th className="py-4 px-3 table_color ">Status</th>
                        <th className="py-4 px-3 table_color">Apply Date </th>
                      </tr>
                    </thead>
                    {leaveSummary.length > 0 ? (
                      <tbody>
                        {leaveSummary &&
                          leaveSummary.map((item) => (
                            <tr key={item.leave_id}>
                              <td>From {item.date}</td>
                              <td>{item.leave_duration}</td>
                              <td>{item.leave_type}</td>
                              <td>{item.note}</td>
                              <td>
                                <span
                                  className={`rounded-pill py-1 px-2 text-white p_css ${
                                    item.status === "Pending"
                                      ? "bg-warning"
                                      : item.status === "Approved"
                                      ? "bg-success"
                                      : item.status === "Rejected"
                                      ? "bg-danger"
                                      : " "
                                  }`}
                                >
                                  {item.status}
                                </span>
                              </td>
                              <td>{item.created_at}</td>
                            </tr>
                          ))}
                      </tbody>
                    ) : (
                      <tbody className="pt-5 pb-5">
                        <tr>
                          <td colSpan="6">
                            <img
                              src="/no_data.svg"
                              alt="no data"
                              width="150px"
                            />
                            <h6>Nothing to show here</h6>
                            <p>
                              Please add a new entity or manage the data table
                              to see the content here Thank you
                            </p>
                          </td>
                        </tr>
                      </tbody>
                    )}
                  </table>
                </div>
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>
        </div>
      </div>
    </section>
  );
};

export default EmployeeSummary;
