import React, { useCallback, useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import { BsDownload } from "react-icons/bs";
import { FaRegCheckSquare, FaRegWindowClose } from "react-icons/fa";
import { IoMdCheckmarkCircleOutline } from "react-icons/io";
import Select from "react-select";
import { Alert, Pagination, Spinner } from "react-bootstrap";

const LeaveRequest = ({ menuWidth }) => {

  const [data, setData] = useState([]);
  const [showLeave, setShowLeave] = useState(false);
  const [loading, setLoading] = useState(false);
  const handleCloseleave = () => {
    setShowLeave(false);
    setValidated(false);
    setLoading(false);
    setError(false);
    setLeaveAssign({
      user_id: "",
      leave_type: "",
      leave_duration: "",
      start_date: "",
      start_time: "",
      end_time: "",
      attachment: "",
      half_time_duration: "",
      note: "",
    });
  };
  const [error, setError] = useState("");
  const [request, setRequest] = useState([]);
  const handleLeave = () => setShowLeave(true);
  const [rejected, setRejected] = useState(false);
  const handleRejectedClose = () => setRejected(false);
  const [accept, setAccept] = useState(false);
  const handleAcceptClose = () => setAccept(false);
  const [validated, setValidated] = useState(false);
  const [selectedEmployeeId, setSelectedEmployeeId] = useState(null);
  const [startDate, setStartDate] = useState("")
  const [endDate, setEndDate] = useState("")


  const fetchData1 = useCallback(() => {
    var requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    let url = `${process.env.REACT_APP_API_BASE_URL}/admin/leave_requests`;

    if (selectedEmployeeId) {
      url += `?users=${selectedEmployeeId}`;
    }

    if (startDate && endDate) {
      url += selectedEmployeeId ? `&start_date=${startDate}&end_date=${endDate}` : `?start_date=${startDate}&end_date=${endDate}`;
    }

    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.status === true) {
          setRequest(result.data);
        }
      })
      .catch((error) => console.log("error", error));
  }, [selectedEmployeeId, startDate, endDate]);




  const handleSelectProject = (selectedOption) => {
    const selectedValue = selectedOption ? selectedOption.value : null;
    setSelectedEmployeeId(selectedValue);
  };

  useEffect(() => {
    fetchData1();
  }, [selectedEmployeeId, startDate, endDate, fetchData1]); // Refetch data when selectedEmployeeId, startDate, or endDate changes

  const [leaveAssign, setLeaveAssign] = useState({
    user_id: "",
    leave_type: "",
    leave_duration: "",
    start_date: "",
    start_time: "",
    end_time: "",
    attachment: "",
    half_time_duration: "",
    note: "",
  });

  const [showFields, setShowFields] = useState({
    startDate: false,
    endDate: false,
    firstHalf: false,
    secondHalf: false,
    startTime: false,
    endTime: false,
  });

  const handleLeaveAssign = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    setValidated(true);
    setLoading(true);
    var formdata = new FormData();
    formdata.append("user_id", leaveAssign.user_id);
    formdata.append("leave_type", leaveAssign.leave_type);
    formdata.append("leave_duration", leaveAssign.leave_duration);
    formdata.append("start_date", leaveAssign.start_date);
    formdata.append("attachment", leaveAssign.attachment);
    formdata.append("start_time", leaveAssign.start_time);
    formdata.append("end_time", leaveAssign.end_time);
    formdata.append("last_date", leaveAssign.last_date);
    formdata.append("half_time_duration", leaveAssign.half_time_duration);
    formdata.append("note", leaveAssign.note);

    var requestOptions = {
      method: "POST",
      body: formdata,
      redirect: "follow",
    };

    fetch(
      `${process.env.REACT_APP_API_BASE_URL}/admin/assign_leave_request`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status === true) {
          fetchData1();
          handleCloseleave();
          setValidated(false);
          setLoading(false);
        } else {
          setError(result.message);
          setLoading(false);
        }
      })
      .catch((error) => console.log("error", error));
  };

  const [leaveIdToHandle, setLeaveIdToHandle] = useState(null);
  const handleLeaveAction = (leaveId, actionType) => {
    setLeaveIdToHandle(leaveId);
    if (actionType === "1") {
      setAccept(true);
    } else if (actionType === "2") {
      setRejected(true);
    }
  };

  const leaveSatus = (leaveId, status) => {
    setLoading(true);
    const formdata = new FormData();
    formdata.append("leave_id", leaveId);
    formdata.append("leave_status", status);

    const requestOptions = {
      method: "POST",
      body: formdata,
      redirect: "follow",
    };
    fetch(
      `${process.env.REACT_APP_API_BASE_URL}/admin/update_leave_status`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status === true) {
          fetchData1();
          setLoading(false);
        }
      })
      .catch((error) => console.error(error));
  };

  const fetchData = () => {
    var requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    fetch(
      `${process.env.REACT_APP_API_BASE_URL}/admin/all-employee-list`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status === true) {
          setData(result.data);
        }
      })
      .catch((error) => console.log("error", error));
  };

  useEffect(() => {
    fetchData();
  }, []);

  const customStyles = {
    control: (base, state) => ({
      ...base,
      boxShadow: state.isFocused
        ? "0 0 0 0.1rem rgba(0, 123, 255, 0.25)"
        : null,
      borderColor: state.isFocused ? "#007bff" : base.borderColor,
    }),
  };
  const handleEmployeeSelect = (selectedOption) => {
    const selectedValue = selectedOption.value;
    setLeaveAssign((prevState) => ({
      ...prevState,
      user_id: selectedValue,
    }));
  };

  const [currentPage, setCurrentPage] = useState(1);
  const projectsPerPage = 6;

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const totalPages = Math.ceil(request.length / projectsPerPage);
  const indexOfLastProject = currentPage * projectsPerPage;
  const indexOfFirstProject = indexOfLastProject - projectsPerPage;
  const currentProjects = request.slice(
    indexOfFirstProject,
    indexOfLastProject
  );


  const reload = () => {
    window.location.reload();
  };
  return (
    <section className="left_width" style={{ marginLeft: `${menuWidth}px` }}>
      {loading === true ?
        <div className="spinner_height w-100">
          <Spinner animation="grow" size="sm" className="text-primary" />
          &nbsp;
          <Spinner animation="grow" size="sm" className="text-primary" />
          &nbsp;
          <Spinner animation="grow" size="sm" className="text-primary" />
          &nbsp;
          <Spinner animation="grow" size="sm" className="text-primary" />
        </div>
        :
        <div>
          <div className="d-lg-flex justify-content-between align-items-center pt-4 pb-3 w-100">
            <h4 className="m-0">Leave Request</h4>
            {/* <div>
          <button className="btn btn-primary puch_btn" onClick={handleLeave}>
            Assign leave
          </button>
        </div> */}
          </div>
          <div className="employee_table p-4 text-center">
            <div className="d-lg-flex justify-content-between align-items-center">
              <Select
                options={data.map((item) => ({
                  value: item.id,
                  label: `${item.first_name} ${item.last_name}`,
                }))}
                styles={customStyles}
                placeholder="Select an Employee"
                onChange={handleSelectProject}
                className="text-uppercase search_select_css1 "
                required
              />

              <span className="ml-3">From</span>
              <Form.Control
                type="date"
                className="mx-2 h-75"
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
              />
              To
              <Form.Control
                type="date"
                className="mx-2 h-75"
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
              />
              <button className="btn btn-primary puch_btn" onClick={reload}>
                Reset
              </button>
            </div>



            <div>
              <div className="table-responsive custom-scrollbar table-view-responsive  py-primary">
                <table className="table mb-0">
                  <thead>
                    <tr>
                      <th className=" py-4 px-3 table_color">Name</th>
                      <th className=" py-4 px-3 table_color">Date & Time</th>
                      <th className=" py-4 px-3 table_color">Leave Duration</th>
                      <th className="py-4 px-3 table_color text-right">Leave Type</th>
                      <th className="py-4 px-3 table_color text-right">Note</th>
                      <th className="py-4 px-3 table_color text-right">Status </th>
                      <th className="py-4 px-3 table_color text-right">Apply Date </th>
                      <th className="py-4 px-3 table_color text-right">Actions</th>
                    </tr>
                  </thead>
                  {currentProjects.length > 0 ? (
                    <tbody>
                      {currentProjects &&
                        currentProjects.map((item) => (
                          <tr key={item.leave_id}>
                            <td>{item.employee_name}</td>
                            <td>
                              {item.leave_start_date} to {item.leave_end_date}
                            </td>
                            <td>{item.leave_duration}</td>
                            <td>{item.leave_type}</td>
                            <td>{item.note}</td>
                            <td>
                              <span
                                className={`rounded-pill py-1 px-2 text-white p_css ${item.status === "Pending"
                                    ? "bg-warning"
                                    : item.status === "Approved"
                                      ? "bg-success"
                                      : item.status === "Rejected"
                                        ? "bg-danger"
                                        : item.status === "Cancelled"
                                          ? "bg-danger"
                                          : " "
                                  }`}
                              >
                                {item.status}
                              </span>
                            </td>
                            <td>{item.created_at}</td>
                            <td>
                              {item.status === "Cancelled" ?
                              "":
                                <>
                                  <FaRegCheckSquare
                                    onClick={() =>
                                      handleLeaveAction(item.leave_id, "1")
                                    }
                                    className="left_icon_css text-success"
                                    role="button"
                                  />
                                  &nbsp;
                                  <FaRegWindowClose
                                    onClick={() =>
                                      handleLeaveAction(item.leave_id, "2")
                                    }
                                    className="left_icon_css text-danger "
                                    role="button"
                                  />
                                </>
                              
                              }
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  ) : (
                    <tbody className="pt-5 pb-5">
                      <tr>
                        <td colSpan="8">
                          <img src="/no_data.svg" alt="no data" width="150px" />
                          <h6>Nothing to show here</h6>
                          <p>
                            Please add a new entity or manage the data table to see
                            the content here Thank you
                          </p>
                        </td>
                      </tr>
                    </tbody>
                  )}
                </table>


                <Pagination className="mt-2 float-right">
                  <Pagination.First onClick={() => handlePageChange(1)} />
                  <Pagination.Prev
                    onClick={() => handlePageChange(currentPage - 1)}
                    disabled={currentPage === 1}
                  />
                  {[...Array(totalPages)].map((_, index) => {
                    // Display only a fixed number of page numbers before and after the current page
                    if (
                      index + 1 === 1 || // Display the first page
                      index + 1 === totalPages || // Display the last page
                      index + 1 === currentPage || // Display the current page
                      index + 1 === currentPage - 1 || // Display the page before the current page
                      index + 1 === currentPage + 1 // Display the page after the current page
                    ) {
                      return (
                        <Pagination.Item
                          key={index + 1}
                          active={index + 1 === currentPage}
                          onClick={() => handlePageChange(index + 1)}
                        >
                          {index + 1}
                        </Pagination.Item>
                      );
                    } else if (
                      index + 1 === currentPage - 2 || // Display ellipsis two pages before the current page
                      index + 1 === currentPage + 2 // Display ellipsis two pages after the current page
                    ) {
                      return <Pagination.Ellipsis key={index + 1} />;
                    }
                    return null;
                  })}
                  <Pagination.Next
                    onClick={() => handlePageChange(currentPage + 1)}
                    disabled={currentPage === totalPages}
                  />
                  <Pagination.Last onClick={() => handlePageChange(totalPages)} />
                </Pagination>
              </div>
            </div>
          </div>
        </div>
      }

      {/*---------------Assign Leave-------------------------*/}

      <Modal show={showLeave} onHide={handleCloseleave} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Assign leave</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form noValidate validated={validated} onSubmit={handleLeaveAssign}>
            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlTextarea1"
            >
              <Form.Label>Employee</Form.Label>
              <Select
                options={data.map((item) => ({
                  value: item.id,
                  label: `${item.first_name} ${item.last_name}`,
                }))}
                styles={customStyles}
                placeholder="Select a Employee"
                onChange={handleEmployeeSelect}
                className="text-uppercase search_select_css"
                required
              />
            </Form.Group>
            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlTextarea1"
            >
              <Form.Label>Leave Type</Form.Label>
              <Form.Control
                aria-label="Default select example"
                onChange={(e) => {
                  setLeaveAssign((prevState) => ({
                    ...prevState,
                    leave_type: e.target.value,
                  }));
                }}
                required
              />
            </Form.Group>
            <Form.Group className="mt-3 d-lg-flex justify-content-between">
              <Form.Label className="w-50">Age (Leave duration)</Form.Label>

              <Form.Group
                className="mb-3 d-lg-flex  justify-content-between w-100"
                controlId="leaveDuration"
              >
                <Form.Check
                  type="radio"
                  label="Single Day"
                  name="leaveDuration"
                  value="Single Day"
                  onChange={(e) => {
                    setLeaveAssign({
                      ...leaveAssign,
                      leave_duration: e.target.value,
                    });
                    setShowFields({
                      startDate: true,
                      endDate: false,
                      firstHalf: false,
                      secondHalf: false,
                      startTime: false,
                      endTime: false,
                    });
                  }}
                  required
                />
                <Form.Check
                  type="radio"
                  label="Multi Day"
                  name="leaveDuration"
                  value="Multi Day "
                  onChange={(e) => {
                    setLeaveAssign({
                      ...leaveAssign,
                      leave_duration: e.target.value,
                    });
                    setShowFields({
                      startDate: true,
                      endDate: true,
                      firstHalf: false,
                      secondHalf: false,
                      startTime: false,
                      endTime: false,
                    });
                  }}
                  required
                />
                <Form.Check
                  type="radio"
                  label="Half Day"
                  name="leaveDuration"
                  value="Half Day"
                  onChange={(e) => {
                    setLeaveAssign({
                      ...leaveAssign,
                      leave_duration: e.target.value,
                    });
                    setShowFields({
                      startDate: true,
                      endDate: false,
                      firstHalf: true,
                      secondHalf: true,
                      startTime: false,
                      endTime: false,
                    });
                  }}
                  required
                />
                <Form.Check
                  type="radio"
                  label="Hours"
                  name="leaveDuration"
                  value="Hours"
                  onChange={(e) => {
                    setLeaveAssign({
                      ...leaveAssign,
                      leave_duration: e.target.value,
                    });
                    setShowFields({
                      startDate: true,
                      endDate: false,
                      firstHalf: false,
                      secondHalf: false,
                      startTime: true,
                      endTime: true,
                    });
                  }}
                  required
                />
              </Form.Group>
            </Form.Group>

            <div className="d-lg-flex justify-content-between align-items-center">
              {showFields.startDate && (
                <Form.Group className="mb-3 w-100" controlId="startDate">
                  <Form.Label>Start Date</Form.Label>
                  <Form.Control
                    type="date"
                    className="w-100"
                    onChange={(e) =>
                      setLeaveAssign({
                        ...leaveAssign,
                        start_date: e.target.value,
                      })
                    }
                    required
                  />
                </Form.Group>
              )}
              &nbsp; &nbsp;
              {showFields.endDate && (
                <Form.Group className="mb-3 w-100" controlId="endDate">
                  <Form.Label>End Date</Form.Label>
                  <Form.Control
                    type="date"
                    className="w-100"
                    onChange={(e) =>
                      setLeaveAssign({
                        ...leaveAssign,
                        last_date: e.target.value,
                      })
                    }
                    required
                  />
                </Form.Group>
              )}
              &nbsp; &nbsp;
              {showFields.firstHalf && (
                <Form.Group className=" w-25" controlId="firstHalf">
                  <Form.Label></Form.Label>
                  <Form.Check
                    type="radio"
                    label="First Half"
                    name="leaveDuration"
                    value="First Half"
                    onChange={(e) =>
                      setLeaveAssign({
                        ...leaveAssign,
                        half_time_duration: "First Half",
                      })
                    }
                    required
                  />
                </Form.Group>
              )}
              &nbsp; &nbsp;
              {showFields.secondHalf && (
                <Form.Group className="w-25" controlId="firstHalf">
                  <Form.Label></Form.Label>
                  <Form.Check
                    type="radio"
                    label="Second Half"
                    name="leaveDuration"
                    value="Second Half"
                    onChange={(e) =>
                      setLeaveAssign({
                        ...leaveAssign,
                        half_time_duration: "First Half",
                      })
                    }
                    required
                  />
                </Form.Group>
              )}
              &nbsp; &nbsp;
              {showFields.startTime && (
                <Form.Group className="mb-3 w-100" controlId="startTime">
                  <Form.Label>Start Time</Form.Label>
                  <Form.Control
                    type="time"
                    onChange={(e) =>
                      setLeaveAssign({
                        ...leaveAssign,
                        start_time: e.target.value,
                      })
                    }
                    required
                  />
                </Form.Group>
              )}
              &nbsp; &nbsp;
              {showFields.endTime && (
                <Form.Group className="mb-3 w-100" controlId="endTime">
                  <Form.Label>End Time</Form.Label>
                  <Form.Control
                    type="time"
                    onChange={(e) =>
                      setLeaveAssign({
                        ...leaveAssign,
                        end_time: e.target.value,
                      })
                    }
                    required
                  />
                </Form.Group>
              )}
            </div>
            <div>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlTextarea1"
              >
                <Form.Label>Reason note</Form.Label>
                <Form.Control
                  as="textarea"
                  placeholder="Enter note"
                  rows={3}
                  onChange={(e) =>
                    setLeaveAssign({ ...leaveAssign, note: e.target.value })
                  }
                  required
                />
              </Form.Group>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlTextarea1"
              >
                <Form.Label>Attachments</Form.Label>
                <Form.Control type="file"
                  placeholder="Upload File"
                  value={leaveAssign.attachment}
                  onChange={(e) => setLeaveAssign({ ...leaveAssign, attachment: e.target.value })} />
              </Form.Group>
            </div>
            {error && <Alert variant="danger">{error}</Alert>}

            <Modal.Footer>
              <button
                className="btn btn-secondary puch_btn"
                onClick={handleCloseleave}
                type="button"
              >
                Cancel
              </button>
              {loading ? (
                <button className="btn btn-primary puch_btn" type="button">
                  <Spinner
                    animation="grow"
                    size="sm"
                    style={{ background: "#fff" }}
                  />
                  &nbsp;
                  <Spinner
                    animation="grow"
                    size="sm"
                    style={{ background: "#fff" }}
                  />
                </button>
              ) : (
                <button className="btn btn-primary puch_btn" type="submit">
                  Save
                </button>
              )}
            </Modal.Footer>
          </Form>
        </Modal.Body>
      </Modal>
      {/* ----------------rejected popup ----------------*/}

      <Modal
        show={rejected}
        onHide={handleRejectedClose}
        size="md"
        centered
        className="pt-5 pb-5"
      >
        <Modal.Body>
          <div className="text-center mt-5">
            <BsDownload className="jobdisk_icon" />
            <h5>Are you sure?</h5>
            <p>You are going to reject this leave request</p>
          </div>
          <Modal.Footer className="border-0 justify-content-center  mb-4">
            <button
              className="btn btn-secondary puch_btn"
              onClick={handleRejectedClose}
            >
              No
            </button>
            <button
              className="btn btn_color puch_btn"
              onClick={() => {
                leaveSatus(leaveIdToHandle, "2");

                handleRejectedClose();
              }}
            >
              Yes
            </button>
          </Modal.Footer>
        </Modal.Body>
      </Modal>

      {/* ----------------accept popup ----------------*/}
      <Modal
        show={accept}
        onHide={handleAcceptClose}
        size="md"
        centered
        className="pt-5 pb-5"
      >
        <Modal.Body>
          <div className="text-center mt-5">
            <IoMdCheckmarkCircleOutline className="jobdisk_icon text-success" />
            <h5>Are you sure?</h5>
            <p>You are going to approve this leave request</p>
          </div>
          <Modal.Footer className="border-0 justify-content-center  mb-4">
            <button
              className="btn btn-secondary puch_btn"
              onClick={handleAcceptClose}
            >
              No
            </button>
            <button
              className="btn btn-success puch_btn"
              onClick={() => {
                leaveSatus(leaveIdToHandle, "1");
                handleAcceptClose();
              }}
            >
              Yes
            </button>
          </Modal.Footer>
        </Modal.Body>
      </Modal>
    </section>
  );
};
export default LeaveRequest;
