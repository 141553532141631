// import React from "react";
// const Notifcation = ({ menuWidth }) => {

//   return (
//     <section className="left_width" style={{ marginLeft: `${menuWidth}px` }}>
//       <div className="d-lg-flex justify-content-between align-items-center pt-4  pb-3 w-100">
//         <h4>All Notifications</h4>
//       </div>
//       <div className="d-lg-flex justify-content-between">
//       <input type="date" className="tab_css" />
//       <input type="search" className="tab_css" />
//       </div>
//       <div className="table-responsive custom-scrollbar table-view-responsive py-primary">
//         <table className="table mb-0">
//           <thead>
//             <tr>
//               <th track-by="0" className="datatable-th py-4 px-3 table_color ">
//                 <span className="font-size-default">
//                   <span>Title</span>
//                 </span>
//               </th>
//               <th track-by="1" className="datatable-th py-4 px-3 table_color">
//                 <span className="font-size-default">
//                   <span>Notification form</span>
//                 </span>
//               </th>
//               <th track-by="2" className="datatable-th py-4 px-3 table_color">
//                 <span className="font-size-default">
//                   <span>Date</span>
//                 </span>
//               </th>
//               <th
//                 track-by="3"
//                 className="datatable-th py-4 px-3 table_color text-right"
//               >
//                 <span className="font-size-default">Time</span>
//               </th>
//             </tr>
//           </thead>
//           <tbody></tbody>
//         </table>
//       </div>
//     </section>
//   );
// };

// export default Notifcation;

import React from "react";

const Notification = ({ menuWidth }) => {
  return (
    <section className="left_width" style={{ marginLeft: `${menuWidth}px` }}>
      <div className="d-lg-flex justify-content-between align-items-center pt-4  pb-3 w-100">
        <h4>Attendance</h4>
      </div>
      <div className="row justify-content-between">
        <div className="col-lg-2 col-md-2 col-sm-12 attendance_box text-success">
        <h4 className="m-0">00</h4>
          <h6 className="small m-0">Presents</h6>
        </div>
        <div className="col-lg-2 col-md-2 col-sm-12 attendance_box text-primary">
          <h4 className="m-0">00</h4>
          <h6 className="small m-0">Saturday Sunday</h6>
        </div>
        <div className="col-lg-2 col-md-2 col-sm-12 attendance_box text-danger">
        <h4 className="m-0">00</h4>
          <h6 className="small m-0">Leaves</h6>
        </div>
        <div className="col-lg-2 col-md-2 col-sm-12 attendance_box text-warning">
        <h4 className="m-0">00</h4>
          <h6 className="small m-0">Half-day</h6>
        </div>
        <div className="col-lg-2 col-md-2 col-sm-12 attendance_box text-success">
        <h4 className="m-0">00</h4>
          <h6 className="small m-0">Holidays</h6>
        </div>
      </div>



      <div className="table-responsive custom-scrollbar table-view-responsive py-primary">
                    <div className="">
                      <table className="table mb-0">
                        <thead>
                          <tr>
                            <th
                              className=" py-4 px-0 table_color_width"
                              style={{ width: "10px" }}
                            >
                              <span className="font-size-default ">S.No.</span>
                            </th>
                            <th className=" py-4 px-1 table_color text-right ">
                              <span className="font-size-default ">
                                Punch Date
                              </span>
                            </th>
                            <th track-by="2" className=" py-4 px-1 table_color">
                              <span className="font-size-default">
                                <span>In</span>
                              </span>
                            </th>
                            <th track-by="0" className=" py-4 px-1 table_color">
                              <span className="font-size-default">
                                <span>Out</span>
                              </span>
                            </th>
                            <th track-by="2" className=" py-4 px-1 table_color">
                              <span className="font-size-default">
                                <span>Status</span>
                              </span>
                            </th>
                            <th track-by="1" className=" py-4 px-1 table_color">
                              <span className="font-size-default ">
                                <span>Punch-in note</span>
                              </span>
                            </th>
                            <th track-by="1" className=" py-4 px-1 table_color">
                              <span className="font-size-default ">
                                <span>Break Time</span>
                              </span>
                            </th>
                            <th className=" py-4 px-1 table_color text-right">
                              <span className="font-size-default">
                                Total Hours
                              </span>
                            </th>
                          </tr>
                        </thead>
                          <>
                                <tbody>
                                  <tr>
                                    <td>1</td>
                                    <td>1-04-2024</td>
                                    <td>1</td>
                                    
                                    <td>1</td>
                                    <td > <div className="holiday_btn">Present</div></td>
                                    <td>1</td>
                                    <td>1</td>
                                    <td>1</td>
                                  </tr>
                                  <tr>
                                    <td>2</td>
                                    <td>1-04-2024</td>
                                    <td>1</td>
                                    
                                    <td>1</td>
                                    <td > <div className="holiday_btn">Present</div></td>
                                    <td>1</td>
                                    <td>1</td>
                                    <td>1</td>
                                  </tr>
                                </tbody>
                                <tbody>
                                  <tr>
                                    <td className="">
                                     1
                                    </td>
                                    <td className=""     colSpan={3}>
                                    1-04-2024
                                    </td>
                                    <td
                                      className=""
                                  
                                    >
                                      <div className="holiday_btn">Holiday</div>
                                    </td>
                                    <td
                                      className=""
                                      colSpan={3}
                                    >
                                  
                                    </td>
                                   
                                  </tr>
                                </tbody>
                              

                                <tbody>
                                  <tr>
                                    <td className="">
                                     1
                                    </td>
                                    <td className="" colSpan={3}>
                                    1-04-2024
                                    </td>
                                    <td className=""><div className="halfday_btn">Saturday Sunday</div></td>
                                    <td
                                      className=""
                                      colSpan={3}
                                    >
                                     1
                                    </td>
                                  </tr>
                                </tbody>
                             
                         
                                <tbody>
                                  <tr>
                                    <td>
                                      1
                                    </td>
                                    <td  colSpan={3}>
                                   1
                                    </td>
                                    <td>
                                     <div className="leave_btn">Leave</div>
                                    </td>
                                    <td
                                     
                                      colSpan={3}
                                    >
                                     
                                    </td>
                                  </tr>
                                </tbody>
                            <tfoot>
                              <tr>
                                <td></td>
                                <th colSpan={2}>Total Time</th>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <th>1111</th>
                              </tr>
                              <tr>
                                <td></td>
                                <th colSpan={2}>Total Break Time</th>
                                <td></td>
                                <td></td>
                                <td></td>

                                <th>111</th>
                                <td></td>
                              </tr>
                            </tfoot>
                          </>
                      </table>
                    </div>
                  </div>
    </section>
  );
};

export default Notification;
