import React, { useCallback, useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import { useAuth } from "../utils/Context";
import { Alert, Pagination } from "react-bootstrap";
import { BiEdit } from "react-icons/bi";
import { RiDeleteBin5Line } from "react-icons/ri";
import { BsDownload } from "react-icons/bs";

const ProjectDetails = ({ menuWidth }) => {
  const { id, role_id } = useAuth();
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [accept, setAccept] = useState(false);
  const [project_name, setProject_name] = useState("");
  const [project_link, setProject_link] = useState("");
  const handleAccept = (projectid) => {
    setDeleted({ projectid });
    setAccept(true);
  };
  const [deleted, setDeleted] = useState({
    project_id: "",
  });

  const handleAcceptClose = () => setAccept(false);

  const [data, setData] = useState({
    project_name: "",
    project_link: "",
  });
  const [addProject, setAddProject] = useState({
    project_name: "",
    project_link: "",
    error: "",
  });
  const [projectList, setProjectList] = useState([]);
  const [showEdit, setShowEdit] = useState(false);
  const [projectid, setProjectid] = useState();
  const [validated, setValidated] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const projectsPerPage = 10;

  useEffect(() => {
    if (projectid) {
      var requestOptions = {
        method: "GET",
        redirect: "follow",
      };

      fetch(
        `${process.env.REACT_APP_API_BASE_URL}/admin/project-edit?id=${projectid}`,
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          if (result.status === true) {
            setData(result.data);
          }
        })
        .catch((error) => console.log("error", error));
    }
  }, [projectid]);



  const fetchData = useCallback(() => {
    setLoading(true);
    var requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    fetch(
      `${process.env.REACT_APP_API_BASE_URL}/admin/project-list?project_name=${project_name}&project_link=${project_link}&user_id=`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status === true) {
          setProjectList(result.data);
        }
        setLoading(false);
      })
      .catch((error) => console.log("error", error));
  },[project_name,project_link])

  useEffect(() => {
    fetchData();
  }, [project_name, project_link,fetchData]);

  const handleClose = () => {
    setShow(false);
    setValidated(false);
    setAddProject({
      project_name: "",
      project_link: "",
      error: "",
    });
  };
  const handleShow = () => setShow(true);

  const handleEditClose = () => setShowEdit(false);
  const handleEditShow = () => setShowEdit(true);

  const handleAddproject = (event) => {
    const form = event.currentTarget;
    event.preventDefault();
    if (form.checkValidity() === false) {
      event.stopPropagation();
    }
    setValidated(true);

    var formdata = new FormData();
    formdata.append("employee_id", id);
    formdata.append("project_name", addProject.project_name);
    formdata.append("project_link", addProject.project_link);

    var requestOptions = {
      method: "POST",
      body: formdata,
      redirect: "follow",
    };

    fetch(
      `${process.env.REACT_APP_API_BASE_URL}/admin/project`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status === true) {
          handleClose();
          fetchData();
          // Reset form fields
          setAddProject({
            project_name: "",
            project_link: "",
            error: "", // Clear any previous error message
          });
          // Reset form validation
          setValidated(false);
        } else {
          setAddProject({ ...addProject, error: result.message });
        }
      })
      .catch((error) => console.log("error", error));
  };

  const handleSaveProject = () => {
    if (projectid) {
      var formdata = new FormData();
      formdata.append("id", projectid);
      formdata.append("employee_id", id);
      formdata.append("project_name", data.project_name);
      formdata.append("project_link", data.project_link);

      // Append project_link only if it's not null or empty
      if (data.project_link && data.project_link.trim() !== "") {
        formdata.append("project_link", data.project_link.trim());
      } else {
        // If project_link is null or empty, append it with an empty string
        formdata.append("project_link", "");
      }

      var requestOptions = {
        method: "POST",
        body: formdata,
        redirect: "follow",
      };

      fetch(
        `${process.env.REACT_APP_API_BASE_URL}/admin/project`,
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          if (result.status === true) {
            fetchData();
            handleEditClose();
          }
        })
        .catch((error) => console.log("error", error));
    }
  };

  const handleEditProject = (projectId) => {
    setProjectid(projectId);
    handleEditShow();
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const totalPages = Math.ceil(projectList.length / projectsPerPage);
  const indexOfLastProject = currentPage * projectsPerPage;
  const indexOfFirstProject = indexOfLastProject - projectsPerPage;
  const currentProjects = projectList.slice(
    indexOfFirstProject,
    indexOfLastProject
  );
  const handleDelete = () => {
    const formdata = new FormData();
    formdata.append("project_id", deleted.projectid);

    const requestOptions = {
      method: "POST",
      body: formdata,
      redirect: "follow",
    };

    fetch(
      `${process.env.REACT_APP_API_BASE_URL}/admin/delete-project`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status === true) {
          setDeleted(!deleted);
          fetchData();
          handleAcceptClose();
        }
      })
      .catch((error) => console.error(error));
  };
  const handleProjectNameChange = (e) => {
    setProject_name(e.target.value);
    setProject_link("");
  };
  const handleProjectLinkChange = (e) => {
    setProject_link(e.target.value);
    setProject_name("");
  };

  return (
    <section className="left_width" style={{ marginLeft: `${menuWidth}px` }}>
      <div className="d-flex justify-content-between align-items-center pt-4  pb-3 w-100">
        <h4 className="m-0">All Projects</h4>
        <div>
          <button className="btn btn-success puch_btn" onClick={handleShow}>
            Add Project
          </button>
        </div>
      </div>
      <div className="d-lg-flex justify-content-between mb-3">
        <div>
          <label>Search by project name</label>
          <input
            type="text"
            className="tab_css"
            name="to Date"
            value={project_name}
            onChange={handleProjectNameChange}
          />
        </div>
        <div>
          <label>Search by url</label>
          <input
            type="text"
            className="tab_css"
            name="from Date"
            value={project_link}
            onChange={handleProjectLinkChange}
          />
        </div>
      </div>
      {!loading ? (
        <div className="table-responsive custom-scrollbar table-view-responsive py-primary ">
          <table className="table data-table mb-0 ">
            <thead>
              <tr>
                <th
                  className="py-4 px-3 table_color" width="10px">S.No.</th>
                <th className="py-4 px-3 table_color">Project Name</th>
                <th className="py-4 px-3 table_color">Link</th>
                <th className="py-4 px-3 table_color text-right">Status</th>
                <th className="py-4 px-3 table_color text-right">Date</th>
                <th className="py-4 px-3 table_color text-right">Update By</th>
                <th className="py-4 px-3 table_color text-right">Action</th>
              </tr>
            </thead>
            {currentProjects.length > 0 ? (
              <tbody>
                {currentProjects.map((item) => (
                  <tr key={item.id}>
                    <td className=" px-3" width="10px">
                      {item.sr_no}
                    </td>
                    <td>{item.project_name}</td>
                    <td>{item.project_link}</td>
                    <td className="text-center">
                      {item.status === 1 ? (
                        <div className="btn-primary text-white rounded-pill">
                          Active
                        </div>
                      ) : item.status === 2 ? (
                        <div className="btn-success">Inactive</div>
                      ) : (
                        ""
                      )}
                    </td>
                    <td>{item.date}</td>
                    <td>{item.user_id}</td>
                    <td className="text-center">
                      <span className="hover_btn ">
                        <BiEdit
                          className="left_icon_css"
                          onClick={() => handleEditProject(item.id)}
                        />
                      </span>
                      &nbsp; &nbsp;
                      {role_id === "1" ? (
                        <span className="hover_btn">
                          <RiDeleteBin5Line
                            onClick={() => handleAccept(item.id)}
                            className="left_icon_css text-danger"
                          />
                        </span>
                      ) : (
                        ""
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            ) : (
              <tbody className="pt-5 pb-5">
                <tr>
                  <td colSpan="7" className="text-center py-5">
                    <img src="/no_data.svg" alt="no data" width="150px" />
                    <h6>Nothing to show here</h6>
                    <p className="small text-secondary">
                      Please add a new entity or manage the data table to see
                      the content here Thank you
                    </p>
                  </td>
                </tr>
              </tbody>
            )}
          </table>
          <Pagination className="mt-2 float-right">
            <Pagination.First onClick={() => handlePageChange(1)} />
            <Pagination.Prev
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1}
            />
            {[...Array(totalPages)].map((_, index) => {
              // Display only a fixed number of page numbers before and after the current page
              if (
                index + 1 === 1 || // Display the first page
                index + 1 === totalPages || // Display the last page
                index + 1 === currentPage || // Display the current page
                index + 1 === currentPage - 1 || // Display the page before the current page
                index + 1 === currentPage + 1 // Display the page after the current page
              ) {
                return (
                  <Pagination.Item
                    key={index + 1}
                    active={index + 1 === currentPage}
                    onClick={() => handlePageChange(index + 1)}
                  >
                    {index + 1}
                  </Pagination.Item>
                );
              } else if (
                index + 1 === currentPage - 2 || // Display ellipsis two pages before the current page
                index + 1 === currentPage + 2 // Display ellipsis two pages after the current page
              ) {
                return <Pagination.Ellipsis key={index + 1} />;
              }
              return null;
            })}
            <Pagination.Next
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={currentPage === totalPages}
            />
            <Pagination.Last onClick={() => handlePageChange(totalPages)} />
          </Pagination>
        </div>
      ) : (
        <div className="spinner_height">
          <div className="loader"></div>
        </div>
      )}

      {/* -------------------Add project------------------------- */}

      <Modal show={show} onHide={handleClose} size="lg">
        <Modal.Header closeButton className="p-4  ">
          <Modal.Title>Add Project</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form noValidate validated={validated} onSubmit={handleAddproject}>
            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlTextarea1"
            >
              <Form.Label>Project Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter project name"
                value={addProject.project_name}
                onChange={(e) =>
                  setAddProject({ ...addProject, project_name: e.target.value })
                }
                required
              />
              <Form.Control.Feedback type="invalid">
                Please enter project name.
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group
              className="mb-3 w-100 px-1"
              controlId="exampleForm.ControlTextarea1"
            >
              <Form.Label>URL</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter project URL"
                value={addProject.project_link}
                onChange={(e) =>
                  setAddProject({
                    ...addProject,
                    project_link: e.target.value,
                  })
                }
              />
            </Form.Group>
            {addProject.error && (
              <Alert variant="danger">{addProject.error}</Alert>
            )}

            <Modal.Footer>
              <button
                className="btn btn-secondary puch_btn"
                onClick={handleClose}
                type="button"
              >
                Cancel
              </button>
              &nbsp;
              <button className="btn btn-primary puch_btn" type="submit">
                Save
              </button>
            </Modal.Footer>
          </Form>
        </Modal.Body>
      </Modal>
      {/* -----------------Edit project-------------------------- */}
      <Modal show={showEdit} onHide={handleEditClose} size="lg">
        <Modal.Header closeButton className="p-4  ">
          <Modal.Title>Edit Project</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlTextarea1"
            >
              <Form.Label>Project Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter project name"
                value={data && data.project_name}
                onChange={(e) =>
                  setData({
                    ...data,
                    project_name: e.target.value,
                  })
                }
              />
            </Form.Group>
            <Form.Group
              className="mb-3 w-100 px-1"
              controlId="exampleForm.ControlTextarea1"
            >
              <Form.Label>URL</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter project URL"
                value={data && data.project_link}
                onChange={(e) =>
                  setData({
                    ...data,
                    project_link: e.target.value,
                  })
                }
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-secondary puch_btn"
            onClick={handleEditClose}
          >
            Cancel
          </button>
          <button
            className="btn btn-primary puch_btn"
            onClick={handleSaveProject}
          >
            Save
          </button>
        </Modal.Footer>
      </Modal>

      {/* ----------------delete popup ----------------*/}
      <Modal
        show={accept}
        onHide={handleAcceptClose}
        size="md"
        centered
        className="pt-5 pb-5"
      >
        <Modal.Body>
          <div className="text-center mt-5">
            <BsDownload className="jobdisk_icon" />
            <h5>Are you sure?</h5>
            <p>You are going to delete this project details</p>
          </div>
          <Modal.Footer className="border-0 justify-content-center  mb-4">
            <button
              className="btn btn-secondary puch_btn"
              onClick={handleAcceptClose}
            >
              No
            </button>
            <button
              className="btn btn_color puch_btn"
              onClick={() => handleDelete(deleted.projectid)}
            >
              Yes
            </button>
          </Modal.Footer>
        </Modal.Body>
      </Modal>
    </section>
  );
};
export default ProjectDetails;
