import React, { useEffect, useState } from "react";
import { Alert, Spinner } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import { BsDownload } from "react-icons/bs";
import { FaEdit } from "react-icons/fa";
import { RiDeleteBin5Line } from "react-icons/ri";


const Announcements = ({ menuWidth }) => {
  const [show, setShow] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const handleShowEdit = () => setShowEdit(true);
  const handleShow = () => setShow(true);
  const [error, setError] = useState("");
  // const [accept, setAccept] = useState(false);
  const [acceptDelete, setAcceptDelete] = useState(false);
  const [announceToDelete, setAnnounceToDelete] = useState(null); // Track the announcement to delete

  const handleClose = () => {
    setShow(false);
    setError("");
    setValidated(false);
    setAddAnnouncements({
      title: "",
      start_date: "",
      end_date: "",
      description: "",
    });
  };
  const handleCloseEdit = () => {
    setShowEdit(false);
    setError("");
    setValidated(false);
  };
  const [validated, setValidated] = useState(false);
  const [loading, setLoading] = useState(false);

  // const [content, setContent] = useState("");

  // const handleChange = (value) => {
  //   setContent(value);
  //   setAddAnnouncements({ ...addAnnouncements, description: value });
  // };

  const [addAnnouncements, setAddAnnouncements] = useState({
    title: "",
    start_date: "",
    end_date: "",
    description: "",
  });

  const handleAddAnnouncements = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    setValidated(true);
    var formdata = new FormData();
    formdata.append("title", addAnnouncements.title);
    formdata.append("start_date", addAnnouncements.start_date);
    formdata.append("end_date", addAnnouncements.end_date);
    formdata.append("description", addAnnouncements.description);

    var requestOptions = {
      method: "POST",
      body: formdata,
      redirect: "follow",
    };

    fetch(
      `${process.env.REACT_APP_API_BASE_URL}/admin/new_announcement`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status === true) {
          setAddAnnouncements(result.data);
          handleClose();
          fetchData();
          setValidated(false);
        }
        else {
          setAddAnnouncements({ ...addAnnouncements, error: result.message });
        }
      })
      .catch((error) => console.log("error", error));
  };

  const [getAnnouncements, setGetAnnouncements] = useState([]);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    setLoading(true);
    var requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    fetch(
      `${process.env.REACT_APP_API_BASE_URL}/admin/get_announcements`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status === true) {
          setGetAnnouncements(result.data);
        }
        setLoading(false);
      })
      .catch((error) => console.log("error", error));
  };

  const [editAnnouncements, setEditAnnouncements] = useState();

  const [updateAnnouncements, setUpdateAnnouncements] = useState({
    announcement_id: "",
    title: "",
    start_date: "",
    end_date: "",
    description: "",
  });

  useEffect(() => {
    if (editAnnouncements) {
      const requestOptions = {
        method: "GET",
        redirect: "follow",
      };

      fetch(
        `${process.env.REACT_APP_API_BASE_URL}/admin/find_announcement?announcement_id=${editAnnouncements}`,
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          if (result.status === true) {
            // Set the values of updateAnnouncements properly here
            setUpdateAnnouncements(result.data);
          }
        })
        .catch((error) => console.log("error", error));
    }
  }, [editAnnouncements]);

  const handleUpdateAnnouncements = () => {

    var formdata = new FormData();
    formdata.append("announcement_id", editAnnouncements);
    formdata.append("title", updateAnnouncements.title);
    formdata.append("start_date", updateAnnouncements.start_date);
    formdata.append("end_date", updateAnnouncements.end_date);
    formdata.append("description", updateAnnouncements.description ||" ");

    var requestOptions = {
      method: "POST",
      body: formdata,
      redirect: "follow",
    };

    fetch(
      `${process.env.REACT_APP_API_BASE_URL}/admin/update_announcement`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status === true) {
          setUpdateAnnouncements(result.data);
          handleCloseEdit();
          fetchData();
          setValidated(false);
        }
        setError(result.message);
      })
      .catch((error) => console.log("error", error));
  };

  const handleDeleteShow = (announceid) => {
    setAnnounceToDelete(announceid);
    setAcceptDelete(true);
  };

  const handleDeleteClose = () => {
    setAnnounceToDelete(null);
    setAcceptDelete(false);
  };

  const handleDelete = () => {
    const requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    fetch(
      `${process.env.REACT_APP_API_BASE_URL}/admin/remove_announcement?announcement_id=${announceToDelete}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status === true) {
          fetchData();
          setAcceptDelete(false);
        }
      })
      .catch((error) => console.error(error));
  };

  const handleEditDesignationopen = (editAnnouncementid) => {
    setEditAnnouncements(editAnnouncementid);
    handleShowEdit();
  };


  const formatDate = (dateString) => {
    if (!dateString) return "Not added yet";

    // Parse the date string into a Date object
    const date = new Date(dateString);

    // Define an array of month names
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    // Extract day, month, and year
    const day = date.getDate();
    const month = monthNames[date.getMonth()];
    const year = date.getFullYear();

    // Format the date as desired
    const formattedDate = `${day} ${month}, ${year}`;

    return formattedDate;
  };

  return (
    <section className="left_width" style={{ marginLeft: `${menuWidth}px` }}>
      <div className="d-flex justify-content-between align-items-center pt-4 pb-3 w-100">
        <h4 className="m-0">Announcements</h4>
        <div>
          <button className="btn btn-primary puch_btn" onClick={handleShow}>
            Add Announcement
          </button>
        </div>
      </div>
      <div className="employee_table p-4 text-center">
        <div>
          <div className="table-responsive custom-scrollbar table-view-responsive py-primary">
            {!loading ? (
              <table className="table mb-0">
                <thead>
                  <tr>
                    <th
                      track-by="0"
                      className="datatable-th py-3 px-2 table_color "
                    >
                      <span className="font-size-default">
                        <span>Title</span>
                      </span>
                    </th>
                    <th
                      track-by="2"
                      className="datatable-th py-3 px-2 table_color"
                    >
                      <span className="font-size-default">
                        <span>Start date</span>
                      </span>
                    </th>
                    <th
                      track-by="3"
                      className="datatable-th py-3 px-2 table_color text-right"
                    >
                      <span className="font-size-default">End date</span>
                    </th>
                    <th
                      track-by="3"
                      className="datatable-th py-3 px-2 table_color text-right"
                    >
                      <span className="font-size-default">Description</span>
                    </th>
                    <th
                      track-by="3"
                      className="datatable-th py-3 px-2 table_color text-right"
                    >
                      <span className="font-size-default">Action</span>
                    </th>
                  </tr>
                </thead>
                {getAnnouncements && getAnnouncements.length > 0 ? (
                  <tbody>
                    {getAnnouncements &&
                      getAnnouncements.map((item) => (
                        <tr key={item.id}>
                          <td>{item.title}</td>
                          <td>{formatDate(item.start_date)}</td>
                          <td>{formatDate(item.end_date)}</td>
                          <td
                            dangerouslySetInnerHTML={{
                              __html: item.description,
                            }}
                          />
                          <td className="">
                            <span className="hover_btn">
                              <FaEdit
                                onClick={() =>
                                  handleEditDesignationopen(item.id)
                                }
                                className="left_icon_css"
                              />
                            </span>
                            &nbsp; &nbsp; &nbsp;
                            <span className="hover_btn">
                              <RiDeleteBin5Line
                                onClick={() => handleDeleteShow(item.id)}
                                className="left_icon_css text-danger"
                              />
                            </span>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                ) : (
                  <tbody>
                    <tr className="text-center">
                      <td colSpan="5">
                        <span className="pt-5 pb-5">
                          <img src="/no_data.svg" alt="no data" width="150px" />
                          <h6>Nothing to show here</h6>
                          <p className="small text-secondary">
                            Please add a new entity or manage the data table to
                            see the content here <br />
                            Thank you
                          </p>
                        </span>
                      </td>
                    </tr>
                  </tbody>
                )}
              </table>
            ) : (
              <div className="spinner_height">
                <div className="loader"></div>
              </div>
            )}
          </div>
        </div>
      </div>

      {/* -------------------Add Announcement------------------------- */}

      <Modal show={show} onHide={handleClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Add Announcement</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form
            noValidate
            validated={validated}
            onSubmit={handleAddAnnouncements}
          >
            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlTextarea1"
            >
              <Form.Label>Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Name"
                value={addAnnouncements.title}
                onChange={(e) =>
                  setAddAnnouncements({
                    ...addAnnouncements,
                    title: e.target.value,
                  })
                }
                required
              />
              <Form.Control.Feedback type="invalid">
                Please enter title.
              </Form.Control.Feedback>
            </Form.Group>
            <div className="d-lg-flex justify-content-between">
              <Form.Group
                className="mb-3 w-100 px-2"
                controlId="exampleForm.ControlTextarea1"
              >
                <Form.Label>Start Date</Form.Label>
                <Form.Control
                  type="date"
                  placeholder=""
                  value={addAnnouncements.start_date}
                  onChange={(e) =>
                    setAddAnnouncements({
                      ...addAnnouncements,
                      start_date: e.target.value,
                    })
                  }
                  required
                />
                <Form.Control.Feedback type="invalid">
                  Please select start date.
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group
                className="mb-3 w-100 px-2"
                controlId="exampleForm.ControlTextarea1"
              >
                <Form.Label>End Date</Form.Label>
                <Form.Control
                  type="date"
                  placeholder=""
                  value={addAnnouncements.end_date}
                  onChange={(e) =>
                    setAddAnnouncements({
                      ...addAnnouncements,
                      end_date: e.target.value,
                    })
                  }
                  required
                />
                <Form.Control.Feedback type="invalid">
                  Please select end date.
                </Form.Control.Feedback>
              </Form.Group>
            </div>
            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlTextarea1"
            >
              <Form.Label>Description</Form.Label>
              <Form.Control
                as="textarea"
                placeholder=""
                value={addAnnouncements.description}
                onChange={(e) =>
                  setAddAnnouncements({
                    ...addAnnouncements,
                    description: e.target.value,
                  })
                }
                // required
              />
              {/* <Form.Control.Feedback type="invalid">
                Please enter description.
              </Form.Control.Feedback> */}
            </Form.Group>
            {/* {error && <Alert variant="danger">{error}</Alert>} */}
            {addAnnouncements.error && (
              <Alert variant="danger">{addAnnouncements.error}</Alert>
            )}
            <Modal.Footer className="mt-3">
              <button
                className="btn btn-secondary puch_btn"
                onClick={handleClose}
                type="button"
              >
                Cancel
              </button>

              {loading ? (
                <button className="btn btn-primary puch_btn" type="button">
                  <Spinner
                    animation="grow"
                    size="sm"
                    style={{ background: "#fff" }}
                  />
                  &nbsp;
                  <Spinner
                    animation="grow"
                    size="sm"
                    style={{ background: "#fff" }}
                  />
                </button>
              ) : (
                <button className="btn btn-primary puch_btn" type="submit">
                  Save
                </button>
              )}
              
              {/* <button className="btn btn-primary puch_btn">Save</button> */}
            </Modal.Footer>
          </Form>
        </Modal.Body>
      </Modal>

      {/* -------------------Edit Announcement------------------------- */}

      <Modal show={showEdit} onHide={handleCloseEdit} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Edit Announcement</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form
            noValidate
            validated={validated}
            onSubmit={handleUpdateAnnouncements}
          >
            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlTextarea1"
            >
              <Form.Label>Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Name"
                value={updateAnnouncements.title}
                onChange={(e) =>
                  setUpdateAnnouncements({
                    ...updateAnnouncements,
                    title: e.target.value,
                  })
                }
                required
              />
              <Form.Control.Feedback type="invalid">
                Please enter title.
              </Form.Control.Feedback>
            </Form.Group>
            <div className="d-lg-flex justify-content-between">
              <Form.Group
                className="mb-3 w-100 px-2"
                controlId="exampleForm.ControlTextarea1"
              >
                <Form.Label>Start Date</Form.Label>
                <Form.Control
                  type="date"
                  placeholder=""
                  value={updateAnnouncements.start_date}
                  onChange={(e) =>
                    setUpdateAnnouncements({
                      ...updateAnnouncements,
                      start_date: e.target.value,
                    })
                  }
                  required
                />
                <Form.Control.Feedback type="invalid">
                  Please select start date.
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group
                className="mb-3 w-100 px-2"
                controlId="exampleForm.ControlTextarea1"
              >
                <Form.Label>End Date</Form.Label>
                <Form.Control
                  type="date"
                  placeholder=""
                  value={updateAnnouncements.end_date}
                  onChange={(e) =>
                    setUpdateAnnouncements({
                      ...updateAnnouncements,
                      end_date: e.target.value,
                    })
                  }
                  required
                />
                <Form.Control.Feedback type="invalid">
                  Please select end date.
                </Form.Control.Feedback>
              </Form.Group>
            </div>
            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlTextarea1"
            >
              <Form.Label>Description</Form.Label>
              <Form.Control
                as="textarea"
                placeholder=""
                value={updateAnnouncements.description}
                onChange={(e) =>
                  setUpdateAnnouncements({
                    ...updateAnnouncements,
                    description: e.target.value,
                  })
                }
                // required
              />
              {/* <Form.Control.Feedback type="invalid">
                Please enter description.
              </Form.Control.Feedback> */}
            </Form.Group>
            {/* {error && <Alert variant="danger">{error}</Alert>} */}
            <Modal.Footer className="mt-3">
              <button
                className="btn btn-secondary puch_btn"
                onClick={handleCloseEdit}
                type="button"
              >
                Cancel
              </button>
              <button className="btn btn-primary puch_btn">Save</button>
            </Modal.Footer>
          </Form>
        </Modal.Body>
      </Modal>

      {/* ----------------delete popup ----------------*/}
      <Modal
        show={acceptDelete}
        onHide={handleDeleteClose}
        size="md"
        centered
        className="pt-5 pb-5"
      >
        <Modal.Body>
          <div className="text-center mt-5">
            <BsDownload className="jobdisk_icon" />
            <h5>Are you sure?</h5>
            <p>You are going to delete this announcement</p>
          </div>
          <Modal.Footer className="border-0 justify-content-center  mb-4">
            <button
              className="btn btn-secondary puch_btn"
              onClick={handleDeleteClose}
            >
              No
            </button>
            <button
              className="btn btn_color puch_btn"
              onClick={() => handleDelete(getAnnouncements.announceToDelete)}
            >
              Yes
            </button>
          </Modal.Footer>
        </Modal.Body>
      </Modal>
    </section>
  );
};

export default Announcements;
