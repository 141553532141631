import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import Select from "react-select";
import { Alert, Pagination, Spinner } from "react-bootstrap";
import { useAuth } from "../utils/Context";

const EmployeeList = ({ menuWidth }) => {
  const { id } = useAuth();
  const [departments, setDepartments] = useState([]);
  const [designations, setDesignations] = useState([]);
  const [employee_id, setEmployee_id] = useState([]);
  const [employment_statuses, setEmploymentStatuses] = useState([]);
  const [roles, setRoles] = useState([]);
  const [show, setShow] = useState(false);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [validated, setValidated] = useState(false);
  const navigate = useNavigate();

  const [addCredentials, setAddCredentials] = useState({
    first_name: "",
    last_name: "",
    name: "",
    email: "",
    gander: "",
    employee_id: "",
    department_id: "",
    designation_id: "",
    employee_ment_id: "",
    contact_number: "",
    emergency_contact: "",
    date_of_birth: "",
    joining_date: "",
    added_by: id,  // Initialize with id
    sallery: "",
    role_id: "",
    personal_email: "",
    marital_status: "",
    religion: "",
  });

  const handleShow = () => setShow(true);

  const handleClose = () => {
    setShow(false);
    setValidated(false);
    setAddCredentials({
      first_name: "",
      last_name: "",
      name: "",
      email: "",
      gander: "",
      employee_id: "",
      department_id: "",
      designation_id: "",
      employee_ment_id: "",
      contact_number: "",
      emergency_contact: "",
      date_of_birth: "",
      joining_date: "",
      added_by: id,  // Ensure this is kept as id
      sallery: "",
      role_id: "",
      personal_email: "",
      marital_status: "",
      religion: "",
    });
  };

  const customStyles = {
    control: (base, state) => ({
      ...base,
      boxShadow: state.isFocused
        ? "0 0 0 0.1rem rgba(0, 123, 255, 0.25)"
        : null,
      borderColor: state.isFocused ? "#007bff" : base.borderColor,
    }),
  };

  useEffect(() => {
    var requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    fetch(
      `${process.env.REACT_APP_API_BASE_URL}/admin/employee-form`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status === true) {
          const {
            deparments,
            designations,
            employment_statuses,
            roles,
            employee_id,
          } = result.data;
          setEmployee_id(employee_id);
          setDepartments(deparments);
          setDesignations(designations);
          setEmploymentStatuses(employment_statuses);
          setRoles(roles);
        }
      })
      .catch((error) => console.log("error", error));
  }, []);

  useEffect(() => {
    setAddCredentials((prevState) => ({
      ...prevState,
      added_by: id,
    }));
  }, [id]);

  const handleAddEmployee = (event) => {
    event.preventDefault();

    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    setValidated(true);
    setLoading(true);
    var formdata = new FormData();
    formdata.append("first_name", addCredentials.first_name);
    formdata.append("last_name", addCredentials.last_name);
    formdata.append("name", addCredentials.name);
    formdata.append("email", addCredentials.email);
    formdata.append("gander", addCredentials.gander);
    formdata.append("employee_id", addCredentials.employee_id);
    formdata.append("department_id", addCredentials.department_id);
    formdata.append("designation_id", addCredentials.designation_id);
    formdata.append("employee_ment_id", addCredentials.employee_ment_id);
    formdata.append("contact_number", addCredentials.contact_number);
    formdata.append("emergency_contact", addCredentials.emergency_contact);
    formdata.append("date_of_birth", addCredentials.date_of_birth);
    formdata.append("joining_date", addCredentials.joining_date);
    formdata.append("added_by", addCredentials.added_by);
    formdata.append("sallery", addCredentials.sallery);
    formdata.append("role_id", addCredentials.role_id);
    formdata.append("religion", addCredentials.religion);
    formdata.append("personal_email", addCredentials.personal_email);
    formdata.append("marital_status", addCredentials.marital_status);

    var requestOptions = {
      method: "POST",
      body: formdata,
      redirect: "follow",
    };

    fetch(
      `${process.env.REACT_APP_API_BASE_URL}/admin/employee-post`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status === true) {
          setAddCredentials({
            first_name: "",
            last_name: "",
            email: "",
            gander: "",
            employee_id: "",
            department_id: "",
            designation_id: "",
            employee_ment_id: "",
            contact_number: "",
            emergency_contact: "",
            date_of_birth: "",
            joining_date: "",
            added_by: id,  // Keep id as added_by
            sallery: "",
            role_id: "",
            marital_status: "",
            personal_email: "",
            religion: "",
            name: "",
          });
          fetchData();
          handleClose();
          setValidated(false);
        } else {
          setAddCredentials({ ...addCredentials, error: result.message });
        }
        setLoading(false);
      })
      .catch((error) => console.log("error", error));
  };

  const fetchData = () => {
    var requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    fetch(
      `${process.env.REACT_APP_API_BASE_URL}/admin/all-employee-list`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status === true) {
          setData(result.data);
        }
      })
      .catch((error) => console.log("error", error));
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleUserClick = (userId, Id) => {
    navigate(`/employee-details/${userId}`);
  };

  const handleDepartmentChange = (selectedOption) => {
    const selectedValue = selectedOption.value;
    setAddCredentials((prevState) => ({
      ...prevState,
      department_id: selectedValue,
    }));
  };

  const [currentPage, setCurrentPage] = useState(1);
  const projectsPerPage = 9;
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const totalPages = Math.ceil(data.length / projectsPerPage);
  const indexOfLastProject = currentPage * projectsPerPage;
  const indexOfFirstProject = indexOfLastProject - projectsPerPage;
  const currentProjects = data.slice(indexOfFirstProject, indexOfLastProject);


  return (
    <section className="left_width" style={{ marginLeft: `${menuWidth}px` }}>
      <div className="d-lg-flex justify-content-between align-items-center pt-4  pb-3 w-100">
        <h4>All Employees</h4>
        <div>
          <button className="btn btn-success puch_btn" onClick={handleShow}>
            Add Employee
          </button>
        </div>
      </div>
    {!loading ? (
  <>
    {currentProjects.length > 0 ? (
      <div className="row">
        {currentProjects.map((item) => (
          <div
            key={item.id}
            className="col-lg-4 col-md-4 col-sm-6 col-xs-12"
          >
            <div className="box_shadow mt-3 position-relative py-3">
              <div className="ribbon">
                {item.status === 1 ? (
                  <span>
                    <div className="bg-primary">Active</div>
                  </span>
                ) : item.status === 2 ? (
                  <span>
                    <div className="bg-danger">Inactive</div>
                  </span>
                ) : (
                  <span>
                    <div className="bg-primary">Active</div>
                  </span>
                )}
              </div>

              {item.photo ? (
                <img
                  src={item.photo}
                  className="name_width m-auto"
                  alt={item.name}
                />
              ) : (
                <h5 className="name_width m-auto text-uppercase">
                  {item.first_name.charAt(0)}
                </h5>
              )}
              <h6 className="my-3 text-uppercase">
                {item.first_name}&nbsp;{item.last_name}
              </h6>
              <p className="small mt-2 mb-2"> {item.designation_id}</p>
              <p className="admin_left_font mb-2">{item.employee_id}</p>
              {item.employee_ment_id === "Probation" ? (
                <span className="rounded-pill btn-warning py-1 px-2 text-white p_css">
                  Probation
                </span>
              ) : item.employee_ment_id === "Permanent" ? (
                <span className="rounded-pill btn-primary py-1 px-2 text-white p_css">
                  Permanent
                </span>
              ) : (
                <span className="rounded-pill btn-danger py-1 px-2 text-white p_css">
                  Terminated
                </span>
              )}
              <div className="mb-1 d-flex justify-content-between mt-2">
                <div className="leave_css1">
                  Benefit <br />
                  {item.banefit_leave}
                </div>
                <div className="leave_css2">
                  Apply leave <br />
                  {item.apply_leave}
                </div>
                <div className="leave_css3">
                  Pending & Extra <br /> {item.extra_and_pending_leave}
                </div>
              </div>

              <button
                className="btn btn-primary py-0 px-2 rounded-pill view_btn_css"
                onClick={() => handleUserClick(item.id)}
              >
                View Details
              </button>
            </div>
          </div>
        ))}
    <Pagination className="mt-2 float-right mb-3" style={{ marginBottom: '20px' }}>
      <Pagination.First onClick={() => handlePageChange(1)} />
      <Pagination.Prev
        onClick={() => handlePageChange(currentPage - 1)}
        disabled={currentPage === 1}
      />
      {[...Array(totalPages)].map((_, index) => {
        // Display only a fixed number of page numbers before and after the current page
        if (
          index + 1 === 1 || // Display the first page
          index + 1 === totalPages || // Display the last page
          index + 1 === currentPage || // Display the current page
          index + 1 === currentPage - 1 || // Display the page before the current page
          index + 1 === currentPage + 1 // Display the page after the current page
        ) {
          return (
            <Pagination.Item
              key={index + 1}
              active={index + 1 === currentPage}
              onClick={() => handlePageChange(index + 1)}
            >
              {index + 1}
            </Pagination.Item>
          );
        } else if (
          index + 1 === currentPage - 2 || // Display ellipsis two pages before the current page
          index + 1 === currentPage + 2 // Display ellipsis two pages after the current page
        ) {
          return <Pagination.Ellipsis key={index + 1} />;
        }
        return null;
      })}
      <Pagination.Next
        onClick={() => handlePageChange(currentPage + 1)}
        disabled={currentPage === totalPages}
      />
      <Pagination.Last onClick={() => handlePageChange(totalPages)} />
    </Pagination>
    </div>

) : (
  <div className="pt-5 pb-5 text-center w-100">
    <img src="/no_data.svg" alt="no data" width="150px" />
    <h6>Nothing to show here</h6>
    <p className="small text-secondary">
      Please add a new entity or manage the data table to see the content here <br />
      Thank you
    </p>
  </div>
)}
 </>
) : (
  <div className="spinner_height">
    <div className="loader"></div>
  </div>
)}

      {/* -------------------Add Employee---------------------------- */}

      <Modal show={show} onHide={handleClose} size="lg">
        <Modal.Header closeButton className="p-4">
          <Modal.Title>Add Employee</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form noValidate validated={validated} onSubmit={handleAddEmployee}>
            <div className="d-lg-flex justify-content-between">
              <Form.Group
                className="mb-3 w-100"
                controlId="exampleForm.ControlTextarea1"
              >
                <Form.Label>First Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter first name"
                  value={addCredentials.first_name}
                  onChange={(e) => {
                    setAddCredentials((prevState) => ({
                      ...prevState,
                      first_name: e.target.value,
                    }));
                  }}
                  required
                />
                <Form.Control.Feedback type="invalid">
                  Please enter first name.
                </Form.Control.Feedback>
              </Form.Group>
              &nbsp;&nbsp;&nbsp;
              <Form.Group
                className="mb-3 w-100"
                controlId="exampleForm.ControlTextarea1"
              >
                <Form.Label>Last Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter last name"
                  value={addCredentials.last_name}
                  onChange={(e) => {
                    setAddCredentials((prevState) => ({
                      ...prevState,
                      last_name: e.target.value,
                    }));
                  }}
                  required
                />
                <Form.Control.Feedback type="invalid">
                  Please enter last name.
                </Form.Control.Feedback>
              </Form.Group>
            </div>
            <div className="d-lg-flex justify-content-between">
              <Form.Group
                className="mb-3 w-100"
                controlId="exampleForm.ControlTextarea1"
              >
                <Form.Label>User Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter user name"
                  value={addCredentials.name}
                  onChange={(e) => {
                    setAddCredentials((prevState) => ({
                      ...prevState,
                      name: e.target.value,
                    }));
                  }}
                  required
                />
                <Form.Control.Feedback type="invalid">
                  Please enter user name.
                </Form.Control.Feedback>
              </Form.Group>
              &nbsp;&nbsp;&nbsp;
              <Form.Group
                className="mb-3 w-100"
                controlId="exampleForm.ControlTextarea1"
              >
                <Form.Label>Joining date</Form.Label>
                <Form.Control
                  type="date"
                  placeholder="Enter joining date"
                  value={addCredentials.joining_date}
                  onChange={(e) => {
                    setAddCredentials((prevState) => ({
                      ...prevState,
                      joining_date: e.target.value,
                    }));
                  }}
                  required
                />
                <Form.Control.Feedback type="invalid">
                  Please enter joining date.
                </Form.Control.Feedback>
              </Form.Group>
            </div>
            <div className="d-lg-flex justify-content-between">
              <Form.Group
                className="mb-3 w-100"
                controlId="exampleForm.ControlTextarea1"
              >
                <Form.Label>Email</Form.Label>
                <Form.Control
                  type="Email"
                  placeholder="Enter email"
                  value={addCredentials.email}
                  onChange={(e) => {
                    setAddCredentials((prevState) => ({
                      ...prevState,
                      email: e.target.value,
                    }));
                  }}
                  required
                />
                <Form.Control.Feedback type="invalid">
                  Please enter email address.
                </Form.Control.Feedback>
              </Form.Group>
              &nbsp;&nbsp;&nbsp;
              <Form.Group
                className="mb-3 w-100"
                controlId="exampleForm.ControlTextarea1"
              >
                <Form.Label>Personal Email</Form.Label>
                <Form.Control
                  type="Email"
                  placeholder="Enter email"
                  value={addCredentials.personal_email}
                  onChange={(e) => {
                    setAddCredentials((prevState) => ({
                      ...prevState,
                      personal_email: e.target.value,
                    }));
                  }}
                  required
                />
                <Form.Control.Feedback type="invalid">
                  Please enter personal email address.
                </Form.Control.Feedback>
              </Form.Group>
            </div>

            <div className="d-lg-flex justify-content-between">
              <Form.Group
                className="mb-3 w-100"
                controlId="exampleForm.ControlTextarea1"
              >
                <Form.Label>Contact Number</Form.Label>
                <Form.Control
                  type="number" 
                  maxLength={10}
                  placeholder="Enter Contact Number"
                  value={addCredentials.contact_number}
                  onChange={(e) => {
                    setAddCredentials((prevState) => ({
                      ...prevState,
                      contact_number: e.target.value,
                    }));
                  }}
                  required
                />
                <Form.Control.Feedback type="invalid">
                  Please enter contact number.
                </Form.Control.Feedback>
              </Form.Group>
              &nbsp;&nbsp;&nbsp;
              <Form.Group
                className="mb-3 w-100"
                controlId="exampleForm.ControlTextarea1"
              >
                <Form.Label>Emergency Contact Number</Form.Label>
                <Form.Control
                    type="number" 
                    maxLength={10}
                  placeholder="Enter Emergency Contact"
                  value={addCredentials.emergency_contact}
                  onChange={(e) => {
                    setAddCredentials((prevState) => ({
                      ...prevState,
                      emergency_contact: e.target.value,
                    }));
                  }}
                  required
                />
                <Form.Control.Feedback type="invalid">
                  Please enter emergency contact number.
                </Form.Control.Feedback>
              </Form.Group>
            </div>

            <div className="d-lg-flex justify-content-between">
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlTextarea1"
              >
                <Form.Label>Gender</Form.Label>
                <div className="d-flex">
                  <Form.Check
                    type="radio"
                    label="Male"
                    name="gender"
                    value={1}
                    checked={addCredentials.gander === 1}
                    onChange={(e) => {
                      setAddCredentials((prevState) => ({
                        ...prevState,
                        gander: parseInt(e.target.value, 10),
                      }));
                    }}
                    required
                  />
                  &nbsp;&nbsp;
                  <Form.Check
                    type="radio"
                    label="Female"
                    name="gender"
                    value={2}
                    checked={addCredentials.gander === 2}
                    onChange={(e) => {
                      setAddCredentials((prevState) => ({
                        ...prevState,
                        gander: parseInt(e.target.value, 10),
                      }));
                    }}
                    required
                  />
                  <Form.Control.Feedback type="invalid">
                    Please select gender.
                  </Form.Control.Feedback>
                </div>
              </Form.Group>

              <Form.Group
                className="mb-3 w-50"
                controlId="exampleForm.ControlTextarea1"
              >
                <Form.Label>Marital Status</Form.Label>
                <div className="d-flex">
                  <Form.Check
                    type="radio"
                    label="Unmarried "
                    name="unmarried"
                    value={1}
                    checked={addCredentials.marital_status === 1}
                    onChange={(e) => {
                      setAddCredentials((prevState) => ({
                        ...prevState,
                        marital_status: parseInt(e.target.value, 10),
                      }));
                    }}
                    required
                  />
                  &nbsp;&nbsp;
                  <Form.Check
                    type="radio"
                    label="Married"
                    name="unmarried"
                    value={2}
                    checked={addCredentials.marital_status === 2}
                    onChange={(e) => {
                      setAddCredentials((prevState) => ({
                        ...prevState,
                        marital_status: parseInt(e.target.value, 10),
                      }));
                    }}
                    required
                  />
                  <Form.Control.Feedback type="invalid">
                    Please select Marital Status.
                  </Form.Control.Feedback>
                </div>
              </Form.Group>
            </div>
            <div className="d-lg-flex justify-content-between">
              <Form.Group
                className="mb-3 w-100"
                controlId="exampleForm.ControlTextarea1"
              >
                <Form.Label>Department</Form.Label>
                <Select
                  options={departments.map((item) => ({
                    value: item.id,
                    label: item.name,
                  }))}
                  styles={customStyles}
                  placeholder="Choose a department"
                  onChange={handleDepartmentChange}
                  className="text-uppercase search_select_css"
                  required
                />
                <Form.Control.Feedback type="invalid">
                  Please select department.
                </Form.Control.Feedback>
              </Form.Group>
              &nbsp;&nbsp;&nbsp;
              <Form.Group
                className="mb-3  w-100"
                controlId="exampleForm.ControlTextarea1"
              >
                <Form.Label>Designation</Form.Label>
                <Form.Select
                  aria-label="Default select example"
                  value={addCredentials.designation_id}
                  onChange={(e) => {
                    setAddCredentials((prevState) => ({
                      ...prevState,
                      designation_id: e.target.value,
                    }));
                  }}
                  required
                  className="text-uppercase search_select_css"
                >
                  <option value="" disabled selected>
                    Choose a designation
                  </option>
                  {designations.map((item) => (
                    <option key={item.id} value={item.id}>
                      {item.name}
                    </option>
                  ))}
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                  Please select desigination.
                </Form.Control.Feedback>
              </Form.Group>
            </div>

            <div className="d-lg-flex justify-content-between">
              <Form.Group
                className="mb-3  w-100"
                controlId="exampleForm.ControlTextarea1"
              >
                <Form.Label>Employment Status</Form.Label>
                <Form.Select
                  aria-label="Default select example"
                  value={addCredentials.employee_ment_id}
                  onChange={(e) => {
                    setAddCredentials((prevState) => ({
                      ...prevState,
                      employee_ment_id: e.target.value,
                    }));
                  }}
                  required
                  className="text-uppercase search_select_css"
                >
                  <option value="" disabled selected>
                    Choose an employment status
                  </option>
                  {employment_statuses.map((item) => (
                    <option key={item.id} value={item.id}>
                      {item.name}
                    </option>
                  ))}
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                  Please select employment status.
                </Form.Control.Feedback>
              </Form.Group>
              &nbsp;&nbsp;&nbsp;
              <Form.Group
                className="mb-3 w-100"
                controlId="exampleForm.ControlTextarea1"
              >
                <Form.Label>Role</Form.Label>
                {/* <Select
                  // isMulti
                  options={roles.map((item) => ({
                    value: item.id,
                    label: item.role,
                  }))}
                  styles={customStyles}
                  placeholder="Select role"
                  onChange={(selectedOptions) => {
                    const selectedValues = selectedOptions.map(
                      (option) => option.value
                    );
                    setAddCredentials((prevState) => ({
                      ...prevState,
                      role_id: selectedValues,
                    }));
                  }}
                  required
                  className="text-uppercase search_select_css"
                /> */}

<Form.Select
                  aria-label="Default select example"
                  value={addCredentials.role_id}
                  onChange={(e) => {
                    setAddCredentials((prevState) => ({
                      ...prevState,
                      role_id: e.target.value,
                    }));
                  }}
                  required
                  className="text-uppercase"
                >
                  <option value="" disabled selected>
                    Choose an Role
                  </option>
                  {roles.map((item) => (
                    <option key={item.id} value={item.id}>
                      {item.role}
                    </option>
                  ))}
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                  Please select Role.
                </Form.Control.Feedback>
              </Form.Group>
            </div>
            <div className="d-lg-flex justify-content-between">
              <Form.Group
                className="mb-3 w-100"
                controlId="exampleForm.ControlTextarea1"
              >
                <Form.Label>Date of Birth</Form.Label>
                <Form.Control
                  type="Date"
                  placeholder="Enter D.O.B."
                  value={addCredentials.date_of_birth}
                  onChange={(e) => {
                    setAddCredentials((prevState) => ({
                      ...prevState,
                      date_of_birth: e.target.value,
                    }));
                  }}
                  required
                />
                <Form.Control.Feedback type="invalid">
                  Please enter D.O.B.
                </Form.Control.Feedback>
              </Form.Group>
              &nbsp;&nbsp;&nbsp;
              <Form.Group
                className="mb-3 w-100"
                controlId="exampleForm.ControlTextarea1"
              >
                <Form.Label>Religion</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter religion"
                  value={addCredentials.religion}
                  onChange={(e) => {
                    setAddCredentials((prevState) => ({
                      ...prevState,
                      religion: e.target.value,
                    }));
                  }}
                  required
                />
                <Form.Control.Feedback type="invalid">
                  Please enter religion.
                </Form.Control.Feedback>
              </Form.Group>
            </div>
            <div className="d-lg-flex justify-content-between">
              <Form.Group
                className="mb-3 w-100"
                controlId="exampleForm.ControlTextarea1"
              >
                <Form.Label>Salary</Form.Label>
                <Form.Control
                  type="number"
                  placeholder="Enter salary"
                  value={addCredentials.sallery}
                  onChange={(e) => {
                    setAddCredentials((prevState) => ({
                      ...prevState,
                      sallery: e.target.value,
                    }));
                  }}
                  required
                />
                <Form.Control.Feedback type="invalid">
                  Please enter salery.
                </Form.Control.Feedback>
              </Form.Group>
           
            </div>
            {addCredentials.error && <Alert variant="danger">{addCredentials.error}</Alert>}
            <Modal.Footer className="">
                <button
                  className="btn btn-secondary puch_btn"
                  onClick={handleClose}
                  type="button"
                >
                  Cancel
                </button>
                &nbsp; &nbsp;
                {! loading ? 
                <button className="btn btn-primary puch_btn" type="submit">
                  Add
                </button>
                :
                <button className="btn btn-primary puch_btn" disabled>
               <Spinner
                    animation="grow"
                    size="sm"
                    style={{ background: "#fff" }}
                  />
                  &nbsp;
                  <Spinner
                    animation="grow"
                    size="sm"
                    style={{ background: "#fff" }}
                  />
              </button> }
            </Modal.Footer>
          </Form>
        </Modal.Body>
      </Modal>
    </section>
  );
};
export default EmployeeList;
