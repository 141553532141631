import React from 'react'
import { FcOk } from "react-icons/fc";
const LeaveAprove = () => {
  return (

    <div className='text-center d-flex justify-content-center align-items-center'  style={{height: "100vh",  }}>
        <div>
        <FcOk  style={{fontSize: "60px",  }}/>
        <p>Email Approved successfully</p>
        <a href="/" className='btn btn-primary'>Login</a>
        </div>
        </div>
  )
}

export default LeaveAprove;