import React, { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import Select from "react-select";

const Summary = ({ menuWidth }) => {
  const [singleday, setSingleday] = useState(true);
  const [showAvailability, setShowAvailability] = useState(false);
  const [showLeave, setShowLeave] = useState(false);

  const toggleMultiday = () => {
    setSingleday(!singleday);
  };

  //   const toggleSingleday = () => {
  //     setMultiday(!multiday);
  //   };

  const tonggleAvailability = () => {
    setShowAvailability(!showAvailability);
  };
  const handleCloseleave = () => setShowLeave(false);

  const [selectedEmployeeId, setSelectedEmployeeId] = useState(null);
  const [data, setData] = useState([]);
  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    var requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    fetch(
      `${process.env.REACT_APP_API_BASE_URL}/admin/all-employee-list`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status === true) {
          setData(result.data);
        }
      })
      .catch((error) => console.log("error", error));
  };

  const [leaveSummary, setLeaveSummary] = useState([]);

  useEffect(() => {
    if (selectedEmployeeId) {
      const requestOptions = {
        method: "GET",
        redirect: "follow",
      };

      fetch(
        `${process.env.REACT_APP_API_BASE_URL}/admin/get_leave_summary?user_id=${selectedEmployeeId}&timeline&custom_date=`,
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          if (result.status === true) {
            setLeaveSummary(result.data);
          }
        })
        .catch((error) => console.error(error));
    }
  }, [selectedEmployeeId]);
  
  const handleSelectProject = (selectedOption) => {
    const selectedValue = selectedOption.value;
    //  const selectedUserId = e.target.value;
    setLeaveSummary((prevState) => ({
      ...prevState,
      user_id: selectedValue,
    }));
    setSelectedEmployeeId(selectedValue);
  };
  const customStyles = {
    control: (base, state) => ({
      ...base,
      boxShadow: state.isFocused
        ? "0 0 0 0.1rem rgba(0, 123, 255, 0.25)"
        : null,
      borderColor: state.isFocused ? "#007bff" : base.borderColor,
    }),
  };
  const reload = () => {
    window.location.reload();
  };
  return (
    <section
      className="left_width p-2"
      style={{ marginLeft: `${menuWidth}px` }}
    >
      <div className="d-lg-flex justify-content-between align-items-center pt-4 pb-3 w-100">
        <h4 className="m-0">Leave Summary</h4>
      </div>

      <div className="employee_table p-4 text-center h-auto">
        <div>
          <div className="row justify-content-between align-items-center">
            <div className="col-lg-11">
              <Select
                options={data.map((item) => ({
                  value: item.id,
                  label: `${item.first_name} ${item.last_name}`,
                }))}
                styles={customStyles}
                placeholder="Select a Employee"
                onChange={handleSelectProject}
                className="text-uppercase search_select_css"
                style={{ zIndex: "999" }}
                required
              />
            </div>
            <div className="col-lg-1">
            <button className="btn btn-primary puch_btn" onClick={reload}>
              Reset
            </button>
            </div>
          </div>
          <div
            className="table-responsive custom-scrollbar table-view-responsive py-primary"
            style={{ zIndex: "-1" }}
          >
            <table className="table mb-0">
              <thead>
                <tr>
                  <th
                    track-by="1"
                    className="datatable-th py-4 px-3 table_color"
                  >
                    <span className="font-size-default">
                      <span>Date & time</span>
                    </span>
                  </th>
                  <th
                    track-by="2"
                    className="datatable-th py-4 px-3 table_color"
                  >
                    <span className="font-size-default">
                      <span>Leave duration</span>
                    </span>
                  </th>
                  <th
                    track-by="3"
                    className="datatable-th py-4 px-3 table_color text-right"
                  >
                    <span className="font-size-default">Leave Type</span>
                  </th>
                  <th
                    track-by="3"
                    className="datatable-th py-4 px-3 table_color text-right"
                  >
                    <span className="font-size-default">Note</span>
                  </th>
                  <th
                    track-by="3"
                    className="datatable-th py-4 px-3 table_color text-right"
                  >
                    <span className="font-size-default">Apply Date</span>
                  </th>
                  <th
                    track-by="0"
                    className="datatable-th py-4 px-3 table_color "
                  >
                    <span className="font-size-default">
                      <span>Status</span>
                    </span>
                  </th>
                </tr>
              </thead>
              {leaveSummary && leaveSummary.length > 0 ? (
                <tbody>
                  {Array.isArray(leaveSummary) &&
                    leaveSummary.map((item, index) => (
                      <tr key={item.id}>
                        <td>{item.date}</td>
                        <td>{item.leave_duration}</td>
                        <td>{item.leave_type}</td>
                        <td>{item.note}</td>
                        <td>{item.created_at}</td>
                        <td>
                          <span>
                            <span
                              className={`rounded-pill py-1 px-2 text-white p_css ${
                                item.status === "Pending"
                                  ? "bg-warning"
                                  : item.status === "Approved"
                                  ? "bg-success"
                                  : item.status === "Rejected"
                                  ? "bg-danger"
                                  : " "
                              }`}
                            >
                              {item.status}
                            </span>
                          </span>
                        </td>
                      </tr>
                    ))}
                </tbody>
              ) : (
                // <tbody>
                //   <tr>
                //     <td colSpan={6}>First select employee</td>
                //   </tr>
                // </tbody>
                <tbody className="pt-5 pb-5">
                  <tr className="text-center">
                    <td colSpan="8" className="text-center">
                      <div>
                        <img src="/no_data.svg" alt="no data" width="150px" />
                        <h6>Nothing to show here</h6>
                        <p className="small text-secondary">
                          Please add a new entity or manage the data table to
                          see the content here. Thank you.
                        </p>
                      </div>
                    </td>
                  </tr>
                </tbody>
              )}
            </table>
          </div>
        </div>
      </div>
      {/*---------------Assign Leave-------------------------*/}
      <Modal show={showLeave} onHide={handleCloseleave} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Assign leave</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <p>
              Leave availability{" "}
              {showAvailability === true ? (
                <span className="text-primary" onClick={tonggleAvailability}>
                  Hide
                </span>
              ) : (
                <span className="text-primary" onClick={tonggleAvailability}>
                  Show
                </span>
              )}
            </p>
            {showAvailability && (
              <div>No available leaves for this employee</div>
            )}
            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlTextarea1"
            >
              <Form.Label>Leave Type</Form.Label>
              <Form.Select aria-label="Default select example">
                <option>Chose a Teave Type</option>
                <option value="1">One</option>
                <option value="2">Two</option>
                <option value="3">Three</option>
              </Form.Select>
            </Form.Group>

            <Form.Group className="mb-3 d-flex">
              <Form.Label>Age (Leave duration)</Form.Label>

              <Form.Check
                type="radio"
                label="Single Day"
                name="singleday"
                onClick={toggleMultiday}
              />

              <Form.Check
                type="radio"
                label="Multi Day"
                name="singleday"
                onClick={toggleMultiday}
              />
              <Form.Check
                type="radio"
                label="Half Day"
                name="singleday"
                onClick={toggleMultiday}
              />
              <Form.Check type="radio" label="Hours" name="singleday" />
            </Form.Group>

            {singleday === true ? (
              <div>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlTextarea1"
                >
                  <Form.Label>Date</Form.Label>
                  <Form.Control type="date" placeholder="" />
                </Form.Group>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlTextarea1"
                >
                  <Form.Label>Reason note</Form.Label>
                  <Form.Control
                    as="textarea"
                    placeholder="Enter note"
                    rows={3}
                  />
                </Form.Group>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlTextarea1"
                >
                  <Form.Label>Attachments</Form.Label>
                  <Form.Control type="file" placeholder="Upload File" />
                </Form.Group>
              </div>
            ) : (
              <div>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlTextarea1"
                >
                  <Form.Label>Start Date</Form.Label>
                  <Form.Control type="date" placeholder="" />
                </Form.Group>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlTextarea1"
                >
                  <Form.Label>End Date</Form.Label>
                  <Form.Control type="date" placeholder="" />
                </Form.Group>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlTextarea1"
                >
                  <Form.Label>Reason note</Form.Label>
                  <Form.Control
                    as="textarea"
                    placeholder="Enter note"
                    rows={3}
                  />
                </Form.Group>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlTextarea1"
                >
                  <Form.Label>Attachments</Form.Label>
                  <Form.Control type="file" placeholder="Upload File" />
                </Form.Group>
              </div>
            )}
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-secondary puch_btn"
            onClick={handleCloseleave}
          >
            Cancel
          </button>
          <button
            className="btn btn-primary puch_btn"
            onClick={handleCloseleave}
          >
            Save
          </button>
        </Modal.Footer>
      </Modal>
    </section>
  );
};

export default Summary;
