import { useLayoutEffect } from 'react'
import { useAuth } from './Context';

const AutoLogout = () => {
    const { setIsLoggedIn } = useAuth()
    useLayoutEffect(() => {
        const checkLoginStatus = () => {
            const loggedIn = sessionStorage.getItem('isLoggedIn') === 'true';
            const ssloggedIn = localStorage.getItem('isLoggedIn') === 'true';
            if (loggedIn) {
                setIsLoggedIn(true);
                localStorage.setItem('isLoggedIn', 'true');
            }
            if (!loggedIn && ssloggedIn) {
                setIsLoggedIn(true);
                localStorage.setItem('isLoggedIn', 'true');
                sessionStorage.setItem('isLoggedIn', 'true');
            }
        };

        const handleBeforeUnload = () => {
            if (sessionStorage.getItem('isLoggedIn') === 'true') {
                localStorage.removeItem('isLoggedIn');
            }
        };

        checkLoginStatus();
        window.addEventListener('beforeunload', handleBeforeUnload);

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, [setIsLoggedIn]);
    return null
}

export default AutoLogout;