import React, { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import Tab from "react-bootstrap/Tab";
import Nav from "react-bootstrap/Nav";
import { HiOutlineDownload } from "react-icons/hi";
// import { useAuth } from "../utils/Context";
import Select from "react-select";
import { Alert, Spinner } from "react-bootstrap";

const Payslip = ({ menuWidth }) => {

  // const { id } = useAuth();
  const [show, setShow] = useState(false);
  const [accept, setAccept] = useState(false);
  const handleAcceptClose = () => setAccept(false);
  const handleAcceptShow = () => setAccept(true);
  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);
  const [start_date, setStartDate] = useState("");
  const [end_date, setEndDate] = useState("");
  const [loadingId, setLoadingId] = useState(null);
  const handleCloseAddpayslip = () => {
    setAddPayslipPopup(false);
    setValidated(false);
    setAddPayslip({
      user_id: "",
      pf: "",
      leave: "",
      month: "",
      custome_price: "",
      basic_sallery: "",
      error:""
    });
  };

  const [addPayslipPopup, setAddPayslipPopup] = useState();
  const handleAddpayslip = () => setAddPayslipPopup(true);

  const [validated, setValidated] = useState(false);
  // const [viewPayslip, setViewPayslip] = useState({});
  const [listPayslip, setListPayslip] = useState([]);

  // useEffect(() => {
  //   var requestOptions = {
  //     method: "GET",
  //     redirect: "follow",
  //   };
  //   fetch(
  //     `https://hrm.bigzero.co.in/backend/api/admin/get_user_payslip?user_id=${id}&time_duration=${search}`,
  //     requestOptions
  //   )
  //     .then((response) => response.json())
  //     .then((result) => {
  //       if (result.status === true) {
  //         setViewPayslip(result.data);
  //       }
  //     })
  //     .catch((error) => console.log("error", error));
  // }, [id]);

  const [data, setData] = useState([]);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    var requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    fetch(
      `${process.env.REACT_APP_API_BASE_URL}/admin/all-employee-list`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status === true) {
          setData(result.data);
        }
      })
      .catch((error) => console.log("error", error));
  };

  const [addPayslip, setAddPayslip] = useState({
    user_id: "",
    pf: "",
    leave: "",
    month: "",
    custome_price: "",
    basic_sallery: "",
  });

  const handleAddSlip = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }

    setValidated(true);
    setLoading(true);
    var formdata = new FormData();
    formdata.append("user_id", addPayslip.user_id);
    formdata.append("pf", addPayslip.pf);
    formdata.append("leave", addPayslip.leave);
    formdata.append("month", addPayslip.month);
    formdata.append("custome_price", addPayslip.custome_price);
    formdata.append("basic_sallery", addPayslip.basic_sallery);

    var requestOptions = {
      method: "POST",
      body: formdata,
      redirect: "follow",
    };

    fetch(
      `${process.env.REACT_APP_API_BASE_URL}/admin/add_pay_slip`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status === true) {
          handleCloseAddpayslip();
          setValidated(false);
          setLoading(false);
        } else {
          setLoading(false);
          setAddPayslip({ ...addPayslip, error: result.message });
        }
      })
      .catch((error) => console.log("error", error));
  };

  const downloadPdf = (id, user_id, name, create_payslip) => {
    setLoadingId(id);
    setLoading(true);
    var requestOptions = {
      method: "GET",
      responseType: "blob",
      redirect: "follow",
    };
  
    fetch(
      `${process.env.REACT_APP_API_BASE_URL}/admin/get_payslip_pdf?user_id=${user_id}&payslip_id=${id}`,
      requestOptions
    )
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.blob();
      })
      .then((blob) => {
        const url = window.URL.createObjectURL(blob);
        window.open(url, "_blank"); // Open the PDF in a new tab
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error downloading PDF:", error);
      });
  };
  
  const [loading1, setLoading1] = useState(false);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState("thismonth");

  useEffect(() => {
    var requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    fetch(
      `${process.env.REACT_APP_API_BASE_URL}/admin/get_payslips?user_id=&time_duration=${search}&start_date=${start_date}&end_date=${end_date}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status === true) {
          setListPayslip(result.data);
        }
      })
      .catch((error) => console.log("error", error));
  }, [search, start_date, end_date]);

  // const [error, setError] = useState(null);

  const handleExportExcel = () => {
    setLoading(true);
    // setError(null);

    fetch(`${process.env.REACT_APP_API_BASE_URL}/admin/get_payslip_excel`)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }
        return response.blob();
      })
      .then((blob) => {
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "payslip.xlsx");
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => {
        console.error("Error exporting Excel file:", error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const sendEmail = (user_id, id) => {
    setLoadingId(id);
    setLoading1(true);
    var formdata = new FormData();
    formdata.append("user_id", user_id);
    formdata.append("payslip_id", id);
    var requestOptions = {
      method: "POST",
      body: formdata,
      redirect: "follow",
    };

    fetch(
      `${process.env.REACT_APP_API_BASE_URL}/admin/send_payroll_pdf_mail_user`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status === true) {
          handleAcceptShow();
          setLoading1(false);
        }
      })
      .catch((error) => console.error("Error sending email:", error));
  };

  const customStyles = {
    control: (base, state) => ({
      ...base,
      boxShadow: state.isFocused
        ? "0 0 0 0.1rem rgba(0, 123, 255, 0.25)"
        : null,
      borderColor: state.isFocused ? "#007bff" : base.borderColor,
    }),
  };

  const handleEmployeeChange = (selectedOption) => {
    const selectedValue = selectedOption.value;
    setAddPayslip((prevState) => ({
      ...prevState,
      user_id: selectedValue,
    }));
  };


  const handleTabChange = (selectedKey) => {
    setSearch(selectedKey);
    setStartDate(""); // Reset start date when changing tabs
    setEndDate(""); // Reset end date when changing tabs
  };

  const formatDate = (dateString) => {
    if (!dateString) return "Not added yet";

    // Parse the date string into a Date object
    const date = new Date(dateString);

    // Define an array of month names
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    // Extract day, month, and year

    const month = monthNames[date.getMonth()];
    const year = date.getFullYear();

    // Format the date as desired
    const formattedDate = `${month}, ${year}`;

    return formattedDate;
  };

  return (
    <section className="left_width" style={{ marginLeft: `${menuWidth}px` }}>
      <div className="d-lg-flex justify-content-between align-items-center pt-4 pb-3 w-100">
        <h4 className="m-0">Payslip</h4>
        <div>
          <button
            className="btn btn-success puch_btn"
            onClick={handleAddpayslip}
           >
            Add Payslip
          </button>
          &nbsp; &nbsp;
          <button className="btn btn-success puch_btn" onClick={handleShow}>
            Export All
          </button>
        </div>
      </div>
      <div className="employee_table p-4 text-center">
        <div>
          <Tab.Container id="left-tabs-example" defaultActiveKey="thismonth">
            <div className="d-lg-flex justify-content-end ">
              {/* <div className="d-lg-flex justify-content-between align-items-center">
                From
                <Form.Control
                  type="date"
                  className="mx-2 h-75"
                  value={start_date}
                  onChange={(e) => setStartDate(e.target.value)}
                />
                To
                <Form.Control
                  type="date"
                  className="mx-2 h-75"
                  value={end_date}
                  onChange={(e) => setEndDate(e.target.value)}
                />
              </div> */}
              <Nav
                variant="pills"
                className="d-flex"
                onSelect={handleTabChange}
              >
                <Nav.Item>
                  <Nav.Link eventKey="thismonth" className="tab_css_leave">
                    This month
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="lastmonth" className="tab_css_leave">
                    Last month
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="thisyear" className="tab_css_leave">
                    This year
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="lastyear" className="tab_css_leave">
                    Last year
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="total" className="tab_css_leave">
                    Total
                  </Nav.Link>
                </Nav.Item>
              </Nav>
            </div>
            <Tab.Content>
              <Tab.Pane eventKey="thismonth">
              <div className="table-responsive custom-scrollbar table-view-responsive py-primary">
                  <table className="table mb-0">
                    <thead>
                      <tr>
                      <th className="py-4 px-3 table_color ">Name</th>
                        <th className="py-4 px-3 table_color">Month</th>
                        <th className="py-4 px-3 table_color">Leave</th>
                        <th className="py-4 px-3 table_color text-right">PF</th>
                        <th className="py-4 px-3 table_color text-right">Other</th>  
                        <th className="py-4 px-3 table_color text-right">Create date</th>
                        <th className="py-4 px-3 table_color text-right">Salary</th>
                        <th className="py-4 px-3 table_color text-right">Net salary</th>
                        <th className="py-4 px-3 table_color text-right">Details</th>
                        <th className="py-4 px-3 table_color text-right">Email</th>
                      </tr>
                    </thead>
                    {listPayslip.length > 0 ? (
                      <tbody>
                        {listPayslip &&
                          listPayslip.map((item) => (
                            <tr key={item.id}>
                              <td>{item.name}</td>
                              <td>{formatDate(item.month)}</td>
                              <td>{item.leave}</td>
                              <td>{item.pf}</td>
                              <td>{item.custome_price}</td>
                              <td>{item.create_payslip}</td>
                              <td>{item.basic_sallery}</td>
                              <td>{item.pay_total}</td>
                              <td>
                                <button
                                  type="button"
                                  className="btn btn-secondary puch_btn"
                                  onClick={() =>
                                    downloadPdf(
                                      item.id,
                                      item.user_id,
                                      item.name,
                                      item.create_payslip
                                    )
                                  }
                                  disabled={loading && item.id === loadingId}
                                >
                                  {loading && item.id === loadingId ? (
                                    <>
                                      <Spinner
                                        animation="grow"
                                        size="sm"
                                        style={{ background: "#fff" }}
                                      />
                                      &nbsp;
                                      <Spinner
                                        animation="grow"
                                        size="sm"
                                        style={{ background: "#fff" }}
                                      />
                                    </>
                                  ) : (
                                    "View"
                                  )}
                                </button>
                              </td>
                              <td>
                                {loading1 && item.id === loadingId ? (
                                  <button
                                    className="btn btn-secondary puch_btn"
                                    type="button"
                                  >
                                    <Spinner
                                      animation="grow"
                                      size="sm"
                                      style={{ background: "#fff" }}
                                    />
                                    &nbsp;
                                    <Spinner
                                      animation="grow"
                                      size="sm"
                                      style={{ background: "#fff" }}
                                    />
                                  </button>
                                ) : (
                                  <button
                                    type="button"
                                    className="btn btn-secondary puch_btn"
                                    onClick={() =>
                                      sendEmail(item.user_id, item.id)
                                    }
                                  >
                                    Send
                                  </button>
                                )}
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    ) : (
                      <tbody>
                        <tr className="text-center">
                          <td colSpan="9">
                            <span className="pt-5 pb-5">
                              <img
                                src="/no_data.svg"
                                alt="no data"
                                width="150px"
                              />
                              <h6>Nothing to show here</h6>
                              <p className="small text-secondary">
                                Please add a new entity or manage the data table
                                to see the content here <br />
                                Thank you
                              </p>
                            </span>
                          </td>
                        </tr>
                      </tbody>
                    )}
                  </table>
                </div>
              </Tab.Pane>
              <Tab.Pane eventKey="lastmonth">
              <div className="table-responsive custom-scrollbar table-view-responsive py-primary">
                  <table className="table mb-0">
                    <thead>
                      <tr>
                      <th className="py-4 px-3 table_color ">Name</th>
                        <th className="py-4 px-3 table_color">Month</th>
                        <th className="py-4 px-3 table_color">Leave</th>
                        <th className="py-4 px-3 table_color text-right">PF</th>
                        <th className="py-4 px-3 table_color text-right">Other</th>                 
                        <th className="py-4 px-3 table_color text-right">Create date</th>
                        <th className="py-4 px-3 table_color text-right">Salary</th>
                        <th className="py-4 px-3 table_color text-right">Net salary</th>
                        <th className="py-4 px-3 table_color text-right">Details</th>
                        <th className="py-4 px-3 table_color text-right">Email</th>
                      </tr>
                    </thead>
                    {listPayslip.length > 0 ? (
                      <tbody>
                        {listPayslip &&
                          listPayslip.map((item) => (
                            <tr key={item.id}>
                              <td>{item.name}</td>
                              <td>{formatDate(item.month)}</td>
                              <td>{item.leave}</td>
                              <td>{item.pf}</td>
                              <td>{item.custome_price}</td>
                              <td>{item.create_payslip}</td>
                              <td>{item.basic_sallery}</td>
                              <td>{item.pay_total}</td>
                              <td>
                                <button
                                  type="button"
                                  className="btn btn-secondary puch_btn"
                                  onClick={() =>
                                    downloadPdf(
                                      item.id,
                                      item.user_id,
                                      item.name,
                                      item.create_payslip
                                    )
                                  }
                                  disabled={loading && item.id === loadingId}
                                >
                                  {loading && item.id === loadingId ? (
                                    <>
                                      <Spinner
                                        animation="grow"
                                        size="sm"
                                        style={{ background: "#fff" }}
                                      />
                                      &nbsp;
                                      <Spinner
                                        animation="grow"
                                        size="sm"
                                        style={{ background: "#fff" }}
                                      />
                                    </>
                                  ) : (
                                    "View"
                                  )}
                                </button>
                              </td>
                              <td>
                                {loading1 && item.id === loadingId ? (
                                  <button
                                    className="btn btn-secondary puch_btn"
                                    type="button"
                                  >
                                    <Spinner
                                      animation="grow"
                                      size="sm"
                                      style={{ background: "#fff" }}
                                    />
                                    &nbsp;
                                    <Spinner
                                      animation="grow"
                                      size="sm"
                                      style={{ background: "#fff" }}
                                    />
                                  </button>
                                ) : (
                                  <button
                                    type="button"
                                    className="btn btn-secondary puch_btn"
                                    onClick={() =>
                                      sendEmail(item.user_id, item.id)
                                    }
                                  >
                                    Send
                                  </button>
                                )}
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    ) : (
                      <tbody>
                        <tr className="text-center">
                          <td colSpan="9">
                            <span className="pt-5 pb-5">
                              <img
                                src="/no_data.svg"
                                alt="no data"
                                width="150px"
                              />
                              <h6>Nothing to show here</h6>
                              <p className="small text-secondary">
                                Please add a new entity or manage the data table
                                to see the content here <br />
                                Thank you
                              </p>
                            </span>
                          </td>
                        </tr>
                      </tbody>
                    )}
                  </table>
                </div>
              </Tab.Pane>
              <Tab.Pane eventKey="thisyear">
              <div className="table-responsive custom-scrollbar table-view-responsive py-primary">
                  <table className="table mb-0">
                    <thead>
                      <tr>
                      <th className="py-4 px-3 table_color ">Name</th>
                        <th className="py-4 px-3 table_color">Month</th>
                        <th className="py-4 px-3 table_color">Leave</th>
                        <th className="py-4 px-3 table_color text-right">PF</th>   
                             <th className="py-4 px-3 table_color text-right">Other</th>  
                        <th className="py-4 px-3 table_color text-right">Create date</th>
                        <th className="py-4 px-3 table_color text-right">Salary</th>
                        <th className="py-4 px-3 table_color text-right">Net salary</th>
                        <th className="py-4 px-3 table_color text-right">Details</th>
                        <th className="py-4 px-3 table_color text-right">Email</th>
                      </tr>
                    </thead>
                    {listPayslip.length > 0 ? (
                      <tbody>
                        {listPayslip &&
                          listPayslip.map((item) => (
                            <tr key={item.id}>
                              <td>{item.name}</td>
                              <td>{formatDate(item.month)}</td>
                              <td>{item.leave}</td>
                              <td>{item.pf}</td>
                              <td>{item.pf}</td>
                              <td>{item.create_payslip}</td>
                              <td>{item.basic_sallery}</td>
                              <td>{item.pay_total}</td>
                              <td>
                                <button
                                  type="button"
                                  className="btn btn-secondary puch_btn"
                                  onClick={() =>
                                    downloadPdf(
                                      item.id,
                                      item.user_id,
                                      item.name,
                                      item.create_payslip
                                    )
                                  }
                                  disabled={loading && item.id === loadingId}
                                >
                                  {loading && item.id === loadingId ? (
                                    <>
                                      <Spinner
                                        animation="grow"
                                        size="sm"
                                        style={{ background: "#fff" }}
                                      />
                                      &nbsp;
                                      <Spinner
                                        animation="grow"
                                        size="sm"
                                        style={{ background: "#fff" }}
                                      />
                                    </>
                                  ) : (
                                    "View"
                                  )}
                                </button>
                              </td>
                              <td>
                                {loading1 && item.id === loadingId ? (
                                  <button
                                    className="btn btn-secondary puch_btn"
                                    type="button"
                                  >
                                    <Spinner
                                      animation="grow"
                                      size="sm"
                                      style={{ background: "#fff" }}
                                    />
                                    &nbsp;
                                    <Spinner
                                      animation="grow"
                                      size="sm"
                                      style={{ background: "#fff" }}
                                    />
                                  </button>
                                ) : (
                                  <button
                                    type="button"
                                    className="btn btn-secondary puch_btn"
                                    onClick={() =>
                                      sendEmail(item.user_id, item.id)
                                    }
                                  >
                                    Send
                                  </button>
                                )}
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    ) : (
                      <tbody>
                        <tr className="text-center">
                          <td colSpan="9">
                            <span className="pt-5 pb-5">
                              <img
                                src="/no_data.svg"
                                alt="no data"
                                width="150px"
                              />
                              <h6>Nothing to show here</h6>
                              <p className="small text-secondary">
                                Please add a new entity or manage the data table
                                to see the content here <br />
                                Thank you
                              </p>
                            </span>
                          </td>
                        </tr>
                      </tbody>
                    )}
                  </table>
                </div>
              </Tab.Pane>
              <Tab.Pane eventKey="lastyear">
              <div className="table-responsive custom-scrollbar table-view-responsive py-primary">
                  <table className="table mb-0">
                    <thead>
                      <tr>
                      <th className="py-4 px-3 table_color ">Name</th>
                        <th className="py-4 px-3 table_color">Month</th>
                        <th className="py-4 px-3 table_color">Leave</th>
                        <th className="py-4 px-3 table_color text-right">PF</th>
                        <th className="py-4 px-3 table_color text-right">Other</th>  
                        <th className="py-4 px-3 table_color text-right">Create date</th>
                        <th className="py-4 px-3 table_color text-right">Salary</th>
                        <th className="py-4 px-3 table_color text-right">Net salary</th>
                        <th className="py-4 px-3 table_color text-right">Details</th>
                        <th className="py-4 px-3 table_color text-right">Email</th>
                      </tr>
                    </thead>
                    {listPayslip.length > 0 ? (
                      <tbody>
                        {listPayslip &&
                          listPayslip.map((item) => (
                            <tr key={item.id}>
                              <td>{item.name}</td>
                              <td>{formatDate(item.month)}</td>
                              <td>{item.leave}</td>
                              <td>{item.pf}</td>
                              <td>{item.custome_price}</td>
                              <td>{item.create_payslip}</td>
                              <td>{item.basic_sallery}</td>
                              <td>{item.pay_total}</td>
                              <td>
                                <button
                                  type="button"
                                  className="btn btn-secondary puch_btn"
                                  onClick={() =>
                                    downloadPdf(
                                      item.id,
                                      item.user_id,
                                      item.name,
                                      item.create_payslip
                                    )
                                  }
                                  disabled={loading && item.id === loadingId}
                                >
                                  {loading && item.id === loadingId ? (
                                    <>
                                      <Spinner
                                        animation="grow"
                                        size="sm"
                                        style={{ background: "#fff" }}
                                      />
                                      &nbsp;
                                      <Spinner
                                        animation="grow"
                                        size="sm"
                                        style={{ background: "#fff" }}
                                      />
                                    </>
                                  ) : (
                                    "View"
                                  )}
                                </button>
                              </td>
                              <td>
                                {loading1 && item.id === loadingId ? (
                                  <button
                                    className="btn btn-secondary puch_btn"
                                    type="button"
                                  >
                                    <Spinner
                                      animation="grow"
                                      size="sm"
                                      style={{ background: "#fff" }}
                                    />
                                    &nbsp;
                                    <Spinner
                                      animation="grow"
                                      size="sm"
                                      style={{ background: "#fff" }}
                                    />
                                  </button>
                                ) : (
                                  <button
                                    type="button"
                                    className="btn btn-secondary puch_btn"
                                    onClick={() =>
                                      sendEmail(item.user_id, item.id)
                                    }
                                  >
                                    Send
                                  </button>
                                )}
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    ) : (
                      <tbody>
                        <tr className="text-center">
                          <td colSpan="9">
                            <span className="pt-5 pb-5">
                              <img
                                src="/no_data.svg"
                                alt="no data"
                                width="150px"
                              />
                              <h6>Nothing to show here</h6>
                              <p className="small text-secondary">
                                Please add a new entity or manage the data table
                                to see the content here <br />
                                Thank you
                              </p>
                            </span>
                          </td>
                        </tr>
                      </tbody>
                    )}
                  </table>
                </div>
              </Tab.Pane>
              <Tab.Pane eventKey="total">
                <div className="table-responsive custom-scrollbar table-view-responsive py-primary">
                  <table className="table mb-0">
                    <thead>
                      <tr>
                      <th className="py-4 px-3 table_color ">Name</th>
                        <th className="py-4 px-3 table_color">Month</th>
                        <th className="py-4 px-3 table_color">Leave</th>
                        <th className="py-4 px-3 table_color text-right">PF</th>
                        <th className="py-4 px-3 table_color text-right">Other</th>  
                        <th className="py-4 px-3 table_color text-right">Create date</th>
                        <th className="py-4 px-3 table_color text-right">Salary</th>
                        <th className="py-4 px-3 table_color text-right">Net salary</th>
                        <th className="py-4 px-3 table_color text-right">Details</th>
                        <th className="py-4 px-3 table_color text-right">Email</th>
                      </tr>
                    </thead>
                    {listPayslip.length > 0 ? (
                      <tbody>
                        {listPayslip &&
                          listPayslip.map((item) => (
                            <tr key={item.id}>
                              <td>{item.name}</td>
                              <td>{formatDate(item.month)}</td>
                              <td>{item.leave}</td>
                              <td>{item.pf}</td>
                              <td>{item.custome_price}</td>
                              <td>{item.create_payslip}</td>
                              <td>{item.basic_sallery}</td>
                              <td>{item.pay_total}</td>
                              <td>
                                <button
                                  type="button"
                                  className="btn btn-secondary puch_btn" target="_blank"
                                  onClick={() =>
                                    downloadPdf(
                                      item.id,
                                      item.user_id,
                                      item.name,
                                      item.create_payslip
                                    )
                                  }
                                  disabled={loading && item.id === loadingId}
                                >
                                  {loading && item.id === loadingId ? (
                                    <>
                                      <Spinner
                                        animation="grow"
                                        size="sm"
                                        style={{ background: "#fff" }}
                                      />
                                      &nbsp;
                                      <Spinner
                                        animation="grow"
                                        size="sm"
                                        style={{ background: "#fff" }}
                                      />
                                    </>
                                  ) : (
                                    "View"
                                  )}
                                </button>
                              </td>
                              <td>
                                {loading1 && item.id === loadingId ? (
                                  <button
                                    className="btn btn-secondary puch_btn"
                                    type="button"
                                  >
                                    <Spinner
                                      animation="grow"
                                      size="sm"
                                      style={{ background: "#fff" }}
                                    />
                                    &nbsp;
                                    <Spinner
                                      animation="grow"
                                      size="sm"
                                      style={{ background: "#fff" }}
                                    />
                                  </button>
                                ) : (
                                  <button
                                    type="button"
                                    className="btn btn-secondary puch_btn"
                                    onClick={() =>
                                      sendEmail(item.user_id, item.id)
                                    }
                                  >
                                    Send
                                  </button>
                                )}
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    ) : (
                      <tbody>
                        <tr className="text-center">
                          <td colSpan="9">
                            <span className="pt-5 pb-5">
                              <img
                                src="/no_data.svg"
                                alt="no data"
                                width="150px"
                              />
                              <h6>Nothing to show here</h6>
                              <p className="small text-secondary">
                                Please add a new entity or manage the data table
                                to see the content here <br />
                                Thank you
                              </p>
                            </span>
                          </td>
                        </tr>
                      </tbody>
                    )}
                  </table>
                </div>
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>
        </div>
      </div>

      {/* ---------------------Export------------------------- */}

      <Modal show={show} onHide={handleClose} size="md" centered>
        <Modal.Body>
          <Form className="export_popup pt-5 pb-5">
            <HiOutlineDownload className="export_icon text-primary" />
            <h4>Export all payslip.</h4>
            <p>All payslip data will be export in .xlsx format.</p>
            <button
              className="btn btn-secondary puch_btn"
              onClick={handleClose}
              type="button"
            >
              Cancel
            </button>
            &nbsp; &nbsp;
            <button
              className="btn btn-primary puch_btn"
              onClick={handleExportExcel}
              disabled={loading}
            >
              Export
            </button>
          </Form>
        </Modal.Body>
      </Modal>
      {/*--------------add-payslip------------------------*/}

      <Modal show={addPayslipPopup} onHide={handleCloseAddpayslip} size="lg">
        <Modal.Header closeButton className="p-4  ">
          <Modal.Title>Add Payslip Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form noValidate validated={validated} onSubmit={handleAddSlip}>
            <div className="row">
          <Form.Group className="col-md-6">
              <Form.Label className="mt-3 editor_font">Employee</Form.Label>

              <Select
                options={data.map((item) => ({
                  value: item.id,
                  label: `${item.first_name} ${item.last_name}`,
                }))}
                styles={customStyles}
                placeholder="Select a Employee"
                onChange={handleEmployeeChange}
                className="text-uppercase search_select_css"
                required
              />
             </Form.Group>
             <Form.Group className="col-md-6">
 <Form.Label className="mt-3 editor_font">Month</Form.Label>
              <Form.Control
                type="month"
                placeholder=""
                maxLength={3}
                value={addPayslip.month}
                onChange={(e) =>
                  setAddPayslip({ ...addPayslip, month: e.target.value })
                }
                required
              />
              </Form.Group>
              </div>
              <div className="row">
              <Form.Group className="col-md-6">
               <Form.Label className="mt-3 editor_font">Total salary</Form.Label>
              <Form.Control
                type="number"
                placeholder="Enter basic salery"
                value={addPayslip.basic_sallery}
                onChange={(e) =>
                  setAddPayslip({
                    ...addPayslip,
                    basic_sallery: e.target.value,
                  })
                }
                required
              />
               </Form.Group>
               <Form.Group className="col-md-6">
               <Form.Label className="mt-3 editor_font">Basic salary</Form.Label>
              <Form.Control
                type="number"
                placeholder="Enter basic salery"
                value={addPayslip.basic_sallery}
                onChange={(e) =>
                  setAddPayslip({
                    ...addPayslip,
                    basic_sallery: e.target.value,
                  })
                }
                required
              />
               </Form.Group>
               </div>
              <div className="row">
               <Form.Group className="col-md-6">
               <Form.Label className="mt-3 editor_font">
                Deductions(Leave)
              </Form.Label>
              <Form.Control
                type="number"
                placeholder="Enter deductions(leave)"
                value={addPayslip.leave}
                onChange={(e) =>
                  setAddPayslip({ ...addPayslip, leave: e.target.value })
                }
                required
              />
               </Form.Group>
               <Form.Group className="col-md-6">
                <Form.Label className="mt-3 editor_font">
                Provident Fund(PF)
              </Form.Label>
              <Form.Control
                type="number"
                placeholder="Enter pf deductions"
                value={addPayslip.pf}
                onChange={(e) =>
                  setAddPayslip({ ...addPayslip, pf: e.target.value })
                }
                required
              />
               </Form.Group>
               </div>
              <div className="row">
               <Form.Group className="col-md-6">
                <Form.Label className="mt-3 editor_font">
                ESIC Deductions
              </Form.Label>
              <Form.Control
                type="number"
                placeholder="Enter pf deductions"
                value={addPayslip.pf}
                onChange={(e) =>
                  setAddPayslip({ ...addPayslip, pf: e.target.value })
                }
                required
              />
               </Form.Group>
               <Form.Group className="col-md-6">
                  <Form.Label className="mt-3 editor_font">
                Tax Deducted at Source(TDS)
              </Form.Label>
              <Form.Control
                type="number"
                placeholder="Enter pf deductions"
                value={addPayslip.pf}
                onChange={(e) =>
                  setAddPayslip({ ...addPayslip, pf: e.target.value })
                }
                required
              />
               </Form.Group>
               </div>
              <div className="row mb-2">
               <Form.Group className="col-md-6">
                  <Form.Label className="mt-3 editor_font">
                  Advance Payment 
              </Form.Label>
              <Form.Control
                type="number"
                placeholder="Enter pf deductions"
                value={addPayslip.pf}
                onChange={(e) =>
                  setAddPayslip({ ...addPayslip, pf: e.target.value })
                }
                required
              />
               </Form.Group>
               <Form.Group className="col-md-6">
              <Form.Label className="mt-3 editor_font">
                Deductions(Other)
              </Form.Label>
              <Form.Control
                type="number"
                placeholder="Enter deductions(other)"
                value={addPayslip.custome_price}
                onChange={(e) =>
                  setAddPayslip({
                    ...addPayslip,
                    custome_price: e.target.value,
                  })
                }
                required
              />
             
            
             
             
            </Form.Group>
            </div>
            {addPayslip.error && (
              <Alert variant="danger">{addPayslip.error}</Alert>
            )}

            <Modal.Footer>
              <button
                className="btn btn-secondary puch_btn"
                onClick={handleCloseAddpayslip}
                type="button"
              >
                Cancel
              </button>
              {loading ? (
                <button className="btn btn-primary puch_btn" disabled>
                  <Spinner
                    animation="grow"
                    size="sm"
                    style={{ background: "#fff" }}
                  />
                  &nbsp;
                  <Spinner
                    animation="grow"
                    size="sm"
                    style={{ background: "#fff" }}
                  />
                </button>
              ) : (
                <button className="btn btn-primary puch_btn" type="submit">
                  Save
                </button>
              )}
            </Modal.Footer>
          </Form>
        </Modal.Body>
      </Modal>

      {/* ----------------Message popup ----------------*/}
      <Modal
        show={accept}
        onHide={handleAcceptClose}
        size="md"
        centered
        className="pt-5 pb-5"
      >
        <Modal.Body>
          <div className="text-center mt-5">
            <h5>Payslip send successfully</h5>
          </div>
          <Modal.Footer className="border-0 justify-content-center  mb-4">
            <button
              className="btn btn-success puch_btn"
              onClick={handleAcceptClose}
            >
              Okay
            </button>
          </Modal.Footer>
        </Modal.Body>
      </Modal>
    </section>
  );
};

export default Payslip;
