import React, { createContext, useState } from 'react'

const AuthContext = createContext({});

const AuthProvider = (props) => {

    const [isLoggedIn, setIsLoggedIn] = useState(false)
    const [loggedIn, setLoggedIn] = useState(false)
    const [role_id, setRole_id] = useState("")
    const [id, setId] = useState("")
    const [first_name, setFirst_name] = useState("")
    const [role_name, setRole_name] = useState("")
    const [employee_id, setEmployee_id] = useState("")
    const [photo, setPhoto] = useState("")
    const [last_name, setLast_name] = useState("")
    const authContextValue = {
        isLoggedIn, setIsLoggedIn,
        id, setId,
        loggedIn, setLoggedIn,
        role_id, setRole_id,
        employee_id, setEmployee_id,
        first_name, setFirst_name,
        role_name, setRole_name,
        photo, setPhoto,
        last_name, setLast_name
    }
    return <AuthContext.Provider value={authContextValue} {...props} />
}
const useAuth = () => React.useContext(AuthContext)
export {
    AuthProvider, useAuth
}