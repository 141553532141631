import React, { useCallback, useEffect, useState } from "react";
import { Alert, Spinner } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import { BsDownload } from "react-icons/bs";
import { FaEdit } from "react-icons/fa";
import { RiDeleteBin5Line } from "react-icons/ri";

const Departments = ({ menuWidth }) => {
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const [deleted, setDeleted] = useState({
    department_id: "",
  });
  const [accept, setAccept] = useState(false);

  const handleAccept = (department_id) => {
    setDeleted({ department_id });
    setAccept(true);
  };
  const handleAcceptClose = () => setAccept(false);
  const handleClose = () => {
    setShow(false);
    setValidated(false)
    setLoading(false)
    setError(false)
    setAddDepartment({
      department_id:"",
    name: "",
  });
};

  const [showEdit, setShowEdit] = useState(false);
  const handleCloseEdit = () => setShowEdit(false);
  const handleShowEdit = () =>   setShowEdit(true);
  
  const [validated, setValidated] = useState(false);
  const [loading, setLoading] = useState(false);
  //eslint-disable
  const [error, setError] = useState("");
  const [addDepartment, setAddDepartment] = useState({
    name: "",
  });

  const handleAddDepartment = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    setValidated(true);
    var formdata = new FormData();
    formdata.append("name", addDepartment.name);

    var requestOptions = {
      method: "POST",
      body: formdata,
      redirect: "follow",
    };

    fetch(
      `${process.env.REACT_APP_API_BASE_URL}/admin/new_department`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status === true) {
          setAddDepartment(result.data);
          fetchData();
        }
        else {
          setAddDepartment({ ...addDepartment, error: result.message });
        }
      })
      .catch((error) => console.log("error", error));
  };

  const [getDepartment, setGetDepartment] = useState([]);

  const fetchData = useCallback(() => {
    setLoading(true);
    var requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    fetch(
      `${process.env.REACT_APP_API_BASE_URL}/admin/get_departments`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status === true) {
          setGetDepartment(result.data);
          handleClose();
        }
        setLoading(false);
      })
      .catch((error) => console.log("error", error));
  },[])

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const [editDepartment, setEditDepartment] = useState();

  const [updateDepartment, setUpdateDepartment] = useState({
    department_id:"",
    name: "",
  });



  const fetchData1 = useCallback(() => {
    if(editDepartment) {
      const requestOptions = {
        method: "GET",
        redirect: "follow"
      };
      
      fetch(`${process.env.REACT_APP_API_BASE_URL}/admin/find_department?department_id=${editDepartment}`, requestOptions)
           .then((response) => response.json())
           .then((result) => {
             if (result.status === true) {
               setUpdateDepartment(result.data);
             }
           })
           .catch((error) => console.log("error", error));
          }
  }, [editDepartment]);

  useEffect(() => {
    fetchData1();
  }, [fetchData1]);

  const handleUpdateDepartment = () => {
    var formdata = new FormData();
    formdata.append("department_id", editDepartment);
    formdata.append("name", updateDepartment.name);

    var requestOptions = {
      method: "POST",
      body: formdata,
      redirect: "follow",
    };

    fetch(
      `${process.env.REACT_APP_API_BASE_URL}/admin/edit_department_detail`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status === true) {
          setUpdateDepartment(
            { name: "" }
            );
          fetchData();
          setValidated(false);
          setLoading(false);
          handleCloseEdit();
        }
        setError(result.message);
      })
      .catch((error) => console.log("error", error));
  };



  const handleDelete = () => {
    const formdata = new FormData();
    formdata.append("department_id", deleted.department_id);

    const requestOptions = {
      method: "POST",
      body: formdata,
      redirect: "follow",
    };

    fetch(
      `${process.env.REACT_APP_API_BASE_URL}/admin/remove_department`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status === true) {
          setDeleted(result.data);
          fetchData();
          handleAcceptClose();
        }
      })
      .catch((error) => console.error(error));
  };

  const handleEditDesignationopen = (editDepartmentId) => {
    setEditDepartment(editDepartmentId); 
    handleShowEdit()
  };
  return (
    <section className="left_width" style={{ marginLeft: `${menuWidth}px` }}>
      <div className="d-flex justify-content-between align-items-center pt-4 mb-3 w-100">
        <h4 className="m-0">Departments</h4>
        <div>
          <button className="btn btn-primary puch_btn" onClick={handleShow}>
            Add Departments
          </button>
        </div>
      </div>
      <div className="employee_table p-4 text-center">
        <div>
          <div className="table-responsive custom-scrollbar table-view-responsive py-primary">
            {!loading ? (
              <table className="table mb-0">
                <thead>
                  <tr>
                 
                    <th
                      track-by="0"
                      className="datatable-th py-4 px-3 table_color "
                    >
                      <span className="font-size-default">
                        <span>Id</span>
                      </span>
                    </th>
                    <th
                      track-by="0"
                      className="datatable-th py-4 px-3 table_color "
                    >
                      <span className="font-size-default">
                        <span>Name</span>
                      </span>
                    </th>
                   
                    <th
                      track-by="3"
                      className="datatable-th py-4 px-3 table_color text-right"
                    >
                      <span className="font-size-default">Status</span>
                    </th>
                   
                    <th
                      track-by="3"
                      className="datatable-th py-4 px-3 table_color text-right"
                    >
                      <span className="font-size-default">Created</span>
                    </th>
                    <th
                      track-by="3"
                      className="datatable-th py-4 px-3 table_color text-right"
                    >
                      <span className="font-size-default">Actions</span>
                    </th>
                  </tr>
                </thead>

                {getDepartment.length > 0 ? (
                  <tbody>
                    {getDepartment &&
                      getDepartment.map((item) => (
                        <tr key={item.id}>
                           <td className="text-center">{item.sr_no}</td>
                          <td className="text-center">{item.name}</td>

                          <td className="text-center">
                            {item.status === "Active" ? (
                              <span className="btn btn-primary py-0 rounded-pill small">
                                {item.status}
                              </span>
                            ) : (
                              <span className="btn btn-danger py-0 rounded-pill small">
                                {item.status}
                              </span>
                            )}
                          </td>
                          <td className="text-center">{item.created}</td>
                          <td className="text-center">
                          <span className="hover_btn">
                            <FaEdit onClick={() => handleEditDesignationopen(item.id)} className="left_icon_css" />
                            </span>
                            &nbsp; &nbsp;
                                <span className="hover_btn">
                                  <RiDeleteBin5Line
                                    onClick={() =>
                                      handleAccept(item.id)
                                    }
                                    className="left_icon_css text-danger"
                                  />
                                </span>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                ) : (
                  <tbody>
                    <tr className="text-center">
                      <td colSpan="9">
                        <span className="pt-5 pb-5">
                          <img src="/no_data.svg" alt="no data" width="150px" />
                          <h6>Nothing to show here</h6>
                          <p className="small text-secondary">
                            Please add a new entity or manage the data table to
                            see the content here <br />
                            Thank you
                          </p>
                        </span>
                      </td>
                    </tr>
                  </tbody>
                )}
              </table>
            ) : (
              <div className="spinner_height">
                <div className="loader"></div>
              </div>
            )}
          </div>
        </div>
      </div>

      {/* -------------------Add --------------------------- */}

      <Modal show={show} onHide={handleClose} size="md">
        <Modal.Header closeButton>
          <Modal.Title>Add Departments</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form noValidate validated={validated} onSubmit={handleAddDepartment}>
            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlTextarea1"
            >
              <Form.Label>Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Name"
                value={addDepartment.name}
                onChange={(e) =>
                  setAddDepartment({ ...addDepartment, name: e.target.value })
                }
                required
              />
            </Form.Group>
            
            {/* {error && <Alert variant="danger">{error}</Alert>} */}
            {addDepartment.error && (
              <Alert variant="danger">{addDepartment.error}</Alert>
            )}
            <Modal.Footer>
              <button
                className="btn btn-secondary puch_btn"
                onClick={handleClose}
                type="button"
              >
                Cancel
              </button>
              {loading ? 
               <button className="btn btn-primary puch_btn" type="button">
               <Spinner animation="grow" size="sm" style={{ background: "#fff" }} />
               &nbsp;
               <Spinner animation="grow" size="sm" style={{ background: "#fff" }} />
             
             </button>
             :
              <button className="btn btn-primary puch_btn" type="submit">
                Save
              </button>
}
            </Modal.Footer>
          </Form>
        </Modal.Body>
      </Modal>
      
      {/* ------------------edit--------------------------- */}

      <Modal show={showEdit} onHide={handleCloseEdit} size="md">
        <Modal.Header closeButton>
          <Modal.Title>Edit Departments</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleUpdateDepartment}>
            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlTextarea1"
            >
              <Form.Label>Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Name"
                value={updateDepartment.name}
                onChange={(e) =>
                  setUpdateDepartment({ ...updateDepartment, name: e.target.value })
                }
              
              />
            </Form.Group>
            
            {/* {error && <Alert variant="danger">{error}</Alert>} */}
            <Modal.Footer>
              <button
                className="btn btn-secondary puch_btn"
                onClick={handleCloseEdit}
                type="button"
              >
                Cancel
              </button>
              <button className="btn btn-primary puch_btn" type="submit">
                Save
              </button>
            </Modal.Footer>
          </Form>
        </Modal.Body>
      </Modal>



       {/* ----------------delete popup ----------------*/}
       <Modal
        show={accept}
        onHide={handleAcceptClose}
        size="md"
        centered
        className="pt-5 pb-5"
      >
        <Modal.Body>
          <div className="text-center mt-5">
            <BsDownload className="jobdisk_icon" />
            <h5>Are you sure?</h5>
            <p>You are going to delete this department</p>
          </div>
          <Modal.Footer className="border-0 justify-content-center  mb-4">
            <button
              className="btn btn-secondary puch_btn"
              onClick={handleAcceptClose}
            >
              No
            </button>

            <button
              className="btn btn_color puch_btn"
              onClick={() => handleDelete(deleted.holiday_id)}
            >
              Yes
            </button>
          </Modal.Footer>
        </Modal.Body>
      </Modal>
    </section>
  );
};

export default Departments;
